import moment from "moment";

import { validateRequiredFieldsSkillsFeedback, validateRequiredFieldsCandidateRate } from 'utils/formValidations';

import FeedbackDialogWrapper from "components/Shared/FeedbackDialog";
import { CANDIDATE_REJECTION_REASONS_ARRAY, CANDIDATE_RATING_STATUSES_ARRAY, CANDIDATE_RATING_STATUSES } from "constants/candidateConstants";
import { DATE_FORMAT_WITH_DOTS } from "constants/commonConstants";

export const CHECKLIST_STEPS_KEYS = {
    SOFT_SKILLS_STEP: 'softSkillsDialog',
    HARD_SKILLS_STEP: 'hardSkillsDialog',
    CANDIDATE_RATED_STEP: 'candidateRatedDialog',
};

export const HIRING_CHECKLIST_TITLES = {
    SOFT_SKILLS_VETTED: 'Soft Skills Vetted',
    HARD_SKILLS_VETTED: 'Hard Skills Vetted',
    CANDIDATE_RATED: 'Candidate Rated',
    PROFILE_SENT_TO_CLIENT: 'Profile Sent to Client',
    APPLICATIONS_UPDATED: 'Applications Updated',
};

export const CHECKLIST_TITLE_TO_PROPERTY_MAP = {
    [HIRING_CHECKLIST_TITLES.SOFT_SKILLS_VETTED]: 'softSkillsStep',
    [HIRING_CHECKLIST_TITLES.HARD_SKILLS_VETTED]: 'hardSkillsStep',
    [HIRING_CHECKLIST_TITLES.CANDIDATE_RATED]: 'sendFeedbackToCandidateStep',
    [HIRING_CHECKLIST_TITLES.PROFILE_SENT_TO_CLIENT]: 'sentProfileToClientStep',
    [HIRING_CHECKLIST_TITLES.APPLICATIONS_UPDATED]: 'updateApplicationStatusStep',
};

export const CHECKLIST_TITLE_TO_DIALOG_MAP = {
    [HIRING_CHECKLIST_TITLES.SOFT_SKILLS_VETTED]: CHECKLIST_STEPS_KEYS.SOFT_SKILLS_STEP,
    [HIRING_CHECKLIST_TITLES.HARD_SKILLS_VETTED]: CHECKLIST_STEPS_KEYS.HARD_SKILLS_STEP,
    [HIRING_CHECKLIST_TITLES.CANDIDATE_RATED]: CHECKLIST_STEPS_KEYS.CANDIDATE_RATED_STEP,
};

export const HIRING_CHECKLIST_STEPS_ORDER = [
    'softSkillsStep',
    'hardSkillsStep',
    'sendFeedbackToCandidateStep',
    'sentProfileToClientStep',
    'updateApplicationStatusStep'
];

export const DIALOG_KEYS_TO_TITLES_MAP = (isEditMode) => ({
    softSkillsDialog: `${isEditMode ? 'Edit' : 'Add'} Soft Skills Feedback`,
    hardSkillsDialog: `${isEditMode ? 'Edit' : 'Add'} Hard Skills Feedback`,
    candidateRatedDialog: 'Rate Candidate',
});

export const DIALOG_INFO_SECTION_CONTENT = {
    softSkillsDialog: 'Write feedback from the candidate video or recruitment interview. Focus on things such as communication, language, personality.',
    hardSkillsDialog: 'Write feedback from the candidate hard skills vetting. Focus on things such as tech stack, tools, known processes and concepts.',
    candidateRatedDialog: 'The feedback you provide in the following field will be sent to the candidate. Please provide constructive and comprehensive comments.',
};

const CANDIDATE_MISSING = 'This candidate didn\'t show up for the interview';

const SOFT_HARD_SKILLS_NOT_COMPLETED_MESSAGE = 'This candidate hasn\'t completed soft and hard skills vetting. Please do that first.';

export const STEP_VALIDATION_FUNCTION = {
    softSkillsDialog: (values) => validateRequiredFieldsSkillsFeedback(values),
    hardSkillsDialog: (values) => validateRequiredFieldsSkillsFeedback(values),
    candidateRatedDialog: (values) => validateRequiredFieldsCandidateRate(values),
};

export const CHECKLIST_DIALOGS_MAP = {
    softSkillsDialog: (props) => (
        <FeedbackDialogWrapper {...props}>
            <FeedbackDialogWrapper.FeedbackDialogInfoSection content={DIALOG_INFO_SECTION_CONTENT[props.dialogKey]} />
            <FeedbackDialogWrapper.CheckboxItem name='candidateMissing' label='Candidate did not show up for interview' size='medium' />
            <FeedbackDialogWrapper.FeedbackDescriptionSection id='description' name='description' label='Description' />
            <FeedbackDialogWrapper.RatingCriteriaWrapper>
                <FeedbackDialogWrapper.RatingCriteriaItem id='languages' name='languages' title='Languages' />
                <FeedbackDialogWrapper.RatingCriteriaItem id='communication' name='communication' title='Communication' />
                <FeedbackDialogWrapper.RatingCriteriaItem id='personality' name='personality' title='Personality' />
            </FeedbackDialogWrapper.RatingCriteriaWrapper>
        </FeedbackDialogWrapper>
    ),
    hardSkillsDialog: (props) => (
        <FeedbackDialogWrapper {...props}>
            <FeedbackDialogWrapper.FeedbackDialogInfoSection content={DIALOG_INFO_SECTION_CONTENT[props.dialogKey]} />
            <FeedbackDialogWrapper.CheckboxItem name='candidateMissing' label='Candidate did not show up for interview' size='medium' />
            <FeedbackDialogWrapper.FeedbackDescriptionSection id='description' name='description' label='Description' />
            <FeedbackDialogWrapper.RatingCriteriaWrapper>
                <FeedbackDialogWrapper.RatingCriteriaItem id='hardSkillsAssessment' name='hardSkillsAssessment' title='Hard Skills Assessment' />
            </FeedbackDialogWrapper.RatingCriteriaWrapper>
        </FeedbackDialogWrapper>
    ),
    candidateRatedDialog: (props) => (
        <FeedbackDialogWrapper {...props}>
            {!props.initialValues.isSoftSkillsStepCompleted || !props.initialValues.isHardSkillsStepCompleted
                ?
                <FeedbackDialogWrapper.FeedbackDialogInfoSection content={SOFT_HARD_SKILLS_NOT_COMPLETED_MESSAGE} classes={['text-red']} />
                :
                <>
                    <FeedbackDialogWrapper.RatingDetailsWrapper
                        type={"Soft Skills"}
                        tooltipContent={`${props.initialValues.softSkillsReviewer} ${moment(props.initialValues.softSkillsRateDate).format(DATE_FORMAT_WITH_DOTS)}`}
                        id='softSkillsAverageScore'
                        name='softSkillsAverageScore'
                        candidateMissingMessage={props.initialValues.softSkillsCandidateMissing ? CANDIDATE_MISSING : null}
                        description={props.initialValues.softSkillsDescription}
                    />
                    <FeedbackDialogWrapper.RatingDetailsWrapper
                        type={"Hard Skills"}
                        tooltipContent={`${props.initialValues.hardSkillsReviewer} ${moment(props.initialValues.hardSkillsRateDate).format(DATE_FORMAT_WITH_DOTS)}`}
                        id='hardSkillsAverageScore'
                        name='hardSkillsAverageScore'
                        candidateMissingMessage={props.initialValues.hardSkillsCandidateMissing ? CANDIDATE_MISSING : null}
                        description={props.initialValues.hardSkillsDescription}
                    />
                    <FeedbackDialogWrapper.DropdownSelect
                        label={"Status"}
                        name="status"
                        selectOptions={CANDIDATE_RATING_STATUSES_ARRAY}
                    />
                    <FeedbackDialogWrapper.DropdownSelect
                        label={"Rejection reason"}
                        name="rejectionReason"
                        selectOptions={CANDIDATE_REJECTION_REASONS_ARRAY}
                        visibilityDependencyField="status"
                        visibilityDependencyValue={CANDIDATE_RATING_STATUSES.REJECTED}
                    />
                    <FeedbackDialogWrapper.FeedbackDescriptionSection id='internalFeedback' name='internalFeedback' label='Internal Feedback (Private)' />
                    <FeedbackDialogWrapper.FeedbackDialogInfoSection content={DIALOG_INFO_SECTION_CONTENT[props.dialogKey]} classes={['text-red']} />
                    <FeedbackDialogWrapper.FeedbackDescriptionSection id='summary' name='summary' label='Feedback send to Candidate' />
                </>
            }
        </FeedbackDialogWrapper>
    ),
};
