import React, { useState } from 'react';

import { TOOLTIP_STATUS } from './JobCandidatesStatusBoxConstants';

import './JobCandidatesStatusBox.scss';

const JobCandidatesStatusBox = ({
    count,
    classType,
}) => {
    const [isShowMorePopupVisible, setIsShowMorePopupVisible] = useState(false);
    const [popupPosition, setPopupPosition] = useState({});

    const handleTooltipHover = (e, visibility) => {
        if (e) {
            const boundingRect = e.target.getBoundingClientRect();
            const leftOffset = boundingRect.left;
            const topOffset = boundingRect.top;

            let xOffset = window.innerWidth - (leftOffset + 16);
            const yOffset = window.innerHeight - (topOffset + 100);
            xOffset = xOffset > 0 ? 0 : xOffset;

            let yTranslate = 0;
            const xTranslate = e.target.offsetLeft - 100 + xOffset;

            if (yOffset < 0) {
                yTranslate = -120;
            }

            setPopupPosition({ transform: `translate(${xTranslate}px, ${yTranslate}px)` });
        }
        setIsShowMorePopupVisible(visibility);
    };

    return (
        <>
            <span
                className={`candidates-status-count ${classType}`}
                onMouseOver={(e) => handleTooltipHover(e, true)}
                onMouseLeave={() => handleTooltipHover(false)}
            >{count}</span>

            {isShowMorePopupVisible && (
                <div className={`jobs-candidates-status-tooltip ${isShowMorePopupVisible ? 'show' : 'hide'}`} style={popupPosition}>
                    <span className={`status-type-indication-box  ${classType}-indicator`}></span> {`${count} ${TOOLTIP_STATUS[classType](count)}`}
                </div>
            )}

        </>
    );
}

export default JobCandidatesStatusBox;
