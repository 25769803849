import GuestLayout from 'layouts/Guest';
import ProdGuestLayout from 'layouts/ProdGuestLayout';

import preval from 'preval.macro';
import MotionSoftwareLogo from 'assets/logo-motion-software.png';
import MotionSoftwareLogoWhite from 'assets/motion-logo-white.svg';
import MotionSoftwareLoginBackground from 'assets/mountain-no-border.png';
import WhoIsAvailableLogo from 'assets/logo.svg';
import WhoIsAvailableLogoWhite from 'assets/logo-white.svg';
import WhoIsAvailableLoginBackground from 'assets/login-background-WIA.svg';

const SERVER_ADDRESSES = {
    test: 'http://localhost:5000',
    development: 'http://localhost:5000',
    qa: 'https://apiqa.wiasaas.com',
    production: 'https://api.wiasaas.com',
    whoisavailable: 'https://server.wiasaas.com',
    staging: 'https://api.wiastaging.motiontest.eu',
};

const APP_ADDRESS = {
    test: 'http://localhost:3000',
    development: 'http://localhost:3000',
    qa: 'https://wiaqa.motiontest.eu',
    production: 'https://whoisavailable.motion-software.com',
    whoisavailable: 'https://app.wiasaas.com',
    staging: 'https://wiastaging.motiontest.eu',
};

const FIREBASE_CONFIGS = {
    test: {
        apiKey: 'AIzaSyBNsCB1Pdd1ESpRi-cBN7i21dgPuaU7bO4',
        authDomain: 'who-is-available-qa.firebaseapp.com',
        databaseURL: 'https://who-is-available-qa.firebaseio.com',
        projectId: 'who-is-available-qa',
        storageBucket: 'who-is-available-qa.appspot.com',
        messagingSenderId: '913904170245',
        appId: '1:913904170245:web:8a35101b8cd6d708f9736c'
    },
    development: {
        apiKey: "AIzaSyA28_cs8fwlZU_2ZDF5jtAGi-NvWcEn26k",
        authDomain: "who-is-available-dev-71d21.firebaseapp.com",
        databaseURL: "https://who-is-available-dev-71d21-default-rtdb.firebaseio.com",
        projectId: "who-is-available-dev-71d21",
        storageBucket: "who-is-available-dev-71d21.appspot.com",
        messagingSenderId: "649056666640",
        appId: "1:649056666640:web:522e7ed69d6157002c7695"
    },
    qa: {
        apiKey: 'AIzaSyBNsCB1Pdd1ESpRi-cBN7i21dgPuaU7bO4',
        authDomain: 'who-is-available-qa.firebaseapp.com',
        databaseURL: 'https://who-is-available-qa.firebaseio.com',
        projectId: 'who-is-available-qa',
        storageBucket: 'who-is-available-qa.appspot.com',
        messagingSenderId: '913904170245',
        appId: '1:913904170245:web:8a35101b8cd6d708f9736c'
    },
    production: {
        apiKey: 'AIzaSyBeeGcHEWVCYGf7Y7sDdXTTWaDZNh9ggqI',
        authDomain: 'who-is-available-production.firebaseapp.com',
        databaseURL: 'https://who-is-available-production.firebaseio.com',
        projectId: 'who-is-available-production',
        storageBucket: 'who-is-available-production.appspot.com',
        messagingSenderId: '866015689857',
        appId: '1:866015689857:web:aea47acafd6629c3856180',
        measurementId: 'G-GZC8BXWP14'
    },
    whoisavailable: {
        apiKey: "AIzaSyB0ccN0VMjk49u8ulcErvg1Zs6x4stC__4",
        authDomain: "who-is-available-softuni.firebaseapp.com",
        projectId: "who-is-available-softuni",
        storageBucket: "who-is-available-softuni.appspot.com",
        messagingSenderId: "995136417364",
        appId: "1:995136417364:web:33b0e7074d22caf55e87c1"
    },
    staging: {
        apiKey: "AIzaSyDtd3BiyZTcQzpV244Oh6uYVVchPDXfol8",
        authDomain: "who-is-available-staging.firebaseapp.com",
        projectId: "who-is-available-staging",
        storageBucket: "who-is-available-staging.appspot.com",
        messagingSenderId: "908583761291",
        appId: "1:908583761291:web:38fbea1dbfa2814f33eb8e"
    },
};

const AMPLITUDE_CONFIGS = {
    test: {
        API_KEY: '8e9cf650256af3bf6b80c9c90db53bcc',
        OPTIONS: {
            includeUtm: true,
            includeReferrer: true,
        }
    },
    development: {
        API_KEY: '8e9cf650256af3bf6b80c9c90db53bcc',
        OPTIONS: {
            includeUtm: true,
            includeReferrer: true,
        }
    },
    qa: {
        API_KEY: '8e9cf650256af3bf6b80c9c90db53bcc',
        OPTIONS: {
            includeUtm: true,
            includeReferrer: true,
        }
    },
    production: {
        API_KEY: '56995abf4d48cc0559b4e4c1acf7edd5',
        OPTIONS: {
            includeUtm: true,
            includeReferrer: true,
        }
    },
    whoisavailable: {
        API_KEY: 'cbcf47f1ec55d17d25ffea2d7c5e0928',
        OPTIONS: {
            includeUtm: true,
            includeReferrer: true,
        }
    },
    staging: {
        API_KEY: '8e9cf650256af3bf6b80c9c90db53bcc',
        OPTIONS: {
            includeUtm: true,
            includeReferrer: true,
        }
    },
};

const GOOGLE_ANALYTICS_TRACKING_IDS = {
    development: 'UA-214739466-1',
    qa: 'UA-214739466-1',
    // production: 'GTM-T3SJBHQ',
    production: 'GTM-KK95MKB',
    whoisavailable: 'UA-212778543-1',
    staging: 'UA-214739466-1',
};

const APP_TRADEMARKS = {
    test: { LOGO: MotionSoftwareLogo, LOGO_WHITE: MotionSoftwareLogoWhite, TITLE: 'Motion Software', LOGO_HREF: 'https://motion-software.com/' },
    development: { LOGO: MotionSoftwareLogo, LOGO_WHITE: MotionSoftwareLogoWhite, TITLE: 'Motion Software', LOGO_HREF: 'https://motion-software.com/' },
    qa: { LOGO: MotionSoftwareLogo, LOGO_WHITE: MotionSoftwareLogoWhite, TITLE: 'Motion Software', LOGO_HREF: 'https://motion-software.com/' },
    production: { LOGO: MotionSoftwareLogo, LOGO_WHITE: MotionSoftwareLogoWhite, TITLE: 'Motion Software', LOGO_HREF: 'https://motion-software.com/' },
    whoisavailable: { LOGO: WhoIsAvailableLogo, LOGO_WHITE: WhoIsAvailableLogoWhite, TITLE: 'WhoIsAvailable', LOGO_HREF: 'https://wiasaas.com/' },
    staging: { LOGO: MotionSoftwareLogo, LOGO_WHITE: MotionSoftwareLogoWhite, TITLE: 'Motion Software', LOGO_HREF: 'https://motion-software.com/' },
};

const APP_LOGIN_BACKGROUNDS = {
    test: MotionSoftwareLoginBackground,
    development: MotionSoftwareLoginBackground,
    qa: MotionSoftwareLoginBackground,
    production: MotionSoftwareLoginBackground,
    whoisavailable: WhoIsAvailableLoginBackground,
    staging: MotionSoftwareLoginBackground,
};

const RECIPIENT_EMAILS = {
    development: 'finance@dispostable.com',
    qa: 'finance@dispostable.com',
    production: 'finance@motion-software.com',
    whoisavailable: 'finance@dispostable.com',
    staging: 'finance@dispostable.com',
};

export const ENVIRONMENTS = {
    DEVELOPMENT: 'development',
    QA: 'qa',
    PRODUCTION: 'production',
    WHOISAVAILABLE: 'whoisavailable',
    STAGING: 'staging',
};

const CANDIDATE_SIGN_UP_LAYOUTS = {
    development: ProdGuestLayout,
    qa: ProdGuestLayout,
    production: ProdGuestLayout,
    whoisavailable: GuestLayout,
    staging: ProdGuestLayout,
}

const DEFAULT_AGENCY_IDS = {
    development: '616e7d134ff6dd0f7443d8d9',
    qa: '616e7d134ff6dd0f7443d8d9',
    production: '5f4e185c05fbb87bd8020574',
    softuni: '60a28d6d6d0d111208a2a1b4',
    staging: '6103d82bc0d3252c5ca39b39',
    unit_testing: '616e7d134ff6dd0f7443d8d9',
};

export const SENTRY_DSN = 'https://5d47cb91fc684e7ba92e0db79dd12a5b@o4505074789777408.ingest.sentry.io/4505074963251200';

export const IS_WHOISAVAILABLE_ENV = process.env.REACT_APP_ENV === ENVIRONMENTS.WHOISAVAILABLE || process.env.NODE_ENV === ENVIRONMENTS.WHOISAVAILABLE;

export const MOTION_SOFTWARE_AGENCY_NAME = 'Motion Software';
export const MOTION_SOFTWARE_WEBSITE_URL = 'https://motion-software.com';
export const APP_VERSION = preval`module.exports = process.env.REACT_APP_VERSION + '.' + Math.trunc(Date.now() / 1000);`;
export const BASE_URL = SERVER_ADDRESSES[process.env.REACT_APP_ENV || process.env.NODE_ENV];
export const BASE_APP_URL = APP_ADDRESS[process.env.REACT_APP_ENV || process.env.NODE_ENV];
export const FIREBASE_CONFIG = FIREBASE_CONFIGS[process.env.REACT_APP_ENV || process.env.NODE_ENV]
export const AMPLITUDE_CONFIG = AMPLITUDE_CONFIGS[process.env.REACT_APP_ENV || process.env.NODE_ENV];
export const BASE_APP_TRADEMARK = APP_TRADEMARKS[process.env.REACT_APP_ENV || process.env.NODE_ENV];
export const RECIPIENT_EMAIL = RECIPIENT_EMAILS[process.env.REACT_APP_ENV || process.env.NODE_ENV];
export const APP_LOGIN_BACKGROUND = APP_LOGIN_BACKGROUNDS[process.env.REACT_APP_ENV || process.env.NODE_ENV];
export const GOOGLE_ANALYTICS_TRACKING_ID = GOOGLE_ANALYTICS_TRACKING_IDS[process.env.REACT_APP_ENV || process.env.NODE_ENV];
export const CANDIDATE_SIGN_UP_LAYOUT = CANDIDATE_SIGN_UP_LAYOUTS[process.env.REACT_APP_ENV || process.env.NODE_ENV];
export const DEFAULT_AGENCY_ID = DEFAULT_AGENCY_IDS[process.env.REACT_APP_ENV || process.env.NODE_ENV];
