import React from 'react';
import { connect } from 'react-redux';

import { Formik } from 'formik';

import { showNotification } from 'actions/notificationActions';
import { loginSuccess, logout } from 'actions/authActions';
import { createSSOLoggingRecord } from 'actions/loggingActions';

import { validateRequiredFields, validateEmailAddress } from 'utils/formValidations';

import LoginFormView from '../LoginForm';

const LoginFormik = ({
    blur,
    gotoUrl,
    signUpButtonLink,
    loginLoading,
    login,
    showNotification,
    loginSuccess,
    createSSOLoggingRecord,
    logout,
}) => (
    <Formik
        initialValues={{
            email: '',
            password: '',
            rememberMe: false,
        }}
        validate={values => {
            return { ...validateRequiredFields(values, ['email', 'password']), ...validateEmailAddress(values, 'email') };
        }}
        onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false);
            const { email, password, rememberMe } = values;
            login(email.toLowerCase().trim(), password.trim(), rememberMe);
        }}
    >
        {(props) => <LoginFormView
            blur={blur}
            gotoUrl={gotoUrl}
            signUpButtonLink={signUpButtonLink}
            loginLoading={loginLoading}
            login={login}
            showNotification={showNotification}
            loginSuccess={loginSuccess}
            createSSOLoggingRecord={createSSOLoggingRecord}
            logout={logout}
            {...props}
        />}
    </Formik>
);

const mapDispatchToProps = {
    showNotification,
    loginSuccess,
    createSSOLoggingRecord,
    logout,
};

export default connect(null, mapDispatchToProps)(LoginFormik);
