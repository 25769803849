import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import MatchingLinearProgress from 'components/JobPage/SharedComponents/MatchingLinearProgress';
import LocationLabel from 'components/Shared/LocationLabel';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';
import SecondaryButton from 'components/Shared/Buttons/SecondaryButton';

import InformationIcon from 'assets/applied-candidates-information-icon.svg';
import { ReactComponent as VettedIcon } from 'assets/feather-user-check-icon.svg';

import { formatStringAsClassName, getRoleBadgeText } from 'utils/helpers';
import { handleScoreTooltipHover, getStatusDotInformation } from './MatchingProfileUtils';

import { CANDIDATE_STATUSES, STATUSES_MAPPER, CANDIDATE_MATCHING_TOOLTIP_TITLE } from 'constants/candidateConstants';

const MatchingProfile = ({
    profile,
    handleNameClick,
    handleRateClick,
    handleSendToClientClick,
}) => {
    const [isShowMorePopupVisible, setIsShowMorePopupVisible] = useState(false);
    const [popupTransform, setPopupTransform] = useState({});
    const { dotTooltipTitle, dotClassName } = getStatusDotInformation(profile);

    return (
        <div className="matching-profile-wrapper">
            <div className="matching-profile-role-badge">
                <span>{getRoleBadgeText(profile)}</span>
            </div>

            <LocationLabel isMatch={profile.isMatchingLocation} location={profile.userLocation} />

            <div className="matching-profile-information">
                <div className="matching-profile-avatar-wrapper">
                    <Avatar
                        className="matching-profile-image"
                        alt="Profile Image"
                        src={profile?.profilePictureImgUrl}
                    />
                    <Tooltip title={dotTooltipTitle} placement='right-end' classes={{ tooltip: 'custom-tooltip' }}>
                        <div className={dotClassName} />
                    </Tooltip>
                </div>
                <div className="matching-profile-about-wrapper">
                    <div className="name-status-wrapper">
                        <Link
                            className="name-link"
                            to={handleNameClick(profile.role, profile._id, profile.supplierId || profile._id)}
                        >
                            <p className="name">{profile.name}</p>
                        </Link>
                        {profile.isVetted
                            ? <div className="status-wrapper">
                                <span className="status vetted">Vetted</span>
                                <VettedIcon />
                            </div>
                            :
                            <div className="status-wrapper">
                                <span className={`status${profile.status ? ` ${formatStringAsClassName(profile.status)}` : ''}`}>
                                    {profile.status === CANDIDATE_STATUSES.INCOMPLETE_PROFILE ? 'Incomplete' : STATUSES_MAPPER[profile.status]}
                                </span>
                            </div>
                        }
                    </div>
                    {profile.position && <p className="position">{profile.position}</p>}
                </div>
            </div>

            <div className="matching-profile-score-wrapper">
                <div className="matching-profile-matching-text-container">
                    <span className="matching-profile-matching-text">Matching Score</span>
                    <span
                        className="matching-profile-information-icon"
                        onMouseOver={(e) => handleScoreTooltipHover(e, true, setPopupTransform, setIsShowMorePopupVisible)}
                        onMouseLeave={() => handleScoreTooltipHover(null, false, setPopupTransform, setIsShowMorePopupVisible)}
                    >
                        <img src={InformationIcon} />
                    </span>

                    <div className={`matching-profile-matching-tooltip ${isShowMorePopupVisible ? 'show' : 'hide'}`} style={popupTransform}>
                        <h3 className="matching-profile-matching-tooltip-title">
                            {CANDIDATE_MATCHING_TOOLTIP_TITLE}
                        </h3>
                        {Object.values(profile.categoryWeights || {}).some(weight => weight !== 0) &&
                            <h3 className="matching-profile-matching-tooltip-title">This candidate has scored high in these categories</h3>
                        }
                        <MatchingLinearProgress categoryWeights={profile.categoryWeights} />
                    </div>

                </div>
                <div className="matching-profile-matching-number">
                    {profile.matchingScore} <small>/100</small>
                </div>
            </div>


            <div className="matching-profile-actions-container">
                {profile.isVetted
                    ? <PrimaryButton
                        className="send-profiles-button"
                        text="Send To Client"
                        handleClick={handleSendToClientClick.bind(null, profile)}
                    />
                    : <SecondaryButton
                        className="rate-button"
                        text={`Rate ${getRoleBadgeText(profile)}`}
                        handleClick={handleRateClick.bind(null, profile.role, profile._id, profile.supplierId || profile._id)}
                    />
                }
            </div>
        </div>
    )
};

export default MatchingProfile;
