import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { showNotification } from 'actions/notificationActions';

import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';
import InterviewInvitationCard from './InterviewInvitationCard';

import { INTERVIEW_INVITATION_MESSAGES } from 'constants/messageConstants';
import { NOTIFICATION_TYPES } from 'constants/notificationTypes';

import './InterviewInvitationTable.scss'

const InterviewInvitationTable = ({
    agencyId,
    interviewInvitations,
    updateInterviewDate,
    showNotification,
}) => {
    const handleSubmit = (interviewId, timeSlot) => {
        if (Object.keys(timeSlot).length > 0) {
            const date = moment.utc(timeSlot.time);

            date.set({
                date: moment(timeSlot.date).date(),
                month: moment(timeSlot.date).month(),
                year: moment(timeSlot.date).year(),
            });

            const isDateInPast = moment.utc().isAfter(date);
            if (isDateInPast) {
                showNotification(INTERVIEW_INVITATION_MESSAGES.INTERVIEW_DATE_IN_PAST, NOTIFICATION_TYPES.ERROR);
            } else {
                updateInterviewDate(agencyId, interviewId, timeSlot);
            }
        }
    };

    if (!interviewInvitations || !interviewInvitations.length) {
        return null;
    }

    return (
        <div className="interview-invitation-table-wrapper">
            <div>
                <SectionActionsWrapper>
                    <SectionActionsWrapper.SectionTitle
                        sectionTitle='My Invitations for Interview'
                    />
                </SectionActionsWrapper>

                <div className="interview-invitation-table-container">
                    {interviewInvitations.map((interview, index) =>
                        <InterviewInvitationCard
                            key={index}
                            agencyId={agencyId}
                            interview={interview}
                            handleSubmit={handleSubmit}
                        />
                    )}
                </div>
            </div>
        </div >
    )
};

const mapDispatchToProps = {
    showNotification
};

export default connect(null, mapDispatchToProps)(InterviewInvitationTable);
