import React from 'react';

import { ErrorMessage } from 'formik';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

const currenciesList = [
    { value: 1, label: 'GBP' },
    { value: 2, label: 'USD' },
    { value: 3, label: 'EUR' },
    { value: 4, label: 'BGN' },
]

const BankDetailsFormView = (props) => {

    const handleChange = (e) => {
        props.showHeaderButtons();
        props.handleChange(e);
    }

    return (<form className="bank-details-form" autoComplete="off">
        <div className="cols" >
            <div className="col col-1of2">
                <TextField
                    required
                    name="firstName"
                    label="First Name"
                    type="text"
                    value={props.values.firstName}
                    onChange={handleChange}
                    variant="outlined"
                    error={props.errors.firstName && props.touched.firstName}
                />
                <ErrorMessage name="firstName" component="div" className="invalid-field-message" />
            </div>
            <div className="col col-1of2">
                <TextField
                    required
                    name="lastName"
                    label="Last Name"
                    type="text"
                    value={props.values.lastName}
                    onChange={handleChange}
                    variant="outlined"
                    error={props.errors.lastName && props.touched.lastName}
                />
                <ErrorMessage name="lastName" component="div" className="invalid-field-message" />
            </div>
            <div className="col">
                <TextField
                    required
                    name="iban"
                    label="IBAN"
                    type="text"
                    value={props.values.iban}
                    onChange={handleChange}
                    error={props.errors.iban && props.touched.iban}
                    variant="outlined"
                />
                <ErrorMessage name="iban" component="div" className="invalid-field-message" />
            </div>
            <div className="col col-1of2">
                <TextField
                    required
                    name="swift"
                    label="SWIFT"
                    type="text"
                    value={props.values.swift}
                    onChange={handleChange}
                    variant="outlined"
                />
                <ErrorMessage name="swift" component="div" className="invalid-field-message" />
            </div>
            <div className="col col-1of2">
                <TextField
                    required
                    select
                    name="currency"
                    label="Account Currency"
                    value={props.values.currency}
                    onChange={handleChange}
                    variant="outlined"
                    error={props.errors.currency && props.touched.currency}
                >
                    {currenciesList.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
                <ErrorMessage name="currency" component="div" className="invalid-field-message" />
            </div>
        </div>
    </form>)
}

export default BankDetailsFormView;
