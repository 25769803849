import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import TodayIcon from '@material-ui/icons/Today';

const ActiveHolidaysToolbar = ({
    label,
    onNavigate,
}) => {
    const navigate = action => {
        onNavigate(action)
    }

    return(
        <div className='rbc-toolbar'>
            <div className='arrows'>
                <IconButton className='prev-arrow' onClick={() => navigate('PREV')}>
                    <ArrowLeftIcon color='#29325B'/>
                </IconButton>
                <span className='rbc-toolbar-label'>{label}</span>
                <IconButton className='next-arrow' onClick={() => navigate('NEXT')}>
                    <ArrowRightIcon color='#29325B'/>
                </IconButton>
            </div>
            <IconButton className='today' onClick={() => navigate('TODAY')}>
                Today
                <TodayIcon />
            </IconButton>
        </div>
    )
}

export default ActiveHolidaysToolbar;
