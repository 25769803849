import React from 'react';

import { ErrorMessage } from 'formik';

import TextField from '@material-ui/core/TextField';
import Switcher from 'components/Shared/Switcher/Switcher';
import MaskedToggableInputField from 'components/Shared/MaskedToggableInputField';

const AgencySettingsCalendlyFormView = ({
    values,
    handleChange,
    setFieldValue,
    handleShowHeaderButtons,
    errors,
    touched,
}) => {

    return (
        <form autoComplete="off">
            <div className="agency-calendly-checkbox-wrapper">
                <Switcher
                    initialValue={values.isUsingCalendly}
                    isChecked={values.isUsingCalendly}
                    changeStatusHandler={() => {
                        setFieldValue('isUsingCalendly', !values.isUsingCalendly);
                        handleShowHeaderButtons();
                    }}
                />
                <span className="checkbox-text">Agency uses Calendly?</span>
            </div>
            {values.isUsingCalendly &&
                <>
                    <div className="agency-calendly-settings-form-block">
                        <div className="agency-calendly-settings-form-field">
                            <TextField
                                required
                                label="Calendly URL"
                                variant="outlined"
                                name="calendlyUrl"
                                value={values.calendlyUrl}
                                onChange={(e) => { handleChange(e); handleShowHeaderButtons(); }}
                                error={errors.calendlyUrl && touched.calendlyUrl}
                                fullWidth
                            />
                            <ErrorMessage name="calendlyUrl" component="div" className="invalid-field-message" />
                        </div>

                        <div className="agency-calendly-settings-form-field">
                            <MaskedToggableInputField
                                label="Calendly API Key"
                                name="calendlyApiKey"
                                value={values.calendlyApiKey}
                                errors={errors}
                                touched={touched}
                                setFieldValue={setFieldValue}
                                handleShowHeaderButtons={handleShowHeaderButtons}
                            />
                            <ErrorMessage name="calendlyApiKey" component="div" className="invalid-field-message" />
                        </div>
                    </div>
                    <div className="agency-calendly-settings-form-field">
                        <MaskedToggableInputField
                            label="Calendly Personal Access Token"
                            name="calendlyPersonalAccessToken"
                            value={values.calendlyPersonalAccessToken}
                            errors={errors}
                            touched={touched}
                            setFieldValue={setFieldValue}
                            handleShowHeaderButtons={handleShowHeaderButtons}
                        />
                        <ErrorMessage name="calendlyPersonalAccessToken" component="div" className="invalid-field-message" />
                    </div>
                </>
            }
        </form>
    )
};

export default AgencySettingsCalendlyFormView;
