import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { parseQueryString, updateQueryWithMultipleEntries } from 'utils/helpers';

import { TableWrapperContext } from '../TableWrapper';

import { Typography } from '@material-ui/core';

import './TableCategoryFilter.scss';

const TableCategoryFilter = ({
    filters,
    defaultFilter,
    queryParamName,
}) => {
    const history = useHistory();
    const [currentlySelectedFilter, setCurrentlySelectedFilter] = useState(defaultFilter);

    const { withPagination, pageQuerySelector } = useContext(TableWrapperContext);

    useEffect(() => {
        const query = parseQueryString(history.location.search, true);
        if (query[queryParamName]) {
            const selectedFilter = filters.find(x => x.value === query[queryParamName][0]);
            setCurrentlySelectedFilter(selectedFilter);
        } else {
            setCurrentlySelectedFilter(defaultFilter);
        }

    }, [history.location.search]);

    const handleCategoryItemClick = (item) => {
        const filterObj = { [queryParamName]: item.value };

        // if the filters have changed, we need to set the page back to 1
        if (withPagination) {
            filterObj[pageQuerySelector] = 1;
        }

        const updatedQuery = updateQueryWithMultipleEntries(history.location.search, filterObj);
        history.replace({ pathname: history.location.pathname, search: updatedQuery });
    };

    return (
        <div className="table-category-filter-wrapper">
            {filters.map(x => (
                <div 
                    key={x.value}
                    className={`table-category-filter-element${currentlySelectedFilter.value === x.value ? ' selected' : ''}`}
                    onClick={() => handleCategoryItemClick(x)}
                >
                    <Typography variant="h1">{x.name}</Typography>
                </div>
            ))}
        </div>
    )
};

export default TableCategoryFilter;
