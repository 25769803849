import React from 'react';
import { ErrorMessage } from 'formik';
import PasswordInput from 'components/Common/PasswordInput';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';

const PasswordResetFormView = (props) => (
    <form autoComplete="off">
        <div>
            <PasswordInput 
                className="login-field"
                name="newPassword"
                handleChange={props.handleChange}
                label="New Password"
                id="pwd-reset-new" 
                error={props.errors.newPassword} 
                touched={props.touched.newPassword}
            />
            <ErrorMessage name="newPassword" component="div" className="invalid-field-message" />
        </div>
        <div>
            <PasswordInput 
                className="login-field"
                name="confirmPassword"
                handleChange={props.handleChange}
                label="Confirm Password"
                id="pwd-reset-confirm" 
                error={props.errors.confirmPassword} 
                touched={props.touched.confirmPassword}
            />
            <ErrorMessage name="confirmPassword" component="div" className="invalid-field-message" />
        </div>
        <div className="login-btns center-btns">
            <PrimaryButton text="Change Password" type="submit" handleClick={props.handleSubmit} />
        </div>
    </form>
);

export default PasswordResetFormView;
