import React, { useEffect } from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import { sendVerificationEmail, sendPasswordResetEmail } from 'actions/authActions';

import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';
import ExpiredLinkCard from './ExpiredLinkCard';

import { EXPIRED_LINK_PAGE_DATA, EXPIRED_LINK_TYPES } from './ExpiredLinkPageConstants';

const ExpiredLinkPage = ({
    sendVerificationEmail,
    sendPasswordResetEmail
}) => {
    const history = useHistory();
    const location = useLocation();
    const { type } = useParams();
    const { email, role } = location?.state || {};

    const expiredLinkPageData = EXPIRED_LINK_PAGE_DATA[type];

    const handleButtonClick = () => {
        switch (type) {
            case EXPIRED_LINK_TYPES.SET_PASSWORD:
                sendVerificationEmail({ email, role });
                break;
            case EXPIRED_LINK_TYPES.RESET_PASSWORD:
                sendPasswordResetEmail(email);
                break;
            default:
                break;
        }
    };

    const isTypeValid = Object.values(EXPIRED_LINK_TYPES).includes(type);

    useEffect(() => {
        if (!email || !isTypeValid) {
            history.push({
                pathname: '/login'
            });
        }
    }, []);

    return (
        <ExpiredLinkCard
            expiredLinkPageData={expiredLinkPageData}
        >
            <div className="expired-link-buttons">
                <PrimaryButton
                    text={expiredLinkPageData?.buttonName}
                    handleClick={handleButtonClick}
                />
            </div>
        </ExpiredLinkCard>
    )
}

const mapDispatchToProps = {
    sendVerificationEmail,
    sendPasswordResetEmail
};

export default connect(null, mapDispatchToProps)(ExpiredLinkPage);
