import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import { registerCandidate, login } from 'actions/authActions';

import { setReferrer, getReferrer, setGCLID, getGCLID, setNewCandidateEmail, getNewCandidateEmail } from 'utils/queryUtils';
import { decryptString } from 'utils/helpers';

import RegisterCandidateDesktopComponent from './RegisterCandidateDesktopComponent';
import RegisterCandidateMobileComponent from './RegisterCandidateMobileComponent';

import { IS_WHOISAVAILABLE_ENV, ENVIRONMENTS } from 'constants/env';
import { CRYPTO_SECRET } from 'constants/commonConstants';

import './RegisterCandidate.scss';

const RegisterCandidate = ({
    registerCandidate,
    login,
}) => {
    const history = useHistory();

    const gotoUrl = history.location.state?.gotoUrl;
    const jobOpportunityId = history.location.state?.jobOpportunityId;
    setReferrer(history.location?.search);
    setGCLID(history.location?.search);
    setNewCandidateEmail(history.location?.search);

    
    const referrer = getReferrer();
    const gclid = getGCLID();
    const newCandidateEmail = getNewCandidateEmail() || '';

    const initialValues = IS_WHOISAVAILABLE_ENV
    ? { name: '', email: '', password: '', confirmPassword: '', }
    : { name: '', email: decryptString(newCandidateEmail, CRYPTO_SECRET), password: '', confirmPassword: '', isTermsAndConditionsAccepted: false };
    
    const isNewStyle = process.env.REACT_APP_ENV !== ENVIRONMENTS.WHOISAVAILABLE;

    if (isMobile) {
        return (
            <RegisterCandidateMobileComponent
                initialValues={initialValues}
                gotoUrl={gotoUrl}
                jobOpportunityId={jobOpportunityId}
                referrer={referrer}
                gclid={gclid}
                registerCandidate={registerCandidate}
                login={login}
                history={history}
            />
        )
    }

    return (
        <RegisterCandidateDesktopComponent
            isNewStyle={isNewStyle}
            initialValues={initialValues}
            gotoUrl={gotoUrl}
            jobOpportunityId={jobOpportunityId}
            referrer={referrer}
            gclid={gclid}
            registerCandidate={registerCandidate}
            login={login}
            history={history}
        />
    );
};

const mapDispatchToProps = {
    registerCandidate,
    login,
};

export default connect(null, mapDispatchToProps)(RegisterCandidate);
