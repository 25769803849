export const CHART_COLORS = {
    GRID_STROKE: '#EAF0F4',
    AXIS_LINES_TICK: 'rgba(67, 66, 93, 0.5)',
    OVERALL_STROKE: '#0010AC',
    COLORS: [
        { STROKE: 'rgba(84, 216, 255, 0.5)', DOT: '#54D8FF' },
        { STROKE: 'rgba(22,214,150, 0.5)', DOT: '#16D696' },
        { STROKE: 'rgba(255, 204, 216, 0.5)', DOT: '#FFCCD8' },
        { STROKE: 'rgba(163, 161, 251, 0.5)', DOT: '#A3A1FB' },
        { STROKE: 'rgba(255, 218, 131, 0.5)', DOT: '#FFDA83' },
        { STROKE: 'rgba(255, 131, 115, 0.5)', DOT: '#FF8373' },
        { STROKE: 'rgba(118, 215, 196, 0.5)', DOT: '#1ABC9C' },
        { STROKE: 'rgba(133, 146, 158 , 0.5)', DOT: '#2E4053' },
        { STROKE: 'rgba(125, 60, 152 , 0.5)', DOT: '#7D3C98' },
        { STROKE: 'rgba(46, 204, 113 , 0.5)', DOT: '#2ECC71' },
    ],
};

export const CHART_PARAMETERS = {
    DATE_FORMAT: 'MMMM Do YYYY',
    CHART_HEIGHT: 417,
    LEGEND_HEIGHT: 50,
    STROKE_WIDTH: 2,
};

