import api from './api';
import requester from './requester';

const tokenPropService = {
    fetchHomeInformationWithToken: (agencyId, userData, token, isLogin) => requester(api.homeInformation(agencyId, userData.userId, userData.role, isLogin), null, token).getOne(),
    setRememberMeWithToken: (token) => requester(api.rememberMe(), null, token).update(),
    fetchKnowYourTeamSampleWithToken: (agencyId, userId, size, token) => requester(api.teamSample(agencyId, userId, size), null, token).getMany(),
};

export default tokenPropService;
