import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';

import { updateContactInfo, editContractInformation } from 'actions/employeeActions';
import { fetchEmployeePublicHolidays } from 'actions/publicHolidaysActions';
import { getCountries, isCandidateMode, getContactInfo, getCurrentUser, getDisplayMode, getEmployeeContractInformation } from 'reducers';

import Paper from '@material-ui/core/Paper';
import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';
import PersonalInformationContactFormView from './PersonalInfoContactFormView';
import PersonalInfoCandidateContactFormView from './PersonalInfoCandidateContactFormView';
import PersonalInfoSupplierAdminContractFormView from './PersonalInfoSupplierAdminContractFormView';
import { Formik } from 'formik';

import inputFieldMaxLength from './PersonalInfoContactFormConstants';
import { ROLES } from 'constants/userConstants';

import './PersonalInfoContactForm.scss';

import {
    validatePhoneNumber,
    validateInputForWhiteSpaces,
    validateInputLength,
    validateLinkedInURL,
    validateGitHubURL
} from 'utils/formValidations';

const PersonalInfoContactForm = ({
    agencyId,
    employeeId,
    departmentId,
    authUserId,
    contactInfo,
    contractInfo,
    updateContactInfo,
    editContractInformation,
    countries,
    isCandidateMode,
    fetchEmployeePublicHolidays,
    displayMode,
    isSupplierEmployee,
}) => {
    const [showHeaderButtons, setShowHeaderButtons] = useState(false);

    const handleUpdateInfo = async (updatedInfo, resetForm) => {
        if (displayMode === ROLES.SUPPLIER_ADMIN || isSupplierEmployee) {
            await editContractInformation(agencyId, departmentId, employeeId, { country: updatedInfo.country })
        } else {
            await updateContactInfo(agencyId, departmentId, employeeId, updatedInfo, authUserId);
        }
        !isCandidateMode && await fetchEmployeePublicHolidays(agencyId, employeeId);
        resetForm();
    };

    const contactFormRef = useRef();

    const discardHandlerContactInfo = () => {
        setShowHeaderButtons(false);
        contactFormRef.current.values.country = contractInfo?.country || '';
        contactFormRef.current.values.phone = contactInfo?.phone || '';
        contactFormRef.current.values.currentLocation = contactInfo?.currentLocation || '';
        contactFormRef.current.values.address = contactInfo?.address || '';
        contactFormRef.current.values.linkedIn = contactInfo?.linkedIn || '';
        contactFormRef.current.values.gitHub = contactInfo?.gitHub || '';
    }

    const handleFormViewSelect = (props) => {
        if (displayMode === ROLES.CANDIDATE) {
            return <PersonalInfoCandidateContactFormView {...props}
                countries={countries}
                handleShowHeaderButtons={() => setShowHeaderButtons(true)}
            />
        } else if (displayMode === ROLES.SUPPLIER_ADMIN || isSupplierEmployee) {
            return <PersonalInfoSupplierAdminContractFormView {...props}
                countries={countries}
                handleShowHeaderButtons={() => setShowHeaderButtons(true)}
            />
        } else {
            return <PersonalInformationContactFormView {...props}
                countries={countries}
                handleShowHeaderButtons={() => setShowHeaderButtons(true)}
            />
        }
    }

    return (
        <div className='personal-info-wrapper'>
            <SectionActionsWrapper>
                <SectionActionsWrapper.SectionTitle
                    sectionTitle='Contact'
                />

                {showHeaderButtons && (
                    <SectionActionsWrapper.RightAlignedActions>
                        <SectionActionsWrapper.RightAlignedActions.SecondaryButton
                            text='Discard'
                            onClick={() => discardHandlerContactInfo()}
                            buttonStyle='discard'
                        />

                        <SectionActionsWrapper.RightAlignedActions.SecondaryButton
                            text='Save'
                            onClick={() => contactFormRef.current.handleSubmit()}
                            buttonStyle='save'
                        />
                    </SectionActionsWrapper.RightAlignedActions>
                )}
            </SectionActionsWrapper>


            <Paper className='personal-info-content'>
                <Formik
                    initialValues={{
                        country: contractInfo?.country || '', // only for suppliers
                        phone: contactInfo?.phone || '',
                        currentLocation: contactInfo?.currentLocation || '',
                        address: contactInfo?.address || '',
                        linkedIn: contactInfo?.linkedIn || '',
                        gitHub: contactInfo?.gitHub || '',
                    }}
                    validate={values => {
                        const validateLinkedIn = values.linkedIn !== '' ? validateLinkedInURL(values, 'linkedIn') : {};
                        const validateGitHub = values.gitHub !== '' ? validateGitHubURL(values, 'gitHub') : {};
                        return {
                            ...validatePhoneNumber(values, 'phone'),
                            ...validateInputLength(values, [{ property: 'address', maxLength: inputFieldMaxLength.address }]),
                            ...validateInputForWhiteSpaces(values, ['address']),
                            ...validateLinkedIn,
                            ...validateGitHub,
                        };
                    }}
                    innerRef={contactFormRef}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        setSubmitting(false);
                        handleUpdateInfo(values, resetForm);
                        setShowHeaderButtons(false);
                    }}
                    enableReinitialize={true}
                >
                    {(props) =>
                        handleFormViewSelect(props)
                    }
                </Formik>
            </Paper>
        </div>
    )
}

const mapStateToProps = state => ({
    countries: getCountries(state),
    isCandidateMode: isCandidateMode(state),
    contactInfo: getContactInfo(state),
    contractInfo: getEmployeeContractInformation(state),
    authUserId: getCurrentUser(state)._id,
    displayMode: getDisplayMode(state),
});

const mapDispatchToProps = {
    updateContactInfo,
    editContractInformation,
    fetchEmployeePublicHolidays,
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInfoContactForm);
