import React, { useEffect, useState } from 'react';
import { Switch, Route, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

import {
    fetchCandidatePersonalInformationPage,
    resetCandidateInformationState
} from 'actions/candidateActions';
import { disableUsers, restoreUsers } from 'actions/userActions';
import {
    getEmployeeBasicInfo,
    getCandidateApplications,
    getCandidateCriteria,
    getProfileDetails,
} from 'reducers';

import { getStateAgencyName } from 'utils/helpers';

import SubmenuActionButton from 'components/Shared/SubmenuActionButton';
import ConfirmDeleteDialog from 'components/Shared/ConfirmDeleteDialog';
import CandidatePersonalInformation from './CandidatePersonalInformation';
import MoveToEmployeesDialog from 'components/Shared/MoveToEmployeesDialog';
import SubmenuContentLayout from 'layouts/SubmenuContentLayout';
import CandidateHiringDetails from './CandidateHiringDetails';
import ApplicationsAndInterviews from './ApplicationsAndInterviews/ApplicationsAndInterviews';
import ConfirmRestoreUserDialog from 'components/Shared/ConfirmRestoreUserDialog/ConfirmRestoreUserDialog';
import CandidateDetailsPage from 'components/CandidateDetailsPage';
import { HISTORICAL_NOTES_TYPES } from 'components/Shared/HistoricalNotes/HistoricalNotesConstants';

import { APPLICATION_STATUSES } from 'constants/applicationConstants';
import { ROLES } from 'constants/userConstants';
import { DATE_FORMAT_WITH_DOTS_FULL_YEAR } from 'constants/commonConstants';
import { subPages } from 'constants/pageNames';

const personItems = {
    overview: { text: 'Overview', path: '' },
    profileInformation: { text: 'Profile Information', path: '/profile-information' },
    applications_and_inteviews: { text: 'Applications & Interviews', path: '/applications' },
    interviews: { text: 'Interviews', path: '/interviews' },
    hiringDetails: { text: 'Hiring Details', path: '/hiring-details' },
};

const oldApplications = [APPLICATION_STATUSES.APPROVED, APPLICATION_STATUSES.REJECTED, APPLICATION_STATUSES.INVITED_FOR_INTERVIEW];

const Candidate = ({
    match,
    history,
    fetchCandidatePersonalInformationPage,
    basicInfo,
    videoInterviewFeedback,
    candidateApplications,
    agencyId,
    resetCandidateInformationState,
    restoreUsers,
    disableUsers,
    profileDetails,
    agencyName,
    userId,
    displayMode,
}) => {
    const { id } = useParams();
    const isCandidateHired = candidateApplications.some(x => x.status === APPLICATION_STATUSES.APPROVED);

    const [openMoveToEmployeesDialog, setOpenMoveToEmployeesDialog] = useState(false);
    const [openRestoreDialog, setOpenRestoreDialog] = useState(false);
    const [openConfirmRemoveDialog, setOpenConfirmRemoveDialog] = useState(false);

    const handleOpenMoveToEmployeeDialog = () => setOpenMoveToEmployeesDialog(true);
    const handleCloseMoveToEmployeeDialog = () => setOpenMoveToEmployeesDialog(false);

    const handleOpenRestoreDialog = () => setOpenRestoreDialog(true);
    const handleCloseRestoreDialog = () => setOpenRestoreDialog(false);

    const handleOpenConfirmRemoveDialog = () => setOpenConfirmRemoveDialog(true);
    const handleCloseConfirmRemoveDialog = () => setOpenConfirmRemoveDialog(false);

    useEffect(() => {
        fetchCandidatePersonalInformationPage(agencyId, id);
        return resetCandidateInformationState;
    }, [id]);

    const approvedApplication = candidateApplications.some((x) => {
        return x.status === "Approved";
    });

    const submenuItems = () => {
        const { profileInformation, applications_and_inteviews, hiringDetails, overview } = personItems;
        if (approvedApplication) {
            return Object.values({ overview, profileInformation, applications_and_inteviews, hiringDetails });
        } else {
            return Object.values({ overview, profileInformation, applications_and_inteviews });
        }
    };

    const handleRemoveEmployee = () => {
        disableUsers([id], ROLES.EMPLOYEE)
            .then(() => {
                history.push(`/${getStateAgencyName()}/candidates`);
            });
        handleCloseConfirmRemoveDialog();
    };

    const restoreUser = () => {
        restoreUsers([id], basicInfo.role);
        handleCloseRestoreDialog();
    };

    const newApplicationsCount = candidateApplications ? candidateApplications.filter(a => !oldApplications.includes(a.status)).length : 0;

    const restoreCandidateButton = basicInfo.role === ROLES.CANDIDATE &&
        <SubmenuActionButton
            handleClick={handleOpenRestoreDialog}
            text="Restore Profile"
            helperText={`Deleted on: ${moment(profileDetails.dateDeleted).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR)}`}
            buttonStyle="save"
        />;

    const defaultActionButtons = basicInfo.role === ROLES.CANDIDATE && (
        <>
            <SubmenuActionButton handleClick={handleOpenMoveToEmployeeDialog} text="Move to Employees" />
            <SubmenuActionButton handleClick={handleOpenConfirmRemoveDialog} text="Delete Profile" buttonStyle="delete" />
        </>
    )

    return (
        <SubmenuContentLayout
            path={match.url}
            profileName={`${basicInfo.firstName} ${basicInfo.lastName}`}
            avatarUrl={basicInfo.profilePictureImgUrl}
            role={basicInfo.role}
            items={submenuItems()}
            applicationsCount={newApplicationsCount}
            actionButtons={profileDetails.isDeleted ? restoreCandidateButton : defaultActionButtons}
            displayMode={displayMode}
        >
            <Switch>
                <Route path={`${match.path}${personItems.profileInformation.path}`} render={(props) => (
                    <CandidatePersonalInformation
                        {...props}
                        userId={id}
                        agencyId={agencyId}
                        isCandidateProfile={true}
                        pageName={subPages.PROFILE_INFORMATION}
                    >
                        {isCandidateHired && <CandidatePersonalInformation.HiredBanner />}
                        <CandidatePersonalInformation.ProfileInformation
                            videoInterviewFeedback={videoInterviewFeedback}
                        />
                    </CandidatePersonalInformation>
                )} />

                <Route exact path={`${match.path}${personItems.applications_and_inteviews.path}`} render={(props) => (
                    <ApplicationsAndInterviews agencyId={agencyId} userId={userId} candidateId={id} {...props} />
                )} />

                <Route exact path={`${match.path}/jobs/:jobId/applications/:applicationId`} render={(props) => (
                    <CandidateDetailsPage userId={userId} agencyName={agencyName} agencyId={agencyId} fromCandidateContext />
                )} />

                <Route path={`${match.path}${personItems.hiringDetails.path}`} render={(props) => (
                    <div>
                        <CandidateHiringDetails userId={id} agencyId={agencyId} />
                    </div>
                )} />

                <Route path={`${match.path}${personItems.overview.path}`} exact pageName={subPages.OVERVIEW} render={(props) => (
                    <CandidatePersonalInformation {...props} userId={userId} candidateId={id} agencyId={agencyId} isCandidateProfile={true} pageName={subPages.OVERVIEW}>
                        <CandidatePersonalInformation.HiringChecklist />
                        <CandidatePersonalInformation.BasicInformation />
                        <CandidatePersonalInformation.ContactForm />
                        <CandidatePersonalInformation.HistoricalNotes noteType={HISTORICAL_NOTES_TYPES.USER} targetId={id} />
                    </CandidatePersonalInformation>
                )} />
            </Switch >

            <ConfirmDeleteDialog
                itemToDelete={`${basicInfo.firstName} ${basicInfo.lastName}`}
                handleDeleteItem={handleRemoveEmployee}
                openDialog={openConfirmRemoveDialog}
                handleCloseDialog={handleCloseConfirmRemoveDialog}
            />

            {openMoveToEmployeesDialog &&
                <MoveToEmployeesDialog
                    agencyId={agencyId}
                    userId={id}
                    userName={`${basicInfo.firstName} ${basicInfo.lastName}`}
                    openDialog={openMoveToEmployeesDialog}
                    handleCloseDialog={handleCloseMoveToEmployeeDialog}
                    userRole={ROLES.CANDIDATE}
                />
            }

            {
                <ConfirmRestoreUserDialog
                    openDialog={openRestoreDialog}
                    handleCloseDialog={handleCloseRestoreDialog}
                    handleConfirmDialog={restoreUser}
                />
            }
        </SubmenuContentLayout >
    )
};

const mapStateToProps = state => ({
    basicInfo: getEmployeeBasicInfo(state),
    candidateApplications: getCandidateApplications(state).list,
    videoInterviewFeedback: getCandidateCriteria(state),
    profileDetails: getProfileDetails(state),
});

const mapDispatchtoProps = {
    fetchCandidatePersonalInformationPage,
    resetCandidateInformationState,
    restoreUsers,
    disableUsers,
};

export default connect(mapStateToProps, mapDispatchtoProps)(Candidate);
