import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { deleteJob, updateJob, updateJobOpportunityPrivacy } from 'actions/jobOpportunityActions';
import { showNotification } from 'actions/notificationActions';

import Typography from '@material-ui/core/Typography';
import PaperElement from 'components/CandidateProfileJourney/SharedCandidateJourneyComponents/PaperElement';
import JobDetailsHeader from './JobsDetailsHeader';
import JobTypeLabel from 'components/JobsDashboard/SharedComponents/JobTypeLabel';
import JobCandidatesStatusBox from 'components/JobsDashboard/SharedComponents/JobCandidatesStatusBox';
import JobOptionsPopover from 'components/JobsDashboard/SharedComponents/JobOptionsPopover';
import ConfirmDeleteDialog from 'components/Shared/ConfirmDeleteDialog';
import JobFormDialog from 'components/Shared/JobFormDialog';
import ContentModeratorWrapper from 'components/Shared/ContentModeratorWrapper';
import ShareOptionsPopup from 'components/Shared/ShareOptionsPopup';

import { parseJobData } from 'utils/jobUtils';
import { getStateAgencyName, formatStringAsClassName } from 'utils/helpers';

import { INITIAL_QUERY_STRING_JOBS_DASHBOARD } from 'components/JobsDashboard/JobsDashboardConstants';
import { JOB_FORM_TITLES, JOB_OPPORTUNITIES_PRIVACY } from 'constants/jobOpportunitiesConstants';
import { BASE_APP_URL, ENVIRONMENTS, DEFAULT_AGENCY_ID, MOTION_SOFTWARE_WEBSITE_URL } from 'constants/env';

import './JobDetails.scss';

const JobDetails = ({
    agencyId,
    isAdmin,
    isClient,
    job,
    isWithLinkToJobPage,
    deleteJob,
    updateJob,
    updateJobOpportunityPrivacy,
    showNotification,
}) => {
    const history = useHistory();

    const url = process.env.REACT_APP_ENV === ENVIRONMENTS.PRODUCTION && DEFAULT_AGENCY_ID === agencyId
        ? `${MOTION_SOFTWARE_WEBSITE_URL}/job-opportunities?id=${job._id}`
        : `${BASE_APP_URL}/${getStateAgencyName()}/job-opportunities/${job._id}`;

    const trackingUrl = process.env.REACT_APP_ENV === ENVIRONMENTS.PRODUCTION && DEFAULT_AGENCY_ID === agencyId
        ? `${MOTION_SOFTWARE_WEBSITE_URL}/job-opportunities?id=${job._id}&referralCampaign=LinkedInPaidJobPost`
        : `${BASE_APP_URL}/${getStateAgencyName()}/job-opportunities/${job._id}?referralCampaign=LinkedInPaidJobPost`;

    const jobPrivacy = Object.values(JOB_OPPORTUNITIES_PRIVACY).find(x => x._id === job.privacy)?.value;

    const [anchorEl, setAnchorEl] = useState(null);
    const [shareAnchorEl, setShareAnchorEl] = useState(null);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
    const [jobImgData, setJobImgData] = useState(null);

    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    const handleDotsClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseSharePopup = () => {
        setShareAnchorEl(null);
    }

    const handleShareClick = (event) => {
        setShareAnchorEl(event.currentTarget);
    };

    const handleDeleteJob = async () => {
        await deleteJob(agencyId, job._id, job.jobType);

        handleCloseDeleteDialog();
        history.push(`/${getStateAgencyName()}/jobs-dashboard${INITIAL_QUERY_STRING_JOBS_DASHBOARD}`);
    };

    const handleOpenDeleteDialog = () => {
        setIsDeleteDialogOpen(true);
        handleClosePopover();
    };

    const handleCloseDeleteDialog = () => setIsDeleteDialogOpen(false);

    const onEditClick = () => {
        setIsEditDialogOpen(true);
        handleClosePopover();
    };

    const handleCloseDialog = () => {
        setIsEditDialogOpen(false);
        setJobImgData(null);
    };

    const handleSubmitJobForm = async (values) => {
        const parsedJob = parseJobData({ ...job, ...values }, isAdmin);

        const formData = new FormData();

        if (jobImgData) {
            formData.append('jobImageFile', jobImgData.file);
            formData.append('jobImageInfo', JSON.stringify(jobImgData.info));
        } else if (!jobImgData && !values.jobImgUrl) {
            formData.append('jobImageFile', null);
            formData.append('jobImageInfo', JSON.stringify({}));
        }

        formData.append('jobData', JSON.stringify({ ...parsedJob }));
        await updateJob(agencyId, job._id, formData);
        handleCloseDialog();
    };

    return (
        <>
            <PaperElement classes={["job-details-wrapper"]}>
                <JobTypeLabel jobType={job?.jobType} />

                <JobDetailsHeader
                    job={job}
                    isAdmin={isAdmin}
                    isClient={isClient}
                    handleDotsClick={handleDotsClick}
                    handleShareClick={handleShareClick}
                    isWithLinkToJobPage={isWithLinkToJobPage}
                />

                <div className="job-details-profiles">
                    <div className="flex-wrapper">
                        <div className="total-profiles-count">
                            <span className="total-profiles-count-number">{job?.profilesCount}</span>
                        </div>

                        {isAdmin &&
                            <div className="job-state-details">
                                {job.status && <p className={`box ${formatStringAsClassName(job.status)}-status`}>{job.status}</p>}
                                {jobPrivacy && <p className={`box ${formatStringAsClassName(jobPrivacy)}-privacy`}>{jobPrivacy}</p>}
                                {job.priority && <p className={`box ${formatStringAsClassName(job.priority)}-priority`}>{job.priority} Priority</p>}
                            </div>
                        }
                    </div>

                    <div className="candidates-statuses-count-boxes">
                        {job.rejectedApplicationsCount > 0 &&
                            <JobCandidatesStatusBox
                                classType="rejected-applications"
                                count={job.rejectedApplicationsCount}
                            />
                        }
                        {job.pendingApplicationsCount > 0 &&
                            <JobCandidatesStatusBox
                                classType="pending-applications"
                                count={job.pendingApplicationsCount}
                            />
                        }
                        {job.approvedApplicationsCount > 0 &&
                            <JobCandidatesStatusBox
                                classType="approved-applications"
                                count={job.approvedApplicationsCount}
                            />
                        }
                        {job.pendingAdminReviewApplicationsCount > 0 &&
                            <JobCandidatesStatusBox
                                classType="pending-admin-applications"
                                count={job.pendingAdminReviewApplicationsCount}
                            />
                        }
                        {job.notVettedApplicationsCount > 0 &&
                            <JobCandidatesStatusBox
                                classType="not-vetted-applications"
                                count={job.notVettedApplicationsCount}
                            />
                        }
                        {job.newApplicationsCount > 0 &&
                            <JobCandidatesStatusBox
                                classType="new-applications"
                                count={job.newApplicationsCount}
                            />
                        }
                    </div>
                </div>
                <div className="job-details-body">
                    <ContentModeratorWrapper>
                        {
                            job.aboutTheClient &&
                            <div className="job-section">
                                <Typography className="job-section-title" variant="body2" >
                                    About the company
                                </Typography>
                                <p className="job-section-text">{job.aboutTheClient}</p>
                            </div>
                        }
                        {
                            job.jobSummary &&
                            <div className="job-section">
                                <Typography className="job-section-title" variant="body2" >
                                    About the job
                                </Typography>
                                <p className="job-section-text">{job.jobSummary}</p>
                            </div>
                        }
                        {job.mandatorySkills?.length > 0 && (
                            <div className="job-section">
                                <Typography className="job-section-title" variant="body2">
                                    Mandatory Skills
                                </Typography>
                                <div className="skills-container">
                                    {job.mandatorySkills.map((x) => (
                                        <div key={x.name} className="skill-container">
                                            <span className="skill">{x.name}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                        {job.niceToHaveSkills?.length > 0 && (
                            <div className="job-section">
                                <Typography className="job-section-title" variant="body2">
                                    Nice To Have
                                </Typography>
                                <div className="skills-container">
                                    {job.niceToHaveSkills.map((x) => (
                                        <div key={x.name} className="skill-container">
                                            <span className="skill">{x.name}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                        {
                            job.aboutCandidate &&
                            <div className="job-section">
                                <Typography className="job-section-title" variant="body2" >
                                    About the candidate
                                </Typography>
                                <p className="job-section-text">{job.aboutCandidate}</p>
                            </div>
                        }
                        {
                            job.requirements &&
                            <div className="job-section">
                                <Typography className="job-section-title" variant="body2" >
                                    Requirements
                                </Typography>
                                <p className="job-section-text">{job.requirements}</p>
                            </div>
                        }
                        {
                            job.benefits &&
                            <div className="job-section">
                                <Typography className="job-section-title" variant="body2" >
                                    Additional benefits
                                </Typography>
                                <p className="job-section-text">{job.benefits}</p>
                            </div>
                        }
                    </ContentModeratorWrapper>
                </div>
            </PaperElement >

            <ShareOptionsPopup
                handleClose={handleCloseSharePopup}
                anchorEl={shareAnchorEl}
                url={url}
                showNotification={showNotification}
                trackingUrl={trackingUrl}
            />

            <JobOptionsPopover
                agencyId={agencyId}
                anchorEl={anchorEl}
                handleClose={handleClosePopover}
                onEditClick={onEditClick}
                updatePrivacy={updateJobOpportunityPrivacy}
                updateJob={updateJob}
                selectedJob={job}
                openDeleteDialog={handleOpenDeleteDialog}
                isAdmin={isAdmin}
            />

            <ConfirmDeleteDialog
                openDialog={isDeleteDialogOpen}
                handleCloseDialog={handleCloseDeleteDialog}
                handleDeleteItem={handleDeleteJob}
                itemToDelete={job.position}
            />

            <JobFormDialog
                title={JOB_FORM_TITLES.EDIT}
                clientId={job.clientId}
                open={isEditDialogOpen}
                onClose={handleCloseDialog}
                onSubmit={handleSubmitJobForm}
                agencyId={agencyId}
                selectedItem={job}
                isAdmin={isAdmin}
                setJobImgData={setJobImgData}
            />
        </>
    )
};

const mapDispatchToProps = {
    deleteJob,
    updateJob,
    updateJobOpportunityPrivacy,
    showNotification,
};

export default connect(null, mapDispatchToProps)(JobDetails);
