import borisRadevPhoto from 'assets/showcaseCommunityPhotos/boris-radev.jpg';
import mariyaTsvetanovaPhoto from 'assets/showcaseCommunityPhotos/mariya-tsvetanova.jpg';
import viktorViktorovPhoto from 'assets/showcaseCommunityPhotos/viktor-viktorov.jpg';

export const TESTIMONIALS_DATA = [
    {
        name: 'Boris Radev',
        position: 'Senior Software Engineer',
        text: 'Motion is freedom, giving you the tools to reach you desired destination, in a great company.',
        image: borisRadevPhoto
    },
    {
        name: 'Mariya Tsvetanova',
        position: 'Senior Talent Specialist',
        text: 'Fully remote, flex hours, great benefits and community around the company. Great working place for people with different lifestyles, mum - friendly and with a great vibe.',
        image: mariyaTsvetanovaPhoto
    },
    {
        name: 'Viktor Viktorov',
        position: 'Senior Software Engineer',
        text: 'Be able to work from any point in the world. Friendly and communicative team members and crew. Be able to speak freely and open to anyone from the company. Helpful and understanding staff and members.',
        image: viktorViktorovPhoto
    }
];
