export const tableColumns = [
    { name: "Name", value: "name", isSortable: true },
    { name: "E-mail", value: "email", isSortable: true },
];

export const defaultColumns = ["name", "email"];

export const DEFAULT_ITEMS_PER_PAGE = 25;
export const PAGE_QUERY_SELECTOR = "page";
export const SORT_QUERY_SELECTOR = "sort";
export const SEARCH_QUERY_SELECTOR = "search";

export const INITIAL_QUERY = {
    page: 1,
    search: "",
    sortBy: "name",
    sortByDir: "desc",
    itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
    pagesToLoad: 5,

};

export const QUERY_PARAMS_MAP = {
    page: PAGE_QUERY_SELECTOR,
    search: SEARCH_QUERY_SELECTOR,
    sortBy: SORT_QUERY_SELECTOR,
    sortByDir: `${SORT_QUERY_SELECTOR}Dir`,
};
