import React, { useRef, useCallback, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useDropzone } from 'react-dropzone';

import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';
import SecondaryButton from 'components/Shared/Buttons/SecondaryButton';
import CommonDialog from 'components/Shared/CommonDialog';
import UploadCvFile from './UploadCvFile';
import PaperElement from 'components/CandidateProfileJourney/SharedCandidateJourneyComponents/PaperElement';

import { showNotification } from 'actions/notificationActions';
import { generateRawCv } from 'actions/rawCvActions';

import { getDisplayMode, getEmployeeUploadedCv } from 'reducers';

import { CONTRACTS_MESSAGES } from 'constants/messageConstants';
import { NOTIFICATION_TYPES } from 'constants/notificationTypes';
import { ACCEPTED_FORMAT } from 'constants/commonConstants';

import Base64 from 'utils/base64';

import './UploadCv.scss';

const UploadCV = ({
    generateRawCv,
    agencyId,
    userId,
    showNotification,
    role,
    uploads,
    setUploads,
    uploadedCv,
    isEmployeeMode,
    isSupplierEmployeeMode,
    enterEditMode,
}) => {
    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
    const [newUploadedFile, setNewUploadedFile] = useState({});

    const inputFilesRef = useRef();
    const buttonRef = useRef();

    const buttonRefLinkedIn = useRef();

    const [inputFilesRefState, setInputFilesRefState] = useState(inputFilesRef);

    const dragAndDrop = (ref) => {
        if (ref && ref.current) {
            ref.current.addEventListener("dragover", function (e) {
                e.preventDefault();
                e.target.classList.add('file-hover-animation');
            }, false);

            ['dragleave', 'drop'].forEach(eventName =>
                ref.current.addEventListener(eventName, function (e) {
                    e.preventDefault();
                    e.target.classList.remove('file-hover-animation');
                }, false)
            );
        }
    };

    useEffect(() => {
        dragAndDrop(buttonRef);
        dragAndDrop(buttonRefLinkedIn);
    }, []);

    useEffect(() => {
        return () => uploadedCv.originalFileName && setUploads({ type: 'file', filesAttached: [{ name: uploadedCv.originalFileName }] });
    }, [uploadedCv.originalFileName]);

    const handleCloseConfirmationDialog = (ref) => {
        setNewUploadedFile({});
        setIsConfirmationDialogOpen(false);
        ref.current.value = '';
    };

    const handleProceed = (ref) => {
        handleGenerateCV(newUploadedFile.filesAttached, ref);
        setIsConfirmationDialogOpen(false);
    }

    const handleUpload = (data) => {
        if (Object.keys(uploads).length > 0) {
            setNewUploadedFile(data);
            setIsConfirmationDialogOpen(true);
        } else {
            setUploads(data);
        }
    };

    const handleOnInputChange = (e) => {
        if (e.target.files.length === 0) {
            return;
        }

        handleUpload({ type: 'file', filesAttached: Array.from(e.target.files) });
    }

    const handleGenerateCV = (files, ref) => {
        if (files.length <= 1) {
            files.forEach(file => {
                if (file.size === 0) {
                    showNotification(CONTRACTS_MESSAGES.ADD_EMPTY_FILE_FAIL, NOTIFICATION_TYPES.ERROR);
                } else {
                    const fileExt = file.name.split('.').pop();

                    if (!ACCEPTED_FORMAT.some(x => x === fileExt)) {
                        showNotification(CONTRACTS_MESSAGES.ADD_FILE_FORMAT_FAIL, NOTIFICATION_TYPES.ERROR);
                    } else {
                        const reader = new FileReader();

                        reader.onload = function (event) {
                            const modifiedDate = (new Date(file.lastModified)).toISOString().substring(0, 10);
                            const base64Text = Base64.encodeArray(event.target.result);

                            const data = new FormData();

                            data.append(`attachedfile1`, file);
                            data.append(`documentAsBase64String`, base64Text);
                            data.append(`documentLastModified`, modifiedDate);

                            generateRawCv(agencyId, userId, data, role);
                        }

                        reader.readAsArrayBuffer(file);
                    }
                }
            })
        } else {
            showNotification(CONTRACTS_MESSAGES.MAX_FILES_COUNT_REACHED, NOTIFICATION_TYPES.ERROR);
        }
        ref.current.value = '';
    };

    const handleSave = (ref) => {
        if (uploads?.type === 'file') {
            handleGenerateCV(uploads.filesAttached, ref);
        }
    }

    const onDrop = useCallback((acceptedFiles) => handleUpload({ type: 'file', filesAttached: acceptedFiles }));

    const { getRootProps } = useDropzone({ onDrop });

    return (
        <div className="upload-cv-wrapper">
            <PaperElement classes={['raw-cv-profile-content', 'max']}>
                <div className="raw-cv-steps-wrapper">
                    <div className="row">
                        <div className="cols">
                            <div className="col-1of3">
                                <div className="text-info">
                                    <h2>Upload a resume</h2>
                                    {(isEmployeeMode || isSupplierEmployeeMode)
                                        ? <>
                                            <p>Let us know more about you, so our algorithm can suggest the most suitable jobs.</p>
                                            <p>Don't worry if your CV is outdated or uncompleted, you will be able to edit it later.</p>
                                        </>
                                        : <p>After uploading the user CV, you will have the ability to edit their skills and experience.</p>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="cols">
                            <div className="col-1of3">
                                <UploadCvFile
                                    setInputFilesRefState={setInputFilesRefState}
                                    buttonRef={buttonRef}
                                    handleOnInputChange={handleOnInputChange}
                                    inputFilesRef={inputFilesRef}
                                    getRootProps={getRootProps}
                                    uploads={uploads}
                                    setUploads={setUploads}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </PaperElement>

            <div className="journey-buttons-wrapper">
                <SecondaryButton
                    text='Edit'
                    handleClick={enterEditMode} />
                <PrimaryButton
                    text='Save'
                    handleClick={() => handleSave(inputFilesRef)}
                    disabled={!uploads?.type} />
            </div>

            <CommonDialog
                handleProceedDialog={() => handleProceed(inputFilesRefState)}
                dialogTitle="Are you sure you want to upload a new file?"
                dialogSubtitle="Your previous changes will be lost."
                openDialog={isConfirmationDialogOpen}
                handleCloseDialog={() => handleCloseConfirmationDialog(inputFilesRefState)}
                primaryButtonText="Proceed"
                secondaryButtonText="Cancel"
            />
        </div>
    );
};


const mapStateToProps = state => ({
    role: getDisplayMode(state),
    uploadedCv: getEmployeeUploadedCv(state),
});

const mapDispatchToProps = {
    generateRawCv,
    showNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadCV);
