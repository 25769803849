/* eslint-disable no-unused-vars */
import React from 'react';
import moment from 'moment';
import { Form, ErrorMessage } from 'formik';
import momentTz from 'moment-timezone';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

import isHoliday from 'hoc/isHoliday';

import { DATE_FORMAT_WITH_DOTS_FULL_YEAR } from 'constants/commonConstants';

const timezones = moment.tz.names();

const InterviewMarkAsInvitedFormView = ({
    values,
    setFieldValue,
}) => {
    return (
        <Form className="interview-mark-as-invited-form-view form">
                <div className="timezone-field">
                    <Autocomplete
                        id="timezone"
                        name="timezone"
                        selectOnFocus
                        freeSolo={true}
                        options={timezones}
                        handleHomeEndKeys
                        value={values.timezone}
                        onChange={(e, newValue) => {
                            setFieldValue('timezone', newValue)
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                id="timezone"
                                name="timezone"
                                label="Timezone"
                                fullWidth
                                variant="outlined"
                            />
                        )}
                    />

                    <ErrorMessage name="timezone" component="div" className="invalid-field-message" />
                </div>

                <div className="date-field">
                    <KeyboardDatePicker
                        autoOk={true}
                        allowKeyboardControl={true}
                        error={false}
                        helperText={null}
                        label="Select Date"
                        name="interviewTimeSlots.0.date"
                        renderDay={isHoliday}
                        format={DATE_FORMAT_WITH_DOTS_FULL_YEAR}
                        variant="inline"
                        inputVariant="outlined"
                        fullWidth
                        disableToolbar
                        value={values.interviewTimeSlots[0].date}
                        onChange={value => {
                            setFieldValue('interviewTimeSlots.0.date', moment(value));
                        }}
                    />
                    <ErrorMessage name="interviewTimeSlots.0.date" component="div" className="invalid-field-message" />
                </div>

                <div className="time-field">
                    <KeyboardTimePicker
                        ampm={false}
                        error={false}
                        helperText={null}
                        label="Select Time"
                        name="interviewTimeSlots.0.time"
                        variant="outlined"
                        inputVariant="outlined"
                        fullWidth
                        keyboardIcon={<AccessTimeIcon />}
                        value={values.interviewTimeSlots[0].time}
                        onChange={value => {
                            setFieldValue("interviewTimeSlots.0.time", moment(value).format());
                        }}
                    />
                    <ErrorMessage name="interviewTimeSlots.0.time" component="div" className="invalid-field-message" />
                </div>
        </Form>
    );
};

export default InterviewMarkAsInvitedFormView;
