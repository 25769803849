import React from 'react';
import moment from 'moment';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import DialogActions from '@material-ui/core/DialogActions';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { ErrorMessage } from 'formik';
import SecondaryButton from 'components/Shared/Buttons/SecondaryButton';

import isHoliday from 'hoc/isHoliday';

import {
    EMPLOYEE_OCCUPATION_STATUSES_ARRAY,
    EMPLOYEE_OCCUPATION_STATUSES_MAP,
    EMPLOYEE_OCCUPATION_STATUSES
} from 'constants/employeeConstants';
import { DATE_FORMAT_WITH_DOTS_FULL_YEAR } from 'constants/commonConstants';

const EditStatusDialogForm = ({
    values,
    handleChange,
    errors,
    touched,
    handleCloseDialog,
    handleSubmit,
    selectedEmployee,
    setFieldValue,
}) => {
    const isProjectEnding = EMPLOYEE_OCCUPATION_STATUSES_MAP[values.occupationStatus] === EMPLOYEE_OCCUPATION_STATUSES.PROJECT_ENDING;

    return <form className="edit-status-form" autoComplete="off">
        <div className="occupation-status-field">
            <TextField
                select
                name="occupationStatus"
                value={values.occupationStatus}
                onChange={handleChange}
                label="Status"
                error={errors.occupationStatus && touched.occupationStatus}
                margin="normal"
                variant="outlined"
                fullWidth
            >
                {EMPLOYEE_OCCUPATION_STATUSES_ARRAY.map((status, i) => (
                    <MenuItem
                        native="true"
                        key={i}
                        value={status.key}
                    >
                        {status.value}
                    </MenuItem>
                ))}
            </TextField>
            <ErrorMessage name="occupationStatus" component="div" className="invalid-field-message" />
        </div>
        {isProjectEnding &&
            <div className="project-ending-date-field">
                <KeyboardDatePicker
                    autoOk
                    label="Ending Date"
                    name="projectEndDate"
                    variant="inline"
                    disableToolbar
                    format={DATE_FORMAT_WITH_DOTS_FULL_YEAR}
                    openTo="date"
                    inputVariant="outlined"
                    value={values.projectEndDate}
                    onChange={value => {
                        setFieldValue('projectEndDate', moment(value));
                    }}
                    fullWidth
                    renderDay={isHoliday}
                />
                <ErrorMessage name="projectEndDate" component="div" className="invalid-field-message" />
            </div>}
        <DialogActions className="dialog-actions">
            <SecondaryButton buttonStyle="discard" handleClick={handleCloseDialog} text="Discard" />
            <SecondaryButton buttonStyle="save" handleClick={handleSubmit} text="Save" type="submit" />
        </DialogActions>
    </form>
};

export default EditStatusDialogForm;
