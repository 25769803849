import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import {
    getCurrentUser,
    getSelectedClient,
    getClientTasks,
    getAgencyId,
    getAdmins,
    getTasksListDataFetchState,
} from 'reducers';
import { fetchTasks, editTask, createTask } from 'actions/clientActions';
import { fetchAdmins } from 'actions/agencyActions';

import TaskCardList from 'components/Person/Tasks/TaskCardList';
import isHoliday from 'hoc/isHoliday';

const Tasks = ({
    match,
    currentUser,
    agencyId,
    selectedUser,
    fetchTasks,
    editTask,
    createTask,
    tasks,
    isHoliday,
    fetchAdmins,
    admins,
    dataFetchState
}) => {
    useEffect(() => {
        fetchTasks(match.params.id, agencyId);
        fetchAdmins(agencyId);
    }, []);

    const submitTaskHandler = (task, clientId, agencyId) => {
        if (task._id) {
            return editTask(task, clientId, agencyId);
        }

        createTask(task, clientId, agencyId);
    };

    return (
        <TaskCardList
            dataFetchState={dataFetchState}
            tasks={tasks}
            currentUser={currentUser}
            selectedUser={selectedUser}
            submitTaskHandler={submitTaskHandler}
            agencyId={agencyId}
            taskAdmins={admins}
            renderHolidays={isHoliday}
        />
    )
};

const mapStateToProps = (state, props) => ({
    currentUser: getCurrentUser(state),
    agencyId: getAgencyId(state),
    selectedUser: getSelectedClient(state, props.match.params.id),
    tasks: getClientTasks(state),
    admins: getAdmins(state),
    dataFetchState: getTasksListDataFetchState(state),
});

const mapDispatchToProps = {
    fetchTasks,
    editTask,
    createTask,
    isHoliday,
    fetchAdmins,
};

export default connect(mapStateToProps, mapDispatchToProps)(Tasks);
