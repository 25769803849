export const HOLIDAY_SIGNATURE_STATUSES = {
    PENDING: 'sign pending',
    REJECTED: 'sign rejected',
    SIGNED: 'signed',
};

export const HOLIDAY_APPROVAL_STATUSES = {
    PENDING: 'pending',
    APPROVED: 'approved',
    REJECTED: 'rejected',
    DELETED: 'deleted',
};
