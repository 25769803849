import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import InputAdornment from '@material-ui/core/InputAdornment';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';

import { useDebounce } from 'utils/hooks';
import { parseQueryString, updateQueryWithMultipleEntries } from 'utils/helpers';

import { TableWrapperContext } from '../TableWrapper';

const TableSearch = ({
    queryParamName,
    placeholderText,
}) => {
    const history = useHistory();

    const { withPagination, pageQuerySelector } = useContext(TableWrapperContext);

    const [fieldValue, setFieldValue] = useState("");

    const debouncedInputValue = useDebounce(fieldValue, 1000);

    const onChangeHandler = (event) => setFieldValue(event.target.value);

    useEffect(() => {
        const filterObj = { [queryParamName]: fieldValue.trim() };

        // if the filters have changed, we need to set the page back to 1
        if (withPagination) {
            filterObj[pageQuerySelector] = 1;
        }

        const updatedQuery = updateQueryWithMultipleEntries(history.location.search, filterObj);
        history.replace({ pathname: history.location.pathname, search: updatedQuery });
    }, [debouncedInputValue]);

    useEffect(() => {
        const queryParams = parseQueryString(history.location.search, true);
        if (queryParams[queryParamName]) {
            setFieldValue(queryParams[queryParamName][0].trim());
        } else {
            setFieldValue("");
        }
    }, [history.location.search]);

    return (
        <InputBase
            className={"search-term-field"}
            placeholder={placeholderText || "Search"}
            autoComplete="off"
            name="searchTerm"
            value={fieldValue}
            onChange={onChangeHandler}
            startAdornment={(
                <InputAdornment position="start">
                    <SearchIcon className="search-icon icon" />
                </InputAdornment>
            )}
        />
    );
};

export default TableSearch;
