import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { getStateAgencyName } from 'utils/helpers';
import DashboardSectionWrapper from '../../SharedDashboardComponents/DashboardSectionWrapper';
import ClientDashboardBox from '../ClientDashboardBox';

import { CLIENT_PROJECT_STATUSES } from 'constants/clientConstants';
import { FILTER_TYPES } from 'components/Client/Projects/ProjectsConstants';
const ClientProjects = ({
    activeProjectsCount,
    pastProjectsCount,
}) => {
    const [selectedProjectType, setSelectedProjectType] = useState('');

    const handleClickProjectType = (projectType) => {
        setSelectedProjectType(oldState => oldState === projectType ? '' : projectType);
    }

    return (
        <DashboardSectionWrapper>
            <DashboardSectionWrapper.SectionTitle>Projects</DashboardSectionWrapper.SectionTitle>

            <DashboardSectionWrapper.SectionContent classes={['list column']}>
                <Link to={`/${getStateAgencyName()}/current-projects?status=${FILTER_TYPES.ACTIVE}`}>
                    <ClientDashboardBox
                        boxText={CLIENT_PROJECT_STATUSES.ACTIVE}
                        boxCount={activeProjectsCount}
                        handleClick={handleClickProjectType}
                        selectedBox={selectedProjectType}
                    />
                </Link>
                <Link to={`/${getStateAgencyName()}/current-projects?status=${FILTER_TYPES.PAST}`}>
                    <ClientDashboardBox
                        boxText={CLIENT_PROJECT_STATUSES.PAST}
                        boxCount={pastProjectsCount}
                        handleClick={handleClickProjectType}
                        selectedBox={selectedProjectType}
                    />
                </Link>
            </DashboardSectionWrapper.SectionContent>
        </DashboardSectionWrapper>
    );
};

export default ClientProjects;
