import React from 'react';

import ConfirmDialog from '../ConfirmDialog';
import { CONFIRM_DIALOG_INVITE_USER_CONFIRMATION_ITEMS } from '../ConfirmDialog/ConfirmDialogConstants'
import './ConfirmInviteUserDialog.scss';

const ConfirmInviteUserDialog = ({
    openDialog,
    invitedUser,
    handleInviteUser,
    handleCloseDialog,
}) => (
    <ConfirmDialog 
        openDialog={openDialog}
        dialogTitle={CONFIRM_DIALOG_INVITE_USER_CONFIRMATION_ITEMS.GET_DIALOG_TITLE(invitedUser)}
        confirmButtonName={CONFIRM_DIALOG_INVITE_USER_CONFIRMATION_ITEMS.CONFIRM_BUTTON_NAME}
        discardButtonName={CONFIRM_DIALOG_INVITE_USER_CONFIRMATION_ITEMS.DISCARD_BUTTON_NAME}
        handleConfirmDialog={handleInviteUser}
        handleCloseDialog={handleCloseDialog}
    />
);

export default ConfirmInviteUserDialog;
