import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Formik } from 'formik';

import LoginCard from 'components/Shared/LoginCard';

import { resetPassword } from 'actions/authActions';
import PasswordResetFormView from './PasswordResetForm';

import { 
    validateRequiredFields, 
    validatePasswordRequirements, 
    validatePasswordEquality } 
from 'utils/formValidations';

const PasswordReset = ({
    resetPassword
}) => {
    const history = useHistory();
    const { actionCode } = history?.location?.state || {};

    return (
        <LoginCard msg="Reset your password">
            <Formik
                initialValues={{
                    newPassword: '',
                    confirmPassword: ''
                }}
                validate={values => ({             
                    ...validatePasswordRequirements(values, 'newPassword'),
                    ...validatePasswordEquality(values, 'newPassword', 'confirmPassword'),
                    ...validateRequiredFields(values, ['newPassword', 'confirmPassword'])
                })}
                onSubmit={(values, { setSubmitting }) => {
                    values.newPassword = values.newPassword.trim();
                    values.confirmPassword = values.confirmPassword.trim();
                    setSubmitting(false);
                    resetPassword(actionCode, values.newPassword)
                        .then(() => {
                            history.push("/login"); 
                        })
                }}
            >   
                {(props) => <PasswordResetFormView {...props} />}
            </Formik>
        </LoginCard>
    )
};

const mapDispatchToProps = {
    resetPassword
};

export default connect(null, mapDispatchToProps)(PasswordReset);
