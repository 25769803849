import React, { useState } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { getCurrentEmployeeHolidaysHistory } from 'reducers';

import TableWrapper from 'components/Shared/TableWrapper';
import ConfirmDeleteDialog from 'components/Shared/ConfirmDeleteDialog';
import ExportOptionsPopover from 'components/Shared/ExportOptionsPopover';
import Tooltip from '@material-ui/core/Tooltip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import { ReactComponent as ExportIcon } from 'assets/export-button-icon.svg';

import {
    SORT_QUERY_SELECTOR,
    PAGE_QUERY_SELECTOR,
    DATE_QUERY_SELECTOR,
    DEFAULT_ITEMS_PER_PAGE,
    CONFIRM_DELETE_DIALOG_TITLE,
} from './HistoryHolidaysTableConstants';
import { HOLIDAY_TYPES } from '../HolidaysRequests/HolidayRequestDialog/HolidayRequestDialogConstants';
import { MONTH_YEAR_DATE_FORMAT, DATE_FORMAT_WITH_DOTS_FULL_YEAR } from 'constants/commonConstants';
import { HOLIDAY_APPROVAL_STATUSES, HOLIDAY_SIGNATURE_STATUSES } from 'constants/holidayConstants';
import { ROLES } from 'constants/userConstants';
import { DOCUMENTS_MESSAGES } from 'constants/messageConstants';
import { NOTIFICATION_TYPES } from 'constants/notificationTypes';
import { DOCUMENT_TYPES } from 'constants/documentsConstants';

import { getDataFromQuery, getHolidaysExportFileName, getTableColumnsAndFilters } from './HistoryHolidaysTableUtils';
import { capitalizeFirstLetter, parseQueryString } from 'utils/helpers';

import './HistoryHolidaysTable.scss';

const HistoryHolidaysTable = ({
    currentUserId,
    userId,
    agencyId,
    departmentId,
    displayMode,
    isUsingEvrotrust,
    isTableDataLoading,
    sendHolidaySignReminderEmail,
    handleDownloadSignedFile,
    deleteHolidayRequests,
    showNotification,
    exportDocumentsData,
    downloadDocumentsFiles,
    downloadManagersEmployeesDocumentsFiles,
}) => {
    const history = useHistory();
    const isDepartmentView = !!departmentId || history.location?.pathname?.includes('/departments/holidays');
    const isAdmin = displayMode === ROLES.ADMIN || displayMode === ROLES.SUPPLIER_ADMIN;
    const isAgencyAdmin = displayMode === ROLES.ADMIN;
    const isAgencyMember = displayMode === ROLES.ADMIN || displayMode === ROLES.EMPLOYEE;

    const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = useState(false);
    const [holidayToDelete, setHolidayToDelete] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);

    const { TABLE_COLUMNS, DEFAULT_TABLE_COLUMNS, TABLE_FILTERS } = getTableColumnsAndFilters(displayMode, isUsingEvrotrust);

    const dataSelector = (state, page) => {
        const parsedQuery = parseQueryString(history.location.search, true);
        const { sortBy, sortByDir, date, filters } = getDataFromQuery(parsedQuery, TABLE_FILTERS);

        return getCurrentEmployeeHolidaysHistory(state, page - 1, DEFAULT_ITEMS_PER_PAGE, filters, date, sortBy, sortByDir).holidays;
    };

    const historyHolidaysWithoutPagination = useSelector((state) => {
        const parsedQuery = parseQueryString(history.location.search, true);
        const { sortBy, sortByDir, date, filters } = getDataFromQuery(parsedQuery, TABLE_FILTERS);

        return getCurrentEmployeeHolidaysHistory(state, null, null, filters, date, sortBy, sortByDir).holidays;
    });

    const totalCount = useSelector((state) => {
        const parsedQuery = parseQueryString(history.location.search, true);
        const { sortBy, sortByDir, date, filters } = getDataFromQuery(parsedQuery, TABLE_FILTERS);

        return getCurrentEmployeeHolidaysHistory(state, null, null, filters, date, sortBy, sortByDir).totalCount;
    });

    const getHolidayDocumentsInformation = holiday => {
        return (
            <div>
                {holiday.trackingInfo?.transactions.length > 0 ? holiday.trackingInfo.transactions.map(transaction =>
                    <div key={transaction.transactionID}>
                        <p>TransactionID: {transaction.transactionID}</p>
                        <p>Document Proccesed: {transaction.isProcessing ? 'No' : 'Yes'} </p>
                        {(holiday.signatureStatus === HOLIDAY_SIGNATURE_STATUSES.SIGNED || transaction.status) &&
                            <p>
                                Document Status: {holiday.signatureStatus === HOLIDAY_SIGNATURE_STATUSES.SIGNED ? capitalizeFirstLetter(HOLIDAY_SIGNATURE_STATUSES.SIGNED) : transaction.status}
                            </p>
                        }
                    </div>
                ) : null}
            </div>
        );
    };

    const adjustHistoryHolidaysKeysFunction = holidays => holidays.map(holiday => {
        return {
            ...holiday,
            _id: holiday._id,
            employeeId: userId,
            from: moment.utc(holiday.startDate).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR),
            to: moment.utc(holiday.endDate).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR),
            numberOfDays: holiday.numberOfDays,
            type: holiday.type === 'Paid' && holiday.byArticle ? `${holiday.type} Art. ${holiday.byArticle}` : holiday.type,
            requestedOn: moment.utc(holiday.requestedOn).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR),
            status: (<span className={`approval-status ${holiday.status}`}>{holiday.status}</span>),
            signatureStatus: isAgencyMember && isUsingEvrotrust && (
                <div className="signature-status-wrapper">
                    <span className={`${holiday.signatureStatus}`}>{holiday.signatureStatus}</span>
                    {(isAgencyAdmin || isDepartmentView) && holiday.trackingInfo
                        ? <Tooltip title={getHolidayDocumentsInformation(holiday)} classes={{ tooltip: 'custom-tooltip' }}>
                            <InfoOutlinedIcon className="global-information-icon" />
                        </Tooltip>
                        : null
                    }
                </div>
            ),
            item: holiday,
        };
    });

    const handleSendReminderEmail = holiday => {
        sendHolidaySignReminderEmail(agencyId, departmentId, holiday.employeeId, holiday._id);
    }

    const handleCloseConfirmDeleteDialog = () => {
        setIsConfirmDeleteDialogOpen(false);
        setHolidayToDelete({});
    };

    const handleDeleteHolidayHistoryClick = holiday => {
        setHolidayToDelete(holiday);
        setIsConfirmDeleteDialogOpen(true);
    };

    const handleConfirmDeleteDialogSubmit = () => {
        const { _id, employeeId } = holidayToDelete;
        deleteHolidayRequests(employeeId, agencyId, departmentId, [_id]);
        setHolidayToDelete({});
        setIsConfirmDeleteDialogOpen(false);
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    const handleExportButtonClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleExportTable = async () => {
        if (historyHolidaysWithoutPagination?.length === 0) {
            showNotification(DOCUMENTS_MESSAGES.NO_DOCUMENTS_TO_EXPORT_WARNING, NOTIFICATION_TYPES.WARNING);
            return;
        }

        const fileName = `WIA_Holidays_Export_as_of_${moment().format(DATE_FORMAT_WITH_DOTS_FULL_YEAR)}`;

        const mappedHolidays = historyHolidaysWithoutPagination.map(holiday => ({
            firstName: holiday.firstName,
            lastName: holiday.lastName,
            from: holiday.startDate,
            to: holiday.endDate,
            numberOfDays: holiday.numberOfDays,
            type: holiday.type,
            byArticle: holiday.byArticle,
            requestedOn: holiday.requestedOn,
            status: holiday.status,
            signatureStatus: holiday.signatureStatus,
            userId: holiday.employeeId,
            documentType: DOCUMENT_TYPES.HOLIDAY,
        }));

        await exportDocumentsData(agencyId, mappedHolidays, fileName);
        handleClosePopover();
    };

    const handleExportDocumentsFiles = async () => {
        if (historyHolidaysWithoutPagination?.length === 0) {
            showNotification(DOCUMENTS_MESSAGES.NO_DOCUMENTS_TO_EXPORT_WARNING, NOTIFICATION_TYPES.WARNING);
            return;
        }

        const fileName = getHolidaysExportFileName(historyHolidaysWithoutPagination);

        const mappedHolidays = historyHolidaysWithoutPagination.map(holiday => ({
            _id: holiday._id,
            documentType: DOCUMENT_TYPES.HOLIDAY,
            userId: holiday.employeeId,
        }));

        const queryString = `?documentType=${DOCUMENT_TYPES.HOLIDAY}`;

        departmentId
            ? await downloadManagersEmployeesDocumentsFiles(agencyId, mappedHolidays, fileName, currentUserId, queryString)
            : await downloadDocumentsFiles(agencyId, mappedHolidays, fileName, queryString);
        handleClosePopover();
    };

    const resendEmailAction = { title: 'Resend Email', handleClick: handleSendReminderEmail };
    const downloadFileAction = { title: 'Download File', handleClick: handleDownloadSignedFile };
    const deleteHolidayHistoryAction = { title: 'Delete Holiday History', handleClick: handleDeleteHolidayHistoryClick }

    const getActionsHandler = holiday => {
        const actions = [];

        if ((isAgencyAdmin || isDepartmentView) && [HOLIDAY_TYPES.PAID, HOLIDAY_TYPES.UNPAID].includes(holiday.type)) {
            actions.push(resendEmailAction);
        }

        if ([HOLIDAY_TYPES.PAID, HOLIDAY_TYPES.UNPAID].includes(holiday.type)) {
            actions.push(downloadFileAction);
        }

        if (isAdmin && holiday.status !== HOLIDAY_APPROVAL_STATUSES.DELETED) {
            actions.push(deleteHolidayHistoryAction);
        }

        return actions;
    };

    return (
        <div className="history-holidays-wrapper">
            <TableWrapper
                title='Holidays History'
                columns={TABLE_COLUMNS}
                defaultColumns={DEFAULT_TABLE_COLUMNS}
                dataSelector={dataSelector}
                sortQuerySelector={SORT_QUERY_SELECTOR}
                adjustKeysFunction={adjustHistoryHolidaysKeysFunction}
                isTableDataLoading={isTableDataLoading}
                addMargin
                withPagination
                withActions
                getActionsHandler={getActionsHandler}
                pagesCount={Math.ceil(totalCount / DEFAULT_ITEMS_PER_PAGE)}
                pageQuerySelector={PAGE_QUERY_SELECTOR}
            >
                <TableWrapper.TableAlignedItems>
                    <TableWrapper.TableAlignedItems.LeftAligned>
                        <TableWrapper.TableAlignedItems.TableHeader />
                        <TableWrapper.TableAlignedItems.TableFilter filters={TABLE_FILTERS} />
                        <TableWrapper.TableAlignedItems.TableDateFilter
                            placeholderText={"Holiday Date"}
                            queryParamName={DATE_QUERY_SELECTOR}
                            format={MONTH_YEAR_DATE_FORMAT}
                        />
                    </TableWrapper.TableAlignedItems.LeftAligned>
                    <TableWrapper.TableAlignedItems.RightAligned>
                        <TableWrapper.TableAlignedItems.TableActionButton
                            text='Export'
                            icon={<ExportIcon />}
                            handleClick={handleExportButtonClick}
                        />
                    </TableWrapper.TableAlignedItems.RightAligned>
                </TableWrapper.TableAlignedItems>
                <TableWrapper.TableContent />
            </TableWrapper>

            <ConfirmDeleteDialog
                itemToDelete={CONFIRM_DELETE_DIALOG_TITLE}
                openDialog={isConfirmDeleteDialogOpen}
                handleDeleteItem={handleConfirmDeleteDialogSubmit}
                handleCloseDialog={handleCloseConfirmDeleteDialog}
            />

            <ExportOptionsPopover
                anchorEl={anchorEl}
                handleClose={handleClosePopover}
                handleExportTable={handleExportTable}
                handleExportDocumentsFiles={handleExportDocumentsFiles}
                dataSelector={getCurrentEmployeeHolidaysHistory}
                displayMode={displayMode}
            />
        </div>
    );
};

export default HistoryHolidaysTable;
