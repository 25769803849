import React, { useState, useRef } from 'react';
import moment from 'moment';
import { Formik } from 'formik';

import Paper from '@material-ui/core/Paper';
import DisplayImage from 'components/Person/PersonalInfo/DisplayImage';
import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';
import CompanyInformationBasicFormView from './CompanyInformationBasicFormView';

import {
  validateRequiredFields,
  validateInputLength,
  validateEmailAddress,
} from 'utils/formValidations';

import basicLogo from 'assets/default-logo.svg';

import { NOTIFICATIONS_CONTAINER_COLORS } from 'components/Shared/SectionActionsWrapper/SectionActionsWrapperConstants';
import { getInitialValues } from './CompanyInformationBasicFormConstants';
import { maxFieldLength } from '../CompanyInformationConstants';
import { DATE_FORMAT_WITH_DOTS_FULL_YEAR } from 'constants/commonConstants';
import { REFERRAL_CAMPAIGN_COLORS } from 'constants/referralCampaign';
import './CompanyInformationBasicForm.scss';
import 'react-image-crop/lib/ReactCrop.scss';

const CompanyInformationBasicForm = ({
  companyId,
  agencyId,
  basicInformation,
  updateAvatar,
  deleteAvatar,
  countries,
  saveBasicInfo,
  updateBasicInformation,
  isEmailChangeDisabled,
  isClient,
}) => {
  const [showHeaderButtons, setShowHeaderButtons] = useState(false);

  const basicSettingsFormRef = useRef();

  const discardHandlerBasicInfo = () => {
    setShowHeaderButtons(false);
    basicSettingsFormRef.current.values.name = basicInformation?.name || '';
    basicSettingsFormRef.current.values.country =
      basicInformation?.country || '';
    basicSettingsFormRef.current.values.email = basicInformation?.email || '';
    if (isClient) {
      basicSettingsFormRef.current.values.hqLocation =
        basicInformation?.hqLocation || '';
    }
  };

  return (
    <div className='company-information-wrapper company-basic-information'>
      <SectionActionsWrapper>
        <SectionActionsWrapper.SectionTitle
          sectionTitle='Basic'
        />

        {isClient && (
          <SectionActionsWrapper.LeftAlignedActions>
            {basicInformation.registrationDate && (
              <SectionActionsWrapper.LeftAlignedActions.NotificationsContainer
                text={`Registration: ${moment(basicInformation.registrationDate).format(
                  DATE_FORMAT_WITH_DOTS_FULL_YEAR
                )}`}
                color={NOTIFICATIONS_CONTAINER_COLORS.ORANGE}
                isLowerCase={true}
              />
            )}

            {basicInformation.referrer && (
              <SectionActionsWrapper.LeftAlignedActions.NotificationsContainer
                text={`Referral Campaign: ${basicInformation.referrer}`}
                color={
                  REFERRAL_CAMPAIGN_COLORS[basicInformation.referrer] ||
                  REFERRAL_CAMPAIGN_COLORS['WebsiteOrganic']
                }
                isLowerCase={true}
              />
            )}
          </SectionActionsWrapper.LeftAlignedActions>
        )}

        {showHeaderButtons && (
          <SectionActionsWrapper.RightAlignedActions>
            <SectionActionsWrapper.RightAlignedActions.SecondaryButton
              text='Discard'
              onClick={discardHandlerBasicInfo}
              buttonStyle='discard'
            />
            <SectionActionsWrapper.RightAlignedActions.SecondaryButton
              text='Save'
              onClick={() => basicSettingsFormRef.current.handleSubmit()}
              buttonStyle='save'
            />
          </SectionActionsWrapper.RightAlignedActions>
        )}
      </SectionActionsWrapper>

      <Paper className="company-information-content-wrapper">
        <div className="company-information-container">
          <Formik
            initialValues={getInitialValues(isClient, basicInformation)}
            innerRef={basicSettingsFormRef}
            validate={values => {
              return {
                ...validateRequiredFields(values, ['name']),
                ...validateInputLength(values, [
                  { property: 'name', maxFieldLength },
                ]),
                ...validateEmailAddress(values, 'email')
              };
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              values.name = values.name.trim();
              if (values.hasOwnProperty('email')) {
                values.email = values.email.toLowerCase().trim();
              }
              setSubmitting(false);
              saveBasicInfo(values, updateBasicInformation, resetForm);
              setShowHeaderButtons(false);
            }}
            enableReinitialize={true}
          >
            {(props) =>
              <CompanyInformationBasicFormView {...props}
                isEmailChangeDisabled={isEmailChangeDisabled}
                countries={countries}
                basicInformation={basicInformation}
                handleShowHeaderButtons={() => setShowHeaderButtons(true)}
                isClient={isClient}
              />
            }
          </Formik>

          <div className='basic-settings-logo'>
            <DisplayImage
              imageUrl={basicInformation.logoImgUrl || basicLogo}
              imageDetails={basicInformation.logoImgDetails}
              handleUpdateAvatar={updateAvatar}
              handleDeleteAvatar={deleteAvatar}
              entityId={companyId}
              agencyId={agencyId}
              defaultLogo={!basicInformation.logoImgUrl}
            />
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default CompanyInformationBasicForm;
