import React, { useState } from 'react';
import { connect } from 'react-redux';

import { fetchAgencyPerformanceCriterias, updatePerformanceCriterias } from 'actions/agencyActions';
import { getPerformanceCriterias } from 'reducers';

import AddCriteriaDialog from './AddCriteriaDialog';
import ConfirmDeleteDialog from 'components/Shared/ConfirmDeleteDialog';
import TableWrapper from 'components/Shared/TableWrapper';

import { ReactComponent as AddButtonIcon } from 'assets/add-button-icon.svg';

import {
    PERFORMANCE_CRITERIA_TABLE_COLUMNS,
    DEFAULT_PERFORMANCE_CRITERIA_TABLE_COLUMNS,
    PERFORMANCE_CRITERIA_SORT_QUERY_SELECTOR,
    PERFORMANCE_CRITERIA_PAGE_QUERY_SELECTOR,
    DEFAULT_ITEMS_PER_PAGE,
    INITIAL_QUERY,
    QUERY_PARAMS_MAP,
} from './PerformanceCriteriaConstants';

import { usePreloadTable } from 'utils/hooks';

const PerformanceCriteria = ({
    agencyId,
    performanceCriterias,
    fetchAgencyPerformanceCriterias,
    updatePerformanceCriterias,
}) => {
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [selectedCriteria, setSelectedCriteria] = useState('');

    const tableFetchAction = fetchAgencyPerformanceCriterias.bind(null, agencyId);
    const dataSelector = (state, page) => getPerformanceCriterias(state, page - 1, DEFAULT_ITEMS_PER_PAGE);

    const [reload, setReload] = useState(false);
    const [isTableDataLoading, totalCount] = usePreloadTable(INITIAL_QUERY, QUERY_PARAMS_MAP, null, tableFetchAction, reload, setReload);

    const handleOpenAddDialog = () => setOpenAddDialog(true);

    const handleCloseAddDialog = () => {
        setOpenAddDialog(false);
        setSelectedCriteria('');
    };

    const handleCloseConfirmDialog = () => {
        setOpenConfirmDialog(false);
        setSelectedCriteria('');
    };

    const handleRemoveClick = criteria => {
        setSelectedCriteria(criteria);
        setOpenConfirmDialog(true);
    };

    const handleEditClick = criteria => {
        setSelectedCriteria(criteria);
        setOpenAddDialog(true);
    };

    const handleCriteriaSubmit = async (item) => {
        const criteriaName = item.criteria;
        const index = performanceCriterias.indexOf(selectedCriteria);
        index >= 0
            ? performanceCriterias[index] = criteriaName
            : performanceCriterias.push(criteriaName);

        await updatePerformanceCriterias(performanceCriterias, agencyId, false);
        setReload(true);
    };

    const handleRemoveCriteria = async criteria => {
        const index = performanceCriterias.indexOf(criteria);
        performanceCriterias.splice(index, 1);
        await updatePerformanceCriterias(performanceCriterias, agencyId, false);
        setReload(true);
        setOpenConfirmDialog(false);
    };

    const adjustPerformanceCriteriaKeysFunction = (stateData) => stateData.map(x => ({ title: x, item: x }));

    const actions = [
        { title: 'Edit', handleClick: handleEditClick },
        { title: 'Remove', handleClick: handleRemoveClick },
    ];

    const getPopoverActions = () => actions;

    return (
        <div className="employee-info-wrapper">
            <TableWrapper
                title="Performance Criteria"
                columns={PERFORMANCE_CRITERIA_TABLE_COLUMNS}
                defaultColumns={DEFAULT_PERFORMANCE_CRITERIA_TABLE_COLUMNS}
                dataSelector={dataSelector}
                sortQuerySelector={PERFORMANCE_CRITERIA_SORT_QUERY_SELECTOR}
                adjustKeysFunction={adjustPerformanceCriteriaKeysFunction}
                isTableDataLoading={isTableDataLoading}
                addMargin
                withPagination
                withActions
                getActionsHandler={getPopoverActions}
                pagesCount={Math.ceil(totalCount / DEFAULT_ITEMS_PER_PAGE)}
                pageQuerySelector={PERFORMANCE_CRITERIA_PAGE_QUERY_SELECTOR}
            >
                <TableWrapper.TableAlignedItems>
                    <TableWrapper.TableAlignedItems.LeftAligned>
                        <TableWrapper.TableAlignedItems.TableHeader />
                    </TableWrapper.TableAlignedItems.LeftAligned>
                    <TableWrapper.TableAlignedItems.RightAligned>
                        <TableWrapper.TableAlignedItems.TableActionButton
                            text={"Add New"}
                            icon={<AddButtonIcon />}
                            handleClick={handleOpenAddDialog}
                        />
                    </TableWrapper.TableAlignedItems.RightAligned>
                </TableWrapper.TableAlignedItems>
                <TableWrapper.TableContent />
            </TableWrapper>

            <AddCriteriaDialog
                selectedCriteria={selectedCriteria}
                openDialog={openAddDialog}
                criterias={performanceCriterias}
                handleCloseDialog={handleCloseAddDialog}
                handleCriteriaSubmit={handleCriteriaSubmit}
                title='criteria'
            />

            <ConfirmDeleteDialog
                itemToDelete={selectedCriteria}
                handleDeleteItem={() => handleRemoveCriteria(selectedCriteria)}
                openDialog={openConfirmDialog}
                handleCloseDialog={handleCloseConfirmDialog}
            />
        </div>
    );
};

const mapStateToProps = state => ({
    performanceCriterias: getPerformanceCriterias(state),
});

const mapDispatchToProps = {
    fetchAgencyPerformanceCriterias,
    updatePerformanceCriterias,
};

export default connect(mapStateToProps, mapDispatchToProps)(PerformanceCriteria);
