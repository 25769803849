import React from 'react';
import { isMobile } from 'react-device-detect';

import queryString from 'query-string';
import { Route } from 'react-router-dom';

import { ROLES } from 'constants/userConstants';
import { ACCOUNT_ROUTES_MODES } from 'constants/commonConstants';

import { verifyUserEmail, checkActionCode } from 'actions/authActions';

import store from 'utils/store';

export const getAccountRoute = async (location) => {
    const params = queryString.parse(location.search);
    const { mode, oobCode, email, passwordOobCode, expirationDate, gotoUrl, userRole } = params;
    let path, role;

    switch (mode) {
        case ACCOUNT_ROUTES_MODES.VERIFY_EMAIL:
            path = await verifyUserEmail(oobCode, expirationDate, email)(store.dispatch) || `/login`;
            break;
        case ACCOUNT_ROUTES_MODES.VERIFY_ADMIN_EMAIL:
            role = ROLES.ADMIN;
            path = await verifyUserEmail(oobCode, expirationDate, email)(store.dispatch) || `/login`;
            break;
        case ACCOUNT_ROUTES_MODES.VERIFY_EMPLOYEE_EMAIL:
            role = ROLES.EMPLOYEE;
            path = await verifyUserEmail(oobCode, expirationDate, email)(store.dispatch) || `/account/passwordset`;
            break;
        case ACCOUNT_ROUTES_MODES.VERIFY_CLIENT_EMAIL:
            role = ROLES.CLIENT;
            path = await verifyUserEmail(oobCode, expirationDate, email)(store.dispatch) || `/account/passwordset`;
            break;
        case ACCOUNT_ROUTES_MODES.VERIFY_SUPPLIER_ADMIN_EMAIL:
            role = ROLES.SUPPLIER_ADMIN;
            path = await verifyUserEmail(oobCode, expirationDate, email)(store.dispatch) || `/account/passwordset`;
            break;
        case ACCOUNT_ROUTES_MODES.VERIFY_SUPPLIER_EMPLOYEE_EMAIL:
            role = ROLES.SUPPLIER_EMPLOYEE;
            path = await verifyUserEmail(oobCode, expirationDate, email)(store.dispatch) || `/account/passwordset`;
            break;
        case ACCOUNT_ROUTES_MODES.VERIFY_RECRUITER_EMAIL:
            role = ROLES.RECRUITER;
            path = await verifyUserEmail(oobCode, expirationDate, email)(store.dispatch) || `/account/passwordset`;
            break;
        case ACCOUNT_ROUTES_MODES.VERIFY_CANDIDATE_EMAIL:
            role = ROLES.CANDIDATE;
            path = await verifyUserEmail(oobCode, expirationDate, email)(store.dispatch) || `/account/passwordset`;
            break;
        case ACCOUNT_ROUTES_MODES.RESET_PASSWORD:
            role = userRole;
            path = await checkActionCode(oobCode)(store.dispatch) || '/account/passwordreset';
            break;
        default:
            path = '/login';
            break;
    }

    return ({
        pathname: path,
        state: {
            role,
            gotoUrl,
            email,
            passwordOobCode,
            actionCode: oobCode,
        }
    })
};

export const RouteWrapper = ({
    component: Component,
    layout: Layout,
    noDesktopLayout,
    backgroundColor,
    slidesData,
    ...rest
}) => {
    return (
        !isMobile && noDesktopLayout
            ? (<Route {...rest} component={Component} />)
            : (
                <Route {...rest} render={props =>
                    <Layout {...props} backgroundColor={backgroundColor} slidesData={slidesData}>
                        <Component {...props} />
                    </Layout>
                } />
            )
    );
};
