import clientService from 'services/clientService';
import employeeService from 'services/employeeService';
import agencyService from 'services/agencyService';
import store from 'utils/store';
import { logEvent } from 'utils/amplitude';

import { showNotification } from 'actions/notificationActions';
import { showApplicationLoader, hideApplicationLoader } from 'actions/applicationLoaderActions';
import { NOTIFICATION_TYPES } from 'constants/notificationTypes';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitudeConstants';
import {
    CLIENT_MESSAGES,
    AGENCY_MESSAGES,
    DASHBOARD_MESSAGES,
    TASK_MESSAGES,
    HOLIDAYS_MESSAGES,
    EMAIL_MESSAGES,
    CHECK_OCCUPATION_STATUS_MESSAGE,
} from 'constants/messageConstants';

import { getAgencyBasicInfo } from 'reducers';
import { tasksDataFetchSuccess, tasksDataFetchingSuccess, fetchEmployeesSuccess } from 'actions/sharedActions';
import { fetchInvoicesSuccess } from 'actions/invoiceActions';
import { fetchAppliedConsultantsDataSuccess } from './jobApplicationActions'
import { showDialogActionButtonLoader, hideDialogActionButtonLoader } from './sectionsLoadersActions';
import {
    fetchPerformanceCriteriasSuccess,
    fetchApprovedHolidaysSuccess,
    dashboardDataFetchSuccess,
    dashboardDataFetchingSuccess,
    approvedHolidaysTableDataFetchSuccess,
    approvedHolidaysTableDataFetchingSuccess,
} from 'actions/agencyActions';
import { authorizedShowNotification } from 'actions/actionHelpers';
import { EMPLOYEE_OCCUPATION_STATUSES_ARRAY } from 'constants/employeeConstants';

import {
    CLIENT_FETCH_ALL_SUCCESS,
    CLIENT_PAGE_FETCH_SUCCESS,
    CLIENT_REGISTER_FETCH_SUCCESS,
    CLIENT_INFORMATION_FETCH_SUCCESS,
    CLIENT_AVATAR_UPDATE_SUCCESS,
    CLIENT_AVATAR_DELETE_SUCCESS,
    CLIENT_BASIC_INFORMATION_UPDATE_SUCCESS,
    CLIENT_CONTACT_INFORMATION_UPDATE_SUCCESS,
    CLIENT_INVOICE_INFORMATION_UPDATE_SUCCESS,
    CLIENT_PROJECT_ADD_SUCCESS,
    CLIENT_PROJECT_UPDATE_SUCCESS,
    CLIENT_PROJECTS_FETCH_SUCCESS,
    CLIENT_INVOICING_FETCH_SUCCESS,
    PROJECT_REVIEWS_FETCH_SUCCESS,
    EMPLOYEE_TO_PROJECT_ADD_SUCCESS,
    DEDICATED_EMPLOYEE_TO_PROJECT_ADD_SUCCESS,
    PROJECT_EMPLOYEE_DELETE_SUCCESS,
    PROJECT_DEDICATED_EMPLOYEE_DELETE_SUCCESS,
    CLIENT_ACCOUNT_MANAGEMENT_INFORMATION_UPDATE_SUCCESS,
    CLIENT_TASK_CREATE_SUCCESS,
    CLIENT_TASKS_FETCH_SUCCESS,
    CLIENT_TASK_EDIT_SUCCESS,
    CLIENT_PROJECT_FILES_ADD_SUCCESS,
    CLIENT_PROJECT_DOCUMENTS_FETCH_SUCCESS,
    CLIENT_PROJECT_LINK_ADD_SUCCESS,
    CLIENT_PROJECT_FILE_DELETE_SUCCESS,
    CLIENT_PROJECT_LINK_DELETE_SUCCESS,
    CLIENT_PROJECTS_FEEDBACK_FETCH_SUCCESS,
    CLIENT_PROJECT_FETCH_PENDING,
    CLIENT_PROJECT_FETCH_SUCCESS,
    ALL_CLIENT_TEAM_HISTORY_FETCH_SUCCESS,
    CLIENT_PROJECT_EMPLOYEE_HISTORY_FETCH_SUCCESS,
    CLIENT_UPDATE_FLAG_SUCCESS,
    CLIENT_TOTAL_REVENUE_FETCH_SUCCESS,
    CLIENTS_ACCOUNTS_TABLE_DATA_FETCH_SUCCESS,
    CLIENTS_ACCOUNTS_TABLE_DATA_FETCHING_SUCCESS,
    CLIENTS_PROJECTS_PAGE_DATA_FETCH_SUCCESS,
    CLIENTS_PROJECTS_PAGE_DATA_FETCHING_SUCCESS,
    CLIENT_PROJECT_DOCUMENTS_DATA_FETCH_SUCCESS,
    CLIENT_PROJECT_DOCUMENTS_DATA_FETCHING_SUCCESS,
    CLIENT_PROJECTS_WITH_EMPLOYEES_DATA_FETCH_SUCCESS,
    CLIENT_PROJECTS_WITH_EMPLOYEES_DATA_FETCHING_SUCCESS,
    CLIENT_PROJECTS_TOTAL_REVENUE_DATA_FETCH_SUCCESS,
    CLIENT_PROJECTS_TOTAL_REVENUE_DATA_FETCHING_SUCCESS,
    CLIENT_INFORMATION_DATA_FETCH_SUCCESS,
    CLIENT_INFORMATION_DATA_FETCHING_SUCCESS,
    CLIENTS_DELETE_SUCCESS,
    CLIENT_INTERVIEW_DATES_FETCH_SUCCESS,
    CLIENT_INTERVIEW_DATES_DATA_FETCH_SUCCESS,
    CLIENT_INTERVIEW_DATES_DATA_FETCHING_SUCCESS,
    CLIENT_JOBS_DASHBOARD_FETCH_SUCCESS,
    CLIENT_NOTIFICATIONS_FETCH_SUCCESS,
    CLIENT_JOBS_DASHBOARD_DATA_FETCH_SUCCESS,
    CLIENT_JOBS_DASHBOARD_DATA_FETCHING_SUCCESS,
    CLIENT_ADMINS_FETCH_SUCCESS,
    CLIENT_ADMINS_REPLACE_SUCCESS,
    CLIENT_ADMIN_ADD_SUCCESS,
    CLIENT_ADMIN_DELETE_SUCCESS,
    CLIENT_PROJECTS_COUNT_FETCH_SUCCESS,
    CLIENT_TEAM_MEMBERS_COUNT_FETCH_SUCCESS,
    CLIENTS_PROJECTS_OVERVIEW_DATA_FETCHING_SUCCESS,
    CLIENTS_PROJECTS_OVERVIEW_DATA_FETCH_SUCCESS,
    AGENCY_QUERIED_CLIENTS_RESET_FETCH_SUCCESS,
    ACCOUNT_MANAGER_FETCH_ALL_SUCCESS,
} from 'actions/actionTypes';

const clientsProjectsOverviewDataFetchingSuccess = () => ({
    type: CLIENTS_PROJECTS_OVERVIEW_DATA_FETCHING_SUCCESS,
});

const clientsProjectsOverviewDataFetchSuccess = () => ({
    type: CLIENTS_PROJECTS_OVERVIEW_DATA_FETCH_SUCCESS,
});

const fetchRegisterClientSuccess = (clientData) => ({
    type: CLIENT_REGISTER_FETCH_SUCCESS,
    payload: clientData
});

const fetchAllClientsSuccess = clientsData => ({
    type: CLIENT_FETCH_ALL_SUCCESS,
    payload: clientsData
});

const fetchClientsPageSuccess = clientsData => ({
    type: CLIENT_PAGE_FETCH_SUCCESS,
    payload: clientsData
});

const fetchAllAccountManagersSuccess = data => ({
    type: ACCOUNT_MANAGER_FETCH_ALL_SUCCESS,
    payload: data
});

export const fetchQueriedClientsResetSuccess = () => ({
    type: AGENCY_QUERIED_CLIENTS_RESET_FETCH_SUCCESS,
    payload: [],
});

export const fetchClientInformationSuccess = clientData => ({
    type: CLIENT_INFORMATION_FETCH_SUCCESS,
    payload: clientData
});

const updateAvatarSuccess = pictureUrl => ({
    type: CLIENT_AVATAR_UPDATE_SUCCESS,
    payload: pictureUrl
});

const deleteAvatarSuccess = () => ({
    type: CLIENT_AVATAR_DELETE_SUCCESS
});

const updateContactInformationSuccess = contactInfo => ({
    type: CLIENT_CONTACT_INFORMATION_UPDATE_SUCCESS,
    payload: contactInfo
});

const updateBasicInformationSuccess = basicInfo => ({
    type: CLIENT_BASIC_INFORMATION_UPDATE_SUCCESS,
    payload: basicInfo
});

const updateInvoiceInformationSuccess = invoiceInfo => ({
    type: CLIENT_INVOICE_INFORMATION_UPDATE_SUCCESS,
    payload: invoiceInfo
});

const addClientProjectSuccess = projectData => ({
    type: CLIENT_PROJECT_ADD_SUCCESS,
    payload: projectData
});

const updateClientProjectSuccess = projectData => ({
    type: CLIENT_PROJECT_UPDATE_SUCCESS,
    payload: projectData
});

const fetchAllClientProjectsSuccess = projects => ({
    type: CLIENT_PROJECTS_FETCH_SUCCESS,
    payload: projects
});

const fetchClientInvoicingSuccess = data => ({
    type: CLIENT_INVOICING_FETCH_SUCCESS,
    payload: data
});

const fetchAllProjectReviewsSuccess = reviews => ({
    type: PROJECT_REVIEWS_FETCH_SUCCESS,
    payload: reviews
});

// TODO: rename addProjectEmployee
const addEmployeeToProjectSuccess = employeeInfo => ({
    type: EMPLOYEE_TO_PROJECT_ADD_SUCCESS,
    payload: employeeInfo
});

const addDedicatedEmployeeToProjectSuccess = employeeInfo => ({
    type: DEDICATED_EMPLOYEE_TO_PROJECT_ADD_SUCCESS,
    payload: employeeInfo
});

const deleteProjectEmployeeSuccess = employeeInfo => ({
    type: PROJECT_EMPLOYEE_DELETE_SUCCESS,
    payload: employeeInfo
});

const deleteDedicatedEmployeeFromProjectSuccess = employeeInfo => ({
    type: PROJECT_DEDICATED_EMPLOYEE_DELETE_SUCCESS,
    payload: employeeInfo
});

const updateClientAccountManagementInformationSuccess = accountManagementInfo => ({
    type: CLIENT_ACCOUNT_MANAGEMENT_INFORMATION_UPDATE_SUCCESS,
    payload: accountManagementInfo
});

const createTaskSuccess = newTasks => ({
    type: CLIENT_TASK_CREATE_SUCCESS,
    payload: newTasks
});

const editTaskSuccess = editedTasks => ({
    type: CLIENT_TASK_EDIT_SUCCESS,
    payload: editedTasks
});

const fetchTasksSuccess = tasks => ({
    type: CLIENT_TASKS_FETCH_SUCCESS,
    payload: tasks
});

const addFeedbackToProjectSuccess = (agencyId, clientId, projectId, feedback) => ({
    agencyId,
    clientId,
    projectId,
    type: PROJECT_REVIEWS_FETCH_SUCCESS,
    payload: feedback
})

const fetchClientProjectDocumentsSuccess = documentsData => ({
    type: CLIENT_PROJECT_DOCUMENTS_FETCH_SUCCESS,
    payload: documentsData
});

const addClientProjectFilesSuccess = filesData => ({
    type: CLIENT_PROJECT_FILES_ADD_SUCCESS,
    payload: filesData
});

const addClientProjectLinkSuccess = linkData => ({
    type: CLIENT_PROJECT_LINK_ADD_SUCCESS,
    payload: linkData
});

const deleteClientProjectLinkSuccess = linkData => ({
    type: CLIENT_PROJECT_LINK_DELETE_SUCCESS,
    payload: linkData
});

const deleteClientProjectFileSuccess = fileData => ({
    type: CLIENT_PROJECT_FILE_DELETE_SUCCESS,
    payload: fileData
});

const fetchClientProjectsFeedbackSuccess = feedbackData => ({
    type: CLIENT_PROJECTS_FEEDBACK_FETCH_SUCCESS,
    payload: feedbackData
});

const fetchClientProjectSuccess = project => ({
    type: CLIENT_PROJECT_FETCH_SUCCESS,
    payload: project,
});

const fetchClientProjectPending = () => ({
    type: CLIENT_PROJECT_FETCH_PENDING,
})

const fetchAllClientTeamHistorySuccess = data => ({
    type: ALL_CLIENT_TEAM_HISTORY_FETCH_SUCCESS,
    payload: data,
});

const fetchClientProjectEmployeeHistorySuccess = data => ({
    type: CLIENT_PROJECT_EMPLOYEE_HISTORY_FETCH_SUCCESS,
    payload: data,
});

const updateClientFlagSuccess = data => ({
    type: CLIENT_UPDATE_FLAG_SUCCESS,
    payload: data,
});

const fetchClientTotalRevenue = data => ({
    type: CLIENT_TOTAL_REVENUE_FETCH_SUCCESS,
    payload: data
});

const clientsAccountsTableDataFetchSuccess = () => ({
    type: CLIENTS_ACCOUNTS_TABLE_DATA_FETCH_SUCCESS,
});

const clientsAccountsTableDataFetchingSuccess = () => ({
    type: CLIENTS_ACCOUNTS_TABLE_DATA_FETCHING_SUCCESS,
});

const clientProjectsPageDataFetchSuccess = () => ({
    type: CLIENTS_PROJECTS_PAGE_DATA_FETCH_SUCCESS,
});

const clientProjectsPageDataFetchingSuccess = () => ({
    type: CLIENTS_PROJECTS_PAGE_DATA_FETCHING_SUCCESS,
});

const clientProjectDocumentsDataFetchSuccess = () => ({
    type: CLIENT_PROJECT_DOCUMENTS_DATA_FETCH_SUCCESS,
});

const clientProjectDocumentsDataFetchingSuccess = () => ({
    type: CLIENT_PROJECT_DOCUMENTS_DATA_FETCHING_SUCCESS,
});

const clientProjectsWithEmployeesDataFetchSuccess = () => ({
    type: CLIENT_PROJECTS_WITH_EMPLOYEES_DATA_FETCH_SUCCESS,
});

const clientProjectsWithEmployeesDataFetchingSuccess = () => ({
    type: CLIENT_PROJECTS_WITH_EMPLOYEES_DATA_FETCHING_SUCCESS,
});

const clientProjectsTotalRevenueDataFetchSuccess = () => ({
    type: CLIENT_PROJECTS_TOTAL_REVENUE_DATA_FETCH_SUCCESS,
});

const clientProjectsTotalRevenueDataFetchingSuccess = () => ({
    type: CLIENT_PROJECTS_TOTAL_REVENUE_DATA_FETCHING_SUCCESS,
});

const clientInformationDataFetchSuccess = () => ({
    type: CLIENT_INFORMATION_DATA_FETCH_SUCCESS,
});

const clientInformationDataFetchingSuccess = () => ({
    type: CLIENT_INFORMATION_DATA_FETCHING_SUCCESS,
});

const clientInterviewDatesFetchSuccess = data => ({
    type: CLIENT_INTERVIEW_DATES_FETCH_SUCCESS,
    payload: data
});

const clientInterviewsDataFetchSuccess = () => ({
    type: CLIENT_INTERVIEW_DATES_DATA_FETCH_SUCCESS,
});

const clientInterviewsDataFetchingSuccess = () => ({
    type: CLIENT_INTERVIEW_DATES_DATA_FETCHING_SUCCESS,
});

const clientJobsDashboardDataFetchSuccess = () => ({
    type: CLIENT_JOBS_DASHBOARD_DATA_FETCH_SUCCESS,
});

const clientJobsDashboardDataFetchingSuccess = () => ({
    type: CLIENT_JOBS_DASHBOARD_DATA_FETCHING_SUCCESS,
});

const clientsDeletionSuccess = (clientIds) => ({
    type: CLIENTS_DELETE_SUCCESS,
    payload: clientIds,
});

const fetchClientAdminsSuccess = (data) => ({
    type: CLIENT_ADMINS_FETCH_SUCCESS,
    payload: data
});

const replaceClientAdminsSuccess = (data) => ({
    type: CLIENT_ADMINS_REPLACE_SUCCESS,
    payload: data
});

const clientAdminDeleteSuccess = (clientAdminId) => ({
    type: CLIENT_ADMIN_DELETE_SUCCESS,
    payload: clientAdminId,
})

const clientAdminAddSuccess = (data) => ({
    type: CLIENT_ADMIN_ADD_SUCCESS,
    payload: data
});

const fetchClientJobsDashboardSuccess = (data) => ({
    type: CLIENT_JOBS_DASHBOARD_FETCH_SUCCESS,
    payload: data
});

const fetchClientNotificationsSuccess = (data) => ({
    type: CLIENT_NOTIFICATIONS_FETCH_SUCCESS,
    payload: data
});

const fetchClientProjectsCountSuccess = (data) => ({
    type: CLIENT_PROJECTS_COUNT_FETCH_SUCCESS,
    payload: data
});

const fetchClientTeamMembersCountSuccess = (data) => ({
    type: CLIENT_TEAM_MEMBERS_COUNT_FETCH_SUCCESS,
    payload: data
});

export const registerClient = (agencyId, clientData, updateState=true) => async dispatch => {
    try {
        dispatch(showDialogActionButtonLoader());

        const agencyInfo = getAgencyBasicInfo(store.getState());

        clientData.currency = agencyInfo.currency;

        const client = await clientService.registerClient(agencyId, clientData);

        logEvent(AMPLITUDE_EVENT_TYPES.ADD_CLIENT_PROFILE, null, { isEmailAdded: !!clientData.email });

        if (client._id) {
            if (updateState) {
                dispatch(fetchRegisterClientSuccess(client));
            }
            
            dispatch(showNotification(CLIENT_MESSAGES.ADD_CLIENT_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
        }
    } catch (error) {
        if (error.message === EMAIL_MESSAGES.EMAIL_ALREADY_USED) {
            dispatch(authorizedShowNotification(error)(CLIENT_MESSAGES.ADD_CLIENT_EMAIL_FAILURE_WARNING, NOTIFICATION_TYPES.WARNING));
        } else if (error.message === EMAIL_MESSAGES.EMAIL_DISABLED) {
            dispatch(authorizedShowNotification(error)(error.message, NOTIFICATION_TYPES.WARNING));
        } else if (error.message === CLIENT_MESSAGES.ADD_CLIENT_NO_ACCOUNT_MANAGER_FAIL) {
            dispatch(authorizedShowNotification(error)(error.message, NOTIFICATION_TYPES.ERROR));
        } else {
            dispatch(authorizedShowNotification(error)(CLIENT_MESSAGES.ADD_CLIENT_FAIL, NOTIFICATION_TYPES.ERROR));
        }
    } finally {
        dispatch(hideDialogActionButtonLoader());
    }
};

export const fetchClients = (agencyId) => async dispatch => {
    try {
        dispatch(clientsAccountsTableDataFetchingSuccess());

        const clientsData = await clientService.fetchClients(agencyId);
        dispatch(fetchAllClientsSuccess(clientsData));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(error.message, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(clientsAccountsTableDataFetchSuccess());
    }
};

export const fetchClientsPage = (agencyId, query, resetState) => async dispatch => {
    try {
        if (resetState) {
            dispatch(fetchQueriedClientsResetSuccess());
        }

        const result = await clientService.fetchClientsPage(agencyId, query);
        dispatch(fetchClientsPageSuccess(result.data));
        dispatch(fetchAllAccountManagersSuccess(result.accountManagers));
        return result.totalCount;
    } catch (error) {
        dispatch(authorizedShowNotification(error)(error.message, NOTIFICATION_TYPES.ERROR));
    }
};

export const exportClients = (agencyId, exportFields, filters) => async dispatch => {
    try {
        await clientService.exportClients(agencyId, { exportFields, filters });
    } catch (error) {
        dispatch(authorizedShowNotification(error)(error.message, NOTIFICATION_TYPES.ERROR));
    }
};

export const fetchClientInformation = (agencyId, clientId) => async dispatch => {
    try {
        dispatch(clientInformationDataFetchingSuccess());

        const clientData = await clientService.fetchClientInformation(agencyId, clientId);

        dispatch(fetchClientInformationSuccess(clientData));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(CLIENT_MESSAGES.FETCH_CLIENT_INFO_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(clientInformationDataFetchSuccess());
    }
};

export const updateAvatar = (croppedImgData, clientId, agencyId) => async dispatch => {
    try {
        const resultUploadedPictureObject = await clientService.updateAvatar(croppedImgData, agencyId, clientId);
        dispatch(updateAvatarSuccess(resultUploadedPictureObject));
        dispatch(showNotification(CLIENT_MESSAGES.UPDATE_AVATAR_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(CLIENT_MESSAGES.UPDATE_AVATAR_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const deleteAvatar = (clientId, agencyId) => async dispatch => {
    try {
        await clientService.deleteAvatar(agencyId, clientId);
        dispatch(deleteAvatarSuccess());
        dispatch(showNotification(CLIENT_MESSAGES.DELETE_AVATAR_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(CLIENT_MESSAGES.DELETE_AVATAR_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const updateBasicInformation = (agencyId, clientId, basicInfo) => async dispatch => {
    try {
        const newBasicInfo = await clientService.updateBasicInformation(agencyId, clientId, basicInfo);
        dispatch(updateBasicInformationSuccess({ basic: newBasicInfo }));
        dispatch(showNotification(CLIENT_MESSAGES.UPDATE_BASIC_INFO_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(CLIENT_MESSAGES.UPDATE_BASIC_INFO_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const updateContactInformation = (agencyId, clientId, contactInfo) => async dispatch => {
    try {
        const newContactInfo = await clientService.updateContactInformation(agencyId, clientId, contactInfo);
        dispatch(updateContactInformationSuccess({ contact: newContactInfo }));
        dispatch(showNotification(CLIENT_MESSAGES.UPDATE_CONTACT_INFO_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(CLIENT_MESSAGES.UPDATE_CONTACT_INFO_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const updateInvoiceInformation = (agencyId, clientId, invoiceInfo) => async dispatch => {
    try {
        const newInvoiceInfo = await clientService.updateInvoiceInformation(agencyId, clientId, invoiceInfo);
        dispatch(updateInvoiceInformationSuccess({ invoicing: newInvoiceInfo }));
        dispatch(showNotification(CLIENT_MESSAGES.UPDATE_INVOICE_INFO_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(CLIENT_MESSAGES.UPDATE_INVOICE_INFO_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const fetchClientProjectsWithEmployees = (agencyId, clientId) => async dispatch => {
    try {
        dispatch(clientProjectsWithEmployeesDataFetchingSuccess());

        const projects = await clientService.fetchClientProjectsWithEmployees(agencyId, clientId);
        // TODO: Fix nested projects response
        dispatch(fetchAllClientProjectsSuccess(projects));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(CLIENT_MESSAGES.FETCH_CLIENT_PROJECTS_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(clientProjectsWithEmployeesDataFetchSuccess());
    }
};

export const fetchClientProjectsTotalRevenue = (agencyId, clientId) => async dispatch => {
    try {
        dispatch(clientProjectsTotalRevenueDataFetchingSuccess());

        const totalRevenue = await clientService.fetchClientProjectsTotalRevenue(agencyId, clientId);

        dispatch(fetchClientTotalRevenue({ totalRevenue }));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(CLIENT_MESSAGES.FETCH_CLIENT_TOTAL_REVENUE_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(clientProjectsTotalRevenueDataFetchSuccess());
    }
};

export const fetchClientProjects = (agencyId, clientId, requiredFields) => async dispatch => {
    try {
        const projects = await clientService.fetchClientProjects(agencyId, clientId, requiredFields);
        // TODO: Fix nested projects response
        dispatch(fetchAllClientProjectsSuccess(projects));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(CLIENT_MESSAGES.FETCH_CLIENT_PROJECTS_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const addClientProject = (agencyId, clientId, projectData) => async dispatch => {
    try {
        const project = await clientService.addClientProject(agencyId, clientId, projectData);

        logEvent(AMPLITUDE_EVENT_TYPES.ADD_CLIENT_PROJECT);
        dispatch(addClientProjectSuccess(project));
        dispatch(showNotification(CLIENT_MESSAGES.ADD_PROJECT_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(CLIENT_MESSAGES.ADD_PROJECT_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const updateClientProject = (projectData, agencyId, clientId) => async dispatch => {
    try {
        const project = await clientService.updateClientProject(agencyId, clientId, projectData);
        dispatch(updateClientProjectSuccess(project));
        dispatch(showNotification(CLIENT_MESSAGES.UPDATE_PROJECT_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(CLIENT_MESSAGES.UPDATE_PROJECT_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const getAllProjectReviews = (agencyId, clientId, projectId) => async dispatch => {
    try {
        const reviews = await clientService.fetchProjectReviews(agencyId, clientId, projectId);
        Object.assign(reviews, { projectId: projectId })
        dispatch(fetchAllProjectReviewsSuccess(reviews));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(CLIENT_MESSAGES.FETCH_PROJECT_REVIEW_FAIL, NOTIFICATION_TYPES.ERROR));
    }
}

export const submitFeedbackToProject = (agencyId, clientId, projectId, feedback) => async dispatch => {
    try {
        await clientService.addFeedbackToProject(agencyId, clientId, projectId, feedback);
        dispatch(addFeedbackToProjectSuccess(agencyId, clientId, projectId, feedback))
        dispatch(showNotification(CLIENT_MESSAGES.ADD_PROJECT_FEEDBACK_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(CLIENT_MESSAGES.ADD_PROJECT_FEEDBACK_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const addEmployeeToProject = (agencyId, clientId, projectId, employeeInfo) => async dispatch => {
    try {
        const employeeData = {
            employeeId: employeeInfo.employee._id,
            hourRate: employeeInfo.hourRate,
            currency: employeeInfo.currency,
            dateAdded: employeeInfo.dateAdded
        };

        const updatedEmployeeInfo = await clientService.addEmployeeToProject(agencyId, clientId, projectId, { employeeData });

        Object.assign(updatedEmployeeInfo, { projectId });

        dispatch(addEmployeeToProjectSuccess(updatedEmployeeInfo));

        const message = employeeInfo.employee.occupationStatus !== EMPLOYEE_OCCUPATION_STATUSES_ARRAY[0].key
            ? {
                messageText: `${CLIENT_MESSAGES.ADD_PROJECT_EMPLOYEE_SUCCESS} \n ${CHECK_OCCUPATION_STATUS_MESSAGE}`,
                messageType: NOTIFICATION_TYPES.WARNING
            }
            : {
                messageText: CLIENT_MESSAGES.ADD_PROJECT_EMPLOYEE_SUCCESS,
                messageType: NOTIFICATION_TYPES.SUCCESS
            }

        dispatch(showNotification(message.messageText, message.messageType));

    } catch (error) {
        dispatch(authorizedShowNotification(error)(CLIENT_MESSAGES.ADD_PROJECT_EMPLOYEE_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const addDedicatedEmployeeToProject = (agencyId, clientId, projectId, employeeInfo) => async dispatch => {
    try {
        const employeeData = {
            employeeId: employeeInfo.employee._id,
            dateAdded: employeeInfo.dateAdded,
            currency: employeeInfo.currency,
            employerTotalCost: employeeInfo.employerTotalCost,
            monthlyServiceFee: employeeInfo.monthlyServiceFee,
        };

        const updatedEmployeeInfo = await clientService.addDedicatedEmployeeToProject(agencyId, clientId, projectId, { employeeData });

        Object.assign(updatedEmployeeInfo, { projectId });

        dispatch(addDedicatedEmployeeToProjectSuccess(updatedEmployeeInfo));

        const message = employeeInfo.employee.occupationStatus !== EMPLOYEE_OCCUPATION_STATUSES_ARRAY[0].key
            ? {
                messageText: `${CLIENT_MESSAGES.ADD_DEDICATED_PROJECT_EMPLOYEE_SUCCESS} \n ${CHECK_OCCUPATION_STATUS_MESSAGE}`,
                messageType: NOTIFICATION_TYPES.WARNING
            }
            : {
                messageText: CLIENT_MESSAGES.ADD_DEDICATED_PROJECT_EMPLOYEE_SUCCESS,
                messageType: NOTIFICATION_TYPES.SUCCESS
            }

        dispatch(showNotification(message.messageText, message.messageType));

    } catch (error) {
        dispatch(authorizedShowNotification(error)(CLIENT_MESSAGES.ADD_DEDICATED_PROJECT_EMPLOYEE_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const updateProjectEmployee = (agencyId, clientId, projectId, employeeInfo, adminName, oldData) => async dispatch => {
    try {
        const employeeData = {
            _id: employeeInfo.employee._id,
            employeeId: employeeInfo.employee.employeeId,
            hourRate: employeeInfo.hourRate,
            currency: employeeInfo.currency,
            dateAdded: employeeInfo.dateAdded,
            billingType: employeeInfo.billingType,
            oldData,
            adminName
        };

        const project = await clientService.updateProjectEmployee(agencyId, clientId, projectId, employeeData.employeeId, { employeeData });
        dispatch(fetchClientProjectSuccess(project));

        dispatch(showNotification(CLIENT_MESSAGES.EDIT_PROJECT_EMPLOYEE_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(CLIENT_MESSAGES.EDIT_PROJECT_EMPLOYEE_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const updateProjectDedicatedEmployee = (agencyId, clientId, projectId, employeeInfo, adminName, oldData) => async dispatch => {
    try {
        const employeeData = {
            _id: employeeInfo.employee._id,
            employeeId: employeeInfo.employee.employeeId,
            hourRate: employeeInfo.hourRate,
            currency: employeeInfo.currency,
            dateAdded: employeeInfo.dateAdded,
            employerTotalCost: employeeInfo.employerTotalCost,
            monthlyServiceFee: employeeInfo.monthlyServiceFee,
            billingType: employeeInfo.billingType,
            oldData,
            adminName,
        };

        const project = await clientService.updateProjectDedicatedEmployee(agencyId, clientId, projectId, employeeData.employeeId, { employeeData });
        dispatch(fetchClientProjectSuccess(project));

        dispatch(showNotification(CLIENT_MESSAGES.EDIT_PROJECT_EMPLOYEE_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(CLIENT_MESSAGES.EDIT_PROJECT_EMPLOYEE_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const deleteProjectEmployee = (agencyId, clientId, projectId, employeeId, dateDeleted) => async dispatch => {
    try {
        const result = await clientService.removeProjectEmployee(agencyId, clientId, projectId, employeeId, dateDeleted);

        dispatch(deleteProjectEmployeeSuccess({ _id: projectId, employee: result }));
        dispatch(showNotification(CLIENT_MESSAGES.DELETE_PROJECT_EMPLOYEE_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(CLIENT_MESSAGES.DELETE_PROJECT_EMPLOYEE_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const deleteDedicatedEmployeeFromProject = (agencyId, clientId, projectId, employeeId, dateDeleted) => async dispatch => {
    try {
        const result = await clientService.removeProjectDedicatedEmployee(agencyId, clientId, projectId, employeeId, dateDeleted);

        dispatch(deleteDedicatedEmployeeFromProjectSuccess({ _id: projectId, employee: result }));
        dispatch(showNotification(CLIENT_MESSAGES.DELETE_DEDICATED_PROJECT_EMPLOYEE_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(CLIENT_MESSAGES.DELETE_DEDICATED_PROJECT_EMPLOYEE_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const updateClientAccountManagementInformation = (agencyId, clientId, updateData, isAccountManagerUpdated) => async dispatch => {
    try {
        const result = await clientService.updateAccountManagementInformation(agencyId, clientId, updateData);

        if (isAccountManagerUpdated) {
            logEvent(AMPLITUDE_EVENT_TYPES.CHANGE_CLIENT_ACCOUNT_MANAGER);
        }

        dispatch(updateClientAccountManagementInformationSuccess(result));
        dispatch(showNotification(CLIENT_MESSAGES.UPDATE_ACCOUNT_MANAGER_INFO_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(CLIENT_MESSAGES.UPDATE_ACCOUNT_MANAGER_INFO_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const createTask = (task, clientId, agencyId) => async dispatch => {
    try {
        const newTask = await clientService.createTask(agencyId, clientId, task);
        dispatch(createTaskSuccess(newTask));
        dispatch(showNotification(TASK_MESSAGES.CREATE_TASK_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(TASK_MESSAGES.CREATE_TASK_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const editTask = (task, clientId, agencyId) => async dispatch => {
    try {
        const editedTask = await clientService.updateTask(agencyId, clientId, task);
        dispatch(editTaskSuccess(editedTask));
        dispatch(showNotification(TASK_MESSAGES.EDIT_TASK_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(TASK_MESSAGES.EDIT_TASK_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const fetchTasks = (clientId, agencyId) => async dispatch => {
    try {
        dispatch(tasksDataFetchingSuccess());

        const tasks = await clientService.getAllTasks(agencyId, clientId);
        dispatch(fetchTasksSuccess(tasks));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(TASK_MESSAGES.FETCH_TASKS_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(tasksDataFetchSuccess());
    }
};

export const addProjectFiles = (agencyId, clientId, projectId, filesData) => async dispatch => {
    try {
        const type = 'files';
        const filesResult = await clientService.addProjectFiles(agencyId, clientId, projectId, type, filesData);

        dispatch(addClientProjectFilesSuccess({ ...filesResult, projectId }));
        dispatch(showNotification(CLIENT_MESSAGES.ADD_PROJECT_FILES_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(CLIENT_MESSAGES.ADD_PROJECT_FILES_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const fetchProjectDocuments = (agencyId, clientId, projectId) => async dispatch => {
    try {
        dispatch(clientProjectDocumentsDataFetchingSuccess());
        const documents = await clientService.fetchProjectDocuments(agencyId, clientId, projectId);
        dispatch(fetchClientProjectDocumentsSuccess({ projectId, ...documents }));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(CLIENT_MESSAGES.FETCH_PROJECT_DOCUMENTS_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(clientProjectDocumentsDataFetchSuccess());
    }
};

export const addProjectLink = (agencyId, clientId, projectId, linkData) => async dispatch => {
    try {
        const type = 'link';
        const link = await clientService.addProjectLink(agencyId, clientId, projectId, type, linkData);

        dispatch(addClientProjectLinkSuccess({ link, projectId }));
        dispatch(showNotification(CLIENT_MESSAGES.ADD_PROJECT_LINK_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(CLIENT_MESSAGES.ADD_PROJECT_LINK_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const downloadProjectFile = (agencyId, clientId, projectId, fileId, documentType, fileName) => async dispatch => {
    try {
        await clientService.downloadProjectDocument(agencyId, clientId, projectId, fileId, documentType, fileName);
    } catch (error) {
        dispatch(authorizedShowNotification(error)(CLIENT_MESSAGES.DOWNLOAD_PROJECT_FILE_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const deleteProjectFile = (agencyId, clientId, projectId, documentId, documentType) => async dispatch => {
    try {
        await clientService.deleteProjectDocument(agencyId, clientId, projectId, documentId, documentType);

        if (documentType === 'link') {
            dispatch(deleteClientProjectLinkSuccess({ documentId, projectId }));
        } else if (documentType === 'file') {
            dispatch(deleteClientProjectFileSuccess({ documentId, projectId }));
        }

        dispatch(showNotification(CLIENT_MESSAGES.DELETE_PROJECT_DOCUMENT_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(CLIENT_MESSAGES.DELETE_PROJECT_DOCUMENT_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const fetchClientProjectsFeedback = (agencyId, clientId) => async dispatch => {
    try {
        const clientProjectsFeedback = await clientService.fetchClientProjectsFeedback(agencyId, clientId);
        dispatch(fetchClientProjectsFeedbackSuccess(clientProjectsFeedback));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(CLIENT_MESSAGES.FETCH_PROJECTS_FEEDBACK_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const fetchClientDashboardInformation = (agencyId, clientId) => async dispatch => {
    try {
        dispatch(dashboardDataFetchingSuccess());
        const result = await clientService.fetchClientDashboardInformation(agencyId, clientId);

        dispatch(fetchClientProjectsFeedbackSuccess(result.clientProjectsFeedback));
        dispatch(fetchClientProjectsCountSuccess(result.projectsCount));
        dispatch(fetchClientTeamMembersCountSuccess(result.teamMembersCount));
        dispatch(fetchInvoicesSuccess(result.invoices));
        dispatch(fetchPerformanceCriteriasSuccess(result.performanceCriteria));
        dispatch(fetchAppliedConsultantsDataSuccess(result.appliedCandidates));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(DASHBOARD_MESSAGES.FETCH_DASHBOARD_PAGE_INFORMATION_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(dashboardDataFetchSuccess());
    }
};

export const fetchProjectEmployeesApprovedHolidays = (agencyId, clientId, projectId, from, to) => async dispatch => {
    try {
        dispatch(approvedHolidaysTableDataFetchingSuccess());
        const holidays = await clientService.fetchProjectEmployeesApprovedHolidays(agencyId, clientId, projectId, from, to);

        dispatch(fetchApprovedHolidaysSuccess(holidays));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(HOLIDAYS_MESSAGES.FETCH_HOLIDAY_REQUESTS_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(approvedHolidaysTableDataFetchSuccess());
    }
};

export const fetchClientProject = (agencyId, clientId, projectId) => async dispatch => {
    dispatch(clientsProjectsOverviewDataFetchingSuccess());
    dispatch(fetchClientProjectPending());

    try {
        const project = await clientService.fetchClientProject(agencyId, clientId, projectId);
        dispatch(fetchClientProjectSuccess(project));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(CLIENT_MESSAGES.FETCH_CLIENT_PROJECT_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(clientsProjectsOverviewDataFetchSuccess());
    }
};

export const fetchAllClientTeamHistory = (agencyId, clientId) => async dispatch => {
    dispatch(showApplicationLoader());
    try {
        const history = await clientService.fetchAllClientTeamHistory(agencyId, clientId);

        dispatch(fetchAllClientTeamHistorySuccess(history));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(CLIENT_MESSAGES.FETCH_ALL_CLIENT_TEAM_HISTORY_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(hideApplicationLoader());
    }
}

export const fetchClientProjectEmployeeHistory = (agencyId, clientId, projectId, employeeId) => async dispatch => {
    dispatch(showApplicationLoader());
    try {
        const history = await clientService.fetchClientProjectEmployeeHistory(agencyId, clientId, projectId, employeeId);

        dispatch(fetchClientProjectEmployeeHistorySuccess(history));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(CLIENT_MESSAGES.FETCH_CLIENT_PROJECT_EMPLOYEE_HISTORY_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(hideApplicationLoader());
    }
};

export const fetchClientProjectDedicatedEmployeeHistory = (agencyId, clientId, projectId, employeeId, field) => async dispatch => {
    dispatch(showApplicationLoader());
    try {
        const history = await clientService.fetchClientProjectDedicatedEmployeeHistory(agencyId, clientId, projectId, employeeId, field);
        dispatch(fetchClientProjectEmployeeHistorySuccess(history));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(CLIENT_MESSAGES.FETCH_CLIENT_PROJECT_EMPLOYEE_HISTORY_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(hideApplicationLoader());
    }
};

export const updateClientFlag = (agencyId, clientId, flagName) => async dispatch => {
    try {
        const flag = await clientService.updateClientFlag(agencyId, clientId, { name: flagName });

        dispatch(updateClientFlagSuccess({ flag, _id: clientId }));
        dispatch(showNotification(CLIENT_MESSAGES.CLIENT_UPDATE_FLAG_SUCCESS, NOTIFICATION_TYPES.SUCCESS))
    } catch (error) {
        dispatch(authorizedShowNotification(error)(CLIENT_MESSAGES.CLIENT_UPDATE_FLAG_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const fetchClientProjectsPage = (agencyId, clientId, requiredFields) => async dispatch => {
    try {
        dispatch(clientProjectsPageDataFetchingSuccess());

        const projects = await clientService.fetchClientProjectsWithEmployees(agencyId, clientId);
        const { performanceCriterias } = await agencyService.getAgencyPerformanceCriterias(agencyId);
        const employeesData = await employeeService.fetchAllClientConsultants(agencyId, requiredFields);

        dispatch(fetchEmployeesSuccess(employeesData));
        dispatch(fetchPerformanceCriteriasSuccess(performanceCriterias));
        dispatch(fetchAllClientProjectsSuccess(projects));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(CLIENT_MESSAGES.CLIENT_UPDATE_FLAG_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(clientProjectsPageDataFetchSuccess());
    }
}

export const fetchClientInvoicingInformation = (agencyId, clientId) => async dispatch => {
    try {
        const invoicingInformation = await clientService.fetchClientInvoicingInformation(agencyId, clientId);

        dispatch(fetchClientInvoicingSuccess(invoicingInformation));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(CLIENT_MESSAGES.CLIENT_FETCH_INVOICING_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(clientProjectsPageDataFetchSuccess());
    }
}

export const fetchClientInterviewDates = (agencyId, clientId, startDate, endDate, isInitialPageVisit) => async dispatch => {
    try {
        if (isInitialPageVisit) {
            dispatch(clientInterviewsDataFetchingSuccess());
        }

        const result = await clientService.fetchClientInterviewDates(agencyId, clientId, startDate, endDate);

        dispatch(clientInterviewDatesFetchSuccess(result));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(CLIENT_MESSAGES.CLIENT_UPDATE_FLAG_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(clientInterviewsDataFetchSuccess());
    }
}

export const deleteClients = (agencyId, clientIds) => async dispatch => {
    try {
        const clientsIds = await clientService.deleteClients(agencyId, clientIds);
        dispatch(clientsDeletionSuccess(clientsIds));
        dispatch(showNotification(CLIENT_MESSAGES.CLIENTS_DELETION_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (err) {
        dispatch(authorizedShowNotification(err)(CLIENT_MESSAGES.CLIENTS_DELETION_FAILED, NOTIFICATION_TYPES.ERROR));
    }
};

export const addClientAdmin = (agencyId, clientId, clientData, updateState = true) => async dispatch => {
    try {
        dispatch(showDialogActionButtonLoader());

        const client = await clientService.addClientAdmin(agencyId, clientId, clientData);

        updateState && dispatch(clientAdminAddSuccess(client));
        dispatch(showNotification(CLIENT_MESSAGES.ADD_CLIENT_ADMIN_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        if (error.message === EMAIL_MESSAGES.EMAIL_ALREADY_USED) {
            dispatch(authorizedShowNotification(error)(CLIENT_MESSAGES.ADD_CLIENT_EMAIL_FAILURE_WARNING, NOTIFICATION_TYPES.WARNING));
        } else if (error.message === EMAIL_MESSAGES.EMAIL_DISABLED) {
            dispatch(authorizedShowNotification(error)(error.message, NOTIFICATION_TYPES.WARNING));
        } else {
            dispatch(authorizedShowNotification(error)(CLIENT_MESSAGES.ADD_CLIENT_ADMIN_FAILED, NOTIFICATION_TYPES.ERROR));
        }
    } finally {
        dispatch(hideDialogActionButtonLoader());
    }
}

export const deleteClientAdmin = (agencyId, clientId, clientAdminId, updateState = true) => async dispatch => {
    try {
        await clientService.deleteClientAdmin(agencyId, clientId, clientAdminId);

        updateState && dispatch(clientAdminDeleteSuccess(clientAdminId));
        dispatch(showNotification(CLIENT_MESSAGES.DELETE_CLIENT_ADMIN_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(CLIENT_MESSAGES.DELETE_CLIENT_ADMIN_FAILED, NOTIFICATION_TYPES.ERROR));
    }
}

export const fetchClientAdmins = (agencyId, clientId, query, replaceState) => async dispatch => {
    try {
        const result = await clientService.fetchClientAdmins(agencyId, clientId, query);

        if (replaceState) {
            dispatch(replaceClientAdminsSuccess(result.clientAdmins));
        } else {
            dispatch(fetchClientAdminsSuccess(result.clientAdmins));
        }
        return result.totalCount;
    } catch (error) {
        dispatch(authorizedShowNotification(error)(AGENCY_MESSAGES.FETCH_USER_MANAGEMENT_PAGE_FAIL, NOTIFICATION_TYPES.ERROR));
    }
}

export const fetchClientJobsDashboard = (agencyId, clientId) => async dispatch => {
    try {
        dispatch(clientJobsDashboardDataFetchingSuccess());

        const jobs = await clientService.fetchClientJobsDashboard(agencyId, clientId);

        dispatch(fetchClientJobsDashboardSuccess(jobs));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(CLIENT_MESSAGES.FETCH_CLIENT_JOBS_DASHBOARD_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(clientJobsDashboardDataFetchSuccess());
    }
};

export const fetchClientNotifications = (agencyId, clientId) => async dispatch => {
    try {
        const notifications = await clientService.fetchClientNotifications(agencyId, clientId);

        dispatch(fetchClientNotificationsSuccess(notifications));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(CLIENT_MESSAGES.FETCH_CLIENT_NOTIFICATIONS_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const updateClientNotificationsSeenList = (agencyId, clientId, notifications) => async dispatch => {
    try {
        await clientService.updateClientNotificationsSeenList(agencyId, clientId, notifications);
    } catch (error) {
        dispatch(authorizedShowNotification(error)(CLIENT_MESSAGES.UPDATE_CLIENT_NOTIFICATIONS_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};
