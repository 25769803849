import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { fetchCandidateVideoInterviewFeedbackInformation, updateCandidateCriteria } from 'actions/candidateActions';

import { getCandidateProfileSteps, getCandidateStatusHistory } from 'reducers';

import FeedbackRating from './FeedbackRating';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { ReactComponent as InfoIcon } from 'assets/info-icon.svg';

import { DATE_FORMAT_WITH_DOTS_FULL_YEAR } from 'constants/commonConstants';
import { CANDIDATE_FEEDBACK_RATING_CRITERIA, CANDIDATE_STATUSES } from 'constants/candidateConstants';
import { CHECKLIST_DIALOGS_MAP } from '../HiringChecklist/HiringChecklistConstants';
import { DATE_SHORT_YEAR } from 'constants/timesFormat';

import { getInitialFormValues, mapValuesToRequestBody } from '../HiringChecklist/HiringChecklistUtils';
import { convertFromMacroCaseToCamelCase, formatStringAsCamelCase, formatStringAsClassName } from 'utils/helpers';
import { getRejectionReasonText } from './CandidateVideoInterviewFeedbackUtils';
import { validateRequiredFieldsCandidateRate } from 'utils/formValidations';

import './CandidateVideoInterviewFeedback.scss';

const CandidateVideoInterviewFeedback = ({
    agencyId,
    userId,
    candidateId,
    videoInterviewFeedback,
    fetchCandidateVideoInterviewFeedbackInformation,
    isAdminMode,
    updateCandidateCriteria,
    candidateProfileSteps,
    statusHistory,
}) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [isStatusHistoryExpanded, setIsStatusHistoryExpanded] = useState(false);

    useEffect(() => {
        fetchCandidateVideoInterviewFeedbackInformation(agencyId, candidateId);
    }, []);

    const handleOpenDialog = () => setOpenDialog(true);
    const handleCloseDialog = () => setOpenDialog(false);

    const statusToLabelMapper = {
        [CANDIDATE_STATUSES.VETTED]: 'Approved',
        [CANDIDATE_STATUSES.REJECTED]: 'Rejected',
        [CANDIDATE_STATUSES.PENDING]: 'Pending',
        [CANDIDATE_STATUSES.PENDING_VIDEO_REVIEW]: 'Pending',
        [CANDIDATE_STATUSES.PENDING_CALENDLY_REVIEW]: 'Pending',
        [CANDIDATE_STATUSES.NEW]: 'Pending',
        [CANDIDATE_STATUSES.INCOMPLETE_PROFILE]: 'Incomplete Profile',
    };

    const handleCandidateRateSubmit = async (_, values) => {
        const criteriaData = mapValuesToRequestBody(userId, values);
        await updateCandidateCriteria(agencyId, candidateId, criteriaData);
        handleCloseDialog();
    }

    const toggleStatusHistory = () => setIsStatusHistoryExpanded(prevState => !prevState);

    return (
        <div className="candidate-video-interview-feedback-wrapper" data-testid="candidate-video-interview-feedback-wrapper-id">
            <div className="candidate-video-interview-feedback-body">
                <div className="feedback-block grid col-3">
                    <div className="feedback-block-content">
                        <h3>Status</h3>
                    </div>
                    <div className="feedback-block-content status-date-block">
                        {videoInterviewFeedback.statusLastEdit && <p className="status-date">{moment.utc(videoInterviewFeedback.statusLastEdit).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR)}</p>}
                        {videoInterviewFeedback.reviewerName && isAdminMode && <p className="reviwer-name">({videoInterviewFeedback.reviewerName})</p>}
                    </div>
                    <div className="feedback-block-content right-aligned">
                        {videoInterviewFeedback.status &&
                            <div className={`status-label ${videoInterviewFeedback.status ? formatStringAsClassName(videoInterviewFeedback.status) : ''}`}>
                                <p>{statusToLabelMapper[videoInterviewFeedback.status]}</p>
                                {videoInterviewFeedback.status === CANDIDATE_STATUSES.REJECTED &&
                                    <Tooltip
                                        classes={{ tooltip: 'custom-tooltip' }}
                                        title={`Rejection reason: ${getRejectionReasonText(videoInterviewFeedback.rejectionReason)}`}
                                        placement="bottom-end"
                                        PopperProps={{
                                            popperOptions: {
                                                modifiers: {
                                                    offset: {
                                                        //Тhis moves the tooltip to the right by 15px
                                                        offset: '15,0',
                                                    }
                                                }
                                            }
                                        }}
                                    >
                                        <div className="info-icon-wrapper">
                                            <InfoIcon />
                                        </div>
                                    </Tooltip>
                                }
                            </div>
                        }
                    </div>
                </div>

                <Divider />

                <div className="feedback-block grid col-2">
                    <div className="feedback-block-content">
                        <h3>History</h3>
                    </div>
                    <div className="feedback-block-content right-aligned global-pointer-on-hover" onClick={toggleStatusHistory}>
                        {isStatusHistoryExpanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowLeftIcon />}
                    </div>
                </div>

                {isStatusHistoryExpanded &&
                    <div className="status-history-content">
                        {statusHistory.map((item, idx) =>
                            <div className="status-history-wrapper" key={idx}>
                                <div className="header-container">
                                    <p className="assignee-name">{item.reviewerName}</p>
                                    <p className="date">{moment(item.date).format(DATE_SHORT_YEAR)}</p>
                                    <div className={`status-label small ${formatStringAsClassName(item.status)}`}><p>{item.status}</p></div>
                                </div>
                                {item.criteria?.internalFeedback &&
                                    <div className="description">
                                        {item.criteria.internalFeedback}
                                    </div>
                                }
                            </div>
                        )}
                    </div>
                }

                <Divider />
                {
                    Object.keys(CANDIDATE_FEEDBACK_RATING_CRITERIA).map((key, i) => {
                        const criterion = CANDIDATE_FEEDBACK_RATING_CRITERIA[key];
                        const camelCaseCriterion = formatStringAsCamelCase(criterion);
                        const camelCaseKey = convertFromMacroCaseToCamelCase(key);

                        if (videoInterviewFeedback[camelCaseCriterion] || videoInterviewFeedback[camelCaseCriterion] === 0) {
                            return (
                                <Fragment key={i}>
                                    <FeedbackRating
                                        key={camelCaseCriterion}
                                        category={criterion}
                                        rating={videoInterviewFeedback[camelCaseCriterion]}
                                    />
                                    <Divider key={`${camelCaseCriterion}_${i}`} />
                                </Fragment>
                            )
                        } else {
                            return (
                                <Fragment key={i}>
                                    <div className="feedback-block grid col-2">
                                        <div className="feedback-block-content" data-testid={`feedback-${formatStringAsClassName(criterion)}`}>
                                            <h3>{criterion}</h3>
                                        </div>
                                        <div className="feedback-block-content left-aligned" data-testid={`feedback-${formatStringAsClassName(criterion)}-content`}>
                                            <p>{candidateProfileSteps[camelCaseKey]?.description}</p>
                                        </div>
                                    </div>
                                    <Divider key={`${camelCaseCriterion}_${i}`} />
                                </Fragment>
                            )
                        }
                    })
                }

                <div className="feedback-block grid col-2">
                    <div className="feedback-block-content" data-testid="feedback-internal">
                        <h3>Overall feedback (private)</h3>
                    </div>
                    <div className="feedback-block-content left-aligned" data-testid="feedback-internal-content">
                        <p className='internal-feedback'>{videoInterviewFeedback.internalFeedback}</p>
                    </div>
                </div>

                <Divider />

                <div className="feedback-block grid col-2">
                    <div className="feedback-block-content" data-testid="feedback-sent-to-candidate">
                        <h3>Candidate feedback (sent to candidate)</h3>
                    </div>
                    <div className="feedback-block-content left-aligned" data-testid="feedback-sent-to-candidate-content">
                        <p>{videoInterviewFeedback.summary}</p>
                    </div>
                </div>

                <div className="buttons-wrapper">
                    <PrimaryButton
                        className="rate-candidate-btn"
                        text="Rate Candidate"
                        handleClick={handleOpenDialog}
                    />
                </div>
            </div>
            {
                CHECKLIST_DIALOGS_MAP.candidateRatedDialog({
                    key: 'candidateRatedDialog',
                    dialogKey: 'candidateRatedDialog',
                    isDialogOpen: openDialog,
                    handleCloseDialog,
                    dialogTitle: 'Rate Candidate',
                    initialValues: getInitialFormValues(candidateProfileSteps, 'candidateRatedDialog'),
                    handleSubmit: handleCandidateRateSubmit,
                    validationFunction: validateRequiredFieldsCandidateRate,
                })
            }
        </div>
    )
};

const mapStateToProps = (state) => ({
    candidateProfileSteps: getCandidateProfileSteps(state),
    statusHistory: getCandidateStatusHistory(state),
});

const mapDispatchToProps = {
    fetchCandidateVideoInterviewFeedbackInformation,
    updateCandidateCriteria,
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidateVideoInterviewFeedback);
