import React from 'react';
import moment from 'moment';
import { Formik, ErrorMessage } from 'formik';

import TextField from '@material-ui/core/TextField';
import CommonFormikDialog from 'components/Shared/CommonFormikDialog';

import { validateRequiredFields, validateInputLength } from 'utils/formValidations';

import {
    MAX_DESCRIPTION_LENGTH,
} from 'components/Shared/HistoricalNotes/HistoricalNotesConstants';

const HistoricalNoteDialog = ({
    agencyId,
    userId,
    targetId,
    jobHistoricalNote,
    handleAddHistoricalNote,
    handleEditHistoricalNote,
    handleCloseDialog,
    isDialogOpen,
    isEditMode,
    noteType,
}) => {

    return (
        <Formik
            enableReinitialize
            initialValues={{
                description: jobHistoricalNote?.description || ''
            }}
            validate={values => {
                return {
                    ...validateRequiredFields(values, ['description']),
                    ...validateInputLength(values, [
                        { property: 'description', maxLength: MAX_DESCRIPTION_LENGTH },
                    ]),
                }
            }}
            onSubmit={async (values) => {
                const noteData = {
                    agencyId,
                    targetId,
                    noteType: noteType,
                    creatorId: userId,
                    date: moment(new Date()),
                    description: values.description,
                };

                isEditMode
                    ? await handleEditHistoricalNote(noteData)
                    : await handleAddHistoricalNote(noteData);

                handleCloseDialog();
            }}
        >
            {(props) => (
                <CommonFormikDialog
                    className="job-historical-note-dialog"
                    size="sm"
                    dialogTitle={isEditMode ? 'Edit note' : 'Add note'}
                    saveBtnText="Save"
                    discardBtnText="Discard"
                    open={isDialogOpen}
                    onClose={handleCloseDialog}
                    onSave={props.handleSubmit}
                >
                    <form className="note-form" autoComplete="off">
                        <div className="note-fields-container">
                            <div className="note-field">
                                <TextField
                                    name="description"
                                    label="Description"
                                    value={props.values.description}
                                    onChange={props.handleChange}
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    minRows={5}
                                />
                                <ErrorMessage name="description" component="div" className="invalid-field-message" />
                            </div>
                        </div>
                    </form>
                </CommonFormikDialog>
            )}
        </Formik>
    );
};

export default HistoricalNoteDialog;
