import store from 'utils/store';
import { logEvent } from 'utils/amplitude';
import moment from 'moment';

import { getAgencyBasicInfo } from 'reducers'
import supplierService from 'services/supplierService';
import agencyService from 'services/agencyService';
import { showNotification } from 'actions/notificationActions';
import { fetchInvoicesSuccess } from 'actions/invoiceActions';
import { fetchEmployeesSuccess, } from 'actions/sharedActions';
import { showApplicationLoader, hideApplicationLoader } from 'actions/applicationLoaderActions';
import { showDialogActionButtonLoader, hideDialogActionButtonLoader } from './sectionsLoadersActions';
import { dashboardDataFetchSuccess, dashboardDataFetchingSuccess } from 'actions/agencyActions';
import { authorizedShowNotification } from 'actions/actionHelpers';
import { fetchJobsSuccess } from 'actions/jobOpportunityActions';

import { NOTIFICATION_TYPES } from 'constants/notificationTypes';
import { SUPPLIER_MESSAGES, CLIENT_MESSAGES, EMAIL_MESSAGES, DASHBOARD_MESSAGES, TASK_MESSAGES, AUTH_MESSAGES, EMPLOYEE_MESSAGES } from 'constants/messageConstants';
import {
    SUPPLIER_REGISTER_FETCH_SUCCESS,
    SUPPLIER_FETCH_ALL_SUCCESS,
    SUPPLIER_REPLACE_ALL_SUCCESS,
    SUPPLIER_INFORMATION_FETCH_SUCCESS,
    SUPPLIER_AVATAR_UPDATE_SUCCESS,
    SUPPLIER_AVATAR_DELETE_SUCCESS,
    SUPPLIER_BASIC_INFORMATION_UPDATE_SUCCESS,
    SUPPLIER_CONTACT_INFORMATION_UPDATE_SUCCESS,
    SUPPLIER_INVOICE_INFORMATION_UPDATE_SUCCESS,
    SUPPLIER_ACCOUNT_MANAGER_INFORMATION_UPDATE_SUCCESS,
    SUPPLIER_EMPLOYEES_PROJECTS_FETCH_SUCCESS,
    SUPPLIER_EMPLOYEES_EXPORT_CV_SUCCESS,
    AGENCY_HOLIDAY_ALLOWANCE_UPDATE_SUCCESS,
    AGENCY_EMPLOYEE_SETTINGS_FETCH_SUCCESS,
    SUPPLIER_TASKS_FETCH_SUCCESS,
    SUPPLIER_TASK_CREATE_SUCCESS,
    SUPPLIER_TASK_EDIT_SUCCESS,
    SUPPLIER_EMPLOYEE_FETCH_SUCCESS,
    SUPPLIER_UPDATE_FLAG_SUCCESS,
    SUPPLIERS_ACCOUNT_MANAGERS_FETCH_SUCCESS,
} from 'actions/actionTypes';
import { ISO_8601_DATE_FORMAT } from 'constants/commonConstants';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitudeConstants';

const fetchRegisterSupplierSuccess = supplierData => ({
    type: SUPPLIER_REGISTER_FETCH_SUCCESS,
    payload: supplierData
});

const fetchAllSuppliersSuccess = suppliers => ({
    type: SUPPLIER_FETCH_ALL_SUCCESS,
    payload: suppliers
});

const replaceAllSuppliersSuccess = suppliers => ({
    type: SUPPLIER_REPLACE_ALL_SUCCESS,
    payload: suppliers
});

const fetchSuppliersManagersSuccess = accountManagers => ({
    type: SUPPLIERS_ACCOUNT_MANAGERS_FETCH_SUCCESS,
    payload: accountManagers,
});

export const fetchSupplierInformationSuccess = supplierData => ({
    type: SUPPLIER_INFORMATION_FETCH_SUCCESS,
    payload: supplierData
});

const updateAvatarSuccess = pictureUrl => ({
    type: SUPPLIER_AVATAR_UPDATE_SUCCESS,
    payload: pictureUrl
});

const deleteAvatarSuccess = () => ({
    type: SUPPLIER_AVATAR_DELETE_SUCCESS
});

const updateSupplierContactInformationSuccess = contactInfo => ({
    type: SUPPLIER_CONTACT_INFORMATION_UPDATE_SUCCESS,
    payload: contactInfo
});

const updateSupplierBasicInformationSuccess = basicInfo => ({
    type: SUPPLIER_BASIC_INFORMATION_UPDATE_SUCCESS,
    payload: basicInfo
});

const updateSupplierInvoiceInformationSuccess = invoiceInfo => ({
    type: SUPPLIER_INVOICE_INFORMATION_UPDATE_SUCCESS,
    payload: invoiceInfo
});

const updateSupplierAccountManagerInformationSuccess = accountManagerInfo => ({
    type: SUPPLIER_ACCOUNT_MANAGER_INFORMATION_UPDATE_SUCCESS,
    payload: accountManagerInfo
});

const fetchAllSupplierProjectsSuccess = projects => ({
    type: SUPPLIER_EMPLOYEES_PROJECTS_FETCH_SUCCESS,
    payload: projects
});

const fetchAgencyEmployeeSettingsSuccess = employeeSettings => ({
    type: AGENCY_EMPLOYEE_SETTINGS_FETCH_SUCCESS,
    payload: employeeSettings
});

const updateHolidayAllowanceSuccess = updatedHolidayAllowance => ({
    type: AGENCY_HOLIDAY_ALLOWANCE_UPDATE_SUCCESS,
    payload: updatedHolidayAllowance
});

const exportSupplierEmployeesCVsSuccess = () => ({
    type: SUPPLIER_EMPLOYEES_EXPORT_CV_SUCCESS,
});

const fetchTasksSuccess = tasks => ({
    type: SUPPLIER_TASKS_FETCH_SUCCESS,
    payload: tasks
});

const createTaskSuccess = newTasks => ({
    type: SUPPLIER_TASK_CREATE_SUCCESS,
    payload: newTasks
});

const editTaskSuccess = editedTasks => ({
    type: SUPPLIER_TASK_EDIT_SUCCESS,
    payload: editedTasks
});

const fetchSupplierEmployeeSuccess = employeeData => ({
    type: SUPPLIER_EMPLOYEE_FETCH_SUCCESS,
    payload: employeeData,
});

const updateSupplierFlagSuccess = data => ({
    type: SUPPLIER_UPDATE_FLAG_SUCCESS,
    payload: data,
});

export const registerSupplier = (agencyId, supplierData, updateState = true) => async dispatch => {
    try {
        dispatch(showDialogActionButtonLoader());

        const agencyInfo = getAgencyBasicInfo(store.getState());
        supplierData.currency = agencyInfo.currency;

        const supplier = await supplierService.registerSupplier(agencyId, supplierData);
        if (supplier._id) {
            logEvent(AMPLITUDE_EVENT_TYPES.ADD_SUPPLIER_PROFILE, null, { isEmailAdded: !!supplierData.email });
            updateState && dispatch(fetchRegisterSupplierSuccess(supplier));
            dispatch(showNotification(SUPPLIER_MESSAGES.ADD_SUPPLIER_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
        }
    } catch (error) {
        if (error.message === EMAIL_MESSAGES.EMAIL_ALREADY_USED) {
            dispatch(authorizedShowNotification(error)(SUPPLIER_MESSAGES.ADD_SUPPLIER_EMAIL_FAILURE_WARNING, NOTIFICATION_TYPES.WARNING));
        } else if (error.message === EMAIL_MESSAGES.EMAIL_DISABLED) {
            dispatch(authorizedShowNotification(error)(error.message, NOTIFICATION_TYPES.WARNING));
        } else {
            dispatch(authorizedShowNotification(error)(SUPPLIER_MESSAGES.ADD_SUPPLIER_FAIL, NOTIFICATION_TYPES.ERROR));
        }
    } finally {
        dispatch(hideDialogActionButtonLoader());
    }
};

export const fetchSupplierEmployeeViewProfileData = (userId, agencyId) => dispatch => {
    try {
        return supplierService.fetchSupplierEmployeeViewProfileData(userId, agencyId);
    } catch (error) {
        dispatch(authorizedShowNotification(error)(SUPPLIER_MESSAGES.FETCH_SUPPLIER_EMPLOYEE_VIEW_PROFILE_DATA_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const exportSupplierEmployeesCVs = (supplierEmployees, agencyId) => async dispatch => {
    try {
        const agencyInfo = await agencyService.fetchAgencyInfo(agencyId);

        let fileName = '';

        if (supplierEmployees.length === 1) {
            fileName = `${supplierEmployees[0].firstName}-${agencyInfo.name}-CV`;
        } else {
            fileName = `${moment().format(ISO_8601_DATE_FORMAT)}-${agencyInfo.name}-CV-Export`
        }

        await supplierService.getSupplierEmployeesExportedCVs(agencyId, supplierEmployees.map(x => x._id), fileName);

        dispatch(exportSupplierEmployeesCVsSuccess());
        dispatch(showNotification(EMPLOYEE_MESSAGES.EXPORT_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(AUTH_MESSAGES.SOMETHING_WENT_WRONG, NOTIFICATION_TYPES.ERROR));
    }
};

export const fetchSuppliers = (agencyId, query, replaceState) => async dispatch => {
    try {
        const result = await supplierService.fetchSuppliers(agencyId, query);

        if (replaceState) {
            dispatch(replaceAllSuppliersSuccess(result.suppliers));
        } else {
            dispatch(fetchAllSuppliersSuccess(result.suppliers));
        }

        return result.totalCount;
    } catch (error) {
        dispatch(authorizedShowNotification(error)(error.message, NOTIFICATION_TYPES.ERROR));
    }
};

export const fetchSuppliersManagers = agencyId => async dispatch => {
    try {
        const result = await supplierService.fetchSuppliersManagers(agencyId);

        dispatch(fetchSuppliersManagersSuccess(result));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(error.message, NOTIFICATION_TYPES.ERROR));
    }
};

export const exportSuppliers = (agencyId, exportFields, filters) => async dispatch => {
    try {
        await supplierService.exportSuppliers(agencyId, { exportFields, filters });
    } catch (error) {
        dispatch(authorizedShowNotification(error)(error.message, NOTIFICATION_TYPES.ERROR));
    }
};

export const fetchSupplierInformation = (agencyId, supplierId) => async dispatch => {
    try {
        dispatch(dashboardDataFetchingSuccess());
        const supplierData = await supplierService.fetchSupplierInformation(agencyId, supplierId);

        dispatch(fetchSupplierInformationSuccess(supplierData));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(SUPPLIER_MESSAGES.FETCH_SUPPLIER_INFO_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(dashboardDataFetchSuccess());
    }
};

export const fetchSupplierEmployeesProjects = (agencyId, supplierId) => async dispatch => {
    try {
        const employeesProjects = await supplierService.fetchSupplierEmployeesProjects(agencyId, supplierId);
        dispatch(fetchAllSupplierProjectsSuccess(employeesProjects));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(CLIENT_MESSAGES.FETCH_CLIENT_PROJECTS_FAIL, NOTIFICATION_TYPES.ERROR));
    }
}

export const updateAvatar = (croppedImgData, supplierId, agencyId) => async dispatch => {
    try {
        const resultUploadedPictureObject = await supplierService.updateAvatar(croppedImgData, agencyId, supplierId);

        dispatch(updateAvatarSuccess(resultUploadedPictureObject));
        dispatch(showNotification(SUPPLIER_MESSAGES.UPDATE_AVATAR_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(SUPPLIER_MESSAGES.UPDATE_AVATAR_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const deleteAvatar = (supplierId, agencyId) => async dispatch => {
    try {
        await supplierService.deleteAvatar(agencyId, supplierId);

        dispatch(deleteAvatarSuccess());
        dispatch(showNotification(SUPPLIER_MESSAGES.DELETE_AVATAR_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(SUPPLIER_MESSAGES.DELETE_AVATAR_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const updateSupplierBasicInformation = (agencyId, supplierId, basicInfo) => async dispatch => {
    try {
        const newBasicInfo = await supplierService.updateSupplierBasicInformation(agencyId, supplierId, basicInfo);

        dispatch(updateSupplierBasicInformationSuccess({ basic: newBasicInfo }));
        dispatch(showNotification(SUPPLIER_MESSAGES.UPDATE_BASIC_INFO_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(SUPPLIER_MESSAGES.UPDATE_BASIC_INFO_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const updateSupplierContactInformation = (agencyId, supplierId, contactInfo) => async dispatch => {
    try {
        const newContactInfo = await supplierService.updateSupplierContactInformation(agencyId, supplierId, contactInfo);

        dispatch(updateSupplierContactInformationSuccess({ contact: newContactInfo }));
        dispatch(showNotification(SUPPLIER_MESSAGES.UPDATE_CONTACT_INFO_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(SUPPLIER_MESSAGES.UPDATE_CONTACT_INFO_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const updateSupplierInvoiceInformation = (agencyId, supplierId, invoiceInfo) => async dispatch => {
    try {
        const newInvoiceInfo = await supplierService.updateSupplierInvoiceInformation(agencyId, supplierId, invoiceInfo);
        dispatch(updateSupplierInvoiceInformationSuccess({ invoicing: newInvoiceInfo }));
        dispatch(showNotification(SUPPLIER_MESSAGES.UPDATE_INVOICE_INFO_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(SUPPLIER_MESSAGES.UPDATE_INVOICE_INFO_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const updateSupplierAccountManagerInformation = (agencyId, supplierId, data, isAccountManagerUpdated) => async dispatch => {
    try {
        const newAccountManagerInfo = await supplierService.updateAccountManagerInformation(agencyId, supplierId, data);

        if (isAccountManagerUpdated) {
            logEvent(AMPLITUDE_EVENT_TYPES.CHANGE_SUPPLIER_ACCOUNT_MANAGER);
        }

        dispatch(updateSupplierAccountManagerInformationSuccess(newAccountManagerInfo));
        dispatch(showNotification(SUPPLIER_MESSAGES.UPDATE_ACCOUNT_MANAGER_INFO_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(SUPPLIER_MESSAGES.UPDATE_ACCOUNT_MANAGER_INFO_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const fetchSupplierEmployeeSettings = (agencyId, supplierId) => async dispatch => {
    try {
        const employeeSettings = await supplierService.fetchSupplierEmployeeSettings(agencyId, supplierId);

        dispatch(fetchAgencyEmployeeSettingsSuccess(employeeSettings));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(SUPPLIER_MESSAGES.FETCH_EMPLOYEE_SETTINGS_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const updateSupplierHolidayAllowance = (agencyId, supplierId, holidayAllowance) => async dispatch => {
    try {
        const updatedSupplierHolidayAllowance = await supplierService.updateSupplierHolidayAllowance(agencyId, supplierId, holidayAllowance);
        dispatch(updateHolidayAllowanceSuccess(updatedSupplierHolidayAllowance));
        dispatch(showNotification(SUPPLIER_MESSAGES.UPDATE_HOLIDAY_ALLOWANCE_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(SUPPLIER_MESSAGES.UPDATE_HOLIDAY_ALLOWANCE_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const fetchSupplierTasks = (clientId, agencyId) => async dispatch => {
    try {
        const tasks = await supplierService.getAllTasks(agencyId, clientId);
        dispatch(fetchTasksSuccess(tasks));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(TASK_MESSAGES.FETCH_TASKS_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const createTask = (task, clientId, agencyId) => async dispatch => {
    try {
        const newTask = await supplierService.createTask(agencyId, clientId, task);
        dispatch(createTaskSuccess(newTask));
        dispatch(showNotification(TASK_MESSAGES.CREATE_TASK_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(TASK_MESSAGES.CREATE_TASK_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const editTask = (task, clientId, agencyId) => async dispatch => {
    try {
        const editedTask = await supplierService.updateTask(agencyId, clientId, task);
        dispatch(editTaskSuccess(editedTask));
        dispatch(showNotification(TASK_MESSAGES.EDIT_TASK_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(TASK_MESSAGES.EDIT_TASK_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const fetchSupplierDashboardInformation = (agencyId, supplierId) => async dispatch => {
    try {
        dispatch(dashboardDataFetchingSuccess());

        const result = await supplierService.fetchSupplierAdminDashboardInformation(agencyId, supplierId);

        dispatch(fetchJobsSuccess(result.jobs));
        dispatch(fetchAllSupplierProjectsSuccess(result.employeesProjects));
        dispatch(fetchInvoicesSuccess(result.invoices));
        dispatch(fetchSupplierInformationSuccess(result.supplierData));
        dispatch(fetchEmployeesSuccess(result.employeesData));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(DASHBOARD_MESSAGES.FETCH_DASHBOARD_PAGE_INFORMATION_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(dashboardDataFetchSuccess());
    }
};

export const fetchSupplierEmployeeProfileInfo = (agencyId, supplierId, employeeId) => async dispatch => {
    dispatch(showApplicationLoader());
    try {
        const result = await supplierService.fetchSupplierEmployeeProfile(agencyId, supplierId, employeeId);

        dispatch(fetchSupplierEmployeeSuccess(result));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.FETCH_EMPLOYEE_VIEW_PROFILE_DATA_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(hideApplicationLoader());
    }
};

export const updateSupplierFlag = (agencyId, supplierId, flagName) => async dispatch => {
    try {
        const flag = await supplierService.updateSupplierFlag(agencyId, supplierId, { name: flagName });

        dispatch(updateSupplierFlagSuccess({ flag, _id: supplierId }));
        dispatch(showNotification(SUPPLIER_MESSAGES.SUPPLIER_UPDATE_FLAG_SUCCESS, NOTIFICATION_TYPES.SUCCESS))
    } catch (error) {
        dispatch(authorizedShowNotification(error)(SUPPLIER_MESSAGES.SUPPLIER_UPDATE_FLAG_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};
