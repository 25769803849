import {
    EMPLOYEES_TABLE_DATA_FETCH_SUCCESS,
    EMPLOYEES_TABLE_DATA_FETCHING_SUCCESS,
    ACTIVE_HOLIDAYS_TABLE_DATA_FETCH_SUCCESS,
    ACTIVE_HOLIDAYS_TABLE_DATA_FETCHING_SUCCESS,
    CLIENTS_ACCOUNTS_TABLE_DATA_FETCH_SUCCESS,
    CLIENTS_ACCOUNTS_TABLE_DATA_FETCHING_SUCCESS,
    CLIENTS_PROJECTS_OVERVIEW_DATA_FETCH_SUCCESS,
    CLIENTS_PROJECTS_OVERVIEW_DATA_FETCHING_SUCCESS,
    JOB_OPPORTUNITIES_LIST_DATA_FETCH_SUCCESS,
    JOB_OPPORTUNITIES_LIST_DATA_FETCHING_SUCCESS,
    JOB_OPPORTUNITY_PAGE_FETCH_SUCCESS,
    JOB_OPPORTUNITY_PAGE_FETCHING_SUCCESS,
    JOB_MATCHING_CONSULTANTS_PAGE_FETCH_SUCCESS,
    JOB_MATCHING_CONSULTANTS_PAGE_FETCHING_SUCCESS,
    HISTORICAL_NOTES_DATA_FETCH_SUCCESS,
    HISTORICAL_NOTES_DATA_FETCHING_SUCCESS,
    EMPLOYEES_RELATED_OPPORTUNITIES_DATA_FETCH_SUCCESS,
    EMPLOYEES_RELATED_OPPORTUNITIES_DATA_FETCHING_SUCCESS,
    EMPLOYEES_PERSONAL_INFORMATION_DATA_FETCH_SUCCESS,
    EMPLOYEES_PERSONAL_INFORMATION_DATA_FETCHING_SUCCESS,
    EMPLOYEES_EMPLOYMENT_INFORMATION_DATA_FETCHING_SUCCESS,
    EMPLOYEES_EMPLOYMENT_INFORMATION_DATA_FETCH_SUCCESS,
    PERFORMANCE_REVIEWS_DATA_FETCH_SUCCESS,
    PERFORMANCE_REVIEWS_DATA_FETCHING_SUCCESS,
    PROFILE_DATA_FETCH_SUCCESS,
    PROFILE_DATA_FETCHING_SUCCESS,
    TIME_TRACKING_DATA_FETCH_SUCCESS,
    TIME_TRACKING_DATA_FETCHING_SUCCESS,
    EMPLOYEES_HOLIDAYS_DATA_FETCH_SUCCESS,
    EMPLOYEES_HOLIDAYS_DATA_FETCHING_SUCCESS,
    CANDIDATE_INTERVIEW_INVITATIONS_DATA_FETCH_SUCCESS,
    CANDIDATE_INTERVIEW_INVITATIONS_DATA_FETCHING_SUCCESS,
    BENEFITS_DATA_FETCH_SUCCESS,
    BENEFITS_DATA_FETCHING_SUCCESS,
    EXPLORE_TALENTS_PROFILES_DATA_FETCH_SUCCESS,
    EXPLORE_TALENTS_PROFILES_DATA_FETCHING_SUCCESS,
    CANDIDATES_PREFERENCES_DATA_FETCH_SUCCESS,
    CANDIDATES_PREFERENCES_DATA_FETCHING_SUCCESS,
    CANDIDATE_VIDEO_INTERVIEW_FEEDBACK_DATA_FETCH_SUCCESS,
    CANDIDATE_VIDEO_INTERVIEW_FEEDBACK_DATA_FETCHING_SUCCESS,
    CANDIDATE_APPLICATIONS_DATA_FETCH_SUCCESS,
    CANDIDATE_APPLICATIONS_DATA_FETCHING_SUCCESS,
    CLIENTS_PROJECTS_PAGE_DATA_FETCH_SUCCESS,
    CLIENTS_PROJECTS_PAGE_DATA_FETCHING_SUCCESS,
    ACCOUNT_MANAGER_DATA_FETCH_SUCCESS,
    ACCOUNT_MANAGER_DATA_FETCHING_SUCCESS,
    CLIENT_PROJECT_DOCUMENTS_DATA_FETCH_SUCCESS,
    CLIENT_PROJECT_DOCUMENTS_DATA_FETCHING_SUCCESS,
    JOB_OPPORTUNITY_DETAILS_DATA_FETCH_SUCCESS,
    JOB_OPPORTUNITY_DETAILS_DATA_FETCHING_SUCCESS,
    CANDIDATE_APPLICATION_DATA_FETCH_SUCCESS,
    CANDIDATE_APPLICATION_DATA_FETCHING_SUCCESS,
    CANDIDATE_APPLICATION_PAGE_UNMOUNTED_SUCCESS,
    DASHBOARD_DATA_FETCH_SUCCESS,
    DASHBOARD_DATA_FETCHING_SUCCESS,
    CANDIDATE_OVERVIEW_PAGE_DATA_FETCH_SUCCESS,
    CANDIDATE_OVERVIEW_PAGE_DATA_FETCHING_SUCCESS,
    CANDIDATE_PROFILE_PAGE_UNMOUNTED_SUCCESS,
    CLIENT_PROJECTS_WITH_EMPLOYEES_DATA_FETCH_SUCCESS,
    CLIENT_PROJECTS_WITH_EMPLOYEES_DATA_FETCHING_SUCCESS,
    CLIENT_PROJECTS_TOTAL_REVENUE_DATA_FETCH_SUCCESS,
    CLIENT_PROJECTS_TOTAL_REVENUE_DATA_FETCHING_SUCCESS,
    CLIENT_INFORMATION_DATA_FETCH_SUCCESS,
    CLIENT_INFORMATION_DATA_FETCHING_SUCCESS,
    TASKS_DATA_FETCH_SUCCESS,
    TASKS_DATA_FETCHING_SUCCESS,
    SICK_LEAVES_DATA_FETCH_SUCCESS,
    SICK_LEAVES_DATA_FETCHING_SUCCESS,
    LOGIN_SUCCESS,
    RAW_CV_PROGRESS_DATA_FETCH_SUCCESS,
    RAW_CV_PROGRESS_DATA_FETCHING_SUCCESS,
    EMPLOYEE_PROFILE_PAGE_UNMOUNTED_SUCCESS,
    CLIENT_PROFILE_PAGE_UNMOUNTED_SUCCESS,
    SUPPLIER_PROFILE_PAGE_UNMOUNTED_SUCCESS,
    CLIENT_INTERVIEW_DATES_DATA_FETCH_SUCCESS,
    CLIENT_INTERVIEW_DATES_DATA_FETCHING_SUCCESS,
    CLIENT_JOBS_DASHBOARD_DATA_FETCH_SUCCESS,
    CLIENT_JOBS_DASHBOARD_DATA_FETCHING_SUCCESS,
    CHECKLISTED_CANDIDATES_DATA_FETCH_SUCCESS,
    CHECKLISTED_CANDIDATES_DATA_FETCHING_SUCCESS,
    SEARCH_DATA_FETCH_SUCCESS,
    SEARCH_DATA_FETCHING_SUCCESS,
    PROJECT_DETAILS_PAGE_UNMOUNT_SUCCESS,
    APPLICATION_PROCESS_INFORMATION_FETCHING_SUCCESS,
    APPLICATION_PROCESS_INFORMATION_FETCH_SUCCESS,
    CANDIDATE_PROFILE_PAGE_DATA_FETCHING_SUCCESS,
    CANDIDATE_PROFILE_PAGE_DATA_FETCH_SUCCESS,
    DIALOG_ACTION_BUTTON_LOADER_SHOW_SUCCESS,
    DIALOG_ACTION_BUTTON_LOADER_HIDE_SUCCESS,
    KPI_REPORT_DATA_FETCH_SUCCESS,
    KPI_REPORT_DATA_FETCHING_SUCCESS,
    REPORTS_PAGE_DATA_FETCH_SUCCESS,
    REPORTS_PAGE_DATA_FETCHING_SUCCESS,
    AI_MATCHED_CANDIDATES_SCORES_FETCHING_SUCCESS,
    AI_MATCHED_CANDIDATES_SCORES_FETCH_SUCCESS,
    CV_PAGE_UPDATE_SUCCESS,
    CV_PAGE_UPDATING_SUCCESS,
    EMPLOYEE_OVERVIEW_FETCHING_SUCCESS,
    EMPLOYEE_OVERVIEW_FETCH_SUCCESS,
} from 'actions/actionTypes';

// Fix these in #7363
const sectionsLoaderInitialState = {
    dashboard: {
        isDataFetching: false,
        isDataFetched: false,
    },
    employeesTable: {
        isDataFetching: false,
        isDataFetched: false,
    },
    candidatesTable: {
        isDataFetching: false,
        isDataFetched: false,
    },
    activeHolidaysTable: {
        isDataFetching: false,
        isDataFetched: false,
    },
    clientsTable: {
        isDataFetching: false,
        isDataFetched: false,
    },
    clientsProjectsOverview: {
        isDataFetching: false,
        isDataFetched: false,
    },
    jobOpportunitiesList: {
        isDataFetching: false,
        isDataFetched: false,
    },
    jobOpportunityPage: {
        isDataFetching: false,
        isDataFetched: false,
    },
    jobMatchingConsultantsPage: {
        isDataFetching: false,
        isDataFetched: false,
    },
    trainingsList: {
        isDataFetching: false,
        isDataFetched: false,
    },
    historicalNotes: {
        isDataFetching: false,
        isDataFetched: false,
    },
    employeesPersonalInformation: {
        isDataFetching: false,
        isDataFetched: false,
    },
    employeesEmploymentInformation: {
        isDataFetching: false,
        isDataFetched: false,
    },
    employeesRelatedOpportunities: {
        isDataFetching: false,
        isDataFetched: false,
    },
    performanceReviews: {
        isDataFetching: false,
        isDataFetched: false,
    },
    profileInformation: {
        isDataFetching: false,
        isDataFetched: false,
    },
    timeTracking: {
        isDataFetching: false,
        isDataFetched: false,
    },
    candidateInterviewInvitations: {
        isDataFetching: false,
        isDataFetched: false,
    },
    benefits: {
        isDataFetching: false,
        isDataFetched: false,
    },
    exploreTalents: {
        isDataFetching: false,
        isDataFetched: false,
    },
    candidatesPreferences: {
        isDataFetching: false,
        isDataFetched: false,
    },
    candidateVideoInterviewFeedback: {
        isDataFetching: false,
        isDataFetched: false,
    },
    candidateApplications: {
        isDataFetching: false,
        isDataFetched: false,
    },
    clientsProjectsPage: {
        isDataFetching: false,
        isDataFetched: false,
    },
    contractsTable: {
        isDataFetching: false,
        isDataFetched: false,
    },
    invoicesHistoryTable: {
        isDataFetching: false,
        isDataFetched: false,
    },
    accountManager: {
        isDataFetching: false,
        isDataFetched: false,
    },
    clientProjectDocuments: {
        isDataFetching: false,
        isDataFetched: false,
    },
    jobOpportunityDetails: {
        isDataFetching: false,
        isDataFetched: false,
    },
    candidateApplication: {
        isDataFetching: false,
        isDataFetched: false,
    },
    candidateOverviewPage: {
        isDataFetching: false,
        isDataFetched: false,
    },
    clientProjectsWithEmployees: {
        isDataFetching: false,
        isDataFetched: false,
    },
    clientProjectsTotalRevenue: {
        isDataFetching: false,
        isDataFetched: false,
    },
    clientInformation: {
        isDataFetching: false,
        isDataFetched: false,
    },
    tasksList: {
        isDataFetching: false,
        isDataFetched: false,
    },
    sickLeaves: {
        isDataFetching: false,
        isDataFetched: false,
    },
    login: true,
    rawCvProgess: {
        isDataFetching: false,
        isDataFetched: false,
    },
    clientInterviews: {
        isDataFetching: false,
        isDataFetched: false,
    },
    clientJobsDashboard: {
        isDataFetching: false,
        isDataFetched: false,
    },
    searchData: {
        isDataFetching: false,
        isDataFetched: false,
    },
    businessTripsList: {
        isDataFetching: false,
        isDataFetched: false,
    },
    documentsTable: {
        isDataFetching: false,
        isDataFetched: false,
    },
    checklistedCandidates: {
        isDataFetching: false,
        isDataFetched: false,
    },
    applicationProcessInformation: {
        isDataFetching: false,
        isDataFetched: false,
    },
    candidateProfilePage: {
        isDataFetching: false,
        isDataFetched: false,
    },
    kpiReport: {
        isDataFetching: false,
        isDataFetched: false,
    },
    reportsPage: {
        isDataFetching: false,
        isDataFetched: false,
    },
    dialogActionButton: false,
    aiMatchedCandidatesScores: {
        isDataFetching: false,
        isDataFetched: false,
    },
    cvPage: {
        isDataUpdating: false,
        isDataUpdated: false,
    },
}

const sectionsLoaders = (state = sectionsLoaderInitialState, action) => {
    switch (action.type) {
        case DASHBOARD_DATA_FETCHING_SUCCESS:
            return { ...state, dashboard: { ...state.dashboard, isDataFetching: true } };
        case DASHBOARD_DATA_FETCH_SUCCESS:
            return { ...state, dashboard: { ...state.dashboard, isDataFetched: true, isDataFetching: false } };

        case EMPLOYEES_TABLE_DATA_FETCHING_SUCCESS:
            return { ...state, employeesTable: { ...state.employeesTable, isDataFetching: true } };
        case EMPLOYEES_TABLE_DATA_FETCH_SUCCESS:
            return { ...state, employeesTable: { ...state.employeesTable, isDataFetched: true, isDataFetching: false } };

        case ACTIVE_HOLIDAYS_TABLE_DATA_FETCHING_SUCCESS:
            return { ...state, activeHolidaysTable: { ...state.activeHolidaysTable, isDataFetching: true } };
        case ACTIVE_HOLIDAYS_TABLE_DATA_FETCH_SUCCESS:
            return { ...state, activeHolidaysTable: { ...state.activeHolidaysTable, isDataFetched: true, isDataFetching: false } };

        case CLIENTS_ACCOUNTS_TABLE_DATA_FETCHING_SUCCESS:
            return { ...state, clientsTable: { ...state.clientsTable, isDataFetching: true } };
        case CLIENTS_ACCOUNTS_TABLE_DATA_FETCH_SUCCESS:
            return { ...state, clientsTable: { ...state.clientsTable, isDataFetched: true, isDataFetching: false } };

        case CLIENTS_PROJECTS_OVERVIEW_DATA_FETCHING_SUCCESS:
            return { ...state, clientsProjectsOverview: { ...state.clientsProjectsOverview, isDataFetching: true } };
        case CLIENTS_PROJECTS_OVERVIEW_DATA_FETCH_SUCCESS:
            return { ...state, clientsProjectsOverview: { ...state.clientsProjectsOverview, isDataFetched: true, isDataFetching: false } };

        case JOB_OPPORTUNITIES_LIST_DATA_FETCHING_SUCCESS:
            return { ...state, jobOpportunitiesList: { ...state.jobOpportunitiesList, isDataFetching: true } };
        case JOB_OPPORTUNITIES_LIST_DATA_FETCH_SUCCESS:
            return { ...state, jobOpportunitiesList: { ...state.jobOpportunitiesList, isDataFetched: true, isDataFetching: false } };

        case JOB_OPPORTUNITY_PAGE_FETCHING_SUCCESS:
            return { ...state, jobOpportunityPage: { ...state.jobOpportunityPage, isDataFetching: true } };
        case JOB_OPPORTUNITY_PAGE_FETCH_SUCCESS:
            return { ...state, jobOpportunityPage: { ...state.jobOpportunityPage, isDataFetched: true, isDataFetching: false } };

        case JOB_MATCHING_CONSULTANTS_PAGE_FETCHING_SUCCESS:
            return { ...state, jobMatchingConsultantsPage: { ...state.jobMatchingConsultantsPage, isDataFetching: true } };
        case JOB_MATCHING_CONSULTANTS_PAGE_FETCH_SUCCESS:
            return { ...state, jobMatchingConsultantsPage: { ...state.jobMatchingConsultantsPage, isDataFetched: true, isDataFetching: false } };

        case HISTORICAL_NOTES_DATA_FETCHING_SUCCESS:
            return { ...state, historicalNotes: { ...state.historicalNotes, isDataFetching: true } };
        case HISTORICAL_NOTES_DATA_FETCH_SUCCESS:
            return { ...state, historicalNotes: { ...state.historicalNotes, isDataFetched: true, isDataFetching: false } };

        case EMPLOYEES_PERSONAL_INFORMATION_DATA_FETCHING_SUCCESS:
            return { ...state, employeesPersonalInformation: { ...state.employeesPersonalInformation, isDataFetching: true } };
        case EMPLOYEES_PERSONAL_INFORMATION_DATA_FETCH_SUCCESS:
            return { ...state, employeesPersonalInformation: { ...state.employeesPersonalInformation, isDataFetched: true, isDataFetching: false } };

        case EMPLOYEES_EMPLOYMENT_INFORMATION_DATA_FETCHING_SUCCESS:
            return { ...state, employeesEmploymentInformation: { ...state.employeesEmploymentInformation, isDataFetching: true } };
        case EMPLOYEES_EMPLOYMENT_INFORMATION_DATA_FETCH_SUCCESS:
            return { ...state, employeesEmploymentInformation: { ...state.employeesEmploymentInformation, isDataFetched: true, isDataFetching: false } };

        case EMPLOYEES_RELATED_OPPORTUNITIES_DATA_FETCHING_SUCCESS:
            return { ...state, employeesRelatedOpportunities: { ...state.employeesRelatedOpportunities, isDataFetching: true } };
        case EMPLOYEES_RELATED_OPPORTUNITIES_DATA_FETCH_SUCCESS:
            return { ...state, employeesRelatedOpportunities: { ...state.employeesRelatedOpportunities, isDataFetched: true, isDataFetching: false } };

        case PERFORMANCE_REVIEWS_DATA_FETCHING_SUCCESS:
            return { ...state, performanceReviews: { ...state.performanceReviews, isDataFetching: true } };
        case PERFORMANCE_REVIEWS_DATA_FETCH_SUCCESS:
            return { ...state, performanceReviews: { ...state.performanceReviews, isDataFetched: true, isDataFetching: false } };

        case PROFILE_DATA_FETCHING_SUCCESS:
            return { ...state, profileInformation: { ...state.profileInformation, isDataFetching: true } };
        case PROFILE_DATA_FETCH_SUCCESS:
            return { ...state, profileInformation: { ...state.profileInformation, isDataFetched: true, isDataFetching: false } };

        case KPI_REPORT_DATA_FETCHING_SUCCESS:
            return { ...state, kpiReport: { ...state.kpiReport, isDataFetching: true } };
        case KPI_REPORT_DATA_FETCH_SUCCESS:
            return { ...state, kpiReport: { ...state.kpiReport, isDataFetched: true, isDataFetching: false } };

        case REPORTS_PAGE_DATA_FETCHING_SUCCESS:
            return { ...state, reportsPage: { ...state.reportsPage, isDataFetching: true } };
        case REPORTS_PAGE_DATA_FETCH_SUCCESS:
            return { ...state, reportsPage: { ...state.reportsPage, isDataFetched: true, isDataFetching: false } };

        case TIME_TRACKING_DATA_FETCHING_SUCCESS:
            return { ...state, timeTracking: { ...state.timeTracking, isDataFetching: true } };
        case TIME_TRACKING_DATA_FETCH_SUCCESS:
            return { ...state, timeTracking: { ...state.timeTracking, isDataFetched: true, isDataFetching: false } };

        case EMPLOYEES_HOLIDAYS_DATA_FETCH_SUCCESS:
            return {
                ...state,
                activeHolidaysTable: { ...state.activeHolidaysTable, isDataFetched: true, isDataFetching: false }
            };
        case EMPLOYEES_HOLIDAYS_DATA_FETCHING_SUCCESS:
            return {
                ...state,
                activeHolidaysTable: { ...state.activeHolidaysTable, isDataFetching: true }
            };

        case CANDIDATE_INTERVIEW_INVITATIONS_DATA_FETCHING_SUCCESS:
            return { ...state, candidateInterviewInvitations: { ...state.candidateInterviewInvitations, isDataFetching: true } };
        case CANDIDATE_INTERVIEW_INVITATIONS_DATA_FETCH_SUCCESS:
            return { ...state, candidateInterviewInvitations: { ...state.candidateInterviewInvitations, isDataFetched: true, isDataFetching: false } };

        case BENEFITS_DATA_FETCHING_SUCCESS:
            return { ...state, benefits: { ...state.benefits, isDataFetching: true } };
        case BENEFITS_DATA_FETCH_SUCCESS:
            return { ...state, benefits: { ...state.benefits, isDataFetched: true, isDataFetching: false } };

        case EXPLORE_TALENTS_PROFILES_DATA_FETCHING_SUCCESS:
            return { ...state, exploreTalents: { ...state.exploreTalents, isDataFetching: true } };
        case EXPLORE_TALENTS_PROFILES_DATA_FETCH_SUCCESS:
            return { ...state, exploreTalents: { ...state.exploreTalents, isDataFetched: true, isDataFetching: false } };

        case CANDIDATES_PREFERENCES_DATA_FETCHING_SUCCESS:
            return { ...state, candidatesPreferences: { ...state.candidatesPreferences, isDataFetching: true } };
        case CANDIDATES_PREFERENCES_DATA_FETCH_SUCCESS:
            return { ...state, candidatesPreferences: { ...state.candidatesPreferences, isDataFetched: true, isDataFetching: false } };

        case CANDIDATE_VIDEO_INTERVIEW_FEEDBACK_DATA_FETCHING_SUCCESS:
            return { ...state, candidateVideoInterviewFeedback: { ...state.candidateVideoInterviewFeedback, isDataFetching: true } };
        case CANDIDATE_VIDEO_INTERVIEW_FEEDBACK_DATA_FETCH_SUCCESS:
            return { ...state, candidateVideoInterviewFeedback: { ...state.candidateVideoInterviewFeedback, isDataFetched: true, isDataFetching: false } };

        case CANDIDATE_APPLICATIONS_DATA_FETCHING_SUCCESS:
            return { ...state, candidateApplications: { ...state.candidateApplications, isDataFetching: true } };
        case CANDIDATE_APPLICATIONS_DATA_FETCH_SUCCESS:
            return { ...state, candidateApplications: { ...state.candidateApplications, isDataFetched: true, isDataFetching: false } };

        case CLIENTS_PROJECTS_PAGE_DATA_FETCHING_SUCCESS:
            return { ...state, clientsProjectsPage: { ...state.clientsProjectsPage, isDataFetching: true } };
        case CLIENTS_PROJECTS_PAGE_DATA_FETCH_SUCCESS:
            return { ...state, clientsProjectsPage: { ...state.clientsProjectsPage, isDataFetched: true, isDataFetching: false } };
        case ACCOUNT_MANAGER_DATA_FETCHING_SUCCESS:
            return { ...state, accountManager: { ...state.accountManager, isDataFetching: true } };
        case ACCOUNT_MANAGER_DATA_FETCH_SUCCESS:
            return { ...state, accountManager: { ...state.accountManager, isDataFetched: true, isDataFetching: false } };

        case CLIENT_PROJECT_DOCUMENTS_DATA_FETCHING_SUCCESS:
            return { ...state, clientProjectDocuments: { ...state.clientProjectDocuments, isDataFetching: true } };
        case CLIENT_PROJECT_DOCUMENTS_DATA_FETCH_SUCCESS:
            return { ...state, clientProjectDocuments: { ...state.clientProjectDocuments, isDataFetched: true, isDataFetching: false } };

        case JOB_OPPORTUNITY_DETAILS_DATA_FETCHING_SUCCESS:
            return { ...state, jobOpportunityDetails: { ...state.jobOpportunityDetails, isDataFetching: true } };
        case JOB_OPPORTUNITY_DETAILS_DATA_FETCH_SUCCESS:
            return { ...state, jobOpportunityDetails: { ...state.jobOpportunityDetails, isDataFetched: true, isDataFetching: false } };

        case CANDIDATE_APPLICATION_DATA_FETCHING_SUCCESS:
            return { ...state, candidateApplication: { ...state.candidateApplication, isDataFetching: true } };
        case CANDIDATE_APPLICATION_DATA_FETCH_SUCCESS:
            return { ...state, candidateApplication: { ...state.candidateApplication, isDataFetched: true, isDataFetching: false } };
        case CANDIDATE_APPLICATION_PAGE_UNMOUNTED_SUCCESS: {
            return { ...state, candidateApplication: { ...state.candidateApplication, isDataFetched: false, isDataFetching: false } };
        }

        case CANDIDATE_OVERVIEW_PAGE_DATA_FETCHING_SUCCESS:
            return { ...state, candidateOverviewPage: { ...state.candidateOverviewPage, isDataFetching: true } };
        case CANDIDATE_OVERVIEW_PAGE_DATA_FETCH_SUCCESS:
            return { ...state, candidateOverviewPage: { ...state.candidateOverviewPage, isDataFetched: true, isDataFetching: false } };
        case CANDIDATE_PROFILE_PAGE_UNMOUNTED_SUCCESS:
            return { ...state, candidateOverviewPage: { ...state.candidateOverviewPage, isDataFetched: false, isDataFetching: false } };

        case CLIENT_PROJECTS_WITH_EMPLOYEES_DATA_FETCHING_SUCCESS:
            return { ...state, clientProjectsWithEmployees: { ...state.clientProjectsWithEmployees, isDataFetching: true } };
        case CLIENT_PROJECTS_WITH_EMPLOYEES_DATA_FETCH_SUCCESS:
            return { ...state, clientProjectsWithEmployees: { ...state.clientProjectsWithEmployees, isDataFetched: true, isDataFetching: false } };

        case CLIENT_PROJECTS_TOTAL_REVENUE_DATA_FETCHING_SUCCESS:
            return { ...state, clientProjectsTotalRevenue: { ...state.clientProjectsTotalRevenue, isDataFetching: true } };
        case CLIENT_PROJECTS_TOTAL_REVENUE_DATA_FETCH_SUCCESS:
            return { ...state, clientProjectsTotalRevenue: { ...state.clientProjectsTotalRevenue, isDataFetched: true, isDataFetching: false } };

        case CLIENT_INFORMATION_DATA_FETCHING_SUCCESS:
            return { ...state, clientInformation: { ...state.clientInformation, isDataFetching: true } };
        case CLIENT_INFORMATION_DATA_FETCH_SUCCESS:
            return { ...state, clientInformation: { ...state.clientInformation, isDataFetched: true, isDataFetching: false } };

        case TASKS_DATA_FETCHING_SUCCESS:
            return { ...state, tasksList: { ...state.tasksList, isDataFetching: true } };
        case TASKS_DATA_FETCH_SUCCESS:
            return { ...state, tasksList: { ...state.tasksList, isDataFetched: true, isDataFetching: false } };

        case SICK_LEAVES_DATA_FETCHING_SUCCESS:
            return { ...state, sickLeaves: { ...state.sickLeaves, isDataFetching: true } };
        case SICK_LEAVES_DATA_FETCH_SUCCESS:
            return { ...state, sickLeaves: { ...state.sickLeaves, isDataFetched: true, isDataFetching: false } };

        case RAW_CV_PROGRESS_DATA_FETCHING_SUCCESS:
            return { ...state, rawCvProgess: { ...state.rawCvProgess, isDataFetching: true } };
        case RAW_CV_PROGRESS_DATA_FETCH_SUCCESS:
            return { ...state, rawCvProgess: { ...state.rawCvProgess, isDataFetched: true, isDataFetching: false } };

        case CLIENT_INTERVIEW_DATES_DATA_FETCHING_SUCCESS:
            return { ...state, clientInterviews: { ...state.clientInterviews, isDataFetching: true } };
        case CLIENT_INTERVIEW_DATES_DATA_FETCH_SUCCESS:
            return { ...state, clientInterviews: { ...state.clientInterviews, isDataFetched: true, isDataFetching: false } };

        case CLIENT_JOBS_DASHBOARD_DATA_FETCHING_SUCCESS:
            return { ...state, clientJobsDashboard: { ...state.clientJobsDashboard, isDataFetching: true } };
        case CLIENT_JOBS_DASHBOARD_DATA_FETCH_SUCCESS:
            return { ...state, clientJobsDashboard: { ...state.clientJobsDashboard, isDataFetched: true, isDataFetching: false } };

        case SEARCH_DATA_FETCHING_SUCCESS:
            return { ...state, searchData: { ...state.searchData, isDataFetching: true } };
        case SEARCH_DATA_FETCH_SUCCESS:
            return { ...state, searchData: { ...state.searchData, isDataFetched: true, isDataFetching: false } };
        case CHECKLISTED_CANDIDATES_DATA_FETCHING_SUCCESS:
            return { ...state, checklistedCandidates: { ...state.checklistedCandidates, isDataFetching: true } };
        case CHECKLISTED_CANDIDATES_DATA_FETCH_SUCCESS:
            return { ...state, checklistedCandidates: { ...state.checklistedCandidates, isDataFetched: true, isDataFetching: false } };

        case LOGIN_SUCCESS:
            return { ...state, login: action.payload };

        case SUPPLIER_PROFILE_PAGE_UNMOUNTED_SUCCESS:
            return {
                ...state,
                isEmployeesTableFetching: { ...state.isEmployeesTableFetching, isDataFetching: false, isDataFetched: false },
            };
        case PROJECT_DETAILS_PAGE_UNMOUNT_SUCCESS:
            return {
                ...state,
                activeHolidaysTable: { ...state.activeHolidaysTable, isDataFetching: false, isDataFetched: false },
                clientsProjectsOverview: { ...state.clientsProjectsOverview, isDataFetching: false, isDataFetched: false },
            }
        case EMPLOYEE_PROFILE_PAGE_UNMOUNTED_SUCCESS:
            return {
                ...state,
                profileInformation: { ...state.profileInformation, isDataFetching: false, isDataFetched: false },
                historicalNotes: { ...state.historicalNotes, isDataFetching: false, isDataFetched: false },
                sickLeaves: { ...state.sickLeaves, isDataFetching: false, isDataFetched: false },
            };
        case CLIENT_PROFILE_PAGE_UNMOUNTED_SUCCESS:
            return {
                ...state,
                clientProjectsWithEmployees: { ...state.clientProjectsWithEmployees, isDataFetching: false, isDataFetched: false },
                isEmployeesTableFetching: { ...state.isEmployeesTableFetching, isDataFetching: false, isDataFetched: false },
                clientProjectsTotalRevenue: { ...state.clientProjectsTotalRevenue, isDataFetching: false, isDataFetched: false },
            };
        case APPLICATION_PROCESS_INFORMATION_FETCHING_SUCCESS:
            return { ...state, applicationProcessInformation: { ...state.applicationProcessInformation, isDataFetching: true } };
        case APPLICATION_PROCESS_INFORMATION_FETCH_SUCCESS:
            return { ...state, applicationProcessInformation: { ...state.applicationProcessInformation, isDataFetched: true, isDataFetching: false } };
        case CANDIDATE_PROFILE_PAGE_DATA_FETCHING_SUCCESS:
            return { ...state, candidateProfilePage: { ...state.candidateProfilePage, isDataFetching: true } };
        case CANDIDATE_PROFILE_PAGE_DATA_FETCH_SUCCESS:
            return { ...state, candidateProfilePage: { ...state.candidateProfilePage, isDataFetched: true, isDataFetching: false } };

        case DIALOG_ACTION_BUTTON_LOADER_SHOW_SUCCESS:
        case DIALOG_ACTION_BUTTON_LOADER_HIDE_SUCCESS:
            return { ...state, dialogActionButton: action.payload };

        case AI_MATCHED_CANDIDATES_SCORES_FETCHING_SUCCESS:
            return { ...state, aiMatchedCandidatesScores: { ...state.aiMatchedCandidatesScores, isDataFetching: true } };
        case AI_MATCHED_CANDIDATES_SCORES_FETCH_SUCCESS:
            return { ...state, aiMatchedCandidatesScores: { ...state.aiMatchedCandidatesScores, isDataFetched: true, isDataFetching: false } };

        case CV_PAGE_UPDATING_SUCCESS:
            return { ...state, cvPage: { ...state.cvPage, isDataUpdated: false, isDataUpdating: true } };
        case CV_PAGE_UPDATE_SUCCESS:
            return { ...state, cvPage: { ...state.cvPage, isDataUpdated: true, isDataUpdating: false } };

        case EMPLOYEE_OVERVIEW_FETCHING_SUCCESS:
            return { ...state, employeeOverviewInformation: { ...state.employeeOverviewInformation, isDataFetching: true } }
        case EMPLOYEE_OVERVIEW_FETCH_SUCCESS:
            return { ...state, employeeOverviewInformation: { ...state.employeeOverviewInformation, isDataFetching: false, isDataFetched: true } }

        default:
            return state;
    }
}

export const getDashboardDataFetchState = state => state.dashboard;

export const getEmployeesTableDataFetchState = state => state.employeesTable;

export const getActiveHolidaysTableDataFetchState = state => state.activeHolidaysTable;

export const getClientsTableDataFetchState = state => state.clientsTable;

export const getClientsProjectsOverviewDataFetchState = state => state.clientsProjectsOverview;

export const getClientsAllProjectsDataFetchState = state => state.clientsAllProjects;

export const getJobOpportunitiesListDataFetchState = state => state.jobOpportunitiesList;

export const getJobOpportunityPageDataFetchState = state => state.jobOpportunityPage;

export const getJobMatchingConsultantsPageFetchState = state => state.jobMatchingConsultantsPage;

export const getHistoricalNotesDataFetchState = state => state.historicalNotes;

export const getRelatedOpportunitiesDataFetchState = state => state.employeesRelatedOpportunities;

export const getEmployeesPersonalInformationDataFetchState = state => state.employeesPersonalInformation;

export const getEmployeesEmploymentInformationDataFetchState = state => state.employeesEmploymentInformation;

export const getTasksListDataFetchState = state => state.tasksList;

export const getPerformanceReviewsDataFetchState = state => state.performanceReviews;

export const getProfileDataFetchState = state => state.profileInformation;

export const getTimeTrackingDataFetchState = state => state.timeTracking;

export const getKpiReportDataFetchState = state => state.kpiReport;

export const getReportsPageDataFetchState = state => state.reportsPage;

export const getCandidateInterviewInvitationsDataFetchState = state => state.candidateInterviewInvitations;

export const getBenefitsDataFetchState = state => state.benefits;

export const getExploreTalentsDataFetchState = state => state.exploreTalents;

export const getCandidatesPreferencesDataFetchData = state => state.candidatesPreferences;

export const getCandidateVideoInterviewDataFetchState = state => state.candidateVideoInterviewFeedback;

export const getCandidateApplicationsDataFetchState = state => state.candidateApplications;

export const getClientsProjectsPageDataFetchState = state => state.clientsProjectsPage;

export const getContractsDataFetchState = state => state.contractsTable;

export const getInvoicesDataFetchState = state => state.invoicesHistoryTable;

export const getAccountManagerDataFetchState = state => state.accountManager;

export const getJobOpportunityDetailsDataFetchState = state => state.jobOpportunityDetails;

export const getClientProjectDocumentsDataFetchState = state => state.clientProjectDocuments;

export const getCandidateApplicationDataFetchState = state => state.candidateApplication;

export const getCandidateOverviewPageDataFetchState = state => state.candidateOverviewPage;

export const getClientProjectsWithEmployeesDataFetchState = state => state.clientProjectsWithEmployees;

export const getClientProjectsTotalRevenueDataFetch = state => state.clientProjectsTotalRevenue;

export const getClientInformationDataFetchState = state => state.clientInformation;

export const getRawCvProgressDataFetchState = state => state.rawCvProgess;

export const getClientInterviewsDataFetchState = state => state.clientInterviews;

export const getClientJobDashboardDataFetchState = state => state.clientJobsDashboard;

export const getBusinessTripsListDataFetchState = state => state.businessTripsList;

export const getDocumentsTableDataFetchState = state => state.documentsTable;

export const getSearchDataFetchState = state => state.searchData;

export const getChecklistedCandidatesDataFetchState = state => state.checklistedCandidates;

export const getLoginLoading = state => state.login;

export const getClientCompanyInformationFetchState = state => {
    const employeesTable = getEmployeesTableDataFetchState(state);
    const clientInformation = getClientInformationDataFetchState(state);

    return {
        isDataFetching: employeesTable.isDataFetching || clientInformation.isDataFetching,
        isDataFetched: employeesTable.isDataFetched && clientInformation.isDataFetched,
    };
}

export const getClientOverviewFetchState = state => {
    const employeesTable = getEmployeesTableDataFetchState(state);
    const clientProjectsWithEmployees = getClientProjectsWithEmployeesDataFetchState(state);
    const clientProjectsTotalRevenue = getClientProjectsTotalRevenueDataFetch(state);
    const historicalNotes = getHistoricalNotesDataFetchState(state);

    return {
        isDataFetching: employeesTable.isDataFetching || clientProjectsWithEmployees.isDataFetching || historicalNotes.isDataFetching || clientProjectsTotalRevenue.isDataFetching,
        isDataFetched: employeesTable.isDataFetched && clientProjectsWithEmployees.isDataFetched && historicalNotes.isDataFetched && clientProjectsTotalRevenue.isDataFetched,
    };
}

export const getProjectDetailsFetchState = state => {
    const projectData = getClientsProjectsOverviewDataFetchState(state);
    const activeHolidays = getActiveHolidaysTableDataFetchState(state);

    return {
        isDataFetching: projectData.isDataFetching || activeHolidays.isDataFetching,
        isDataFetched: projectData.isDataFetched && activeHolidays.isDataFetched,
    };
}

export const getEmployeeOverviewFetchState = state => state.employeeOverviewInformation;

export const getEmployeeProfileCVFetchState = state => {
    const profile = getProfileDataFetchState(state);
    const relatedOpportunities = getRelatedOpportunitiesDataFetchState(state);
    const rawCVProgress = getRawCvProgressDataFetchState(state);

    return {
        isDataFetching: profile.isDataFetching || relatedOpportunities.isDataFetching || rawCVProgress.isDataFetching,
        isDataFetched: profile.isDataFetched && relatedOpportunities.isDataFetched && rawCVProgress.isDataFetched,
    };
}

export const getApplicationProcessInformationFetchState = state => state.applicationProcessInformation;

export const getCandidateProfilePageDataFetchState = state => state.candidateProfilePage;

export const getDialogActionButtonLoading = state => state.dialogActionButton;

export const getAiMatchedCandidatesScoresFetchState = state => state.aiMatchedCandidatesScores;

export const getCvPageUpdateState = state => state.cvPage;

export default sectionsLoaders;
