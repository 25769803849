import React from 'react';

import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import { ROLES } from 'constants/userConstants';

import './ExportOptionsPopover.scss';

const ExportOptionsPopover = ({
    handleClose,
    anchorEl,
    handleExportTable=null,
    handleExportDocumentsFiles=null,
    handleExportSelectedDocumentsFiles=null,
    displayMode,
}) => {

    return (
        <div>
            <Popover
                className="export-options-popover"
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <List className="popover-list">
                    { handleExportTable && [ROLES.ADMIN].includes(displayMode) &&
                        <ListItem button className="popover-list-item" onClick={handleExportTable}>
                            <ListItemText primary="Export Excel list" className="list-item-text" />
                        </ListItem>
                    }
                    { handleExportDocumentsFiles &&
                        <ListItem button className="popover-list-item" onClick={handleExportDocumentsFiles}>
                            <ListItemText primary="Export all" className="list-item-text" />
                        </ListItem>
                    }
                    { handleExportSelectedDocumentsFiles &&
                        <ListItem button className="popover-list-item" onClick={handleExportSelectedDocumentsFiles}>
                            <ListItemText primary="Export selected" className="list-item-text" />
                        </ListItem>
                    }
                </List>
            </Popover>
        </div>
    );
};


export default ExportOptionsPopover;
