import React from 'react';
import { isMobile } from 'react-device-detect';
import { Link as RouterLink, useHistory } from 'react-router-dom';

import PrimaryButton from '../Buttons/PrimaryButton';
import Link from '@material-ui/core/Link';

import { ReactComponent as GoogleIcon } from 'assets/google.svg';

import './SignUpFooter.scss';

const SignUpFooter = ({ handleSubmit, link, label, jobOpportunityId, handleRegisterWithSSO }) => {
    const history = useHistory();

    // due to task #7629 and #7615 we only want candidates and clients to see the google option for now
    const isSignUpWithGoogleAvailable = history.location.pathname.includes('/register/candidate') || history.location.pathname.includes('/register/client');

    return (
        <div className={`sign-up-footer${isMobile ? ' mobile' : ''}`}>
            <PrimaryButton text="Sign Up" type="submit" className={`common-btn ${isMobile ? 'sign-up-mobile-btn' : 'sign-up-btn'}`} handleClick={handleSubmit} />

            {isSignUpWithGoogleAvailable &&
                <>
                    <div className={`text-divider${isMobile ? ' mobile' : ''}`}><p className="divider-content">or</p></div>
                    <button className={`google-btn${isMobile ? ' mobile' : ''}`} onClick={handleRegisterWithSSO}>
                        <GoogleIcon />
                        <p className="google-btn-text">sign up with google</p>
                    </button>
                </>
            }
            <p>
                Already have an account? <Link
                    underline="always"
                    component={RouterLink}
                    to={{ pathname: link, state: { jobOpportunityId } }}
                >
                    {label}
                </Link>
            </p>
        </div>
    )
}

export default SignUpFooter;
