import React from 'react';
import moment from 'moment';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import Tooltip from '@material-ui/core/Tooltip';

import { DATE_FORMAT_WITH_DOTS_FULL_YEAR } from 'constants/commonConstants';
import { PROJECT_TYPES_MAP } from 'constants/projectConstants';

import { formatStringAsClassName } from 'utils/helpers';

export default function CardHeader({ project, isAdminMode }) {
    return (
        <div className="client-project-header-wrapper">
            <div className="flex-aligned-wrapper">
                <div className="project-title-container">
                    <span>{project.name}</span>
                </div>
                {(project.type && isAdminMode) &&
                    <div className={`project-type-container ${formatStringAsClassName(PROJECT_TYPES_MAP[project.type])}`}>
                        {PROJECT_TYPES_MAP[project.type]}
                    </div>
                }
            </div>
            <div className="calendar-project-date-container">
                <div className="calendar-project-date-wrapper">
                    <Tooltip
                        title="Project Start Date"
                        placement="bottom"
                        classes={{ tooltip: 'custom-tooltip' }}
                    >
                        <div className="date-container">
                            <div className="calendar-icon-container">
                                <CalendarTodayIcon className="calendar-icon start" />
                            </div>
                            <div className="date-content">
                                <span>{moment.utc(project.startDate).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR)}</span>
                            </div>
                        </div>
                    </Tooltip>

                    <Tooltip
                        title="Project End Date"
                        placement="bottom"
                        classes={{ tooltip: 'custom-tooltip' }}
                    >
                        <div className="date-container">
                            <div className="calendar-icon-container">
                                <CalendarTodayIcon className="calendar-icon end" />
                            </div>
                            <div className="date-content">
                                <span>
                                    {project.ongoing || !project.endDate ? "Ongoing" : moment.utc(project.endDate).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR)}
                                </span>
                            </div>
                        </div>
                    </Tooltip>
                </div>
            </div>
        </div>
    );
}
