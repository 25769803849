import React from 'react';
import { ErrorMessage } from 'formik';

import TextField from '@material-ui/core/TextField';

const AgencySettingsTeamNotificationsFormView = ({
    values,
    handleChange,
    setFieldValue,
    handleShowHeaderButtons,
    errors,
    touched,
}) => (
    <form autoComplete="off">
        <div className="agency-team-notifications-settings-form-block">
            <div className="agency-team-notifications-settings-form-field">
                <TextField
                    required
                    label="Recruitment Email"
                    variant="outlined"
                    name="recruitmentEmail"
                    value={values.recruitmentEmail}
                    onChange={(e) => { handleChange(e); handleShowHeaderButtons(); }}
                    error={errors.recruitmentEmail && touched.recruitmentEmail}
                    helperText="E.g recruitment@yourcompany.com"
                    fullWidth
                />
                <ErrorMessage name="recruitmentEmail" component="div" className="invalid-field-message" />
            </div>
        </div>
        <div className="agency-team-notifications-settings-form-block">
            <div className="agency-team-notifications-settings-form-field">
                <TextField
                    required
                    label="Finance Email"
                    variant="outlined"
                    name="financeEmail"
                    value={values.financeEmail}
                    onChange={(e) => { handleChange(e); handleShowHeaderButtons(); }}
                    error={errors.financeEmail && touched.financeEmail}
                    helperText="E.g finance@yourcompany.com"
                    fullWidth
                />
                <ErrorMessage name="financeEmail" component="div" className="invalid-field-message" />
            </div>
        </div>
        <div className="agency-team-notifications-settings-form-block">
            <div className="agency-team-notifications-settings-form-field">
                <TextField
                    required
                    label="HR Email"
                    variant="outlined"
                    name="hrEmail"
                    value={values.hrEmail}
                    onChange={(e) => { handleChange(e); handleShowHeaderButtons(); }}
                    error={errors.hrEmail && touched.hrEmail}
                    helperText="E.g hr@yourcompany.com"
                    fullWidth
                />
                <ErrorMessage name="hrEmail" component="div" className="invalid-field-message" />
            </div>
        </div>
    </form>
);

export default AgencySettingsTeamNotificationsFormView;
