import { useState } from 'react';
import { Formik } from 'formik';
import moment from 'moment';

import SendProfilesAutocomplete from '../SharedComponents/SendProfilesAutocomplete';
import SendProfilesListItem from '../SharedComponents/SendProfilesListItem';
import CommonFormikDialog from 'components/Shared/CommonFormikDialog';
import CustomCheckbox from 'components/Shared/CustomCheckbox';
import List from '@material-ui/core/List';

import { validateInputLength, validatesArrayLength } from 'utils/formValidations';

import './AddCommentsDialog.scss';

const AddCommentsDialog = ({
    isAddCommentsDialogOpen,
    handleCloseDialog,
    initialSelectedEmployees,
    inputValue,
    setInputValue,
    handleChangeInputValue,
    searchResult,
    fetchSearchResultsPending,
    isLoading,
    handleSendProfiles,
    notifierName,
    showSearchbar = true,
}) => {
    const [triggeredComments, setTriggeredComments] = useState(initialSelectedEmployees.map(employee => employee._id));

    const handleShowComment = employeeId => {
        setTriggeredComments([...triggeredComments, employeeId]);
    };

    const handleHideComment = employeeId => {
        setTriggeredComments(triggeredComments.filter(x => x !== employeeId));
    };

    return (
        <Formik
            enableReinitialize
            initialValues={{
                selectedEmployees: initialSelectedEmployees,
                comments: new Array(initialSelectedEmployees.length).fill(''),
                notifyClient: true,
            }}
            validate={(values) => {
                return {
                    ...validatesArrayLength(values, 'selectedEmployees', 1),
                    ...values.comments.reduce((acc, comment, index) => {
                        return {
                            ...acc,
                            ...validateInputLength({ [`comments[${index}]`]: comment }, [
                                {
                                    property: `comments[${index}]`,
                                    maxLength: 2000
                                }
                            ])
                        }
                    }, {}),
                }
            }}
            onSubmit={async (values) => {
                const { selectedEmployees, comments, notifyClient } = values;
                const applicationDate = moment.utc().format();

                const profiles = selectedEmployees.map((employee, index) => ({ _id: employee._id, comment: comments[index], applicationDate }));

                await handleSendProfiles(profiles, notifyClient);
            }}
        >
            {(props) =>
                <CommonFormikDialog
                    className="add-comments-dialog"
                    onSave={props.handleSubmit}
                    dialogTitle="Send Profiles"
                    open={isAddCommentsDialogOpen}
                    onClose={handleCloseDialog}
                    saveBtnText="Send"
                    discardBtnText="Discard"
                    size="md"
                >
                    {showSearchbar &&
                        <SendProfilesAutocomplete
                            {...props}
                            inputValue={inputValue}
                            setInputValue={setInputValue}
                            handleChangeInputValue={handleChangeInputValue}
                            searchResult={searchResult}
                            fetchSearchResultsPending={fetchSearchResultsPending}
                            isLoading={isLoading}
                            isWithNote={true}
                            handleShowComment={handleShowComment}
                        />
                    }

                    {props.values.selectedEmployees.length > 0 &&
                        <List className="send-profiles-list-wrapper">
                            {props.values.selectedEmployees.map((selectedEmployee, index) => (
                                <SendProfilesListItem
                                    {...props}
                                    key={selectedEmployee._id}
                                    employee={selectedEmployee}
                                    isWithRemoveProfile={true}
                                    isWithNote={true}
                                    isCommentButtonTriggered={triggeredComments.includes(selectedEmployee._id)}
                                    handleShowComment={handleShowComment}
                                    handleHideComment={handleHideComment}
                                    index={index}
                                />
                            ))}
                        </List>
                    }

                    <div className="checkbox-container">
                        <CustomCheckbox
                            name="notifyClient"
                            isChecked={props.values.notifyClient}
                            className="notification-checkbox"
                            onChangeHandler={() => props.setFieldValue('notifyClient', !props.values.notifyClient)}
                        />
                        <p className="notification-text">Notify {notifierName} about your response?</p>
                    </div>
                </CommonFormikDialog>
            }
        </Formik>
    );
};

export default AddCommentsDialog;
