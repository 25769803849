import { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { updateCandidateCriteria, updateCandidateProfileSteps } from 'actions/candidateActions';
import { getAgencyId, getCandidateProfileSteps, getUserId } from 'reducers';

import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';

import {
    getStepContent,
    getInitialFormValues,
    mapValuesToRequestBody,
    getFirstUncompletedStep,
    getHelperButtonTextFromStepName,
} from './HiringChecklistUtils';

import { formatStringAsClassName, getStateAgencyName } from 'utils/helpers';

import {
    HIRING_CHECKLIST_TITLES,
    CHECKLIST_TITLE_TO_PROPERTY_MAP,
    CHECKLIST_DIALOGS_MAP,
    CHECKLIST_TITLE_TO_DIALOG_MAP,
    DIALOG_KEYS_TO_TITLES_MAP,
    CHECKLIST_STEPS_KEYS,
    STEP_VALIDATION_FUNCTION,
    HIRING_CHECKLIST_STEPS_ORDER,
} from './HiringChecklistConstants';

import { CANDIDATE_PROFILE_STEPS, CANDIDATE_STATUSES, DEFAULT_PROFILE_MATCHING_JOB_FILTER_QUERY } from 'constants/candidateConstants';

import './HiringChecklist.scss';

const HiringChecklist = ({
    candidateProfileSteps,
    userId,
    agencyId,
    updateCandidateProfileSteps,
    updateCandidateCriteria,
}) => {
    const history = useHistory();
    const { id: candidateId } = useParams();

    const [showEditPen, setShowEditPen] = useState({ softSkillsVetted: false, hardSkillsVetted: false, candidateRated: false });
    const [openDialogs, setOpenDialogs] = useState({ softSkillsDialog: false, hardSkillsDialog: false, candidateRatedDialog: false });
    const [editModes, setEditModes] = useState({ softSkillsDialog: false, hardSkillsDialog: false, candidateRatedDialog: false });

    const handleCloseDialog = (dialogKey) => {
        const newValue = { [dialogKey]: false };

        setOpenDialogs(oldState => ({
            ...oldState,
            ...newValue,
        }));
    };

    const handleOpenDialog = (dialogKey, isEditMode) => {
        const newValue = { [dialogKey]: true };

        if (isEditMode) {
            setEditModes(oldState => ({
                ...oldState,
                ...newValue,
            }));
        }

        setOpenDialogs(oldState => ({
            ...oldState,
            ...newValue,
        }));
    };

    const handleDialogFormSubmit = async (dialogKey, values) => {
        let updateObj = {};

        switch (dialogKey) {
            case CHECKLIST_STEPS_KEYS.SOFT_SKILLS_STEP:
                updateObj = { ...values, reviewer: userId, type: CANDIDATE_PROFILE_STEPS.SOFT_SKILLS };
                await updateCandidateProfileSteps(agencyId, candidateId, updateObj);
                break;
            case CHECKLIST_STEPS_KEYS.HARD_SKILLS_STEP:
                updateObj = { ...values, reviewer: userId, type: CANDIDATE_PROFILE_STEPS.HARD_SKILLS };
                await updateCandidateProfileSteps(agencyId, candidateId, updateObj);
                break;
            case CHECKLIST_STEPS_KEYS.CANDIDATE_RATED_STEP:
                updateObj = mapValuesToRequestBody(userId, values);
                await updateCandidateCriteria(agencyId, candidateId, updateObj);
                break;
            default:
                break;
        }

        handleCloseDialog(dialogKey);
    };

    const getHelperButtonAction = (stepName) => {
        let func = null;

        switch (stepName) {
            case HIRING_CHECKLIST_STEPS_ORDER[0]:
                func = handleOpenDialog.bind(null, 'softSkillsDialog', false);
                break;
            case HIRING_CHECKLIST_STEPS_ORDER[1]:
                func = handleOpenDialog.bind(null, 'hardSkillsDialog', false);
                break;
            case HIRING_CHECKLIST_STEPS_ORDER[2]:
                func = handleOpenDialog.bind(null, 'candidateRatedDialog', false);
                break;
            case HIRING_CHECKLIST_STEPS_ORDER[3]:
                func = () => history.push(`/${getStateAgencyName()}/profile/${candidateId}/matching-jobs${DEFAULT_PROFILE_MATCHING_JOB_FILTER_QUERY}`);
                break;
            case HIRING_CHECKLIST_STEPS_ORDER[4]:
                func = () => history.push(`/${getStateAgencyName()}/candidates/${candidateId}/applications`);
                break;
            default:
                func = () => void 0;
                break;
        }

        return func;
    };

    const nextStepToBeCompleted = getFirstUncompletedStep(candidateProfileSteps);
    const helperButtonText = getHelperButtonTextFromStepName(nextStepToBeCompleted);
    const helperButtonAction = getHelperButtonAction(nextStepToBeCompleted);

    return (
        <div className="candidate-hiring-checklist-wrapper">
            <div className="candidate-hiring-checklist-container">
                {Object.values(HIRING_CHECKLIST_TITLES).map((title, idx) => {
                    const step = candidateProfileSteps[CHECKLIST_TITLE_TO_PROPERTY_MAP[title]] || {};
                    const dialogKey = CHECKLIST_TITLE_TO_DIALOG_MAP[title];

                    const isRejectedOrIncomplete = candidateProfileSteps.sendFeedbackToCandidateStep.status === CANDIDATE_STATUSES.REJECTED
                        || candidateProfileSteps.sendFeedbackToCandidateStep.status === CANDIDATE_STATUSES.INCOMPLETE_PROFILE;

                    const previousStepTitle = Object.values(HIRING_CHECKLIST_TITLES)[idx - 1];
                    let isPreviousStepCompleted = true;

                    if (previousStepTitle) {
                        isPreviousStepCompleted = candidateProfileSteps[CHECKLIST_TITLE_TO_PROPERTY_MAP[previousStepTitle]].isCompleted;
                    }

                    return getStepContent(
                        handleOpenDialog,
                        dialogKey,
                        title,
                        step,
                        isRejectedOrIncomplete,
                        showEditPen,
                        setShowEditPen,
                        isPreviousStepCompleted,
                    );
                })}
                {Object.entries(CHECKLIST_DIALOGS_MAP).map(([key, value]) => {
                    return value({
                        key,
                        dialogKey: key,
                        isDialogOpen: openDialogs[key],
                        handleCloseDialog,
                        dialogTitle: DIALOG_KEYS_TO_TITLES_MAP(editModes[key])[key],
                        initialValues: getInitialFormValues(candidateProfileSteps, key),
                        handleSubmit: handleDialogFormSubmit,
                        validationFunction: STEP_VALIDATION_FUNCTION[key],
                    })
                })}
            </div>
            {nextStepToBeCompleted &&
                <div className="action-buttons-wrapper">
                    <PrimaryButton
                        text={helperButtonText}
                        handleClick={helperButtonAction}
                        data-testid={`hiring-helper-btn-${formatStringAsClassName(helperButtonText)}`}
                    />
                </div>
            }
        </div>
    )
};

const mapStateToProps = state => ({
    candidateProfileSteps: getCandidateProfileSteps(state),
    userId: getUserId(state),
    agencyId: getAgencyId(state),
});

const mapDispatchToProps = {
    updateCandidateProfileSteps,
    updateCandidateCriteria,
};

export default connect(mapStateToProps, mapDispatchToProps)(HiringChecklist);
