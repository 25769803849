import React from 'react';
import { connect } from 'react-redux';

import TextField from '@material-ui/core/TextField';
import { Formik, ErrorMessage } from 'formik';

import LoginCard from 'components/Shared/LoginCard';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';

import { sendPasswordResetEmail } from 'actions/authActions';
import { validateRequiredFields, validateEmailAddress } from 'utils/formValidations';

const ForgottenPassword = ({
    sendPasswordResetEmail,
}) => (
    <LoginCard msg="Enter your email and we will send you a password reset link.">
        <Formik
            initialValues={{
                email: ''
            }}
            validate={values => {
                return {...validateRequiredFields(values, ['email']), ...validateEmailAddress(values, 'email')}
            }}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
                sendPasswordResetEmail(values.email.toLowerCase().trim());
            }}
        >
            {({
                values,
                handleChange,
                handleSubmit,
                errors,
                touched,
            }) => (
                <form autoComplete='off'>
                    <div>
                        <TextField 
                            required
                            className='login-field'
                            name='email'
                            value={values.email}
                            onChange={handleChange}
                            id='forgotten-pwd-email' 
                            label='Email'
                            error={errors.email && touched.email}
                            margin='normal'
                            variant='outlined'
                        />
                        <ErrorMessage name='email' component='div' className='invalid-field-message' />
                    </div>
                    <div className="login-btns center-btns">
                        <PrimaryButton type='submit' text='Send Request' handleClick={handleSubmit} />
                    </div>
                </form>
            )}
        </Formik>
    </LoginCard>
);

const mapDispatchToProps = {
    sendPasswordResetEmail
};

export default connect(null, mapDispatchToProps)(ForgottenPassword);
