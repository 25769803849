import { React } from 'react';
import { Formik, ErrorMessage, useFormikContext } from 'formik';

import Rating from '@material-ui/lab/Rating';

import {
    withStyles,
    Tooltip,
    FormControlLabel,
    FormGroup,
    TextField,
    Typography,
    FormControl,
    InputLabel,
    MenuItem,
    Select
} from '@material-ui/core';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { ReactComponent as FilledStar } from 'assets/awesome-star-icon-filled.svg';
import { ReactComponent as EmptyStar } from 'assets/awesome-star-icon-empty.svg';
import { ReactComponent as InfoIcon } from "assets/info-icon.svg";

import CommonFormikDialog from '../CommonFormikDialog';
import CustomCheckbox from '../CustomCheckbox';

import './FeedbackDialog.scss';

const FeedbackDialogWrapper = ({
    dialogKey,
    dialogTitle,
    isDialogOpen,
    handleCloseDialog,
    initialValues,
    handleSubmit,
    validationFunction,
    children
}) => (
    <Formik
        enableReinitialize
        initialValues={initialValues}
        validate={values => {
            return {
                ...validationFunction(values),
            }
        }}
        onSubmit={(values) => {
            handleSubmit(dialogKey, values);
        }}
    >
        {(props) =>
            <CommonFormikDialog
                onSave={props.handleSubmit}
                dialogTitle={dialogTitle}
                open={isDialogOpen}
                onClose={() => handleCloseDialog(dialogKey)}
                saveBtnText="Save"
                discardBtnText="Discard"
                className="feedback-dialog-wrapper"
            >
                {children}
            </CommonFormikDialog>
        }
    </Formik>
);

const FeedbackDialogInfoSection = ({ content, classes = [] }) => (
    <p className={`feedback-dialog-info-section ${classes.join(' ')}`}>{content}</p>
);

const FeedbackDescriptionSection = ({
    label,
    id,
    name,
}) => {
    const { setFieldValue, values } = useFormikContext();

    return (
        <>
            <div className="form-row">
                <TextField
                    fullWidth
                    label={label}
                    variant="outlined"
                    id={id}
                    name={name}
                    value={values[name]}
                    multiline
                    onChange={e => { setFieldValue(name, e.target.value) }}
                    minRows={4}
                />
            </div>
            <ErrorMessage name={name} component="div" className="invalid-field-message" />
        </>
    )
};

const RatingCriteriaWrapper = ({ children }) => (
    <div className="rating-criteria-wrapper">
        {children}
    </div>
);

const RatingCriteriaItem = ({
    title,
    name,
    id,
    readOnly,
}) => {
    const { setFieldValue, values } = useFormikContext();

    return (
        <>
            <div className="rating-criteria-row">
                <Typography component="legend" className={`rating-criterion`}>
                    {title}
                </Typography>
                <Rating
                    readOnly={readOnly}
                    name={name}
                    id={id}
                    value={values[name]}
                    className="rating-stars-wrapper"
                    icon={<FilledStar />}
                    emptyIcon={<EmptyStar />}
                    onChange={e => { setFieldValue(name, Number(e.target.value)) }}
                />
            </div>
            <ErrorMessage name={name} component="div" className="invalid-field-message" />
        </>
    )
};

const CheckboxItem = ({
    name,
    size,
    label
}) => {
    const { setFieldValue, values } = useFormikContext();

    return (
        <FormGroup>
            <FormControlLabel
                control={
                    <CustomCheckbox
                        isChecked={values[name]}
                        onChangeHandler={(e) => { setFieldValue(name, !values[name]) }}
                        size={size}
                    />
                }
                label={label} />
        </FormGroup>
    )
};

const RatingDetailsWrapper = ({
    type,
    tooltipContent = '',
    id,
    name,
    description,
    candidateMissingMessage,
}) => {
    const { values } = useFormikContext();

    const CustomTooltipWrapper = withStyles(() => ({
        tooltip: {
            fontSize: '12px',
            backgroundColor: '#FFFFFF',
            color: '#666666',
            border: '1px solid #dadde9',
            borderRadius: '4px',
            boxShadow: '0px 1px 10px #00000033',
            padding: '8px 13px',
        },
    }))(Tooltip);

    return (
        <div className="rating-details-wrapper">
            <div className="rating-type-with-reviewer-tooltip-wrapper">
                <Typography variant='h5' className="rating-type">
                    {type}
                </Typography>
                <CustomTooltipWrapper title={tooltipContent} placement="bottom-start" className="reviewer-tooltip" data-testid="reviewer-tooltip">
                    <InfoIcon className="info-icon" />
                </CustomTooltipWrapper>
            </div>
            <div className="rating-criteria">
                <Rating
                    readOnly={true}
                    name={name}
                    id={id}
                    value={values[name]}
                    icon={<FilledStar />}
                    emptyIcon={<EmptyStar />}
                    className="rating-stars-wrapper"
                />
            </div>
            <div className="rating-description">
                {candidateMissingMessage && <p className="global-text-red">{candidateMissingMessage}</p>}
                <p>{description}</p>
            </div>
        </div>
    );
};

const DropdownSelect = ({
    name,
    label,
    selectOptions,
    visibilityDependencyField,
    visibilityDependencyValue,
}) => {
    const { setFieldValue, values } = useFormikContext();

    if (values[visibilityDependencyField] === visibilityDependencyValue || !visibilityDependencyField) {
        return (
            <FormControl fullWidth variant="outlined" className="form-row">
                <InputLabel>{label}</InputLabel>
                <Select
                    data-testid="dropdown-select-test-id"
                    name={name}
                    label={label}
                    IconComponent={KeyboardArrowDownIcon}
                    value={values[name]}
                    onChange={(e) => setFieldValue(name, e.target.value)}
                >
                    {selectOptions.map((item, i) => (
                        <MenuItem key={i} value={item.key}>
                            {item.value}
                        </MenuItem>
                    ))}
                </Select>
                <ErrorMessage className="invalid-field-message" name={name} component="div" />
            </FormControl>
        )
    } else {
        return null;
    }

};

FeedbackDialogWrapper.FeedbackDialogInfoSection = FeedbackDialogInfoSection;
FeedbackDialogWrapper.FeedbackDescriptionSection = FeedbackDescriptionSection;
FeedbackDialogWrapper.RatingCriteriaWrapper = RatingCriteriaWrapper;
FeedbackDialogWrapper.RatingCriteriaItem = RatingCriteriaItem;
FeedbackDialogWrapper.CheckboxItem = CheckboxItem;
FeedbackDialogWrapper.RatingDetailsWrapper = RatingDetailsWrapper;
FeedbackDialogWrapper.DropdownSelect = DropdownSelect;

export default FeedbackDialogWrapper;
