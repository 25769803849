import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { registerCandidate, login } from 'actions/authActions';
import { showApplicationLoader, hideApplicationLoader } from 'actions/applicationLoaderActions';

import { Formik } from 'formik';
import { validateEmailAddress, validatePasswordEquality, validatePasswordRequirements, validateRequiredFields } from "utils/formValidations";
import RegisterFormView from '../RegisterCandidate/RegisterFormView';

import { ROLES, REGISTRATION_TYPES } from 'constants/userConstants';
import { IS_WHOISAVAILABLE_ENV } from 'constants/env';

import LoginCard from 'components/Shared/LoginCard';

import './RegisterAgencyCandidate.scss';

const RegisterAgencyCandidate = ({
    registerCandidate,
    login,
    showApplicationLoader,
    hideApplicationLoader
}) => {
    const history = useHistory();
    const { agencyData } = useParams();

    const agencyInfo = JSON.parse(atob(decodeURIComponent(agencyData)));
    const initialValues = IS_WHOISAVAILABLE_ENV
        ? { name: '', email: '', password: '', confirmPassword: '', }
        : { name: '', email: '', password: '', confirmPassword: '', isTermsAndConditionsAccepted: false };

    useEffect(() => {
        if (!agencyInfo || !agencyInfo.agencyName || !agencyInfo.agencyId) {
            history.push({
                pathname: '/login'
            });
        }
    }, []);

    return (
        <LoginCard msg={`Thank you for your interest in applying for a job at ${agencyInfo.agencyName}. In order to complete your profile and view the full list of positions, please use the sign up form below.`}>
            <Formik
                initialValues={initialValues}
                validate={values => {
                    const requiredFields = IS_WHOISAVAILABLE_ENV
                        ? ['name', 'email', 'password', 'confirmPassword']
                        : ['name', 'email', 'password', 'confirmPassword', 'isTermsAndConditionsAccepted'];

                    return {
                        ...validatePasswordRequirements(values, 'password'),
                        ...validatePasswordEquality(values, 'password', 'confirmPassword'),
                        ...validateRequiredFields(values, requiredFields),
                        ...validateEmailAddress(values, 'email'),
                    };
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false);

                    const { confirmPassword, ...rest } = values

                    const userData = {
                        ...rest,
                        role: ROLES.CANDIDATE,
                        registrationType: REGISTRATION_TYPES.SELF_REGISTERED,
                        agencyId: agencyInfo.agencyId,
                        email: values.email.toLowerCase().trim(),
                    };

                    registerCandidate(userData)
                        .then(async (user) => {
                            if (user) {
                                showApplicationLoader();
                                await login(userData.email, values.password, false);
                            }
                            hideApplicationLoader();
                        })
                        .catch(() => hideApplicationLoader())
                }}
            >
                {(props) => <RegisterFormView {...props} />}
            </Formik>
        </LoginCard>
    );
};

const mapDispatchToProps = {
    registerCandidate,
    login,
    showApplicationLoader,
    hideApplicationLoader
};

export default connect(null, mapDispatchToProps)(RegisterAgencyCandidate);
