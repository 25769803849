import React from 'react';
import { connect } from 'react-redux';

import { ROLES } from 'constants/userConstants';

import EmployeeDashboard from './DashboardViews/EmployeeDashboard';
import ClientDashboard from './DashboardViews/ClientDashboard';
import AgencyAdminDashboard from './DashboardViews/AgencyAdminDashboard';
import SupplierAdminDashboard from './DashboardViews/SupplierAdminDashboard';
import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';
import {
    getDisplayMode,
    getHasNeededEvrotrustInfo,
    isHiddenSections,
    getCurrentUser,
    getAgencyBasicInfo,    
} from 'reducers';

import './Dashboard.scss';

const Dashboard = ({
    userId,
    agencyId,
    displayMode,
    hiddenSections,
    hasNeededEvrotrustInfo,
    currentUser,
    isAgencyUsingEvrotrust,
    ...props
}) => {
    const showEvrotrustBanner = isAgencyUsingEvrotrust && !hasNeededEvrotrustInfo;

    return (
        <div className="site-dashboard">
            {/* <Container maxWidth="lg" className="container">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={8} lg={9}>
                            <Paper className="paper fixed-height">
                                Test 1
                        </Paper>
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <Paper className="paper fixed-height">
                                Test 2
                        </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper className="paper">
                                Test 3
                        </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Container> */}
            {/* The above code is commented due to task #576 */}
            {
                (displayMode === ROLES.EMPLOYEE)
                &&
                <EmployeeDashboard userId={userId} agencyId={agencyId}>
                    <EmployeeDashboard.BusinessTripBanner />
                    <EmployeeDashboard.UpcomingInterviewBanner />
                    {showEvrotrustBanner && <EmployeeDashboard.EvrotrustBanner />}

                    <SectionActionsWrapper>
                        <SectionActionsWrapper.RightAlignedActions>
                            <EmployeeDashboard.Shortcuts>
                                <EmployeeDashboard.Shortcuts.RequestHoliday />
                                <EmployeeDashboard.Shortcuts.TrackTime />
                            </EmployeeDashboard.Shortcuts>
                        </SectionActionsWrapper.RightAlignedActions>
                    </SectionActionsWrapper>

                    <EmployeeDashboard.ImportantDocuments />
                    <EmployeeDashboard.EmployeeProjects />
                    <EmployeeDashboard.ActiveHolidays />
                    <EmployeeDashboard.KnowYourTeam />
                </EmployeeDashboard>
            }
            {
                displayMode === ROLES.SUPPLIER_EMPLOYEE &&
                <EmployeeDashboard userId={userId} agencyId={agencyId} >
                    <SectionActionsWrapper>
                        <SectionActionsWrapper.RightAlignedActions>
                            <EmployeeDashboard.Shortcuts>
                                <EmployeeDashboard.Shortcuts.RequestHoliday />
                                <EmployeeDashboard.Shortcuts.TrackTime />
                            </EmployeeDashboard.Shortcuts>
                        </SectionActionsWrapper.RightAlignedActions>
                    </SectionActionsWrapper>
                    <EmployeeDashboard.ImportantDocuments />
                    <EmployeeDashboard.EmployeeProjects />
                    <EmployeeDashboard.ActiveHolidays />
                </EmployeeDashboard>
            }
            {
                (displayMode === ROLES.CLIENT)
                &&
                <ClientDashboard userId={userId} agencyId={agencyId} {...props}>
                    <ClientDashboard.JobOpportunitiesBanners />
                    <ClientDashboard.Profiles />
                    <ClientDashboard.Invoices />
                    <ClientDashboard.Projects />
                    <ClientDashboard.TeamMembers />
                    <ClientDashboard.PerformanceAnalytics />
                </ClientDashboard>
            }
            {displayMode === ROLES.ADMIN &&
                <AgencyAdminDashboard userId={userId} agencyId={agencyId}>
                    <AgencyAdminDashboard.ProjectsOverveiw />
                    <AgencyAdminDashboard.ActiveHolidays />
                    <AgencyAdminDashboard.Tasks />
                </AgencyAdminDashboard>
            }
            {displayMode === ROLES.SUPPLIER_ADMIN &&
                <SupplierAdminDashboard userId={userId} agencyId={agencyId}>
                    <SupplierAdminDashboard.ProjectsOverview />
                    <SupplierAdminDashboard.SupplierJobs />
                    <SupplierAdminDashboard.Invoices />
                </SupplierAdminDashboard>
            }
        </div>
    );
}

const mapStateToProps = state => ({
    displayMode: getDisplayMode(state),
    hiddenSections: isHiddenSections(state),
    hasNeededEvrotrustInfo: getHasNeededEvrotrustInfo(state),
    isAgencyUsingEvrotrust: getAgencyBasicInfo(state).isUsingEvrotrust,
    currentUser: getCurrentUser(state),
});

export default connect(mapStateToProps, null)(Dashboard);
