import { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import moment from 'moment';

import CandidatesChecklistTableActionsWrapper from './CandidatesChecklistTableActionsWrapper';
import CandidatesChecklistTableRedux from './CandidatesChecklistTableRedux';
import TableContainer from '@material-ui/core/TableContainer';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import { ReactComponent as CheckmarkIcon } from 'assets/checkmark-icon.svg';

import { INITIAL_CANDIDATES_CHECKLIST_FILTERS, INITIAL_TABLE_ROWS } from '../CandidatesChecklistConstants';
import { HIRING_CHECKLIST_TITLES } from 'components/CandidateProfilePage/HiringChecklist/HiringChecklistConstants';
import { DATE_FORMAT_WITH_DOTS_FULL_YEAR } from 'constants/commonConstants';
import { CANDIDATE_PROFILE_STEPS } from 'constants/candidateConstants';

import { mapQueryToFilters } from '../CandidatesChecklistUtils';
import { parseQueryString } from 'utils/helpers';
import { getDifferenceInDays, transformDifferenceInDaysToText } from 'utils/formatTimes';

import './CandidatesChecklistTable.scss';

const CandidatesChecklistTable = ({
    agencyRouteName,
    dataFetchState,
    filters,
    setFilters,
    setAreFiltersParsed,
    dataSelector,
}) => {
    const history = useHistory();
    const [fromPage, setFromPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(INITIAL_TABLE_ROWS);

    useEffect(() => {
        const queryString = history.location.search;
        const parsedQuery = parseQueryString(queryString);

        if (Object.keys(parsedQuery).length > 0) {
            const newFilters = mapQueryToFilters(parsedQuery);
            setFilters(newFilters);
        } else {
            setFilters(INITIAL_CANDIDATES_CHECKLIST_FILTERS);
        }
        setAreFiltersParsed(true);
        setFromPage(0);
    }, [history.location.search]);

    const adjustCandidateKeys = (candidate) => {
        const differenceInDays = candidate.lastChangeDate ? getDifferenceInDays(candidate.lastChangeDate) : null;
        const differenceInDaysToText = Number.isInteger(differenceInDays) ? transformDifferenceInDaysToText(differenceInDays) : '-';

        return {
            _id: candidate._id,
            name: (
                <div className="candidate-picture-name-wrapper">
                    <Avatar
                        className="candidate-picture"
                        src={candidate.profilePictureImgUrl}
                    />
                    <Link
                        className="candidate-name"
                        to={`/${agencyRouteName}/candidates/${candidate._id}`}
                    >
                        <span>{candidate.firstName} {candidate?.lastName || ''}</span>
                    </Link>
                </div>
            ),
            status: (
                <div className="candidate-steps-wrapper">
                    {Object.values(CANDIDATE_PROFILE_STEPS).map((propertyName, index) => {
                        const step = candidate[propertyName];
                        const stepTitle = Object.values(HIRING_CHECKLIST_TITLES)[index];

                        return (
                            <Tooltip
                                key={propertyName}
                                classes={{ tooltip: 'custom-tooltip' }}
                                title={step.isCompleted && step.dateOfRate
                                    ? <div>
                                        <p>{stepTitle}</p>
                                        <p>
                                            {
                                                step.ratedBy
                                                    ? `${step.ratedBy}, ${moment(step.dateOfRate).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR)}`
                                                    : `${moment(step.dateOfRate).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR)}`
                                            }
                                        </p>
                                    </div>
                                    : ''
                                }
                            >
                                <div className={`step-box${(step.isCompleted) ? ' done' : ''}`}>
                                    <div className="step-box-content">
                                        {step.isCompleted
                                            ? <CheckmarkIcon className="checkmark-icon" />
                                            : <span>{index + 1}</span>
                                        }
                                    </div>
                                </div>
                            </Tooltip>
                        );
                    })}
                </div>
            ),
            lastChange: (
                <div className={`candidate-last-change-date-wrapper${differenceInDays < 3 && Number.isInteger(differenceInDays) ? ' text-green' : ''}`}>
                    <span>{differenceInDaysToText}</span>
                </div>
            ),
        };
    };

    return (
        <div className="candidates-checklist-table-container">
            <CandidatesChecklistTableActionsWrapper
                filters={filters}
            />

            <TableContainer className="candidates-checklist-table-wrapper">
                <CandidatesChecklistTableRedux
                    adjustCandidateKeys={adjustCandidateKeys}
                    dataSelector={dataSelector}
                    filters={filters}
                    fromPage={fromPage}
                    setFromPage={setFromPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    dataFetchState={dataFetchState}
                />
            </TableContainer>
        </div>
    );
};

export default CandidatesChecklistTable;
