import React, { useState } from 'react';
import { usePreloadTable } from 'utils/hooks';
import moment from 'moment';

import { setInvoiceFormValues } from './BillingTableUtils';

import ConfirmDeleteDialog from 'components/Shared/ConfirmDeleteDialog';
import TableWrapper from 'components/Shared/TableWrapper/TableWrapper';
import AddInvoiceDialog from '../AddInvoiceDialog';
import EditStatusDialog from '../EditStatusDialog';

import EditSharpIcon from '@material-ui/icons/EditSharp';
import { Typography } from '@material-ui/core';

import { logEvent } from 'utils/amplitude';

import { ROLES } from 'constants/userConstants';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitudeConstants';
import { DATE_FORMAT_WITH_DOTS_FULL_YEAR } from 'constants/commonConstants';

import {
    tableColumns,
    tableFilters,
    defaultTableColumns,
    SORT_QUERY_SELECTOR,
    PAGE_QUERY_SELECTOR,
    SEARCH_QUERY_SELECTOR,
    TABLE_INITIAL_QUERY,
    TABLE_QUERY_MAP,
    INVOICE_STATUSES_TO_CLASS_NAME_MAP,
} from './BillingTableConstants';

import { DEFAULT_ITEMS_PER_PAGE } from 'components/Shared/TableWrapper/TableWrapperConstants';

import './BillingTable.scss';

const BillingTable = ({
    tableAction,
    agencyId,
    userId,
    dataSelector,
    downloadInvoiceFiles,
    exportInvoiceTimesheets,
    resendInvoiceEmail,
    addInvoice,
    editInvoice,
    updateInvoiceStatus,
    displayMode,
    isAdmin,
    deleteInvoice,
    invoicerRole,
}) => {
    const [openAddInvoiceDialog, setOpenAddInvoiceDialog] = useState(false);
    const [invoiceStatusData, setInvoiceStatusData] = useState(null)
    const [openEditStatusDialog, setOpenEditStatusDialog] = useState(false);
    const [openEditInvoiceDialog, setOpenEditInvoiceDialog] = useState(false);
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const [openDeleteInvoiceConfirmDialog, setOpenDeleteInvoiceConfirmDialog] = useState(false);


    const [reload, setReload] = useState(false);
    const [isTableLoading, totalCount] = usePreloadTable(TABLE_INITIAL_QUERY, TABLE_QUERY_MAP, tableFilters, tableAction, reload, setReload);

    const getInvoices = (state, page) => dataSelector(state, page - 1, DEFAULT_ITEMS_PER_PAGE);

    // Dialog Controls
    const handleOpenInvoiceDialog = () => {
        setOpenAddInvoiceDialog(true);
        logEvent(AMPLITUDE_EVENT_TYPES.CLICK_ADD_CLIENT_INVOICE);
    };

    // Edit Status Dialog
    const handleOpenEditStatusDialog = (invoice) => {
        setInvoiceStatusData({ _id: invoice._id, status: invoice.statusData });
        setOpenEditStatusDialog(true);
    };

    // Edit Invoice Dialog
    const handleOpenEditInvoiceDialog = (invoice) => {
        setSelectedInvoice(invoice);
        setOpenEditInvoiceDialog(true);
    };

    const handleCloseEditInvoiceDialog = () => {
        setSelectedInvoice(null);
        setOpenEditInvoiceDialog(false);
    };

    // Delete Invoice Dialog
    const handleOpenDeleteInvoiceConfirmDialog = (invoice) => {
        setSelectedInvoice(invoice);
        setOpenDeleteInvoiceConfirmDialog(true);
    };

    const handleCloseDeleteInvoiceConfirmDialog = () => {
        setSelectedInvoice(null);
        setOpenDeleteInvoiceConfirmDialog(false);
    };
    
    const handleCloseInvoiceDialog = () => {
        setOpenAddInvoiceDialog(false);
    };

    const handleCloseEditStatusDialog = () => {
        setOpenEditStatusDialog(false);
    };

    // Download Files
    const concatName = str => str.replace(/\s/g, '').trim();
    const downloadInvoiceHandler = (invoice) => {
        let fileName = '';
        fileName = `${concatName(invoice.projectName)}-Invoice`;

        return downloadInvoiceFiles(agencyId, userId, invoice._id, fileName.toLocaleLowerCase());
    };

    const downloadTimesheetsHandler = (invoice) => {
        const period = moment.utc(invoice.period).format('MM-YYYY');
        const projectData = {
            name: invoice.projectName,
            _id: invoice.projectId
        };

        exportInvoiceTimesheets(agencyId, invoice._id, period, projectData, userId, invoicerRole || displayMode);
    };

    // Resend emails
    const handleResend = (invoice) => {
        resendInvoiceEmail(agencyId, userId, invoice);
    }

    // Add invoice
    const handleAddInvoice = (...props) => {
        addInvoice(...props, false).then(() => {
            setReload(true);
        });
    };

    // Update status
    const handleUpdateStatus = (...props) => {
        updateInvoiceStatus(...props, false).then(() => {
            setReload(true);
        });
    };

    // Delete invoice
    const handleDelete = () => {
        deleteInvoice(agencyId, selectedInvoice._id);
        handleCloseDeleteInvoiceConfirmDialog();
    }

    const isAdminRole = isAdmin || displayMode === ROLES.SUPPLIER_ADMIN;

    const adjustKeys = invoices => invoices.map(invoice => ({
        _id: invoice._id,
        recipientEmails: invoice.recipientEmails,
        projectName: invoice.projectName,
        issueDate: moment.utc(invoice.issueDate).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR),
        dueDate: invoice.dueDate
            ? moment.utc(invoice.dueDate).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR)
            : null,
        amount: invoice.amount,
        currency: invoice.currency,
        statusData: invoice.status,
        status: (
            <div className="edit-status-cell-wrapper">
                <Typography variant="body1" className={INVOICE_STATUSES_TO_CLASS_NAME_MAP[invoice.status]}>{invoice.status}</Typography>
                {isAdminRole && <EditSharpIcon className="edit-sharp-icon" onClick={() => handleOpenEditStatusDialog(invoice)} /> }
            </div>
        ),
        amountPaid: (
            <div>{invoice.amountPaid ? `${invoice.amountPaid.toFixed(2)} ${invoice.currencyPaid}` : '-'}</div>
        ),
        paymentDate: (
            <div>{invoice.paymentDate ? moment.utc(invoice.paymentDate).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR) : '-'}</div>
        ),
        invoiceNumber: (
            <div>{invoice.invoiceNumber ? invoice.invoiceNumber : '-'}</div>
        ),
        item: invoice,
    }));

    const getPopoverActions = () => {
        if (isAdmin) {
            return allActions;
        } else {
            return basicActions;
        }
    }

    const allActions = [
        { title: 'Download Invoice', handleClick: downloadInvoiceHandler },
        { title: 'Download Timesheet', handleClick: downloadTimesheetsHandler },
        { title: 'Resend Email', handleClick: handleResend },
        { title: 'Edit Invoice', handleClick: handleOpenEditInvoiceDialog },
        { title: 'Delete Invoice', handleClick: handleOpenDeleteInvoiceConfirmDialog },
    ];

    const basicActions = [
        { title: 'Download Invoice', handleClick: downloadInvoiceHandler },
        { title: 'Download Timesheet', handleClick: downloadTimesheetsHandler },
    ];

    return (
        <div className="invoices-table-wrapper">
            <TableWrapper
                title="Invoices History"
                columns={tableColumns}
                defaultColumns={defaultTableColumns}
                dataSelector={getInvoices}
                sortQuerySelector={SORT_QUERY_SELECTOR}
                adjustKeysFunction={adjustKeys}
                isTableDataLoading={isTableLoading}
                withPagination
                addMargin
                pagesCount={Math.ceil(totalCount / DEFAULT_ITEMS_PER_PAGE)}
                pageQuerySelector={PAGE_QUERY_SELECTOR}
                withActions
                getActionsHandler={getPopoverActions}
            >
                <TableWrapper.TableAlignedItems>
                    <TableWrapper.TableAlignedItems.LeftAligned>
                        <TableWrapper.TableAlignedItems.TableHeader />
                        <TableWrapper.TableAlignedItems.TableFilter filters={tableFilters} />
                        <TableWrapper.TableAlignedItems.TableSearch queryParamName={SEARCH_QUERY_SELECTOR} />
                    </TableWrapper.TableAlignedItems.LeftAligned>
                    {isAdminRole &&
                        <TableWrapper.TableAlignedItems.RightAligned>
                            <TableWrapper.TableAlignedItems.TableActionButton text="Create New Invoice" handleClick={handleOpenInvoiceDialog} />
                        </TableWrapper.TableAlignedItems.RightAligned>
                    }
                </TableWrapper.TableAlignedItems>
                <TableWrapper.TableContent />
                {
                    isAdminRole && openAddInvoiceDialog &&
                    <AddInvoiceDialog
                        userId={userId}
                        agencyId={agencyId}
                        openDialog={openAddInvoiceDialog}
                        handleCloseDialog={handleCloseInvoiceDialog}
                        addInvoice={handleAddInvoice}
                    />
                }
                {
                    isAdminRole && openEditStatusDialog &&
                    <EditStatusDialog
                        invoiceStatusData={invoiceStatusData}
                        updateInvoiceStatus={handleUpdateStatus}
                        userId={userId}
                        agencyId={agencyId}
                        openDialog={openEditStatusDialog}
                        handleCloseDialog={handleCloseEditStatusDialog}
                    />
                }
                {
                    isAdminRole && openEditInvoiceDialog &&
                    <AddInvoiceDialog
                        userId={userId}
                        agencyId={agencyId}
                        openDialog={openEditInvoiceDialog}
                        handleCloseDialog={handleCloseEditInvoiceDialog}
                        editMode={true}
                        editInvoice={editInvoice}
                        initialValues={selectedInvoice ? setInvoiceFormValues(selectedInvoice) : {}}
                    />
                }
                {
                    isAdminRole && openDeleteInvoiceConfirmDialog &&
                    <ConfirmDeleteDialog
                        itemToDelete={`invoice ${selectedInvoice.projectName}`}
                        handleDeleteItem={handleDelete}
                        openDialog={openDeleteInvoiceConfirmDialog}
                        handleCloseDialog={handleCloseDeleteInvoiceConfirmDialog}
                    />
                }
            </TableWrapper>
        </div>
    );
};

export default BillingTable;
