import moment from 'moment';
import { MOBILE_HEADER_HEIGHT } from 'constants/uiConstants';
import { ROLES } from 'constants/userConstants';
import { ENVIRONMENTS } from 'constants/env';

import MotionSoftwareLoginBackground from 'assets/mountain-no-border.png';

export const showPopupInScreen = (refId, stateChangeFunc) => {
    const element = document.getElementById(refId);
    if (element) {
        const offset = element.getBoundingClientRect();
        const showOnTop = window.innerHeight - offset.y + offset.height < 200;
        let xTranslate;

        if (offset.x < 700) {
            xTranslate = 20;
            stateChangeFunc({ transform: 'translateX(-20%)' });
        } else if (offset.x > 1000) {
            xTranslate = 70;
            stateChangeFunc({ transform: 'translateX(-70%)' });
        } else {
            xTranslate = 50;
            stateChangeFunc({ transform: 'translateX(-50%)' });
        }

        if (showOnTop) {
            stateChangeFunc({ transform: `translate(-${xTranslate}%, -100%)` });
        }
    }
};

export const showSkillsPopupInScreen = (popupId, stateChangeFunc) => {
    const popup = document.getElementById(popupId);
    if (popup) {
        const popupOffset = popup.getBoundingClientRect();

        const style = window.getComputedStyle(popup)
        const matrix = style.transform || style.webkitTransform || style.mozTransform;

        const matrixValues = matrix.match(/matrix.*\((.+)\)/)[1].split(', ')

        const x = Number(matrixValues[4]);
        const y = Number(matrixValues[5]);

        const xOffset = window.innerWidth - (popupOffset.x + popupOffset.width)
        const yOffset = window.innerHeight - (popupOffset.y + popupOffset.height);

        let xTranslate = xOffset < 0 ? `${xOffset + x}px` : `${x}px`;
        let yTranslate = yOffset < 0 ? `-100%` : `${y}px`;

        if (popupOffset.x < 0) {
            xTranslate = `${-popupOffset.x + x}px`;
        }

        if (popupOffset.y < MOBILE_HEADER_HEIGHT) {
            yTranslate = `0%`;
        }

        stateChangeFunc({ transform: `translate(${xTranslate}, ${yTranslate})` });
    }
};

export const componentRenderDelay = (setFunction, delayTime) => {
    setTimeout(() => {
        setFunction(true);
    }, delayTime);
}

export const selectBackgroundImage = (defaultImage, route, role) => {
    const isCandidateSetPassword = role === ROLES.CANDIDATE && (route.includes(`account/passwordreset`) || route.includes(`account/passwordset`));

    if (process.env.REACT_APP_ENV === ENVIRONMENTS.WHOISAVAILABLE && (route.includes(`register/${ROLES.CANDIDATE}`) || isCandidateSetPassword)) {
        return MotionSoftwareLoginBackground;
    }

    return defaultImage;
}

export const shouldWorkspaceDialogBeReset = (workspaceMigrationInfo) => {
    // if the user has not yet seen the dialog this week
    // they should see it
    const hasUserSeenDialogWithinThisWeek = moment(workspaceMigrationInfo.dateSeen).isSame(new Date(), 'week');

    return !hasUserSeenDialogWithinThisWeek;
};
