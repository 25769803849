import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { getAgencyRouteName, getChecklistedCandidates } from 'reducers';

import Banner from 'components/Shared/Banner';
import SecondaryButton from 'components/Shared/Buttons/SecondaryButton';

import { ReactComponent as WarningIcon } from 'assets/banner-warning-icon.svg';

import { INITIAL_QUERY_STRING_CANIDATES_CHECKLIST } from 'components/CandidatesChecklist/CandidatesChecklistConstants';

import './RequireActionBanner.scss';

const RequireActionBanner = ({
    agencyRouteName,
    totalChecklistedCandidatesCount,
}) => (
    totalChecklistedCandidatesCount > 0
    && <Banner
        title={`${totalChecklistedCandidatesCount} Candidates require your action`}
        content="Explore the candidate profiles who require your attention for the next step of completing their journey."
        icon={<WarningIcon />}
        primaryButtonComponent={<SecondaryButton
            text="View"
            component={Link}
            to={`/${agencyRouteName}/candidates/checklist${INITIAL_QUERY_STRING_CANIDATES_CHECKLIST}`}
        />}
    />
);

const mapStateToProps = (state) => ({
    agencyRouteName: getAgencyRouteName(state),
    totalChecklistedCandidatesCount: getChecklistedCandidates(state).totalChecklistedCandidatesCount,
});

export default connect(mapStateToProps)(RequireActionBanner);
