import React, { useState } from 'react';

import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Checkbox, MenuItem, Typography } from '@material-ui/core';

import CheckIcon from '@material-ui/icons/Check';
import { ReactComponent as EditIcon } from 'assets/jobs-edit-icon.svg';
import { ReactComponent as DeleteIcon } from 'assets/jobs-delete-icon.svg';
import { RadioButtonChecked, RadioButtonUnchecked } from '@material-ui/icons';

import { JOB_OPPORTUNITIES_PRIORITIES, JOB_OPPORTUNITIES_PRIVACY, JOB_OPPORTUNITIES_STATUSES, JOB_PROFILE_FORWARD_TYPES } from 'constants/jobOpportunitiesConstants';

import './JobOptionsPopover.scss';

const JobOptionsPopover = ({
    agencyId,
    handleClose,
    anchorEl,
    onEditClick,
    selectedJob,
    updatePrivacy,
    updateJob,
    openDeleteDialog,
    isAdmin,
}) => {
    const [jobStatus, setJobStatus] = useState(selectedJob.status ? selectedJob.status : '');
    const [jobPrivacy, setJobPrivacy] = useState(selectedJob.hasOwnProperty('privacy') ? Object.values(JOB_OPPORTUNITIES_PRIVACY).find(x => x._id === selectedJob.privacy).value : '');
    const [jobPriority, setJobPriority] = useState(selectedJob.priority ? selectedJob.priority : '');
    const [profileForwardType, setProfileForwardType] = useState(selectedJob.hasOwnProperty('profileForwardType') ? selectedJob.profileForwardType : null);

    const changeJobStatusToOpen = () => {
        const newStatus = JOB_OPPORTUNITIES_STATUSES.OPEN;
        setJobStatus(JOB_OPPORTUNITIES_STATUSES.OPEN);

        const formData = new FormData();
        formData.append('jobData', JSON.stringify({ _id: selectedJob._id, clientId: selectedJob.clientId, status: newStatus }));

        updateJob(agencyId, selectedJob._id, formData, true);
    };

    const changeJobStatusToClosed = () => {
        const newStatus = JOB_OPPORTUNITIES_STATUSES.CLOSED;
        setJobStatus(JOB_OPPORTUNITIES_STATUSES.CLOSED);

        const formData = new FormData();
        formData.append('jobData', JSON.stringify({ _id: selectedJob._id, clientId: selectedJob.clientId, status: newStatus }));

        updateJob(agencyId, selectedJob._id, formData, true);
    };

    const changeJobPrivacyToPrivate = () => {
        const newPrivacy = JOB_OPPORTUNITIES_PRIVACY.PRIVATE.value;
        setJobPrivacy(newPrivacy);
        updatePrivacy(agencyId, selectedJob._id, { privacy: JOB_OPPORTUNITIES_PRIVACY.PRIVATE._id });
    };

    const changeJobPrivacyToPublic = () => {
        const hasClientLogo = Boolean(selectedJob.client.logoImgUrl);
        const newPrivacy = JOB_OPPORTUNITIES_PRIVACY.PUBLISHED.value;
        setJobPrivacy(newPrivacy);
        updatePrivacy(agencyId, selectedJob._id, { privacy: JOB_OPPORTUNITIES_PRIVACY.PUBLISHED._id }, hasClientLogo);
    };

    const changeJobPriority = (value) => {
        setJobPriority(value);

        const formData = new FormData();
        formData.append('jobData', JSON.stringify({ priority: value }));

        updateJob(agencyId, selectedJob._id, formData);
    };

    const changeJobProfileForwardType = (value) => {
        setProfileForwardType(value);

        const formData = new FormData();
        formData.append('jobData', JSON.stringify({ profileForwardType: value }));

        updateJob(agencyId, selectedJob._id, formData);
    };

    return (
        <div className="popover-container">
            <Popover
                className="job-options-popover"
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <List className="popover-list">
                    <ListItem button className="popover-list-item" onClick={() => onEditClick(selectedJob)}>
                        <Typography className="list-item-text" />
                        <span className="job-popover-icon"><EditIcon /></span>
                        <span className="job-popover-text">Edit</span>
                        <Typography />
                    </ListItem>
                    <ListItem button className="popover-list-item" onClick={openDeleteDialog}>
                        <Typography className="list-item-text" />
                        <span className="job-popover-icon"><DeleteIcon /></span>
                        <span className="job-popover-text">Delete</span>
                        <Typography />
                    </ListItem>
                    <div className="status-options">
                        <button onClick={changeJobStatusToOpen} className={`${jobStatus === JOB_OPPORTUNITIES_STATUSES.CLOSED ? 'white-bg' : 'green-bg'}`}>Open</button>
                        <button onClick={changeJobStatusToClosed} className={`${jobStatus === JOB_OPPORTUNITIES_STATUSES.CLOSED ? 'grey-bg' : 'white-bg'}`}>Closed</button>
                    </div>
                    {isAdmin &&
                        <div className="status-options">
                            <button onClick={changeJobPrivacyToPublic} className={`${jobPrivacy === JOB_OPPORTUNITIES_PRIVACY.PRIVATE.value ? 'white-bg' : 'green-bg'}`}>Published</button>
                            <button onClick={changeJobPrivacyToPrivate} className={`${jobPrivacy === JOB_OPPORTUNITIES_PRIVACY.PRIVATE.value ? 'grey-bg' : 'white-bg'}`}>Unpublished</button>
                        </div>
                    }
                </List>
                {isAdmin &&
                    <div className="priority-options">
                        <p className="priority-item">Priority</p>

                        {Object.values(JOB_OPPORTUNITIES_PRIORITIES).map((item, index) => {
                            return (
                                <MenuItem
                                    key={index}
                                    className={jobPriority === item ? "filter-list-menu-item selected" : "filter-list-menu-item"}
                                    onClick={() => changeJobPriority(item)}
                                >
                                    <div className="flex-group">
                                        <span className={`rectangle ${item.toLowerCase()}`}></span>
                                        <span className="text">{item}</span>
                                    </div>
                                    {jobPriority === item && <CheckIcon className="check-icon" />}
                                </MenuItem>
                            )
                        })}
                    </div>
                }
                {isAdmin &&
                    <div className="profile-forward-options">
                        {JOB_PROFILE_FORWARD_TYPES.map((item, index) => {
                            return (
                                <MenuItem
                                    key={index}
                                    className={profileForwardType === item.key ? "filter-list-menu-item selected" : "filter-list-menu-item"}
                                    onClick={() => changeJobProfileForwardType(item.key)}
                                >
                                    <div className="flex-group">
                                        <Checkbox
                                            checked={profileForwardType === item.key}
                                            onChange={() => changeJobProfileForwardType(item.key)}
                                            className="no-padding custom-checkbox"
                                            checkedIcon={<RadioButtonChecked />}
                                            icon={<RadioButtonUnchecked />}
                                        />
                                        <p className="text">{item.value}</p>
                                    </div>
                                </MenuItem>
                            )
                        })}
                    </div>
                }
            </Popover>
        </div>
    );
}

export default JobOptionsPopover;
