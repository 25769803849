import React, { Fragment, useState, useEffect, useRef, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import MomentUtils from '@date-io/moment';
import { Typography } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateRangeFilter from 'components/Shared/DateRangeFilter';

import { parseQueryString, updateQueryWithMultipleEntries } from 'utils/helpers';

import CloseIcon from '@material-ui/icons/Close';
import { ReactComponent as DateFilterIcon } from 'assets/table-date-filter-icon.svg';

import { TableWrapperContext } from '../TableWrapper';

import './TableDateFilter.scss';

const TableDateFilter = ({
    queryParamName,
    placeholderText,
    openTo = 'month',
    views = ['year', 'month'],
    format,
    minDate,
    maxDate,
    isRangeCalendar = false,
    ...props
}) => {
    const history = useHistory();

    const anchorElRef = useRef();

    const { handleFilterChanges, withPagination, pageQuerySelector } = useContext(TableWrapperContext);

    const [selectedDate, setSelectedDate] = useState(null);
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    useEffect(() => {
        const queryParams = parseQueryString(history.location.search, true);
        if (queryParams[queryParamName]) {
            setSelectedDate(queryParams[queryParamName]);
        } else {
            setSelectedDate(null);
        }

        Boolean(handleFilterChanges) && handleFilterChanges();
    }, [history.location.search]);

    const handleOpenPopover = () => setIsPopoverOpen(true);
    const handleClosePopover = () => setIsPopoverOpen(false);

    const handleDateChange = (firstDate, secondDate) => {
        const filterObj = isRangeCalendar
            ? { [queryParamName]: `${moment(firstDate).format(format)} - ${moment(secondDate).format(format)}` }
            : { [queryParamName]: moment(firstDate).format(format) };

        // if the filters have changed, we need to set the page back to 1
        if (withPagination) {
            filterObj[pageQuerySelector] = 1;
        }

        const updatedQuery = updateQueryWithMultipleEntries(history.location.search, filterObj);
        history.replace({ pathname: history.location.pathname, search: updatedQuery });
    };

    const handleClear = (event) => {
        event.stopPropagation();
        const filterObj = { [queryParamName]: null };
        const updatedQuery = updateQueryWithMultipleEntries(history.location.search, filterObj);
        history.replace({ pathname: history.location.pathname, search: updatedQuery });
    };

    const renderInput = () => (
        <div className={`table-filter table-date-filter${isPopoverOpen || selectedDate ? ' selected' : ''}`} ref={anchorElRef} onClick={handleOpenPopover}>
            <DateFilterIcon />
            <Typography variant="body1">{selectedDate || placeholderText}</Typography>
            {selectedDate && <CloseIcon onClick={handleClear} />}
        </div>
    );

    return (
        <Fragment>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                {isRangeCalendar ?
                    <DateRangeFilter
                        title={selectedDate || placeholderText}
                        values={selectedDate?.length > 0 ? selectedDate[0].split(" - ") : []}
                        handleChange={handleDateChange}
                        handleClear={handleClear}
                    />
                    :
                    <DatePicker
                        onClose={handleClosePopover}
                        open={isPopoverOpen}
                        openTo={openTo}
                        views={views}
                        format={format}
                        value={selectedDate ? moment(selectedDate, format) : null}
                        minDate={minDate}
                        maxDate={maxDate}
                        onChange={handleDateChange}
                        autoOk
                        TextFieldComponent={renderInput}
                        variant='inline'
                        PopoverProps={{
                            className: 'global-table-date-picker-popover',
                            anchorEl: () => anchorElRef.current,
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "right",
                            },
                        }}
                        {...props}
                    />
                }
            </MuiPickersUtilsProvider>
        </Fragment>
    )
};

export default TableDateFilter;
