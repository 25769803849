import React from 'react';

import moment from 'moment';
import { ErrorMessage } from 'formik';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DialogActions from '@material-ui/core/DialogActions';
import Switch from '@material-ui/core/Switch';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Autocomplete } from "@material-ui/lab";
import SecondaryButton from 'components/Shared/Buttons/SecondaryButton';

import isHoliday from 'hoc/isHoliday';

import './AddEditProjectFormView.scss';
import { DATE_PICKER_FORMAT_MM_YYYY } from 'constants/timesFormat';
import { DIALOG_COMMENT_INPUT } from 'constants/commonConstants';
import AddSkillField from './AddSkillField';
import { inputFieldCharMapper } from './AddEditProjectDialogConstants';

const AddEditProjectFormView = ({
    templates,
    values,
    displayMode,
    setFromTemplate,
    handleChange,
    setFieldValue,
    errors,
    touched,
    handleCloseDialog,
    handleSubmit,
    handleSelectProjectTemplate,
    validateTechnologyField,
    renderHolidays,
    handleCharsMap
}) => {
    const templateButtonText = templates.some(t => t.name === values.name) ? "Save and Update Template" : "Save and Create Template";

    return (
        <form className="add-edit-project-form" autoComplete="off">
            <div className="add-project-top-block">
                <div className='project-name-field'>
                    <Autocomplete
                        id="projectName"
                        className="name-select"
                        name="name"
                        freeSolo={true}
                        options={templates.map(t => t.name)}
                        getOptionLabel={option => option ? option : ''}
                        includeInputInList
                        value={values.name}
                        filterOptions={
                            options => options.filter(o => o.toLowerCase().includes(values.name?.toLowerCase()))
                        }
                        onChange={(e, value) => {
                            if (value !== null) {
                                const templateId = templates.filter(t => t.name === value)[0]._id;
                                handleSelectProjectTemplate(templateId);
                            } else {
                                setFieldValue("name", value);
                            }
                        }}
                        onInputChange={(e, value) => {
                            setFieldValue("name", value);
                        }}
                        renderInput={params => (
                            <TextField
                                {...params}
                                name="name"
                                label="Project Name"
                                variant="outlined"
                                fullWidth
                            />
                        )}
                    />
                    <ErrorMessage name="name" component="div" className="invalid-field-message" />
                </div>
                <div className='project-client-field'>
                    <TextField
                        name="client"
                        value={values.client}
                        onChange={handleChange}
                        id="add-project-client"
                        label="Project Client"
                        error={errors.client && touched.client}
                        margin="normal"
                        variant="outlined"
                        fullWidth
                    />
                    <ErrorMessage name="client" component="div" className="invalid-field-message" />
                </div>
            </div>
            <AddSkillField
                values={values} 
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                validateTechnologyField={validateTechnologyField}
                errors={errors}
            />

            <div className="project-position-field">
                <TextField
                    name="position"
                    value={values.position}
                    onChange={handleChange}
                    id="add-project-position"
                    label="Position"
                    error={errors.position && touched.position}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                />
                <ErrorMessage name="position" component="div" className="invalid-field-message" />
            </div>
            <div className="project-description-field">
                <TextField
                    name="description"
                    value={values.description}
                    onChange={e => {
                        const modifiedString = handleCharsMap(e.target.value, inputFieldCharMapper);
                        setFieldValue('description', modifiedString)
                    }}
                    id="add-project-description"
                    label="Project Description"
                    error={errors.description && touched.description}
                    variant="outlined"
                    fullWidth
                    multiline
                    minRows={DIALOG_COMMENT_INPUT.ROWS}
                />
                <ErrorMessage name="description" component="div" className="invalid-field-message" />
            </div>
            <div>
                <div className="switch-wrapper">
                    <FormControlLabel
                        control={
                            <Switch
                                size="small"
                                color="primary"
                                name="datesUnknown"
                                onChange={handleChange}
                                checked={values.datesUnknown} />
                        }
                        label="Dates unknown"
                        labelPlacement="start"
                        className="switch-label"
                    />

                    <FormControlLabel
                        control={
                            <Switch
                                size="small"
                                color="primary"
                                name="currentlyWork"
                                onChange={(e) => {
                                    if (!values.currentlyWork) {
                                        setFieldValue('endDate', null);
                                    }

                                    handleChange(e);
                                }}
                                checked={values.currentlyWork}
                                disabled={values.datesUnknown} />
                        }
                        label="Currently work here"
                        labelPlacement="start"
                        className="switch-label"
                    />
                </div>

                <div className="date-fields-block">
                    <KeyboardDatePicker
                        autoOk={true}
                        className="start-date-field"
                        label="Start Date"
                        disabled={values.datesUnknown}
                        disableToolbar
                        variant="inline"
                        inputVariant="outlined"
                        fullWidth
                        value={
                            values.datesUnknown
                                ? null
                                : values.startDate
                        }
                        onChange={value => {
                            setFieldValue('startDate', moment(value));
                        }}
                        openTo="year"
                        views={["year", "month"]}
                        renderDay={isHoliday}
                        format={DATE_PICKER_FORMAT_MM_YYYY}
                        allowKeyboardControl={true}
                    />

                    <KeyboardDatePicker
                        autoOk={true}
                        label="Finish Date"
                        disabled={values.datesUnknown || values.currentlyWork}
                        disableToolbar
                        minDate={values.startDate || new Date()}
                        variant="inline"
                        inputVariant="outlined"
                        fullWidth
                        value={
                            (values.datesUnknown || values.currentlyWork)
                                ? null
                                : values.endDate
                        }
                        onChange={value => {
                            setFieldValue('endDate', moment(value));
                        }}
                        openTo="year"
                        views={["year", "month"]}
                        renderDay={isHoliday}
                        format={DATE_PICKER_FORMAT_MM_YYYY}
                        allowKeyboardControl={true}
                    />
                </div>
            </div>

            <DialogActions className="dialog-actions">
                <SecondaryButton buttonStyle="discard" handleClick={handleCloseDialog} text="Discard" />
                {displayMode === 'admin' &&
                    <SecondaryButton
                        buttonStyle="save"
                        handleClick={e => {
                            setFromTemplate(true);
                            handleSubmit(e);
                        }}
                        type="submit"
                        text={templateButtonText}
                     />
                }
                <SecondaryButton buttonStyle="save" handleClick={handleSubmit} text="Save" type="submit" />
            </DialogActions>
        </form>
    );
}

export default AddEditProjectFormView;
