import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';

import { getEmployeeContractInformation, getGeneralInfo, getEmploymentHolidayAllowance, getCountries } from 'reducers';
import { editContractInformation, editEmploymentDates, editHolidayAllowance } from 'actions/employeeActions';

import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';
import ConfirmEmployeeTerminationDialog from 'components/Shared/ConfirmEmployeeTerminationDialog';
import EmploymentContractDetailsFormView from './EmploymentContractDetailsFormView';
import Paper from '@material-ui/core/Paper';

import {
    validateRequiredFields,
    validatePIN,
    validateEmploymentStartDate,
    validateEmploymentTerminationDate,
    validateEmployeeHolidayAllowance,
} from 'utils/formValidations';

import './EmploymentContractDetails.scss';

const EmploymentContractDetails = ({
    agencyId,
    departmentId,
    employeeId,
    contractInformation,
    generalInformation,
    holidayAllowance,
    countries,
    editContractInformation,
    editEmploymentDates,
    editHolidayAllowance,
}) => {
    const [showHeaderButtons, setShowHeaderButtons] = useState(false);
    const [openConfirmEmployeeTerminationDialog, setOpenConfirmEmployeeTerminationDialog] = useState(false);
    const employmentContractDetailsRef = useRef(null);

    const handleDiscard = () => {
        employmentContractDetailsRef.current.handleReset();
        setShowHeaderButtons(false);
    };

    const handleSubmit = async (values, resetForm, isThroughConfirmDialog) => {
        const isUpdatingTerminationDate = generalInformation?.terminationDate && values.terminationDate
            ? !moment(generalInformation?.terminationDate).isSame(values.terminationDate, 'day')
            : values.terminationDate;
        const isUpdatingStartDate = generalInformation?.startDate && values.startDate
            ? !moment(generalInformation?.startDate).isSame(values.startDate, 'day')
            : values.startDate;

        if (isUpdatingTerminationDate && !isThroughConfirmDialog) {
            setOpenConfirmEmployeeTerminationDialog(true);
            return;
        }

        const { employmentType, country, pin, startDate, terminationDate, daysPerYear } = values;

        if (contractInformation.employmentType !== employmentType || contractInformation.country !== country || contractInformation.pin !== pin) {
            await editContractInformation(agencyId, departmentId, employeeId, { employmentType, country, pin });
        }

        if (isUpdatingStartDate || isUpdatingTerminationDate) {
            await editEmploymentDates(agencyId, departmentId, employeeId, { startDate, terminationDate });
        }

        if (Number(holidayAllowance) !== Number(daysPerYear)) {
            await editHolidayAllowance({ daysPerYear }, employeeId, agencyId, departmentId);
        }

        handleCloseConfirmEmployeeTerminationDialog();
        setShowHeaderButtons(false);
        resetForm();
    };

    const handleCloseConfirmEmployeeTerminationDialog = () => setOpenConfirmEmployeeTerminationDialog(false);

    const handleConfirmEmployeeTerminationSubmit = () => {
        handleSubmit(employmentContractDetailsRef.current.values, employmentContractDetailsRef.current.resetForm, true);
    };

    return (
        <div className="employment-contract-details-wrapper">
            <SectionActionsWrapper>
                <SectionActionsWrapper.SectionTitle
                    sectionTitle='Employment Contract Details'
                />

                {showHeaderButtons && (
                    <SectionActionsWrapper.RightAlignedActions>
                        <SectionActionsWrapper.RightAlignedActions.SecondaryButton
                            text='Discard'
                            onClick={handleDiscard}
                            buttonStyle='discard'
                        />
                        <SectionActionsWrapper.RightAlignedActions.SecondaryButton
                            text='Save'
                            onClick={() => employmentContractDetailsRef.current.handleSubmit()}
                            buttonStyle='save'
                        />
                    </SectionActionsWrapper.RightAlignedActions>
                )}
            </SectionActionsWrapper>

            <Paper className="inner-content-wrapper">
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <Formik
                        enableReinitialize
                        innerRef={employmentContractDetailsRef}
                        initialValues={{
                            employmentType: contractInformation?.employmentType || '',
                            startDate: generalInformation?.startDate || null,
                            terminationDate: generalInformation?.terminationDate || null,
                            country: contractInformation.country || '',
                            pin: contractInformation?.pin || '',
                            daysPerYear: holidayAllowance,
                        }}
                        validate={values => {
                            return {
                                ...validateRequiredFields(values, ['employmentType']),
                                ...(values.pin && { ...validatePIN(values, ['pin'], contractInformation.country) }),
                                ...validateEmploymentStartDate(values, generalInformation),
                                ...validateEmploymentTerminationDate(values, generalInformation),
                                ...(values.daysPerYear && { ...validateEmployeeHolidayAllowance(values, ['daysPerYear']) })
                            };
                        }}
                        onSubmit={(values, { resetForm }) => {
                            handleSubmit(values, resetForm);
                        }}
                    >
                        {(props) =>
                            <EmploymentContractDetailsFormView
                                {...props}
                                countries={countries}
                                setShowHeaderButtons={setShowHeaderButtons}
                            />
                        }
                    </Formik>
                </MuiPickersUtilsProvider>
            </Paper>

            <ConfirmEmployeeTerminationDialog
                openDialog={openConfirmEmployeeTerminationDialog}
                handleConfirmDialog={handleConfirmEmployeeTerminationSubmit}
                handleCloseDialog={handleCloseConfirmEmployeeTerminationDialog}
            />
        </div>
    )
};

const mapStateToProps = state => ({
    contractInformation: getEmployeeContractInformation(state),
    generalInformation: getGeneralInfo(state),
    holidayAllowance: getEmploymentHolidayAllowance(state),
    countries: getCountries(state),
});

const mapDispatchToProps = {
    editContractInformation,
    editEmploymentDates,
    editHolidayAllowance,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmploymentContractDetails);
