import { ROLES } from 'constants/userConstants';

const ROLE_OPTIONS = [
    {
        title: 'Find a remote job',
        value: ROLES.CANDIDATE,
    },
    {
        title: 'Build a remote team',
        value: ROLES.UNASSIGNED_CLIENT,
    },
];

const ROLE_OPTIONS_WHOISAVAILABLE = [
    {
        title: 'Manage remote teams',
        value: ROLES.UNASSIGNED_ADMIN,
    }
];

export const ROLE_SELECTION_OPTIONS = {
    test: ROLE_OPTIONS,
    development: ROLE_OPTIONS,
    qa: ROLE_OPTIONS,
    production: ROLE_OPTIONS,
    whoisavailable: ROLE_OPTIONS_WHOISAVAILABLE,
    staging: ROLE_OPTIONS,
};
