import React, { useState } from 'react';
import { connect } from 'react-redux';

import FormikTemplate from 'components/Shared/FormikTemplate/FormikTemplate';
import EditFieldDialog from 'components/Candidate/Dialogs/EditFieldDialog/EditFieldDialog';

import { Divider, Typography } from '@material-ui/core';
import EditSharpIcon from '@material-ui/icons/EditSharp';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';

import { updateContactInfo } from 'actions/employeeActions';

import { ReactComponent as PhoneIcon } from 'assets/phone-square.svg';
import { ReactComponent as SimpleLinkedin } from 'assets/simple-linkedin.svg';
import { ReactComponent as SimpleGithub } from 'assets/simple-github.svg';

import {
    CANDIDATE_OVERVIEW_PAGE_DIALOGS,
    CANDIDATE_OVERVIEW_REQUIRED_FIELDS,
    CANDIDATE_OVERVIEW_LINKEDIN_FIELDS,
    CANDIDATE_OVERVIEW_GITHUB_FIELDS,
    CANDIDATE_OVERVIEW_PHONE_FIELDS,
} from 'constants/candidateConstants';

import './CandidateContactDetails.scss';

const CandidateContactDetails = ({
    agencyId,
    candidateId,
    contactInfo,
    updateContactInfo
}) => {
    const [dialog, setDialog] = useState('');

    const handleOpenDialog = (dialogName) => {
        setDialog(dialogName)
    }

    const handleCloseDialog = () => setDialog('');

    const currentlyOpenedDialog = (props) => {
        switch (dialog) {
            case CANDIDATE_OVERVIEW_PAGE_DIALOGS.PHONE.VALUE:
                return <EditFieldDialog {...props} open={true} onClose={handleCloseDialog} dialogTitle={CANDIDATE_OVERVIEW_PAGE_DIALOGS.PHONE.TITLE} />
            case CANDIDATE_OVERVIEW_PAGE_DIALOGS.LINKEDIN.VALUE:
                return <EditFieldDialog {...props} open={true} onClose={handleCloseDialog} dialogTitle={CANDIDATE_OVERVIEW_PAGE_DIALOGS.LINKEDIN.TITLE} />
            case CANDIDATE_OVERVIEW_PAGE_DIALOGS.GITHUB.VALUE:
                return <EditFieldDialog {...props} open={true} onClose={handleCloseDialog} dialogTitle={CANDIDATE_OVERVIEW_PAGE_DIALOGS.GITHUB.TITLE} />
            default:
                break;
        }
    }

    const getFormikInitialValue = () => {
        switch (dialog) {
            case CANDIDATE_OVERVIEW_PAGE_DIALOGS.PHONE.VALUE:
                return { field: contactInfo?.phone }
            case CANDIDATE_OVERVIEW_PAGE_DIALOGS.LINKEDIN.VALUE:
                return { field: contactInfo?.linkedIn }
            case CANDIDATE_OVERVIEW_PAGE_DIALOGS.GITHUB.VALUE:
                return { field: contactInfo?.gitHub }
            default:
                break;
        }
    }

    const handleUpdateInfo = (values) => {
        switch (dialog) {
            case CANDIDATE_OVERVIEW_PAGE_DIALOGS.PHONE.VALUE:
                updateContactInfo(agencyId, null, candidateId, { ...contactInfo, phone: values.field })
                break;
            case CANDIDATE_OVERVIEW_PAGE_DIALOGS.LINKEDIN.VALUE:
                updateContactInfo(agencyId, null, candidateId, { ...contactInfo, linkedIn: values.field })
                break;
            case CANDIDATE_OVERVIEW_PAGE_DIALOGS.GITHUB.VALUE:
                updateContactInfo(agencyId, null, candidateId, { ...contactInfo, gitHub: values.field })
                break;
            default:
                break;
        }

        handleCloseDialog();
    }

    return (
        <div className="candidate-contact-details">
            <Typography variant="h1"> Contact Details </Typography>
            <div className="contact-form">
                <div className="assignee-information">
                    <div className="row-title">
                        <PhoneIcon />
                        <Typography variant="h5" className="title">
                            Phone Number
                        </Typography>
                    </div>
                    <div className="row-value">
                        {contactInfo.phone}
                    </div>
                    <div className="edit-icon-container">
                        <IconButton className="edit-button" onClick={() => handleOpenDialog(CANDIDATE_OVERVIEW_PAGE_DIALOGS.PHONE.VALUE)}>
                            <Icon>
                                <EditSharpIcon className="edit-sharp-icon" />
                            </Icon>
                        </IconButton>
                    </div>
                </div>
                <Divider variant="middle" className="divider" />
                <div className="assignee-information">
                    <div className="row-title">
                        <SimpleLinkedin />
                        <Typography variant="h5" className="title">
                            LinkedIn
                        </Typography>
                    </div>
                    <div className="row-value">
                        <a href={contactInfo.linkedIn}>{contactInfo.linkedIn}</a>
                    </div>
                    <div className="edit-icon-container">
                        <IconButton className="edit-button" onClick={() => handleOpenDialog(CANDIDATE_OVERVIEW_PAGE_DIALOGS.LINKEDIN.VALUE)}>
                            <Icon>
                                <EditSharpIcon className="edit-sharp-icon" />
                            </Icon>
                        </IconButton>
                    </div>
                </div>
                <Divider variant="middle" className="divider" />
                <div className="assignee-information">
                    <div className="row-title">
                        <SimpleGithub />
                        <Typography variant="h5" className="title">
                            GitHub
                        </Typography>
                    </div>
                    <div className="row-value">
                        <a href={contactInfo.gitHub}>{contactInfo.gitHub}</a>
                    </div>
                    <div className="edit-icon-container">
                        <IconButton className="edit-button" onClick={() => handleOpenDialog(CANDIDATE_OVERVIEW_PAGE_DIALOGS.GITHUB.VALUE)}>
                            <Icon>
                                <EditSharpIcon className="edit-sharp-icon" />
                            </Icon>
                        </IconButton>
                    </div>
                </div>
            </div>

            <FormikTemplate
                initialValues={getFormikInitialValue()}
                updateData={handleUpdateInfo}
                requiredFields={CANDIDATE_OVERVIEW_REQUIRED_FIELDS[dialog]}
                phoneField={CANDIDATE_OVERVIEW_PHONE_FIELDS[dialog]}
                gitHubField={CANDIDATE_OVERVIEW_GITHUB_FIELDS[dialog]}
                linkedInField={CANDIDATE_OVERVIEW_LINKEDIN_FIELDS[dialog]}>
                {(props) => (
                    currentlyOpenedDialog({ ...props })
                )}
            </FormikTemplate>
        </div>
    )
}


const mapDispatchToProps = {
    updateContactInfo,
};

export default connect(null, mapDispatchToProps)(CandidateContactDetails);
