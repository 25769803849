import React from 'react';
import { Link } from 'react-router-dom';

import { Accordion, AccordionSummary, Button, Divider, Typography } from '@material-ui/core';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import FilterListIcon from '@material-ui/icons/FilterList';
import ExploreTalentsSearchBar from './ExploreTalentsSearchBar';
import MultiSelectList from './MultiSelectList';
import Switcher from 'components/Shared/Switcher';
import CategorySkills from 'components/Person/Profile/ProfileSkills/CategorySkills';
import SlimSelect from 'components/Shared/SlimSelect';

import { getTalentRequestResetFiltersLink } from '../ExploreTalentsUtils';

import {
    EXPERIENCE_ITEMS,
    LANGUAGES_ITEMS,
    LOCATIONS_ITEMS,
    PROFILE_TYPES,
    SPECIALITY_ITEMS,
    VETTED_SINCE_ITEMS,
} from '../ExploreTalentsConstants';
import { SKILLS_CATEGORY } from 'constants/jobOpportunitiesConstants';

import './ExploreTalentsSearchFilter.scss';

const ExploreTalentsSearchFilter = ({
    fetchSkillSuggestionsHandler,
    filters,
    handleAddSkill,
    handleRemoveSkill,
    handleFilterChange,
    resetFilters,
    handleSwitchChange,
    handleVettedSinceChange,
    handleSearchTermChange,
    searchTerm,
    showFilters,
    setShowFilters,
    agencyRouteName,
}) => {
    return (
        <div className={`explore-talents-search-filter-wrapper ${showFilters ? 'expanded' : ''}`}>
            <Accordion
                className="explore-talents-accordion"
                expanded={showFilters}
                onChange={() => setShowFilters((oldState) => !oldState)}
                elevation={2}
            >
                <AccordionSummary
                    className={`summary ${showFilters ? 'hidden' : 'visible'}`}
                    expandIcon={<KeyboardArrowDownIcon className="collapse-filters-icon" />}
                >
                    <FilterListIcon />
                    <Typography>Use the filters to easily find the profiles you're looking for.</Typography>
                    <Link
                        onClick={(e) => e.stopPropagation()}
                        className="reset-filters-link"
                        to={getTalentRequestResetFiltersLink(agencyRouteName)}
                    >
                        Reset
                    </Link>
                </AccordionSummary>

                <div className="explore-talents-search-filter-container">
                    <div className="explore-talents-search-filter">
                        <div className="speciality-container">
                            <MultiSelectList
                                subheaderText="Speciality"
                                items={SPECIALITY_ITEMS}
                                onChangeHandler={handleFilterChange('speciality')}
                                checkedItems={filters.speciality}
                            />
                        </div>
                        <div className="main-container">
                            <div className="main-sub-container">
                                <div className="search-container">
                                    <ExploreTalentsSearchBar
                                        searchTerm={searchTerm}
                                        handleChange={(e) => handleSearchTermChange(e.target.value)}
                                        clearInput={() => handleSearchTermChange('')}
                                    />
                                    <Button
                                        onClick={resetFilters}
                                        className="reset-explore-talents-filter-button"
                                        variant="outlined"
                                    >
                                        RESET
                                    </Button>
                                </div>
                                <div className="switches-container">
                                    <Switcher
                                        className="switch-item"
                                        label="Show available only"
                                        isChecked={filters.availableOnly}
                                        changeStatusHandler={handleSwitchChange('availableOnly')}
                                    />
                                    <Switcher
                                        className="switch-item"
                                        label="Show vetted only"
                                        isChecked={filters.vettedOnly}
                                        changeStatusHandler={handleSwitchChange('vettedOnly')}
                                    />
                                    {filters.vettedOnly && (
                                        <div className="vetted-since-container">
                                            <span className="vetted-since-label">Sort:</span>
                                            <SlimSelect
                                                items={VETTED_SINCE_ITEMS}
                                                value={filters.vettedSince.value}
                                                name="vetted-since"
                                                onChangeHandler={handleVettedSinceChange}
                                                className="vetted-since-select"
                                            />
                                        </div>
                                    )}
                                </div>

                                <div className="sub-filters-container">
                                    <MultiSelectList
                                        className="sub-filter"
                                        subheaderText="Profile Type"
                                        items={PROFILE_TYPES}
                                        onChangeHandler={handleFilterChange('profileType')}
                                        checkedItems={filters.profileType}
                                    />
                                    <MultiSelectList
                                        className="sub-filter"
                                        subheaderText="Experience"
                                        items={EXPERIENCE_ITEMS}
                                        onChangeHandler={handleFilterChange('experience')}
                                        checkedItems={filters.experience}
                                    />
                                    <MultiSelectList
                                        className="sub-filter"
                                        subheaderText="Languages"
                                        items={LANGUAGES_ITEMS}
                                        onChangeHandler={handleFilterChange('languages')}
                                        checkedItems={filters.languages}
                                    />
                                    <MultiSelectList
                                        className="sub-filter last"
                                        subheaderText="Location"
                                        items={LOCATIONS_ITEMS}
                                        onChangeHandler={handleFilterChange('location')}
                                        checkedItems={filters.location}
                                    />
                                </div>
                            </div>
                            <Divider />
                            <div className="mandatory-nice-to-have-container">
                                <div className="project-about-technologies-block">
                                    <p className="list-sub-header">Mandatory skills</p>
                                    <CategorySkills
                                        handleSkillDrag={() => void 0}
                                        skillCategory={SKILLS_CATEGORY.MANDATORY}
                                        skills={filters.mandatorySkills}
                                        fetchSkillSuggestions={fetchSkillSuggestionsHandler}
                                        removeSkill={handleRemoveSkill('mandatorySkills')}
                                        addSkill={handleAddSkill('mandatorySkills')}
                                    />
                                </div>
                                <div className="project-about-technologies-block">
                                    <p className="list-sub-header">Nice to have</p>
                                    <CategorySkills
                                        handleSkillDrag={() => void 0}
                                        skillCategory={SKILLS_CATEGORY.NICE_TO_HAVE}
                                        skills={filters.niceToHaveSkills}
                                        fetchSkillSuggestions={fetchSkillSuggestionsHandler}
                                        removeSkill={handleRemoveSkill('niceToHaveSkills')}
                                        addSkill={handleAddSkill('niceToHaveSkills')}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="collapse-filters-icon-container">
                        <KeyboardArrowUpIcon
                            className="collapse-filters-icon"
                            onClick={() => setShowFilters((oldState) => !oldState)}
                        />
                    </div>
                </div>
            </Accordion>
        </div>
    );
};

export default ExploreTalentsSearchFilter;
