import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

import ExternalJobIdLabel from 'components/Shared/ExternalJobIdLabel';
import JobStatusLabel from 'components/JobsDashboard/SharedComponents/JobStatusLabel';
import Avatar from '@material-ui/core/Avatar';

import ThreeDotsIcon from '@material-ui/icons/MoreHoriz';
import { ReactComponent as CompanyIcon } from 'assets/icon-company.svg';
import { ReactComponent as DateAddedIcon } from 'assets/icon-date-added.svg';
import { ReactComponent as AccountIcon } from 'assets/icon-account.svg';
import { ReactComponent as LocationIcon } from 'assets/icon-location.svg';

import ShareButtonIcon from 'assets/share-button-icon.jpg';

import { getStateAgencyName } from 'utils/helpers';

import { DATE_FORMAT_WITH_DOTS_FULL_YEAR } from 'constants/commonConstants';

const JobDetailsHeader = ({
    job,
    isAdmin,
    isClient,
    handleDotsClick,
    handleShareClick,
    isWithLinkToJobPage,
}) => {

    return (
        <div className="job-details-header">
            {(job.jobOpportunityImgUrl) &&
                <Avatar
                    className="job-image"
                    src={job.jobOpportunityImgUrl}
                />
            }
            <div className="job-main-details">
                <div className="row-items">
                    <div className="job-position-wrapper">
                        {(isAdmin && isWithLinkToJobPage)
                            ? <Link
                                to={`/${getStateAgencyName()}/job-page/${job._id}`}
                                className="job-position-link"
                            >
                                <span className="job-position">{job?.position}</span>
                            </Link>
                            : <span className="job-position">{job?.position}</span>
                        }
                    </div>

                    <div className="job-details-actions">
                        {(isAdmin && job.externalJobId) && <ExternalJobIdLabel externalJobId={job.externalJobId} />}
                        <img src={ShareButtonIcon} className="share-button-action" onClick={(event) => handleShareClick(event)} />
                        {(!isAdmin && job?.status) && <JobStatusLabel status={job?.status} />}
                        {(isAdmin || isClient) && <ThreeDotsIcon className="job-three-dots-action" onClick={(event) => handleDotsClick(event)} />}
                    </div>
                </div>

                {isAdmin
                    ? <>
                        <div className="row-items justify-start">
                            <div className="row-item">
                                <CompanyIcon />
                                <Link to={`/${getStateAgencyName()}/clients/${job.client._id}`}>
                                    <span>{job.client?.name}</span>
                                </Link>
                            </div>
                            <div className="row-item">
                                <DateAddedIcon />
                                <span>{moment(job.dateAdded).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR)}</span>
                            </div>
                            <div className="row-item">
                                <AccountIcon />
                                <span>{job.creator?.name}</span>
                            </div>
                        </div>
                        <div className="row-item location">
                            <LocationIcon />
                            <span>Remote {job.geographicLocations?.length > 0 && `(${job.geographicLocations.join(', ')})`}</span>
                        </div>
                    </>
                    : <>
                        <div className="row-items justify-start">
                            <div className="row-item">
                                <DateAddedIcon />
                                <span>{moment(job.dateAdded).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR)}</span>
                            </div>
                            <div className="row-item">
                                <LocationIcon />
                                <span>Remote {job.geographicLocations?.length > 0 && `(${job.geographicLocations.join(', ')})`}</span>
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
    );
};


export default JobDetailsHeader;
