import moment from 'moment';
import { MONTH_YEAR_DATE_FORMAT } from 'constants/commonConstants';

export const TABLE_COLUMNS = [
    { name: 'Name', value: 'name', isSortable: true },
    { name: 'Projects', value: 'projects', isSortable: true },
    { name: 'Days Worked', value: 'daysWorked', isSortable: true },
];

export const DEFAULT_TABLE_COLUMNS = ['name', 'projects', 'daysWorked'];

export const TABLE_FILTERS = [
    { key: 'status', name: 'Status', value: ['Active Clients'] },
];

export const SORT_QUERY_SELECTOR = 'sort';
export const PAGE_QUERY_SELECTOR = 'page';
export const DATE_QUERY_SELECTOR = 'date';
export const DEFAULT_ITEMS_PER_PAGE = 25;

export const TABLE_INITIAL_QUERY = {
    filters: JSON.stringify({}),
    page: 1,
    date: moment().format(MONTH_YEAR_DATE_FORMAT),
    sortBy: 'name',
    sortByDir: 'desc',
};

export const TABLE_QUERY_MAP = {
    filters: 'filters',
    page: PAGE_QUERY_SELECTOR,
    date: DATE_QUERY_SELECTOR,
    sortBy: SORT_QUERY_SELECTOR,
    sortByDir: `${SORT_QUERY_SELECTOR}Dir`,
};
