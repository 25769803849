import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';

import { fetchAdmins } from 'actions/agencyActions';
import {
    fetchAllTasks,
    saveTask,
} from 'actions/employeeActions';

import {
    getCurrentUser,
    getSelectedEmployee,
    getEmployeeTasks,
    getAgencyId,
    getAdmins,
    getTasksListDataFetchState,
} from 'reducers';

import { TASK_ON_BEHALF_OF_TYPE } from 'constants/tasksConstants';

import TaskCardList from './TaskCardList';
import './Tasks.scss';

const Tasks = ({
    match,
    tasks,
    fetchAllTasks,
    fetchAdmins,
    submitTaskHandler,
    currentUser,
    selectedUser,
    agencyId,
    isDataAvailable,
    admins,
    dataFetchState
}) => {
    useEffect(() => {
        if (!isDataAvailable) {
            fetchAllTasks(match.params.id, agencyId, TASK_ON_BEHALF_OF_TYPE.RELATED_TO);
        }
        fetchAdmins(agencyId);
    }, []);

    return (
        <Fragment>
            <TaskCardList
                dataFetchState={dataFetchState}
                tasks={tasks}
                currentUser={currentUser}
                selectedUser={selectedUser}
                submitTaskHandler={submitTaskHandler}
                agencyId={agencyId}
                taskAdmins={admins}
            />
        </Fragment>
    )
}

const mapStateToProps = (state, props) => ({
    tasks: getEmployeeTasks(state),
    currentUser: getCurrentUser(state),
    selectedUser: getSelectedEmployee(state, props.match.params.id),
    agencyId: getAgencyId(state),
    admins: getAdmins(state),
    dataFetchState: getTasksListDataFetchState(state),
});

const mapDispatchToProps = {
    fetchAllTasks,
    submitTaskHandler: saveTask,
    fetchAdmins,
};

export default connect(mapStateToProps, mapDispatchToProps)(Tasks);
