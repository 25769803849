import React from 'react';
import moment from 'moment';

import CommonFormikDialog from 'components/Shared/CommonFormikDialog';
import { ErrorMessage } from 'formik';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import { DATE_FORMAT } from 'constants/commonConstants';
import MomentUtils from '@date-io/moment';

import './AvailableFromDialog.scss';

const AvailableFromDialog = ({
    onSubmit,
    handleChange,
    values,
    onClose,
    setFieldValue,
    handleSubmit,
    open,
    ...props
}) => {
    const onFormSubmit = () => {
        handleSubmit();
    }
    return (
        <form>
            <CommonFormikDialog open={open} onClose={onClose} className={"availability-dialog"} dialogTitle="Edit Availability" saveBtnText="Save" discardBtnText="Discard" onSave={onFormSubmit}>
                <div className="form-row">
                    <div className="form-cols">
                        <div className="col-1of2 candidate-earliestDate">
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    autoOk
                                    name="earliestDate"
                                    variant="inline"
                                    disableToolbar
                                    disablePast
                                    format={DATE_FORMAT}
                                    openTo="date"
                                    inputVariant="outlined"
                                    value={values.earliestDate}
                                    onChange={(value) => {
                                        setFieldValue('earliestDate', moment(value));
                                    }}
                                    fullWidth
                                    disabled={values.currentlyUnavailable}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                            <ErrorMessage name="earliestDate" component="div" className="invalid-field-message" />
                        </div>
                    </div>
                </div>
            </CommonFormikDialog>
        </form>
    );
}

export default AvailableFromDialog;
