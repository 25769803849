import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { getAllReviews, createReview, updateReview, deleteReview } from 'actions/employeeActions';
import { fetchAgencyPerformanceCriterias } from 'actions/agencyActions';
import {
    getOverallPerformance,
    getPerformanceReviews,
    getDisplayName,
    getPerformanceCriterias,
    getUserId,
    getDisplayMode,
    getPerformanceReviewsDataFetchState
} from 'reducers';

import PerformanceTabs from './PerformanceTabs';
import PerformanceReviewOverall from './PerformanceReviewOverall';
import PerformanceAnalytics from 'components/Shared/PerformanceAnalytics';
import PerformanceReviewsHistory from './PerformanceReviewsHistory';

import { generateOverallPerformance } from './PerformanceReviewUtils';

import { TAB_NAMES, defaultTab } from './PerformanceReviewConstants';

import CircularProgress from '@material-ui/core/CircularProgress';

import './PerformanceReview.scss';

const PerformanceReview = ({
    match,
    performanceReviews,
    overallPerformance,
    getAllReviews,
    displayName,
    createReview,
    updateReview,
    agencyId,
    fetchAgencyPerformanceCriterias,
    performanceCriterias,
    userId,
    employeeId,
    deleteReview,
    role,
    dataFetchState
}) => {
    const [selectedTab, setSelectedTab] = useState(defaultTab);

    useEffect(() => {
        const isNotInitialPageLoad = performanceReviews.length !== 0 && performanceCriterias.length !== 0
        getAllReviews(match.params.id, agencyId, isNotInitialPageLoad);
        fetchAgencyPerformanceCriterias(agencyId)
    }, []);

    const filterPerformanceReviews = performanceReviews => {
        const filteredPerformanceReviews = selectedTab.text !== TAB_NAMES.ALL ?
            (selectedTab.text === TAB_NAMES.INTERNAL_FFEDBACK
                ? performanceReviews?.filter(review => review.isInternalFeedback)
                : performanceReviews?.filter(review => !review.isInternalFeedback))
            : performanceReviews;

        return filteredPerformanceReviews;
    };

    return (
        <div>
            {dataFetchState?.isDataFetching
                ? <CircularProgress size={50} disableShrink className="page-loader" />
                : <Fragment>
                    <PerformanceTabs
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                    />

                    <PerformanceReviewOverall
                        displayName={displayName}
                        createReview={createReview}
                        overallPerformance={generateOverallPerformance(filterPerformanceReviews(performanceReviews))}
                        performanceCriterias={performanceCriterias}
                        agencyId={agencyId}
                        employeeId={employeeId}
                        userId={userId}
                        selectedTab={selectedTab}
                    />

                    {performanceReviews.length > 0 &&
                        <PerformanceAnalytics
                            title="Performance analytics"
                            performanceReviews={filterPerformanceReviews(performanceReviews)}
                            performanceCriterias={performanceCriterias}
                        />
                    }

                    {performanceReviews.length > 0 &&
                        <PerformanceReviewsHistory
                            performanceReviews={filterPerformanceReviews(performanceReviews)}
                            displayName={displayName}
                            createReview={createReview}
                            editReview={updateReview}
                            performanceCriterias={performanceCriterias}
                            agencyId={agencyId}
                            employeeId={employeeId}
                            userId={userId}
                            deleteReview={deleteReview}
                            role={role}
                            selectedTab={selectedTab}
                        />
                    }
                </Fragment>
            }
        </div>
    )
}

const mapStateToProps = state => ({
    performanceReviews: getPerformanceReviews(state),
    overallPerformance: getOverallPerformance(state),
    displayName: getDisplayName(state),
    performanceCriterias: getPerformanceCriterias(state),
    userId: getUserId(state),
    role: getDisplayMode(state),
    dataFetchState: getPerformanceReviewsDataFetchState(state),
});

const mapDispatchToProps = {
    getAllReviews,
    createReview,
    updateReview,
    fetchAgencyPerformanceCriterias,
    deleteReview
};

export default connect(mapStateToProps, mapDispatchToProps)(PerformanceReview);
