import React from 'react';

import AssessmentSharpIcon from '@material-ui/icons/AssessmentSharp';
import PersonSharpIcon from '@material-ui/icons/PersonSharp';
import SettingsSharpIcon from '@material-ui/icons/SettingsSharp';
import AccessTimeSharpIcon from '@material-ui/icons/AccessTimeSharp';
import EventSharpIcon from '@material-ui/icons/EventSharp';
import TimelineSharpIcon from '@material-ui/icons/TimelineSharp';
import BusinessSharpIcon from '@material-ui/icons/BusinessSharp';
import InsertDriveFileSharpIcon from '@material-ui/icons/InsertDriveFileSharp';
import AccountBalanceWalletRoundedIcon from '@material-ui/icons/AccountBalanceWalletRounded';
import DescriptionRoundedIcon from '@material-ui/icons/DescriptionRounded';
import { ReactComponent as TrainingsIcon } from 'assets/trainings-icon.svg';
import { ReactComponent as PerksAndEquipmentIcon } from 'assets/perks-and-equipment-icon.svg';
import { ReactComponent as UpcomingInterviews } from 'assets/upcoming-interviews.svg';
import { ReactComponent as DocumentsIcon } from 'assets/documents-icon.svg';

import { temporaryRoute } from 'constants/commonConstants';

export const getEmployeeMenuItems = agencyRouteName => [
	{
		TITLE: 'Dashboard',
		ICON: <AssessmentSharpIcon />,
		ROUTE: `/${agencyRouteName}/dashboard`,
		EXACT: true,
	},
	{
		TITLE: 'Time-tracking',
		ICON: <AccessTimeSharpIcon />,
		ROUTE: `/${agencyRouteName}/time-tracking`,
		EXACT: false,
	},
	{
		TITLE: 'Employment',
		ICON: <BusinessSharpIcon />,
		ROUTE: `/${agencyRouteName}/employment`,
		EXACT: false,
		ITEMS: [
			{
				TEXT: 'Personal',
				ICON: <PersonSharpIcon />,
				ENDPOINT: `/${agencyRouteName}/employment/personal-info`,
			},
			{
				TEXT: 'Contracts',
				ICON: <InsertDriveFileSharpIcon />,
				ENDPOINT: `/${agencyRouteName}/employment/contracts`,
			},
			{
				TEXT: 'Salary',
				ICON: <AccountBalanceWalletRoundedIcon />,
				ENDPOINT: temporaryRoute,
			},
			{
				TEXT: 'CV',
				ICON: <DescriptionRoundedIcon />,
				ENDPOINT: `/${agencyRouteName}/employment/cv`,
			},
			{
				TEXT: 'Perks & Equipment',
				ICON: <PerksAndEquipmentIcon />,
				ENDPOINT: temporaryRoute,
			},
		],
	},
	{
		TITLE: 'Holidays',
		ICON: <EventSharpIcon />,
		ROUTE: `/${agencyRouteName}/holidays`,
		EXACT: false,
	},
	{
		TITLE: 'Perks & Equipment',
		ICON: <PerksAndEquipmentIcon />,
		ROUTE: `/${agencyRouteName}/benefits`,
		EXACT: false,
	},
	{
		TITLE: 'Trainings & Coaching',
		ICON: <TrainingsIcon />,
		ROUTE: `/${agencyRouteName}/trainings`,
		exact: false,
	},
	{
		TITLE: 'Upcoming Interviews',
		ICON: <UpcomingInterviews />,
		ROUTE: `/${agencyRouteName}/upcoming-interviews`,
		exact: false,
	},
	{
		TITLE: 'Performance Review',
		ICON: <TimelineSharpIcon />,
		ROUTE: `/${agencyRouteName}/performance-review`,
		exact: false,
	},
	{
		TITLE: 'Settings',
		ICON: <SettingsSharpIcon />,
		ROUTE: temporaryRoute,
		exact: false,
	},
];

export const HIDDEN_MENU_ITEMS = [
	{
		menuItemTitle: 'Perks & Equipment',
		action: 'hide-all',
	},
];
export const getDepartmentsMenuSubItems = agencyRouteName => [
	{ TEXT: 'Holidays', ICON: <EventSharpIcon />, ENDPOINT: `/${agencyRouteName}/departments/holidays` },
	{ TEXT: 'Documents', ICON: <DocumentsIcon />, ENDPOINT: `/${agencyRouteName}/departments/documents` }
];

