import React from 'react';
import moment from 'moment';

import { Formik } from 'formik';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import Dialog from 'components/Shared/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import EditStatusDialogForm from './EditStatusDialogForm';

import { addUtcOffset } from 'utils/formatTimes';
import { validateRequiredFields } from 'utils/formValidations';

import { EMPLOYEE_OCCUPATION_STATUSES_MAP, EMPLOYEE_OCCUPATION_STATUSES } from 'constants/employeeConstants';

import './EditStatusDialog.scss';

const EditStatusDialog = ({
    isOpen,
    handleCloseDialog,
    selectedEmployee,
    handleEditStatus,
}) => (
    <MuiPickersUtilsProvider utils={MomentUtils}>
        <Formik
            enableReinitialize
            initialValues={{
                occupationStatus: selectedEmployee.occupationStatus || null,
                projectEndDate: selectedEmployee.projectEndDate ? moment.utc(selectedEmployee.projectEndDate) : null,
            }}
            validate={values => {
                const requiredFields = EMPLOYEE_OCCUPATION_STATUSES_MAP[values.occupationStatus] === EMPLOYEE_OCCUPATION_STATUSES.PROJECT_ENDING
                    ? ['occupationStatus', 'projectEndDate']
                    : ['occupationStatus'];
                return { ...validateRequiredFields(values, requiredFields) }
            }}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
                let data;
                EMPLOYEE_OCCUPATION_STATUSES_MAP[values.occupationStatus] !== EMPLOYEE_OCCUPATION_STATUSES.PROJECT_ENDING
                    ? data = { ...values, projectEndDate: null }
                    : data = { ...values, projectEndDate: addUtcOffset(values.projectEndDate) }
                handleEditStatus(selectedEmployee._id, data);
                handleCloseDialog();
            }}
        >
            {(props) =>
                <Dialog className="edit-status-dialog" open={isOpen} onClose={handleCloseDialog}>
                    <MuiDialogTitle disableTypography className="dialog-title">
                        <Typography variant="body2" className="dialog-title-text">Edit Employee Status</Typography>
                        <IconButton className="dialog-close-btn" onClick={handleCloseDialog}>
                            <CloseIcon />
                        </IconButton>
                    </MuiDialogTitle>

                    <DialogContent className="dialog-content">
                        <EditStatusDialogForm
                            {...props}
                            selectedEmployee={selectedEmployee}
                            handleCloseDialog={handleCloseDialog}
                        />
                    </DialogContent>
                </Dialog>
            }
        </Formik>
    </MuiPickersUtilsProvider>

);

export default EditStatusDialog;
