import React, { useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { updateInterviewStatus, createInterviewInvitation, rescheduleInterview } from 'actions/interviewInvitationActions';

import InterviewInviteOptionsDialog from 'components/Shared/InterviewInviteOptionsDialog';
import InterviewMarkAsInvitedPopup from 'components/Shared/InterviewMarkAsInvitedPopup';
import InterviewInvitePopup from 'components/Shared/InterviewInvitePopup';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';
import SecondaryButton from 'components/Shared/Buttons/SecondaryButton';
import InterviewSectionPaper from './InterviewSectionPaper';
import ConfirmCancelInterviewDialog from 'components/Shared/ConfirmCancelInterviewDialog';
import { Divider } from '@material-ui/core';

import { INTERVIEW_INVITATION_STATUSES, INTERVIEW_INVITATION_CONTRIBUTORS } from 'constants/interviewInvitationConstants';
import {
    getHeaderContent,
    DEFAULT_INVITE_DIALOG_TITLE,
    INVITE_DIALOG_TITLES,
    INVITE_BUTTON_TEXTS,
} from './InterviewSectionConstants';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitudeConstants';
import { APPLICATION_STATUSES } from 'constants/applicationConstants';
import { ROLES } from 'constants/userConstants';
import { FORMAT_TIMES_MAP } from 'constants/timesFormat';
import { logEvent } from 'utils/amplitude';

import './InterviewSection.scss';

const InterviewSection = ({
    userId,
    agencyId,
    candidateId,
    applicationId,
    displayMode,
    interviewInvitation,
    jobApplication,
    updateInterviewStatus,
    createInterviewInvitation,
    rescheduleInterview,
}) => {
    const [isInviteDialogOpen, setIsInviteDialogOpen] = useState(false);
    const [isMarkAsInvitedDialogOpen, setIsMarkAsInvitedDialogOpen] = useState(false);
    const [isCancellationDialogOpen, setIsCancellationDialogOpen] = useState(false);
    const [inviteDialogTitle, setInviteDialogTitle] = useState(DEFAULT_INVITE_DIALOG_TITLE);
    const [isLocationDisabled, setIsLocationDisabled] = useState(true);
    const [isInterviewInviteOptionsDialogOpen, setIsInterviewInviteOptionsDialogOpen] = useState(false);

    const status = interviewInvitation?.status || jobApplication?.status;

    const getInviteButtonText = () => (status === INTERVIEW_INVITATION_STATUSES.PENDING ||
        status === INTERVIEW_INVITATION_STATUSES.APPROVED && interviewInvitation.isMarkedAsInvited)
        ? INVITE_BUTTON_TEXTS.RESCHEDULE
        : INVITE_BUTTON_TEXTS.INVITE_TO_INTERVIEW;
    const inviteButtonText = getInviteButtonText();
    const handleOpenCancellationDialog = () => setIsCancellationDialogOpen(true);
    const handleCloseCancellationDialog = () => setIsCancellationDialogOpen(false);

    const handleOpenInviteDialog = () => {
        setInviteDialogTitle(INVITE_DIALOG_TITLES.INVITE);
        setIsLocationDisabled(false);
        setIsInviteDialogOpen(true);
    };
    const handleCloseInviteDialog = () => setIsInviteDialogOpen(false);

    const handleOpenMarkAsInvitedDialog = () => {
        setIsMarkAsInvitedDialogOpen(true);
    };
    const handleCloseMarkAsInvitedDialog = () => setIsMarkAsInvitedDialogOpen(false);

    const handleOpenInterviewInviteOptionsDialog = () => setIsInterviewInviteOptionsDialogOpen(true);
    const handleCloseInterviewInviteOptionsDialog = () => setIsInterviewInviteOptionsDialogOpen(false);

    const handleCancelInterview = () => {
        const isForCancellation = true;
        const isMarkedAsInvited = interviewInvitation.isMarkedAsInvited;
        updateInterviewStatus(agencyId, interviewInvitation._id, { applicationId, status: APPLICATION_STATUSES.REJECTED, isForCancellation, isMarkedAsInvited });
        handleCloseCancellationDialog();
    }

    const handelInterviewInvitationSubmit = async (formData) => {
        logEvent(AMPLITUDE_EVENT_TYPES.CLIENT_INVITE_APPLICANT_TO_INTERVIEW);
        const { clientNote, ...restData } = formData;

        const interviewer = displayMode === ROLES.ADMIN ? jobApplication.clientId : userId;

        const additionalData = {
            dateAdded: moment.utc().format(),
            status: INTERVIEW_INVITATION_STATUSES.PENDING,
            proposedBy: INTERVIEW_INVITATION_CONTRIBUTORS.INTERVIEWER,
            interviewer: interviewer,
            participant: candidateId,
            application: applicationId
        };

        if (inviteButtonText === INVITE_BUTTON_TEXTS.INVITE_TO_INTERVIEW) {
            await createInterviewInvitation(agencyId, { ...restData, ...additionalData, note: clientNote });
        } else {
            await rescheduleInterview(agencyId, interviewInvitation._id, { ...restData, ...additionalData, note: clientNote })
        }
    };

    const handleMarkAsInvitedSubmit = async (formData) => {
        const interviewer = displayMode === ROLES.ADMIN ? jobApplication.clientId : userId;

        const additionalData = {
            dateAdded: moment.utc().format(),
            status: INTERVIEW_INVITATION_STATUSES.APPROVED,
            proposedBy: INTERVIEW_INVITATION_CONTRIBUTORS.INTERVIEWER,
            interviewer: interviewer,
            participant: candidateId,
            application: applicationId,
            isMarkedAsInvited: true,
            interviewDate: {
                date: formData.interviewTimeSlots[0].date,
                time: formData.interviewTimeSlots[0].time
            },
        };

        if (inviteButtonText === INVITE_BUTTON_TEXTS.INVITE_TO_INTERVIEW) {
            await createInterviewInvitation(agencyId, { ...formData, ...additionalData });
        } else {
            await rescheduleInterview(agencyId, interviewInvitation._id, { ...formData, ...additionalData });
        }
    };

    const getMonthAndDay = (date) => {
        const month = moment(date).format('MMMM');
        const givenDate = moment(date);
        const days = givenDate.format('DD');
        return { month, days };
    }

    const getTime = (date) => {
        const time = moment(date);
        const interviewStart = getAmOrPm(moment(time).format(FORMAT_TIMES_MAP.TIME));
        const interviewEnd = getAmOrPm(moment(time).add(1, 'hours').format(FORMAT_TIMES_MAP.TIME));
        return `${interviewStart} - ${interviewEnd}`;
    }

    const getAmOrPm = (time) => {
        const hour = time.split(':')[0];
        if (hour < 12) {
            time = `${time}AM`
        } else {
            time = `${time}PM`
        }
        return time;
    }

    const bodyCompiler = () => {
        switch (status) {
            case APPLICATION_STATUSES.NEW_APPLICATION:
            case APPLICATION_STATUSES.REJECTED:
                return (
                    <div className="notification-section-body">
                        <div className="interview-action-buttons">
                            <PrimaryButton
                                text={inviteButtonText}
                                handleClick={displayMode === ROLES.ADMIN ? handleOpenInterviewInviteOptionsDialog : handleOpenInviteDialog}
                            />
                        </div>
                    </div>
                );
            case INTERVIEW_INVITATION_STATUSES.PENDING:
                return (
                    <div className="notification-section-body">
                        <Divider className="body-divider" />
                        <div className="interview-details-wrapper">
                            {interviewInvitation?.interviewTimeSlots.map(interview => (
                                <div className="interview-details">
                                    <div className="left">
                                        <h3 className="job-position">{jobApplication?.position}</h3>
                                        <p className="time">{getTime(interview.time)}</p>
                                        <p className="location">Location: {interviewInvitation.location}</p>
                                    </div>
                                    <div className="right">
                                        <h3 className="days">{getMonthAndDay(interview.date).days}</h3>
                                        <p className="month">{getMonthAndDay(interview.date).month}</p>
                                    </div>
                                </div>
                            ))
                            }
                        </div>
                        <div className="interview-action-buttons">
                            <SecondaryButton text='Cancel' handleClick={handleOpenCancellationDialog} />
                            <PrimaryButton text={inviteButtonText} handleClick={handleOpenInviteDialog} />
                        </div>
                    </div>
                );
            case INTERVIEW_INVITATION_STATUSES.APPROVED:
                return (
                    <div className="notification-section-body">
                        <Divider className="body-divider" />
                        <div className="interview-details">
                            <div className="left">
                                <h3 className="job-position">{jobApplication?.position}</h3>
                                <p className="time">{getTime(interviewInvitation?.interviewDate?.time)}</p>
                                {interviewInvitation.location && <p className="location">Location: <a href={interviewInvitation.location}>link</a></p>}
                            </div>
                            <div className="right">
                                <h3 className="days">{getMonthAndDay(interviewInvitation?.interviewDate?.date).days}</h3>
                                <p className="month">{getMonthAndDay(interviewInvitation?.interviewDate?.date).month}</p>
                            </div>
                        </div>
                        {(interviewInvitation.isMarkedAsInvited && displayMode === ROLES.ADMIN) &&
                            <div className="interview-action-buttons">
                                <SecondaryButton text='Cancel' handleClick={handleOpenCancellationDialog} />
                                <PrimaryButton text={inviteButtonText} handleClick={handleOpenMarkAsInvitedDialog} />
                            </div>
                        }
                    </div>
                )

            default:
                break;
        }
    }
    const headerContent = getHeaderContent(status);

    return (
        <div className="interview-section-wrapper">
            <InterviewSectionPaper
                title={headerContent?.title}
                text={headerContent?.text}
            >
                {bodyCompiler()}
            </InterviewSectionPaper>
            <InterviewInvitePopup
                displayMode={displayMode}
                dialogTitle={inviteDialogTitle}
                isWithNote={true}
                isDialogOpen={isInviteDialogOpen}
                isLocationDisabled={isLocationDisabled}
                interviewInvitation={interviewInvitation}
                handleCloseDialog={handleCloseInviteDialog}
                handelInterviewInvitationSubmit={handelInterviewInvitationSubmit}
            />
            <ConfirmCancelInterviewDialog
                openDialog={isCancellationDialogOpen}
                handleCloseDialog={handleCloseCancellationDialog}
                handleCancelInterview={handleCancelInterview}
            />
            {displayMode === ROLES.ADMIN &&
                <>
                    <InterviewInviteOptionsDialog
                        isDialogOpen={isInterviewInviteOptionsDialogOpen}
                        handleCloseDialog={handleCloseInterviewInviteOptionsDialog}
                        handleOpenInviteForInterviewDialog={handleOpenInviteDialog}
                        handleOpenMarkAsInvitedDialog={handleOpenMarkAsInvitedDialog}
                    />
                    <InterviewMarkAsInvitedPopup
                        isDialogOpen={isMarkAsInvitedDialogOpen}
                        handleCloseDialog={handleCloseMarkAsInvitedDialog}
                        handleMarkAsInvitedSubmit={handleMarkAsInvitedSubmit}
                    />
                </>
            }
        </div>
    )
}

const mapStateToProps = () => ({

});

const mapDispatchToProps = {
    updateInterviewStatus,
    createInterviewInvitation,
    rescheduleInterview,
};

export default connect(mapStateToProps, mapDispatchToProps)(InterviewSection);
