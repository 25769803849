import React, { useState } from 'react';
import { connect } from 'react-redux';

import {
    createBenefit,
    createBenefitTemplate,
    removeEmployeeBenefit,
    updateBenefit,
} from 'actions/employeeActions';

import {
    getEmployeeBenefitsList,
    getEmployeeBenefitTemplates
} from 'reducers';

import BenefitInfo from './BenefitInfo';
import Paper from '@material-ui/core/Paper';
import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';
import AddEditBenefitDialog from './AddEditBenefitDialog';
import ConfirmDeleteDialog from 'components/Shared/ConfirmDeleteDialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import NothingToShow from 'components/NothingToShow';
import { ReactComponent as NoBenefitsToShowIcon } from 'assets/no-tasks-to-show.svg';
import { ROLES } from 'constants/userConstants';

import { logEvent } from 'utils/amplitude';

import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitudeConstants';
import './MyBenefits.scss'

const MyBenefits = ({
    userId,
    agencyId,
    createBenefit,
    createBenefitTemplate,
    removeEmployeeBenefit,
    updateBenefit,
    benefitTemplates,
    benefits,
    displayMode,
    dataFetchState
}) => {
    const [openAddEditDialog, setOpenAddDialog] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [isTemplate, setIsTemplate] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [benefitToRemove, setBenefitToRemove] = useState({});
    const [currentBenefit, setCurrentBenefit] = useState({});

    const handleSelectProjectTemplate = (name) => {
        const currentTemplate = benefitTemplates.filter(x => x.name === name)[0];
        setCurrentBenefit(currentTemplate);
    }

    // Add Edit Dialog

    const handleAddClick = () => {
        setIsEditMode(false);
        setOpenAddDialog(true);
        logEvent(AMPLITUDE_EVENT_TYPES.CLICK_ADD_PERKS_AND_EQUIPMENT);
    }

    const handleEditClick = benefit => {
        setCurrentBenefit(benefit);
        setIsEditMode(true);
        setOpenAddDialog(true);
    }

    const handleCloseDialog = () => {
        setCurrentBenefit({});
        setIsTemplate(false);
        setOpenAddDialog(false);
    }

    const handleAddBenefit = benefit => {
        createBenefit(agencyId, userId, benefit);
    };

    const handleEditBenefit = (benefitId, benefit) => {
        updateBenefit(agencyId, userId, benefitId, benefit);
    };

    const handleAddBenefitTemplate = benefit => {
        createBenefitTemplate(agencyId, userId, benefit);
    };


    //remove 

    const handleCloseConfirmDialog = () => {
        setOpenConfirmDialog(false);
        setBenefitToRemove({});
    }

    const handleRemoveClick = project => {
        setOpenConfirmDialog(true);
        setBenefitToRemove(project);
    };

    const handleRemoveBenefit = id => {
        removeEmployeeBenefit(agencyId, userId, id);
        setOpenConfirmDialog(false);
    };

    return (
        <>
            {dataFetchState?.isDataFetching && !dataFetchState?.isDataFetched
                ? <CircularProgress size={50} disableShrink className="page-loader" />
                : <div className="benefits-myBenefits-wrapper">
                    <SectionActionsWrapper>
                        <SectionActionsWrapper.SectionTitle
                            sectionTitle='Perks & Equipment'
                        />

                        {displayMode === ROLES.ADMIN && (
                            <SectionActionsWrapper.RightAlignedActions>
                                <SectionActionsWrapper.RightAlignedActions.PrimaryAddButton
                                    text='Add New'
                                    onClick={handleAddClick}
                                />
                            </SectionActionsWrapper.RightAlignedActions>
                        )}
                    </SectionActionsWrapper>

                    {benefits && benefits.length > 0
                        ? <Paper className="benefits-info-content benefits-myBenefits-rows-wrapper">
                            {benefits.map((x, i) => {
                                return (
                                    <BenefitInfo
                                        key={i}
                                        item={x}
                                        removeItem={handleRemoveClick}
                                        editItem={handleEditClick}
                                        displayMode={displayMode}
                                    />
                                )
                            })}
                        </Paper>
                        : <NothingToShow items='Perks and Equipment' icon={<NoBenefitsToShowIcon />} />
                    }
                    <AddEditBenefitDialog
                        agencyId={agencyId}
                        userId={userId}
                        isEditMode={isEditMode}
                        openAddEditDialog={openAddEditDialog}
                        handleCloseDialog={handleCloseDialog}
                        setIsTemplate={setIsTemplate}
                        isTemplate={isTemplate}
                        createBenefit={handleAddBenefit}
                        createBenefitTemplate={handleAddBenefitTemplate}
                        handleSelectProjectTemplate={handleSelectProjectTemplate}
                        updateBenefit={handleEditBenefit}
                        currentBenefit={currentBenefit}
                        templates={benefitTemplates}
                        isEmployeeMode={displayMode === ROLES.EMPLOYEE}
                    />

                    <ConfirmDeleteDialog
                        itemToDelete={benefitToRemove.name}
                        handleDeleteItem={() => handleRemoveBenefit(benefitToRemove._id)}
                        openDialog={openConfirmDialog}
                        handleCloseDialog={handleCloseConfirmDialog}
                    />
                </div>
            }
        </>
    )
}

const mapStateToProps = state => ({
    benefitTemplates: getEmployeeBenefitTemplates(state),
    benefits: getEmployeeBenefitsList(state),
});

const mapDispatchToProps = {
    createBenefit,
    createBenefitTemplate,
    removeEmployeeBenefit,
    updateBenefit,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyBenefits);
