import React from 'react';
import { Link } from 'react-router-dom';

import { getRecruiters } from 'reducers';

import TableWrapper from 'components/Shared/TableWrapper';
import Avatar from '@material-ui/core/Avatar';

import logoImg from 'assets/default-logo.svg';
import { ReactComponent as AddButtonIcon } from 'assets/add-button-icon.svg';

import { usePreloadTable } from 'utils/hooks';
import { getStateAgencyName } from 'utils/helpers';

import {
    TABLE_COLUMNS,
    DEFAULT_TABLE_COLUMNS,
    TABLE_FILTERS,
    SORT_QUERY_SELECTOR,
    PAGE_QUERY_SELECTOR,
    SEARCH_QUERY_SELECTOR,
    DEFAULT_ITEMS_PER_PAGE,
    TABLE_INITIAL_QUERY,
    TABLE_QUERY_MAP,
} from './RecruitersTableConstants';

const RecruitersTable = ({
    recruitersAccountManagers,
    tableFetchAction,
    tablePrefetchAction,
    reload,
    setReload,
    getActionsHandler,
    handleOpenAddDialog,
}) => {
    const dataSelector = (state, page) => getRecruiters(state, page - 1, DEFAULT_ITEMS_PER_PAGE);

    TABLE_FILTERS[0].value = recruitersAccountManagers.map(x => x.name);
    const [isTableDataLoading, totalCount] = usePreloadTable(
        TABLE_INITIAL_QUERY,
        TABLE_QUERY_MAP,
        TABLE_FILTERS,
        tableFetchAction,
        reload,
        setReload,
        tablePrefetchAction
    );

    const generateToString = (id) => {
        return `/${getStateAgencyName()}/recruiters/${id}`;
    };

    const adjustRecruiterKeys = stateData => stateData.map(recruiter => {
        return {
            _id: recruiter._id,
            name: (
                <Link to={generateToString(recruiter._id)}>
                    <div className="logo-name-wrapper">
                        <Avatar
                            alt="Recrutier Avatar"
                            className="avatar-logo-image"
                            src={recruiter.logoImgUrl ? recruiter.logoImgUrl : logoImg}
                        />
                        <div className="name-text">
                            {recruiter.name}
                        </div>
                    </div>
                </Link>
            ),
            contactPerson: recruiter.contactPerson,
            accountManager: (
                <div className="logo-name-wrapper">
                    {recruiter.accountManager.profilePictureImgUrl &&
                        <Avatar
                            alt="Account Manager Avatar"
                            className="avatar-logo-image"
                            src={recruiter.accountManager.profilePictureImgUrl}
                        />
                    }
                    <div className="name-text">
                        {recruiter.accountManager?.name}
                    </div>
                </div>
            ),
            item: recruiter
        };
    });

    return (
        <div className="recruiters-table-container">
            <TableWrapper
                title="Recruiter List"
                columns={TABLE_COLUMNS}
                defaultColumns={DEFAULT_TABLE_COLUMNS}
                dataSelector={dataSelector}
                sortQuerySelector={SORT_QUERY_SELECTOR}
                adjustKeysFunction={adjustRecruiterKeys}
                isTableDataLoading={isTableDataLoading}
                withPagination
                withActions
                getActionsHandler={getActionsHandler}
                addMargin
                pagesCount={Math.ceil(totalCount / DEFAULT_ITEMS_PER_PAGE)}
                pageQuerySelector={PAGE_QUERY_SELECTOR}
            >
                <TableWrapper.TableAlignedItems>
                    <TableWrapper.TableAlignedItems.LeftAligned>
                        <TableWrapper.TableAlignedItems.TableHeader />
                        <TableWrapper.TableAlignedItems.TableFilter
                            filters={TABLE_FILTERS}
                        />
                        <TableWrapper.TableAlignedItems.TableSearch queryParamName={SEARCH_QUERY_SELECTOR} />
                    </TableWrapper.TableAlignedItems.LeftAligned>
                    <TableWrapper.TableAlignedItems.RightAligned>
                        <TableWrapper.TableAlignedItems.TableActionButton
                            text="Add Recruiter"
                            icon={<AddButtonIcon />}
                            handleClick={handleOpenAddDialog}
                        />
                    </TableWrapper.TableAlignedItems.RightAligned>
                </TableWrapper.TableAlignedItems>
                <TableWrapper.TableContent />
            </TableWrapper>
        </div>
    );
};

export default RecruitersTable;
