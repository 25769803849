import React from 'react';
import { useHistory } from 'react-router';
import { ErrorMessage } from 'formik';

import TextField from '@material-ui/core/TextField';
import FieldError from "components/Shared/FieldError";
import SignUpFooter from 'components/Shared/SignUpFooter';
import TermsAndConditionsCheckBox from 'components/Shared/TermsAndConditionsCheckBox';
import PasswordInput from 'components/Common/PasswordInput';

import { IS_WHOISAVAILABLE_ENV } from 'constants/env';
import { motionRedirectLinks } from '../../RegisterConstants';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitudeConstants';

import { signInWithPopup } from 'utils/firebase';
import { logEvent } from 'utils/amplitude';
import { getHomePath } from 'utils/navigationUtils';

const RegisterFormView = ({
    blur,
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    jobOpportunityId,
    gotoUrl,
    referrer,
    campaignId,
    role,
    login,
    showApplicationLoader,
    hideApplicationLoader,
    createSSOLoggingRecord,
}) => {
    const history = useHistory();

    const handleRegisterWithSSO = (event) => {
        event.preventDefault();
        history.replace({ state: { jobOpportunityId, gotoUrl, isFromCandidateRegistration: true } });
        signInWithPopup(jobOpportunityId, gotoUrl, referrer, campaignId, role).then(async (user) => {
            user.isNew && logEvent(AMPLITUDE_EVENT_TYPES.REGISTER, null, { role, type: 'SSO' });
            showApplicationLoader();
            await login(user.email, null, false, true, true, user.credential);
            const homePath = getHomePath(gotoUrl);
            history.push({ pathname: homePath.pathname, state: { jobOpportunityId, gotoUrl, isFromCandidateRegistration: user.isNew } });
            hideApplicationLoader();
        }).catch(err => {
            createSSOLoggingRecord({ message: err.message });
        });
    };

    return (

        <form className={`register-form${blur ? ' blur' : ''}`} autoComplete="off">
            <div className="name-fields">
                <TextField
                    required
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    label="Name"
                    onBlur={handleBlur}
                    error={errors.name && touched.name}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                />
                <ErrorMessage name="name" component="div" className="invalid-field-message" />
            </div>
            <div>
                <TextField
                    required
                    className="register-field email-field"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="register-email"
                    label="Email"
                    error={errors.email && touched.email}
                    margin="normal"
                    variant="outlined"
                />
                <ErrorMessage name="email" component={(name) => <FieldError name={name} />} className="invalid-field-message" />
            </div>
            <div>
                <PasswordInput
                    className="pwd-set-field register-field"
                    name="password"
                    handleChange={handleChange}
                    label="Password"
                    id="pwd-set"
                    error={errors.password}
                    touched={touched.password}
                />
                <ErrorMessage name="password" component="div" className="invalid-field-message" />
            </div>
            <div>
                <PasswordInput
                    className="pwd-set-field register-field"
                    name="confirmPassword"
                    handleChange={handleChange}
                    label="Confirm Password"
                    id="pwd-set-confirm"
                    error={errors.confirmPassword}
                    touched={touched.confirmPassword}
                />
                <ErrorMessage name="confirmPassword" component="div" className="invalid-field-message" />
            </div>
            {!IS_WHOISAVAILABLE_ENV &&
                <div>
                    <TermsAndConditionsCheckBox
                        isTermsAndConditionsAccepted={values.isTermsAndConditionsAccepted}
                        handleChange={handleChange}
                    />
                    <ErrorMessage name="isTermsAndConditionsAccepted" component="div" className="invalid-field-message" />
                </div>
            }
            <SignUpFooter
                handleSubmit={handleSubmit}
                label={motionRedirectLinks.login.label}
                link={motionRedirectLinks.login.link}
                jobOpportunityId={jobOpportunityId}
                handleRegisterWithSSO={handleRegisterWithSSO}
            />
        </form>
    )
};

export default RegisterFormView;

