import React from 'react';
import moment from 'moment';

import TextField from '@material-ui/core/TextField';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MenuItem from '@material-ui/core/MenuItem';
import MomentUtils from '@date-io/moment';

import { profileTypes } from 'components/Person/Profile/ProfileGeneralForm/ProfileGeneralFormConstants';
import { DATE_PICKER_FORMAT_MM_YYYY } from 'constants/timesFormat';
import { DIALOG_COMMENT_INPUT } from 'constants/commonConstants';

const MAX_LENGTH_PROFILE_SUMMARY = 500;

const ImproveGeneralCvFormView = ({
    values,
    errors,
    setFieldValue,
    isEmployee,
}) => {
    return (
        <form className="profile-general-form" autoComplete="off">
            <div className="top-inputs-block">
                <div className="profile-info-form-field">
                    <TextField
                        name="position"
                        value={values.generalInfo.position}
                        onChange={(e) => {
                            setFieldValue('generalInfo.position', e.target.value);
                        }}
                        id="profile-general-position"
                        label="Current position"
                        variant="outlined"
                        fullWidth
                        error={Boolean(errors.position)}
                    />
                    {Boolean(errors.position) && <p className="invalid-field-message">Field is required</p>}
                </div>
                <div className="profile-info-form-field">
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                            autoOk={true}
                            className="start-date-field"
                            label="Experience since"
                            name="experienceSince"
                            disableToolbar
                            disableFuture
                            variant="inline"
                            inputVariant="outlined"
                            fullWidth
                            value={values.generalInfo.experienceSince}
                            onChange={value => {
                                setFieldValue('generalInfo.experienceSince', moment(value));
                            }}
                            openTo="year"
                            views={["year", "month"]}
                            format={DATE_PICKER_FORMAT_MM_YYYY}
                            allowKeyboardControl={true}
                            error={Boolean(errors.experienceSince)}
                        />
                    </MuiPickersUtilsProvider>
                </div>
            </div>

            {isEmployee &&
                <div className="profile-info-form-field profile-type-field">
                    <TextField
                        select
                        className="profile-type-dropdown"
                        name="profileType"
                        variant="outlined"
                        label="Profile Type"
                        onChange={(e) => {
                            setFieldValue('generalInfo.profileType', e.target.value);
                        }}
                        value={values.generalInfo.profileType}
                        error={Boolean(errors.profileType)}>
                        {
                            profileTypes.map((x, i) => (
                                <MenuItem key={i} value={x}>
                                    {x}
                                </MenuItem>
                            ))
                        }
                    </TextField>
                    {Boolean(errors.profileType) && <p className="invalid-field-message">Filed is required</p>}
                </div>
            }

            <div className="profile-info-form-field">
                <TextField
                    name="profileSummary"
                    value={values.generalInfo.profileSummary}
                    onChange={(e) => {
                        setFieldValue('generalInfo.profileSummary', e.target.value);
                    }}
                    id="profile-summary"
                    label="Profile summary"
                    variant="outlined"
                    fullWidth
                    multiline
                    minRows={DIALOG_COMMENT_INPUT.ROWS}
                    inputProps={{
                        maxLength: MAX_LENGTH_PROFILE_SUMMARY
                    }}
                    error={Boolean(errors.profileSummary)}
                />
                {Boolean(errors.profileSummary) && <p className='invalid-field-message'>Filed is required</p>}
            </div>
        </form>
    )
};

export default ImproveGeneralCvFormView;
