import React, { useState, useRef } from 'react';
import { Formik } from 'formik';

import Paper from '@material-ui/core/Paper';

import DisplayImage from 'components/Person/PersonalInfo/DisplayImage';
import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';
import AgencySettingsBasicFormView from './AgencySettingsBasicFormView';

import { validateRequiredFields } from 'utils/formValidations';

import './AgencySettingsBasicForm.scss';
import 'react-image-crop/lib/ReactCrop.scss';

const AgencySettingsBasicForm = ({
    agencyInfo,
    agencyId,
    saveSettings,
    updateAgencyBasicInfo,
    uploadAgencyLogo,
    deleteAgencyLogo,
    countries,
}) => {
    const [showHeaderButtons, setShowHeaderButtons] = useState(false);

    const basicSettingsFormRef = useRef();

    const discardHandlerBasicInfo = () => {
        setShowHeaderButtons(false);
        basicSettingsFormRef.current.values.name = agencyInfo?.name || '';
        basicSettingsFormRef.current.values.country = agencyInfo?.country || '';
        basicSettingsFormRef.current.values.currency = agencyInfo?.currency || '';
    }

    return (
        <div className="agency-settings-wrapper agency-basic-settings">
            <SectionActionsWrapper>
                <SectionActionsWrapper.SectionTitle
                    sectionTitle='Basic'
                />

                {showHeaderButtons && (
                    <SectionActionsWrapper.RightAlignedActions>
                        <SectionActionsWrapper.RightAlignedActions.SecondaryButton
                            text='Discard'
                            onClick={discardHandlerBasicInfo}
                            buttonStyle='discard'
                        />
                        <SectionActionsWrapper.RightAlignedActions.SecondaryButton
                            text='Save'
                            onClick={() => basicSettingsFormRef.current.handleSubmit()}
                            buttonStyle='save'
                        />
                    </SectionActionsWrapper.RightAlignedActions>
                )}
            </SectionActionsWrapper>

            <Paper className="agency-settings-content">
                <div className="agency-settings-content-wrapper">
                    <Formik
                        initialValues={{
                            name: agencyInfo?.name || '',
                            country: agencyInfo?.country || '',
                        }}
                        innerRef={basicSettingsFormRef}
                        validate={values => {
                            return { ...validateRequiredFields(values, ['name']) };
                        }}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                            setSubmitting(false);
                            saveSettings(values, updateAgencyBasicInfo, resetForm);
                            setShowHeaderButtons(false);
                        }}
                        enableReinitialize={true}
                    >
                        {(props) =>
                            <AgencySettingsBasicFormView {...props}
                                agencyInfo={agencyInfo}
                                countries={countries}
                                handleShowHeaderButtons={() => setShowHeaderButtons(true)}
                            />
                        }
                    </Formik>

                    <div className="basic-settings-logo">
                        <DisplayImage
                            imageUrl={agencyInfo.logoImgUrl}
                            imageDetails={agencyInfo.logoImgDetails}
                            handleUpdateAvatar={uploadAgencyLogo}
                            handleDeleteAvatar={deleteAgencyLogo}
                            entityId={agencyId}
                        />
                    </div>
                </div>
            </Paper>
        </div>
    )
};

export default AgencySettingsBasicForm;
