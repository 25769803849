import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getSuppliersAccountManagers, getAgencyId } from 'reducers';

import {
    fetchSuppliers,
    fetchSuppliersManagers,
    registerSupplier,
    exportSuppliers,
    updateSupplierFlag
} from 'actions/supplierActions';
import { disableUsers } from 'actions/userActions';

import SuppliersTable from './SuppliersTable';
import AddSupplierDialog from 'components/Suppliers/AddSupplierDialog';
import ConfirmInviteUserDialog from 'components/Shared/ConfirmInviteUserDialog';
import ConfirmDeleteDialog from 'components/Shared/ConfirmDeleteDialog';

import { ROLES } from 'constants/userConstants';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitudeConstants';
import { DEFAULT_TABLE_COLUMNS, TABLE_QUERY_MAP } from './SuppliersTable/SuppliersTableConstants';

import { logEvent } from 'utils/amplitude';
import { parseQueryString } from 'utils/helpers';

import './Suppliers.scss';

const Suppliers = ({
    agencyId,
    suppliersAccountManagers,
    fetchSuppliers,
    fetchSuppliersManagers,
    registerSupplier,
    disableUsers,
    exportSuppliers,
    updateSupplierFlag,
}) => {
    const history = useHistory();
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [openConfirmRemoveDialog, setOpenConfirmRemoveDialog] = useState(false);
    const [newUserData, setNewUserData] = useState({});
    const [supplierToRemove, setSupplierToRemove] = useState({});
    const [reload, setReload] = useState(false);

    const handleOpenAddDialog = () => {
        logEvent(AMPLITUDE_EVENT_TYPES.CLICK_ADD_SUPPLIER);
        setOpenAddDialog(true);
    };

    const handleCloseAddDialog = () => setOpenAddDialog(false);

    const handleDeleteClick = supplier => {
        setSupplierToRemove(supplier)
        setOpenConfirmRemoveDialog(true);
    };

    const handleCloseConfirmDialog = () => setOpenConfirmDialog(false);

    const handleCloseConfirmRemoveDialog = () => {
        setOpenConfirmRemoveDialog(false);
        setSupplierToRemove({});
    };

    const handleAddSubmit = async (values) => {
        if (values.email) {
            setNewUserData({
                ...values,
                employmentInformation: { generalInfo: { isAvailable: false, isVetted: false } }
            });
            setOpenConfirmDialog(true);
            return;
        }

        await registerSupplier(agencyId,
            {
                ...values,
                employmentInformation: { generalInfo: { isAvailable: false, isVetted: false } }
            },
            false
        );
        setReload(true);
        handleCloseAddDialog();
    };

    const handleConfirmInviteSubmit = async () => {
        handleCloseConfirmDialog();
        await registerSupplier(agencyId, newUserData, false);
        setReload(true);
        handleCloseAddDialog();
        setNewUserData({});
    };

    const handleConfirmDeleteSubmit = async () => {
        await disableUsers([supplierToRemove._id], ROLES.SUPPLIER_ADMIN, false);
        setReload(true);
        setOpenConfirmRemoveDialog(false);
        setSupplierToRemove({});
    };

    const handleExportSuppliers = (filters) => {
        const parsedQueryFromUrl = parseQueryString(history.location.search, true);
        const parsedQueryFilters = parsedQueryFromUrl[TABLE_QUERY_MAP['filters']];

        const appliedFilters = {};

        if (parsedQueryFilters) {
            for (const query of parsedQueryFilters) {
                const filter = filters.find(x => x.value.includes(query)) || null;
                if (filter) {
                    if (appliedFilters[filter.key]) {
                        appliedFilters[filter.key].push(query);
                    } else {
                        appliedFilters[filter.key] = [query];
                    }
                }
            }
        }

        exportSuppliers(agencyId, DEFAULT_TABLE_COLUMNS.reduce((acc, x) => ({ ...acc, [x]: true }), {}), JSON.stringify([appliedFilters]));
    };

    const sortAlphabetically = (a, b) => a.name.localeCompare(b.name);

    const actions = [
        { title: 'Delete', handleClick: handleDeleteClick },
    ];

    const getActionsHandler = () => actions;

    return (
        <div className="suppliers-table-wrapper">
            <SuppliersTable
                suppliersAccountManagers={suppliersAccountManagers}
                tableFetchAction={fetchSuppliers.bind(null, agencyId)}
                tablePrefetchAction={fetchSuppliersManagers.bind(null, agencyId)}
                reload={reload}
                setReload={setReload}
                getActionsHandler={getActionsHandler}
                handleOpenAddDialog={handleOpenAddDialog}
                handleExportSuppliers={handleExportSuppliers}
                updateSupplierFlag={updateSupplierFlag}
            />

            <AddSupplierDialog
                agencyId={agencyId}
                openDialog={openAddDialog}
                handleSubmitClick={handleAddSubmit}
                handleCloseDialog={handleCloseAddDialog}
                sortAlphabetically={sortAlphabetically}
            />

            <ConfirmInviteUserDialog
                invitedUser={'partner'}
                openDialog={openConfirmDialog}
                handleInviteUser={handleConfirmInviteSubmit}
                handleCloseDialog={handleCloseConfirmDialog}
            />

            <ConfirmDeleteDialog
                openDialog={openConfirmRemoveDialog}
                itemToDelete={supplierToRemove.name}
                handleDeleteItem={handleConfirmDeleteSubmit}
                handleCloseDialog={handleCloseConfirmRemoveDialog}
            />
        </div>
    );
}

const mapStateToProps = state => ({
    agencyId: getAgencyId(state),
    suppliersAccountManagers: getSuppliersAccountManagers(state),
});

const mapDispatchToProps = {
    fetchSuppliers,
    registerSupplier,
    disableUsers,
    exportSuppliers,
    updateSupplierFlag,
    fetchSuppliersManagers,
};

export default connect(mapStateToProps, mapDispatchToProps)(Suppliers);
