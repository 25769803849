import React from 'react';
import moment from 'moment';

import KPIList from 'components/Shared/KPIList';
import { CURRENT_QUARTER_YEAR_FORMAT } from 'constants/commonConstants';

import './ReportsKPIs.scss';

const ReportsKPIs = ({
    openJobs,
    publishedJobs,
    jobsWithNoApplications,
    timeToFillPosition,
    averageTimeToHire,
    hiredUsers,
    applicantPipelineProfiles,
    dataFetchState,
}) => {
    const reportKPIsData = [
        { title: 'Open Jobs', amount: openJobs },
        { title: 'Open Published Jobs', amount: publishedJobs },
        { title: 'Open Jobs without Application', amount: jobsWithNoApplications },
        { title: 'Applicant Pipeline', amount: applicantPipelineProfiles },
        {
            title: 'Time to Fill',
            amount: Number.isInteger(timeToFillPosition?.averageDaysCurrentQuarter)
                ? timeToFillPosition?.averageDaysCurrentQuarter
                : 'N/A',
            percentageChange: timeToFillPosition?.percentageChange,
        },
        {
            title: 'Time to Hire',
            amount: Number.isInteger(averageTimeToHire?.averageDaysCurrentQuarter)
                ? averageTimeToHire?.averageDaysCurrentQuarter
                : 'N/A',
            percentageChange: averageTimeToHire?.percentageChange,
        },
        {
            title: `Hired Candidates ${moment().format(CURRENT_QUARTER_YEAR_FORMAT)}`,
            amount: Number.isInteger(hiredUsers?.currentQuarterHiredUsers)
                ? hiredUsers?.currentQuarterHiredUsers
                : 'N/A',
            percentageChange: hiredUsers?.percentageChange,
        }
    ];

    return (
        <div className="reports-kpis-wrapper">
            <KPIList
                data={reportKPIsData}
                dataFetchState={dataFetchState}
            />
        </div>
    );
}

export default ReportsKPIs;
