import React from 'react';
import moment from 'moment';

import Avatar from '@material-ui/core/Avatar';
import './CvProfileInformation.scss';

const CvProfileInformation = ({
    profileInformation
}) => {
    return (
        <>
            <div className="cv-profile-information">
                <div className="intro-container">
                    <h1>{profileInformation.firstName} {profileInformation.lastName}</h1>
                    <h3>{profileInformation.position}</h3>
                    {profileInformation.experienceSince && profileInformation.experienceSince !== ''
                        && <p>{moment().diff(moment(profileInformation.experienceSince), 'years')} years of experience</p>
                    }
                </div>
                <div className="avatar-img-wrapper">
                    <Avatar
                        alt="avatar"
                        className="avatar-img"
                        src={profileInformation.profilePictureImgUrl}
                    />
                </div>
            </div>
        </>
    )
}

export default CvProfileInformation;
