import React, { useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ExternalJobIdLabel from 'components/Shared/ExternalJobIdLabel';
import JobTypeLabel from 'components/JobsDashboard/SharedComponents/JobTypeLabel';

import { DATE_FORMAT_WITH_DOTS_FULL_YEAR } from 'constants/commonConstants';

import { ReactComponent as CompanyIcon } from 'assets/icon-company.svg';
import { ReactComponent as DateAddedIcon } from 'assets/icon-date-added.svg';
import { ReactComponent as AccountIcon } from 'assets/icon-account.svg';
import { ReactComponent as LocationIcon } from 'assets/icon-location.svg';

import { getStateAgencyName } from 'utils/helpers';

import './JobDetailsHeader.scss';

const JobDetailsHeader = ({ jobDetails, goToSingleJobPage, isAdminMode }) => {
    const [showJobDetails, setShowJobDetails] = useState(false);
    const toggleJobDetails = () => setShowJobDetails(state => !state);

    return (
        <div className="candidate-job-details-page-header">
            <JobTypeLabel jobType={jobDetails.jobType} />
            <div className="job-info">
                <div className="job-title-wrapper">
                    <h3 onClick={goToSingleJobPage} className="job-title">{jobDetails?.position}</h3>
                    <div className="job-info-toggle" onClick={toggleJobDetails}>
                        {(isAdminMode && jobDetails.externalJobId) && <ExternalJobIdLabel externalJobId={jobDetails.externalJobId} />}
                        <p>{showJobDetails ? 'Show less information' : 'Show full job information'}</p>
                        {showJobDetails
                            ? <KeyboardArrowDownIcon className="toggle-arrow" />
                            : <KeyboardArrowLeftIcon className="toggle-arrow" />
                        }
                    </div>
                </div>
                {isAdminMode
                    ? <>
                        <div className="row-items justify-start">
                            <div className="row-item">
                                <CompanyIcon />
                                <Link to={`/${getStateAgencyName()}/clients/${jobDetails.client._id}`}>
                                    <span>{jobDetails.client?.name}</span>
                                </Link>
                            </div>
                            <div className="row-item">
                                <DateAddedIcon />
                                <span>{moment(jobDetails.dateAdded).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR)}</span>
                            </div>
                            <div className="row-item">
                                <AccountIcon />
                                <span>{jobDetails.creator?.name}</span>
                            </div>
                        </div>
                        <div className="row-item location">
                            <LocationIcon />
                            <span>Remote {jobDetails.geographicLocations?.length > 0 && `(${jobDetails.geographicLocations.join(', ')})`}</span>
                        </div>
                    </>
                    : <>
                        <div className="row-items justify-start">
                            <div className="row-item">
                                <DateAddedIcon />
                                <span>{moment(jobDetails.dateAdded).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR)}</span>
                            </div>
                            <div className="row-item">
                                <LocationIcon />
                                <span>Remote {jobDetails.geographicLocations?.length > 0 && `(${jobDetails.geographicLocations.join(', ')})`}</span>
                            </div>
                        </div>
                    </>
                }
            </div>
            {showJobDetails &&
                <div className="full-job-details-body">
                    {
                        jobDetails.aboutTheClient &&
                        <div className="job-section">
                            <Typography className="job-section-title" variant="body2" >
                                About the company
                            </Typography>
                            <p className="job-section-text">{jobDetails.aboutTheClient}</p>
                        </div>
                    }
                    {
                        jobDetails.jobSummary &&
                        <div className="job-section">
                            <Typography className="job-section-title" variant="body2" >
                                About the job
                            </Typography>
                            <p className="job-section-text">{jobDetails.jobSummary}</p>
                        </div>
                    }
                    {jobDetails.mandatorySkills?.length > 0 && (
                        <div className="job-section">
                            <Typography className="job-section-title" variant="body2">
                                Mandatory Skills
                            </Typography>
                            <div className="skills-container">
                                {jobDetails.mandatorySkills.map((x) => (
                                    <div key={x.name} className="skill-container">
                                        <span className="skill">{x.name}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    {jobDetails.niceToHaveSkills?.length > 0 && (
                        <div className="job-section">
                            <Typography className="job-section-title" variant="body2">
                                Nice To Have
                            </Typography>
                            <div className="skills-container">
                                {jobDetails.niceToHaveSkills.map((x) => (
                                    <div key={x.name} className="skill-container">
                                        <span className="skill">{x.name}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    {
                        jobDetails.aboutCandidate &&
                        <div className="job-section">
                            <Typography className="job-section-title" variant="body2" >
                                About the candidate
                            </Typography>
                            <p className="job-section-text">{jobDetails.aboutCandidate}</p>
                        </div>
                    }
                    {
                        jobDetails.requirements &&
                        <div className="job-section">
                            <Typography className="job-section-title" variant="body2" >
                                Requirements
                            </Typography>
                            <p className="job-section-text">{jobDetails.requirements}</p>
                        </div>
                    }
                    {
                        jobDetails.benefits &&
                        <div className="job-section">
                            <Typography className="job-section-title" variant="body2" >
                                Additional benefits
                            </Typography>
                            <p className="job-section-text">{jobDetails.benefits}</p>
                        </div>
                    }
                </div>
            }
        </div>
    )
}

export default JobDetailsHeader;
