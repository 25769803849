import React from 'react';
import moment from 'moment';
import { ErrorMessage } from 'formik';

import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { KeyboardDatePicker } from '@material-ui/pickers';

import isHoliday from 'hoc/isHoliday';

import { DATE_FORMAT_WITH_DOTS_FULL_YEAR, MAX_DATE } from 'constants/commonConstants';
import { ROLES } from 'constants/userConstants';
import { EMPLOYMENT_TYPES } from 'constants/employeeConstants';
import { HOLIDAY_TYPES_MAP, HOLIDAY_ARTICLES, DEPARTMENT_VIEW_KEY } from './HolidayRequestDialogConstants';

import './HolidayRequestFormView.scss';

const HolidayRequestFormView = ({
    values,
    handleChange,
    errors,
    touched,
    setFieldValue,
    displayMode,
    employmentType,
    isDepartmentView,
}) => (
    <form className="request-holiday-form" autoComplete="off">
        <div className="date-fields-block">
            <div className="start-date-container">
                <KeyboardDatePicker
                    autoOk={true}
                    required
                    name="startDate"
                    className="start-date-field"
                    label="From"
                    disableToolbar
                    maxDate={values.endDate || MAX_DATE}
                    disablePast={displayMode !== ROLES.ADMIN && !isDepartmentView}
                    variant="inline"
                    inputVariant="outlined"
                    fullWidth
                    value={values.startDate}
                    onChange={value => {
                        setFieldValue('startDate', moment(value));
                    }}
                    renderDay={isHoliday}
                    format={DATE_FORMAT_WITH_DOTS_FULL_YEAR}
                    allowKeyboardControl={true}
                />
                <ErrorMessage name="startDate" component="div" className="invalid-field-message" />
            </div>
            <div className="end-date-container">
                <KeyboardDatePicker
                    autoOk={true}
                    required
                    name="endDate"
                    className="end-date-field"
                    label="To"
                    disableToolbar
                    minDate={values.startDate || new Date()}
                    variant="inline"
                    inputVariant="outlined"
                    fullWidth
                    value={values.endDate}
                    onChange={value => {
                        setFieldValue('endDate', moment(value));
                    }}
                    format={DATE_FORMAT_WITH_DOTS_FULL_YEAR}
                    renderDay={isHoliday}
                    allowKeyboardControl={true}
                />
                <ErrorMessage name="endDate" component="div" className="invalid-field-message" />
            </div>

        </div>
        <div className='holiday-type-field'>
            <TextField
                select
                name="type"
                label="Type"
                value={values.type}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                error={errors.type && touched.type}
                inputProps={{ 'data-testid': "holiday-type-field" }}
            >
                {HOLIDAY_TYPES_MAP[isDepartmentView ? DEPARTMENT_VIEW_KEY : displayMode][employmentType || EMPLOYMENT_TYPES.FULLTIME_CONTRACT].map(x => (
                    <MenuItem key={x.value} value={x.value}>
                        {x.value}
                    </MenuItem>
                ))}
            </TextField>
            <ErrorMessage name="type" component="div" className="invalid-field-message" />
        </div>

        {values.type === 'Paid' &&
            <div className='holiday-type-field'>
                <TextField
                    select
                    name="byArticle"
                    label="By Article"
                    value={values.byArticle}
                    onChange={handleChange}
                    variant="outlined"
                    fullWidth
                    error={errors.byArticle && touched.byArticle}
                    inputProps={{ 'data-testid': "holiday-article-field" }}
                >
                    {HOLIDAY_ARTICLES[isDepartmentView ? DEPARTMENT_VIEW_KEY : displayMode][employmentType || EMPLOYMENT_TYPES.FULLTIME_CONTRACT].map(x => (
                        <MenuItem key={x.value} value={x.value}>
                            {x.value}
                        </MenuItem>
                    ))}
                </TextField>
                <ErrorMessage name="byArticle" component="div" className="invalid-field-message" />
            </div>
        }

        <div className='reason-field'>
            <TextField
                name="reason"
                value={values.reason}
                onChange={handleChange}
                label="Reason"
                error={errors.reason && touched.reason}
                margin="normal"
                variant="outlined"
                fullWidth
            />
            <ErrorMessage name="reason" component="div" className="invalid-field-message" />
        </div>
    </form>
);

export default HolidayRequestFormView;
