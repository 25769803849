import React, { useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { withRouter } from 'react-router-dom';

import { getBookedHolidaysCountForEmployee, } from 'reducers';

import TableWrapper from 'components/Shared/TableWrapper';
import HolidayRejectDialog from 'components/Person/Holidays/PendingHolidaysTable/HolidayRejectDialog';
import Avatar from '@material-ui/core/Avatar';

import {
	mapStateToPropsPicker,
	mapDispatchToPropsPicker,
	getBrowserLocation,
} from '../TeamHolidaysUtils';
import { usePreloadTable } from 'utils/hooks';


import { DATE_FORMAT_WITH_DASH, DATE_FORMAT_WITH_DOTS_FULL_YEAR } from 'constants/commonConstants.js';
import {
	TABLE_COLUMNS,
	DEFAULT_TABLE_COLUMNS,
	TABLE_FILTERS,
	SORT_QUERY_SELECTOR,
	PAGE_QUERY_SELECTOR,
	SEARCH_QUERY_SELECTOR,
	DEFAULT_ITEMS_PER_PAGE,
	TABLE_INITIAL_QUERY,
	TABLE_QUERY_MAP,
} from './PendingTeamHolidaysTableConstants';

import './PendingTeamHolidaysTable.scss';

const PendingTeamHolidaysTable = ({
	userId,
	agencyId,
	generateToString,
	tableFetchAction,
	pendingReload,
	setPendingReload,
	setHistoryReload,
	history,
	approveHolidayRequests,
	rejectHolidayRequest,
}) => {
	const [isRejectHolidayDialogOpen, setIsRejectHolidayDialogOpen] = useState(false);
	const [targetHoliday, setTargetHoliday] = useState({});

	const [checkedRows, setCheckedRows] = useState([]);

	const browserLocation = getBrowserLocation(history);
	const { pendingHolidays: getPendingHolidays } = mapStateToPropsPicker(browserLocation);

	const dataSelector = (state, page) => getPendingHolidays(state, page - 1, DEFAULT_ITEMS_PER_PAGE);
	const [isTableDataLoading, totalCount] = usePreloadTable(
		TABLE_INITIAL_QUERY,
		TABLE_QUERY_MAP,
		TABLE_FILTERS,
		tableFetchAction,
		pendingReload,
		setPendingReload,
	);

	const handleApproveHolidayRequest = async holiday => {
		await approveHolidayRequests(
			agencyId,
			[holiday],
			moment().format(DATE_FORMAT_WITH_DASH),
			moment().add(14, 'day').format(DATE_FORMAT_WITH_DASH),
			userId,
			false,
		);
		setPendingReload(true);
		setHistoryReload(true);
	};

	const handleApproveSelectedRequests = async () => {
		await approveHolidayRequests(
			agencyId,
			checkedRows,
			moment().format(DATE_FORMAT_WITH_DASH),
			moment().add(14, 'day').format(DATE_FORMAT_WITH_DASH),
			userId,
			false,
		);
		setPendingReload(true);
		setHistoryReload(true);
		setCheckedRows([]);
	};

	const handleOpenRejectHolidayDialog = holiday => {
		setTargetHoliday(holiday);
		setIsRejectHolidayDialogOpen(true);
	};

	const handleCloseRejectHolidayDialog = () => {
		setTargetHoliday({});
		setIsRejectHolidayDialogOpen(false);
	};

	const handleRowClick = (item) => history.push(generateToString(item));

	const handleHolidayReject = async rejectionReason => {
		await rejectHolidayRequest(
			agencyId,
			{ _id: targetHoliday._id, employeeId: targetHoliday.employeeId, rejectionReason, },
			userId,
			false
		);
		setPendingReload(true);
		setHistoryReload(true);
		handleCloseRejectHolidayDialog();
	};

	const adjustPendingHolidaysKeys = pendingHolidays => pendingHolidays.map(pendingHoliday => {
		return {
			...pendingHoliday,
			_id: pendingHoliday._id,
			employeeId: pendingHoliday.employeeId,
			employeeName: pendingHoliday.name,
			name: (
				<div className="logo-name-wrapper" onClick={() => handleRowClick(pendingHoliday)}>
					<Avatar
						alt="Empoyee Avatar"
						className="avatar-logo-image"
						src={pendingHoliday.profilePictureImgUrl}
					/>
					<div className="name-text">
						{pendingHoliday.name}
					</div>
				</div>
			),
			from: moment(pendingHoliday.from).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR),
			to: moment(pendingHoliday.to).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR),
			numberOfDays: pendingHoliday.numberOfDays,
			type: pendingHoliday.type === 'Paid' && pendingHoliday.byArticle ? `${pendingHoliday.type} Art. ${pendingHoliday.byArticle}` : pendingHoliday.type,
			item: pendingHoliday,
		};
	});

	const actions = [
		{ title: 'Approve', handleClick: handleApproveHolidayRequest },
		{ title: 'Reject', handleClick: handleOpenRejectHolidayDialog },
	];

	const getActionsHandler = () => actions;

	return (
		<div className="pending-team-holidays-table-container">
			<TableWrapper
				title="Pending Holidays"
				columns={TABLE_COLUMNS}
				defaultColumns={DEFAULT_TABLE_COLUMNS}
				dataSelector={dataSelector}
				sortQuerySelector={SORT_QUERY_SELECTOR}
				adjustKeysFunction={adjustPendingHolidaysKeys}
				isTableDataLoading={isTableDataLoading}
				addMargin
				withPagination
				withActions
				withCheckboxes
				checkedRows={checkedRows}
				setCheckedRows={setCheckedRows}
				getActionsHandler={getActionsHandler}
				pagesCount={Math.ceil(totalCount / DEFAULT_ITEMS_PER_PAGE)}
				pageQuerySelector={PAGE_QUERY_SELECTOR}
			>
				<TableWrapper.TableAlignedItems>
					<TableWrapper.TableAlignedItems.LeftAligned>
						<TableWrapper.TableAlignedItems.TableHeader />
						<TableWrapper.TableAlignedItems.TableFilter filters={TABLE_FILTERS} />
						<TableWrapper.TableAlignedItems.TableSearch queryParamName={SEARCH_QUERY_SELECTOR} />
					</TableWrapper.TableAlignedItems.LeftAligned>
					<TableWrapper.TableAlignedItems.RightAligned>
						{checkedRows.length > 0 &&
							<TableWrapper.TableAlignedItems.TableActionButton text="Approve" handleClick={handleApproveSelectedRequests} />
						}
					</TableWrapper.TableAlignedItems.RightAligned>
				</TableWrapper.TableAlignedItems>
				<TableWrapper.TableContent />
			</TableWrapper>

			<HolidayRejectDialog
				openDialog={isRejectHolidayDialogOpen}
				employeeInfo={targetHoliday}
				handleCloseDialog={handleCloseRejectHolidayDialog}
				handleHolidayReject={handleHolidayReject}
				bookedHolidaysCountSelector={getBookedHolidaysCountForEmployee}
			/>
		</div>
	);
};

const mapDispatchToProps = (dispatch, ownProps) => {
	const browserLocation = getBrowserLocation(ownProps.history);
	const dispatchToPropsPicker = mapDispatchToPropsPicker(browserLocation)
	return {
		approveHolidayRequests: (...args) => dispatch(dispatchToPropsPicker.approveHolidayRequests(...args)),
		rejectHolidayRequest: (...args) => dispatch(dispatchToPropsPicker.rejectHolidayRequest(...args)),
	}
};


export default withRouter(connect(null, mapDispatchToProps)(PendingTeamHolidaysTable));
