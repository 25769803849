import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';

import { setReferrer, getReferrer, setGCLID, getGCLID } from 'utils/queryUtils';

import { registerClient, login } from 'actions/authActions';
import { fetchAllCountries } from 'actions/staticDataActions';
import { getCountries } from 'reducers';

import RegisterClientMobileComponent from './RegisterClientMobileComponent';
import RegisterClientDesktopComponent from './RegisterClientDesktopComponent';

import { IS_WHOISAVAILABLE_ENV } from 'constants/env';

import './RegisterClient.scss';

const RegisterClient = ({
    registerClient,
    login,
    countries,
    fetchAllCountries,
}) => {
    const history = useHistory();

    useEffect(() => {
        fetchAllCountries();
    }, []);

    setReferrer(history.location?.search);
    setGCLID(history.location?.search);
    const referrer = getReferrer();
    const gclid = getGCLID();

    const initialValues = IS_WHOISAVAILABLE_ENV
        ? { name: '', contactPerson: '', email: '', country: '', password: '', confirmPassword: '', }
        : { name: '', contactPerson: '', email: '', country: '', password: '', confirmPassword: '', isTermsAndConditionsAccepted: false, }

    if (isMobile) {
        return (
            <RegisterClientMobileComponent
                referrer={referrer}
                gclid={gclid}
                initialValues={initialValues}
                registerClient={registerClient}
                login={login}
                countries={countries}
            />
        )
    }

    return (
        <RegisterClientDesktopComponent
            referrer={referrer}
            gclid={gclid}
            initialValues={initialValues}
            registerClient={registerClient}
            login={login}
            countries={countries}
        />
    );
};

const mapStateToProps = (state) => ({
    countries: getCountries(state),
});

const mapDispatchToProps = {
    registerClient,
    login,
    fetchAllCountries,
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterClient);
