import React from 'react';

import { Formik } from 'formik';

import Dialog from 'components/Shared/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import MomentUtils from '@date-io/moment';
import EditStatusFormView from './EditStatusFormView';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { validateRequiredFields, validateDecimalNumbers } from 'utils/formValidations';
import { INVOICE_STATUSES_STRINGS } from '../BillingTable/BillingTableConstants';

import { addUtcOffset } from 'utils/formatTimes';

import './EditStatusDialog.scss';

const EditStatusDialog = ({
    agencyId,
    openDialog,
    handleCloseDialog,
    invoiceStatusData,
    updateInvoiceStatus,
}) => {
    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <Formik
                initialValues={{
                    status: invoiceStatusData?.status || '',
                    amountPaid: invoiceStatusData?.amountPaid || invoiceStatusData?.amount || 0,
                    currencyPaid: invoiceStatusData?.currencyPaid || invoiceStatusData?.currency || '',
                    paymentDate: invoiceStatusData?.paymentDate || null,
                }}
                validate={values => {

                    if (values.status === INVOICE_STATUSES_STRINGS.PAID) {
                        const valuesToValidateNumber = { ...values };
                        valuesToValidateNumber.amountPaid = values.amountPaid.toString();

                        return {
                            ...validateRequiredFields(values, ['status', 'amountPaid', 'currencyPaid', 'paymentDate']),
                            ...validateDecimalNumbers(valuesToValidateNumber, ['amountPaid']),
                        }
                    } else {
                        return { ...validateRequiredFields(values, ['status']) }
                    }
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false);

                    if (values.status === INVOICE_STATUSES_STRINGS.PAID) {
                        values.amountPaid = values.amountPaid.toString();
                        while (values.amountPaid.includes(',')) {
                            values.amountPaid = values.amountPaid.replace(',', '.');
                        }
                        const statusData = { ...values, amountPaid: parseFloat(values.amountPaid), paymentDate: addUtcOffset(values.paymentDate) };
                        updateInvoiceStatus(agencyId, invoiceStatusData._id, statusData);
                    } else {
                        updateInvoiceStatus(agencyId, invoiceStatusData._id, { status: values.status, amountPaid: '', currencyPaid: '', paymentDate: '' });
                    }

                    handleCloseDialog();
                }}
            >
                {(props) =>
                    <Dialog className="edit-invoice-status-dialog" open={openDialog} onClose={handleCloseDialog} maxWidth={'md'} onDragEnter={() => void 0}>
                        <MuiDialogTitle disableTypography className="dialog-title">
                            <Typography variant="body2" className="dialog-title-text">Edit Status</Typography>

                            <IconButton className="dialog-close-btn" onClick={handleCloseDialog}>
                                <CloseIcon />
                            </IconButton>
                        </MuiDialogTitle>

                        <DialogContent className="dialog-content">
                            <EditStatusFormView {...props}
                                handleCloseDialog={handleCloseDialog}
                            />
                        </DialogContent>
                    </Dialog>
                }
            </Formik>
        </MuiPickersUtilsProvider>
    );
};

export default EditStatusDialog;
