import React from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import { login } from 'actions/authActions';
import { getLoginLoading } from 'reducers';

import LoginDesktopComponent from './LoginDesktopComponent';
import LoginMobileComponent from './LoginMobileComponent';


import { ENVIRONMENTS } from 'constants/env';

import './Login.scss';

const Login = ({
    login,
    loginLoading,
}) => {
    const location = useLocation();
    const gotoUrl = location.state?.gotoUrl;
    const signUpButtonLink = process.env.REACT_APP_ENV === ENVIRONMENTS.WHOISAVAILABLE ? '/register/agency' : '/register';

    if (isMobile) {
        return (
            <LoginMobileComponent 
                gotoUrl={gotoUrl}
                signUpButtonLink={signUpButtonLink}
                loginLoading={loginLoading}
                login={login}
            />
        )
    }

    return (
        <LoginDesktopComponent
            gotoUrl={gotoUrl}
            signUpButtonLink={signUpButtonLink}
            loginLoading={loginLoading}
            login={login}
        />
    );
};

const mapStateToProps = state => ({
    loginLoading: getLoginLoading(state),
});

const mapDispatchToProps = {
    login,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
