import React from 'react';

import {
    LinkedinShareButton,
} from "react-share";

import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Typography } from '@material-ui/core';
import { ReactComponent as ShareArrowIcon } from 'assets/share-arrow-icon.svg';
import { ReactComponent as CopyTrackingLinkArrow } from 'assets/copy-tracking-url-icon.svg';

import './ShareLinkedinPopup.scss';

const ShareLinkedinPopup = ({
    anchorEl,
    handleClose,
    url,
    trackingUrl,
    handleAddToClipboard,
}) => {
    return (
        <div className="share-linkedin-popup-container">
            <Popover
                className="share-linkedin-options-popover"
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <List className="popup-list">
                    <LinkedinShareButton url={url}>
                        <ListItem button className="popover-list-item">
                            <Typography className="list-item-text" />
                            <span className="share-popover-icon"><ShareArrowIcon /></span>
                            <span className="share-popover-text">Share to Profile</span>
                            <Typography />
                        </ListItem>
                    </LinkedinShareButton>
                    <ListItem button className="popover-list-item" onClick={() => handleAddToClipboard(trackingUrl)}>
                        <Typography className="list-item-text" />
                        <span className="share-popover-icon"><CopyTrackingLinkArrow /></span>
                        <span className="share-popover-text">Copy Tracking URL</span>
                        <Typography />
                    </ListItem>
                </List>
            </Popover>
        </div>
    )
}

export default ShareLinkedinPopup;
