import { EMAIL_VALIDATION_PATTERN, MAX_DATE, MIN_DATE, URL_VALIDATIONS, MAX_DESCRIPTION_LENGTH } from 'constants/commonConstants';
import moment from 'moment';

export const validateRequiredFields = (values, requiredFields) =>
    requiredFields
        .filter(x => {
            if (typeof values[x] === 'string') {
                return !values[x].trim().length > 0;
            }

            if (x.includes('.')) {
                return !x.split('.').reduce(function (prev, curr) {
                    return prev ? prev[curr] : null;
                }, values);
            }

            if (typeof values[x] === 'number') {
                return false;
            }

            return !values[x];
        })
        .reduce((a, b) => ({ ...a, [b]: 'The field is required.' }), {});

const mod97 = string => {
    let checksum = string.slice(0, 2);
    let fragment;
    for (let offset = 2; offset < string.length; offset += 7) {
        fragment = String(checksum) + string.substring(offset, offset + 7);
        checksum = parseInt(fragment, 10) % 97;
    }
    return checksum;
};

export const validateIban = (values, ibanInput) => {
    const errors = {};

    const CODE_LENGTHS = {
        AD: 24, AE: 23, AT: 20, AZ: 28, BA: 20, BE: 16, BG: 22, BH: 22, BR: 29,
        CH: 21, CR: 21, CY: 28, CZ: 24, DE: 22, DK: 18, DO: 28, EE: 20, ES: 24,
        FI: 18, FO: 18, FR: 27, GB: 22, GI: 23, GL: 18, GR: 27, GT: 28, HR: 21,
        HU: 28, IE: 22, IL: 23, IS: 26, IT: 27, JO: 30, KW: 30, KZ: 20, LB: 28,
        LI: 21, LT: 20, LU: 20, LV: 21, MC: 27, MD: 24, ME: 22, MK: 19, MR: 27,
        MT: 31, MU: 30, NL: 18, NO: 15, PK: 24, PL: 28, PS: 29, PT: 25, QA: 29,
        RO: 24, RS: 22, SA: 24, SE: 24, SI: 19, SK: 24, SM: 27, TN: 24, TR: 26
    };

    const iban = String(values[ibanInput]).toUpperCase().replace(/[^A-Z0-9]/g, ''); // keep only alphanumeric characters
    const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/); // match and capture (1) the country code, (2) the check digits, and (3) the rest
    // check syntax and length
    if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
        errors[ibanInput] = 'Invalid IBAN';
        return errors;
    }
    // rearrange country code and check digits, and convert chars to ints
    const digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, (letter) => {
        return letter.charCodeAt(0) - 55;
    });
    // final check
    if (mod97(digits) !== 1) {
        errors[ibanInput] = 'Invalid IBAN';
        return errors;
    }

    if (mod97(digits) === 1) {
        return errors;
    }
};

export const validateEmailAddress = (values, email) => {
    const errors = {};

    if (values[email] && !values[email].match(EMAIL_VALIDATION_PATTERN)) {
        errors[email] = 'Invalid email address.';
    }

    return errors;
};

export const validateEmailAddressesString = (values, emailsField) => {
    const errors = {};

    const emails = values[emailsField].split(',');

    for (let i = 0; i < emails.length; i++) {
        if (emails[i] && !emails[i].match(EMAIL_VALIDATION_PATTERN)) {
            errors[emailsField] = 'Invalid email address.';
            break;
        }
    }

    return errors;
};

export const validateCountryOfTaxResidence = (values, country) => {
    const errors = {};

    if (!values[country]) {
        errors[country] = 'Please select your country of residence'
    }

    return errors;
}

export const validateUrlAddress = (values, documentUrl, canBeEmpty) => {
    const errors = {};

    if (values[documentUrl] === '' && canBeEmpty) { return; }

    if (!values[documentUrl].match(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/)) {
        errors[documentUrl] = 'Invalid URL address';
    }

    return errors;
};

export const validateLinkedInURL = (values, prop) => {
    const errors = {};
    if (prop && !values[prop].match(URL_VALIDATIONS.LINKED_IN)) {
        errors[prop] = 'Invalid LinkedIn URL adress';
    }
    return errors;
};

export const validateGitHubURL = (values, prop) => {
    const errors = {};
    if (prop && !values[prop].match(URL_VALIDATIONS.GITHUB)) {
        errors[prop] = 'Invalid GitHub URL adress';
    }

    return errors;
};

export const validateCalenldyURL = (values, prop) => {
    const errors = {};
    if (!values[prop].match(URL_VALIDATIONS.CALENDLY)) {
        errors[prop] = 'Invalid Calendly URL adress';
    }

    return errors;
};

export const validatePasswordEquality = (values, password, repeatedPassword) => {
    const errors = {};

    if (values[password] &&
        values[repeatedPassword] &&
        (values[password] !== values[repeatedPassword])) {
        errors[repeatedPassword] = 'Your passwords do not match.';
    }

    return errors;
};

export const validateTermsAndConditions = (values, termsAndConditions) => {
    const errors = {};

    if (!values[termsAndConditions]) {
        errors[termsAndConditions] = 'Terms and Conditions should be checked';
    }

    return errors;
}

export const validatePasswordRequirements = (values, password) => {
    const errors = {};
    const passwordErrors = [];

    if (values[password].length < 8) {
        passwordErrors.push('Your password is too short.');
    }

    if (!values[password].match(/[A-Z]/)) {
        passwordErrors.push('Your password must contain at least one capital letter.');
    }

    if (!values[password].match(/\d+/g)) {
        passwordErrors.push('Your password must contain at least one digit.');
    }

    if (passwordErrors.length > 0) {
        errors[password] = passwordErrors;
    }

    return errors
};

export const validatePhoneNumber = (values, phone) => {
    const errors = {};

    if (phone && values[phone].length && !values[phone].match(/^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/)) {
        errors[phone] = 'Your phone number does not meet requirements.';
    }

    return errors;
};

export const validateDecimalNumber = (values, number) => {
    const errors = {};

    if (!values[number].match(/^[+]?[0-9]\d*([\.\,]\d{1,2})?\s*$/)) {
        errors[number] = 'The field does not meet requirements.';
    }

    return errors;
};

export const validateClientProjectName = (values, name, employees) => {
    const errors = {};

    if (!employees.some(x => x._id === values[name]._id || x._id === values[name].employeeId)) {
        errors[name] = 'Person must exist.';
    }

    return errors;
};

export const validateInputLength = (values, validationObject) => {
    const errors = {};
    validationObject.forEach(validation => {
        if (values[validation.property] === null) { return; }

        if (values[validation.property].length > validation.maxLength) {
            errors[validation.property] = `${capitalizedWord(validation.property).replace(/([a-z])([A-Z])/g, '$1 $2')} must be maximum ${validation.maxLength} characters long.`;
        }
    });

    return errors;
};

export const validateDecimalInputPeriod = (values, validationObject) => {
    const errors = {};
    validationObject.forEach(validation => {
        if (values[validation.property] === null) { return; }

        if (Number(values[validation.property]) > validation.maxLength || Number(values[validation.property]) <= validation.minLength) {
            errors[validation.property] = `${capitalizedWord(validation.property).replace(/([a-z])([A-Z])/g, '$1 $2')} must be between ${validation.minLength} and ${validation.maxLength}.`;
        }
    });

    return errors;
};

const capitalizedWord = (word) => word.charAt(0).toUpperCase() + word.slice(1);

export const validateEmployeeHolidayAllowance = (values, requiredFields) => {

    let errors = {};

    errors = validateRequiredFields(values, requiredFields);

    if (Object.keys(errors).length !== 0) {
        return errors;
    }

    for (const prop of requiredFields) {

        if (!(String)(values[prop]).match(/^[0-9]+$/)) {
            errors[prop] = 'Input data must be zero or positive integer.'
            return errors
        }
    }

    return errors;
};

// validate input content to be fulfilled only with white spaces
export const validateInputForWhiteSpaces = (values, requiredFields) => {
    const errors = {};
    for (const prop of requiredFields) {

        if ((String)(values[prop]) !== '' && !(String)(values[prop]).trim()) {
            errors[prop] = 'Field cannot be left only with spaces.'
            return errors
        }
    }

    return errors;
};

// validate input for zero or positive number with up to two digits after decimal point
export const validateInputForDecimalType = (values, requiredFields) => {
    const errors = {};
    for (const prop of requiredFields) {

        if ((String)(values[prop]) !== ''
            && !(String)(values[prop]).match(/^(([0-9]+\.[0-9]{1,2})|([0-9]+))$/)) {
            errors[prop] = 'Input data must be zero or positive number with up to two digits after decimal point.'
            return errors
        }
    }

    return errors;
};

// validate input for zero or positive number with up to two digits after decimal point
export const validateInputForPositiveIntegerType = (values, requiredFields = []) => {
    const errors = {};

    for (const prop of requiredFields) {

        if ((String)(values[prop]) !== ''
            && !(String)(values[prop]).match(/^(([0-9]+\.[0-9]{1,2})|([0-9]+))$/)) {
            errors[prop] = 'Input data must be zero or positive number.'
            return errors
        }
    }

    return errors;
};

export const validateCriteriaUniqueness = (criteria, criterias) => {
    const errors = {};
    criterias.forEach(x => {
        if (x.name === criteria) {
            errors['criteria'] = 'Value must be unique';
            return errors;
        }
    });
    return errors;
};

export const validateDateFields = (values, dateFields) => {
    return dateFields
        .filter(x => values[x] ? !moment(values[x])?.isValid() || moment(values[x]).year() > 2099 : false)
        .reduce((a, b) => ({ ...a, [b]: 'Date format is invalid' }), {});
}

export const validateAreDatesBeforeMinDate = (values, dateFields) => {
    return dateFields
        .filter(
            (dateField) => moment(values[dateField]).isValid() && moment(values[dateField]).isBefore(moment(), 'day')
        )
        .reduce((acc, dateFiled) => ({ ...acc, [dateFiled]: 'Date should not be before minimal date' }), {});
};

export const validateDateNotInPast = (values, dateFields) => {
    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    currentDate = moment.utc(currentDate).format();

    return dateFields
        .filter(x => values[x] ? moment(values[x]).isBefore(currentDate) : false)
        .reduce((a, b) => ({ ...a, [b]: 'Choosen date is in the past' }), {});
};

export const validateDateNotInFuture = (values, dateFields) => {
    const currentDate = moment.utc().format();

    return dateFields
        .filter(x => values[x] ? moment(values[x]).isAfter(currentDate) : false)
        .reduce((a, b) => ({ ...a, [b]: 'Choosen date is in the past' }), {});
}

export const validateStartDateNotBeforeEndDate = (values, startDate, endDate, disabledFuture) => {
    const errors = {};

    if (values[startDate]) {
        const initialMaxDate = disabledFuture ? new Date() : MAX_DATE;
        const start = moment(values[startDate]).clone().startOf('day');
        const end = values[endDate] ? moment(values[endDate]).clone().startOf('day') : initialMaxDate;

        if (start > end) {
            errors[startDate] = 'Choosen date cannot be after end date.';
            return errors;
        }
    }

    return errors;
};

export const validateEndDateNotBeforeStartDate = (values, startDate, endDate) => {
    const errors = {};

    if (values[endDate]) {
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        const start = values[startDate] ? moment(values[startDate]).clone().startOf('day') : currentDate;
        const end = moment(values[endDate]).clone().startOf('day');

        if (end < start) {
            errors[endDate] = 'Choosen date cannot be before start date.';
            return errors;
        }
    }

    return errors;
};

export const validateFilesArrayLength = (values, fieldName, minimumLength) => {
    const errors = {};

    if (values[fieldName].length < minimumLength) {
        errors[fieldName] = `At least ${minimumLength} ${minimumLength > 1 ? 'files' : 'file'} should be uploaded.`;
    }

    return errors;
};

export const validatesArrayLength = (values, arr, minimumLength) => {
    const errors = {};
    if (values[arr].length < minimumLength) {
        errors[arr] = `At least ${minimumLength} ${minimumLength > 1 ? 'options' : 'option'} should be selected.`;
    }

    return errors;
};

export const validateVideoURLAddress = (values, enteredURL) => {
    const errors = {};

    if (!values[enteredURL].match(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/)) {
        errors[enteredURL] = 'Invalid URL address.';
    } else if (!values[enteredURL].match(/^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com)/)) {
        errors[enteredURL] = 'Please provide YouTube address.';
    }
    return errors;
};

export const validateDecimalNumbers = (values, numbers) => {
    const errors = {};

    numbers.forEach(number => {
        const value = values[number].toString();
        if (!value.match(/^[+]?[0-9]\d*([\.\,]\d{1,2})?\s*$/) || value === '0') {
            errors[number] = 'The field does not meet requirements.';
        }
    });

    return errors;
};

export const validateHttpsUrl = (values, softuniCourseUrl) => {
    const errors = {};

    if (!values[softuniCourseUrl].startsWith('https://')) {
        errors[softuniCourseUrl] = 'Please provide a valid address';
    }

    return errors;
};

export const validateArrayRequiredFields = (values, arrayName, requiredFields) => {
    let errors = {};
    const arr = [];

    for (let i = 0; i < values[arrayName].length; i++) {
        for (let y = 0; y < requiredFields.length; y++) {
            if (!values[arrayName][i][requiredFields[y]]) {
                arr[i] = { ...arr[i], [requiredFields[y]]: 'The field is required.' };
            }
        }
    }

    errors = arr.length ? { [arrayName]: arr } : errors;

    return errors;
};

export const validatePIN = (values, pin, country) => {
    const errors = {};

    switch (country) {
        case 'Turkey':
            if (values[pin].length !== 11) {
                errors[pin] = 'Personal identification number must be 11 digits'
            }
            break;
        case 'Romania':
            if (values[pin].length !== 13) {
                errors[pin] = 'Personal identification number must be 13 digits'
            }
            break;
        default:
            if (values[pin].length !== 10) {
                errors[pin] = 'Personal identification number must be 10 digits'
            }
    }

    return errors;
};

export const validateTimesones = (timezone, timezones) => {
    const errors = {};

    if (!timezones.includes(timezone)) {
        errors['timezone'] = 'Invalid timezone';
    }

    return errors;
};

export const validateTimeSlotsWithTimezone = (values, timezone, arrayName) => {
    let errors = {};
    const arr = [];
    const areFieldsSet = values[timezone] && values[arrayName].length && values[arrayName][0].date && values[arrayName][0].time;

    if (areFieldsSet) {
        values[arrayName].forEach((timeSlot, i) => {
            const timeSlotDate = timeSlot.date;
            const timeSlotTime = moment(timeSlot.time);

            if (timeSlotDate && timeSlotTime) {
                timeSlotDate.set({
                    hour: timeSlotTime.get('hour'),
                    minute: timeSlotTime.get('minute'),
                    second: timeSlotTime.get('second')
                });

                const timezoneCurrentTime = moment.tz(values[timezone]).format();
                const timeSlotDateFormatted = timeSlotDate.format();

                const isDateTimePast = timeSlotDateFormatted <= timezoneCurrentTime;

                if (timeSlot.time && isDateTimePast) {
                    arr[i] = { ...arr[i], 'time': 'Proposing time slots in the past is not allowed.' };
                }
            }
        });
    }

    errors = arr.length ? { [arrayName]: arr } : errors;

    return errors;
};

export const validateArrayDateFields = (values, arrayName, dateFields) => {
    let errors = {};
    const arr = [];

    for (let i = 0; i < values[arrayName].length; i++) {
        for (let j = 0; j < dateFields.length; j++) {
            const date = moment(values[arrayName][i][dateFields[j]]);
            if (date === 'Invalid date' || !date?._isValid) {
                arr[i] = { ...arr[i], [dateFields[j]]: 'Invalid Date Format.' };
            }
        }
    }

    errors = arr.length ? { [arrayName]: arr } : errors;

    return errors;
};

export const validateArrayDiversDateFields = (values, arrayName, dateField, timeField) => {
    let errors = {};
    const arr = [];

    for (let i = 0; i < values[arrayName].length; i++) {
        for (let j = 0; j < values[arrayName].length; j++) {
            if (i < j) {
                const firstDate = moment(values[arrayName][i][dateField]);
                const firstTime = moment(values[arrayName][i][timeField]);

                const secondDate = moment(values[arrayName][j][dateField]);
                const secondTime = moment(values[arrayName][j][timeField]);

                if (firstDate.isSame(secondDate, 'day') && Math.abs(firstTime.diff(secondTime, 'minutes')) <= 30) {
                    arr[i] = { ...arr[i], [dateField]: 'Same dates are not allowed.' };
                    arr[j] = { ...arr[i], [dateField]: 'Same dates are not allowed.' };
                }
            }
        }
    }

    errors = arr.length ? { [arrayName]: arr } : errors;

    return errors;
}

export const validateRequiredPairFields = (values, requiredPair) => {
    const errors = {};

    const isValid = requiredPair.every(element => values[element] && values[element].length > 0) || requiredPair.every(element => !values[element]);

    if (!isValid) {
        const missingFields = requiredPair.filter(element => !values[element]);
        missingFields.forEach(field => {
            errors[field] = 'Field is required';
        })
    }

    return errors;
};

export const validateRequiredFieldsSkillsFeedback = (values) => {
    const errors = {};

    if (values.description?.trim().length === 0) {
        errors['description'] = 'Field is required'
    }

    if (values.description?.length > MAX_DESCRIPTION_LENGTH) {
        errors['description'] = `Description too long. Max characters: ${MAX_DESCRIPTION_LENGTH}`;
    }

    const isCandidateMissing = values.candidateMissing;

    Object.entries(values).forEach(([key, value]) => {
        if (!isCandidateMissing && value === 0) {
            errors[key] = 'Field is required';
        }
    });

    return errors;
};

export const validateRequiredFieldsCandidateRate = (values) => {
    const errors = {};

    if (values.internalFeedback?.trim().length === 0) {
        errors['internalFeedback'] = 'Field is required'
    }

    if (values.internalFeedback?.length > MAX_DESCRIPTION_LENGTH) {
        errors['internalFeedback'] = `Description too long. Max characters: ${MAX_DESCRIPTION_LENGTH}`;
    }

    if (values.summary?.trim().length === 0) {
        errors['summary'] = 'Field is required'
    }

    if (values.summary?.length > MAX_DESCRIPTION_LENGTH) {
        errors['summary'] = `Description too long. Max characters: ${MAX_DESCRIPTION_LENGTH}`;
    }

    if (!values.status) {
        errors['status'] = 'Field is required';
    }

    return errors;
};

// makes requiredFiled required only if conditionalField is equal to the conditionalFieldValue
export const validateConditionalField = (values, conditionalField, conditionalFieldValue, requiredField) => {
    const errors = {};

    if (values[conditionalField] === conditionalFieldValue && !values[requiredField]) {
        errors[requiredField] = 'Field is required';
    }

    return errors;
}

// makes sure that the first passed value is contained in the search fields
export const validateFieldIsContainedInAnotherField = (values, firstField, searchFields, errorMessage) => {
    const errors = {};
    let found = false;

    for (const field of searchFields) {
        if (values[field].includes(values[firstField])) {
            found = true;
        }
    }

    if (!found) {
        errors[firstField] = errorMessage;
    }

    return errors;
}

export const validateEmploymentStartDate = (values, generalInformation) => {
    const errors = {};

    if (generalInformation?.startDate && !values.startDate) {
        errors['startDate'] = 'Field is required';
    } else if (values.startDate === 'Invalid date') {
        errors['startDate'] = 'Date format is invalid';
    } else if (moment(values.startDate).isBefore(moment(MIN_DATE), 'day')) {
        errors['startDate'] = 'Date should not be before minimal date';
    } else if ((values.startDate && values.startDate !== 'Invalid date') && (values.terminationDate && values.terminationDate !== 'Invalid date')) {
        const { startDate } = validateStartDateNotBeforeEndDate(values, 'startDate', 'terminationDate');
        if (startDate) {
            errors['startDate'] = startDate;
        }
    }

    return errors;
};

export const validateEmploymentTerminationDate = (values, generalInformation) => {
    const errors = {};

    if (generalInformation?.terminationDate && !values.terminationDate) {
        errors['terminationDate'] = 'Field is required';
    } else if (values.terminationDate === 'Invalid date') {
        errors['terminationDate'] = 'Date format is invalid';
    } else if (moment(values.terminationDate).isBefore(moment(MIN_DATE), 'day')) {
        errors['terminationDate'] = 'Date should not be before minimal date';
    } else if ((values.startDate && values.startDate !== 'Invalid date') && (values.terminationDate && values.terminationDate !== 'Invalid date')) {
        const { terminationDate } = validateEndDateNotBeforeStartDate(values, 'startDate', 'terminationDate');
        if (terminationDate) {
            errors['terminationDate'] = terminationDate;
        }
    }

    return errors;
};
