import moment from 'moment';

import { persistCombineReducers } from 'redux-persist';

import {
    JOB_OPPORTUNITY_ADD_SUCCESS,
    JOB_OPPORTUNITIES_FETCH_SUCCESS,
    JOB_OPPORTUNITIES_COUNT_FETCH_SUCCESS,
    JOB_OPPORTUNITY_UPDATE_SUCCESS,
    JOB_OPPORTUNITY_DELETE_SUCCESS,
    JOB_OPPORTUNITY_DETAILS_FETCH_SUCCESS,
    JOB_OPPORTUNITY_DETAILS_UPDATE_SUCCESS,
    CLIENT_JOBS_FETCH_SUCCESS,
    JOB_OPPORTUNITIES_TECHNOLOGIES_FETCH_SUCCESS,
    HOME_INFORMATION_INITIAL_STATE_SET,
    CLIENT_JOBS_DASHBOARD_FETCH_SUCCESS,
    APPLICATION_UPDATE_STATUS_SUCCESS,
    MATCHING_CONSULTANTS_FETCH_SUCCESS,
    JOB_OPPORTUNITY_NEW_APPLICATIONS_COUNT_UPDATE_SUCCESS,
    JOB_OPPORTUNITY_PENDING_ADMIN_REVIEW_APPLICATIONS_COUNT_UPDATE_SUCCESS,
    TALENT_MATCHING_JOBS_FETCH_SUCCESS,
    JOB_APPLICATION_DELETE_SUCCESS,
    CLIENT_JOBS_RESET_STATE_SUCCESS,
    JOBS_RESET_STATE_SUCCESS,
    INDEXED_JOB_OPPORTUNITIES_FETCH_SUCCESS,
} from 'actions/actionTypes';

import { PERSIST_JOB_OPPORTUNITIES_CONFIG } from 'utils/persistUtils';
import { escapeRegExp } from 'utils/regexUtils';

import { JOB_OPPORTUNITIES_PRIVACY, JOB_OPPORTUNITIES_TYPES } from 'constants/jobOpportunitiesConstants';
import { APPLICATION_STATUSES } from 'constants/applicationConstants';
import { PROFILE_TYPES, VETTING_STATUS, AVAILABILITY_FILTERS, INVITATION_STATUS, LOCATION_TYPES } from 'constants/matchingConsultantsConstants';
import { AVAILABLE_OCCUPATION_STATUSES } from 'constants/employeeConstants';
import { ROLES } from 'constants/userConstants';

const jobOpportunityInitialState = {
    agencyId: '',
    creator: '',
    position: '',
    client: {
        name: '',
        status: ''
    },
    aboutTheClient: '',
    jobSummary: '',
    aboutCandidate: '',
    benefits: '',
    imageData: {},
    clientId: '',
    privacy: JOB_OPPORTUNITIES_PRIVACY.PRIVATE._id,
    profileForwardType: null,
};

const jobOpportunity = (state = jobOpportunityInitialState, action) => {
    if (state._id && state._id !== action.payload._id) {
        return state;
    }

    switch (action.type) {
        case JOB_OPPORTUNITY_UPDATE_SUCCESS:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}

const jobOpportunitiesListInitialState = [];

export const jobOpportunitiesList = (state = jobOpportunitiesListInitialState, action) => {
    switch (action.type) {
        case CLIENT_JOBS_DASHBOARD_FETCH_SUCCESS:
        case TALENT_MATCHING_JOBS_FETCH_SUCCESS:
        case INDEXED_JOB_OPPORTUNITIES_FETCH_SUCCESS:
            return action.payload || jobOpportunitiesListInitialState;
        case CLIENT_JOBS_FETCH_SUCCESS:
        case JOB_OPPORTUNITIES_FETCH_SUCCESS:
            return [...state, ...action.payload];
        case CLIENT_JOBS_RESET_STATE_SUCCESS:
        case JOBS_RESET_STATE_SUCCESS:
            return [];
        case JOB_OPPORTUNITY_ADD_SUCCESS:
            const newJobOpportunity = { ...jobOpportunityInitialState, ...action.payload };
            return [newJobOpportunity, ...state];
        case JOB_OPPORTUNITY_UPDATE_SUCCESS:
            return state.map(x => jobOpportunity(x, action))
        case JOB_OPPORTUNITY_DELETE_SUCCESS:
            return state.filter(x => x._id !== action.payload._id);
        default:
            return state;
    }
};

const jobOpportunitiesCountInitialState = {
    jobsTotalCount: 0,
    contractorJobsTotalCount: 0,
    fullTimeJobsTotalCount: 0,
};

const jobOpportunitiesCount = (state = jobOpportunitiesCountInitialState, action) => {
    switch (action.type) {
        case JOB_OPPORTUNITIES_COUNT_FETCH_SUCCESS:
            return { ...state, ...action.payload };
        case JOB_OPPORTUNITY_DELETE_SUCCESS:
            return {
                ...state,
                jobsTotalCount: state.jobsTotalCount - 1,
                contractorJobsTotalCount: action.payload.jobType === JOB_OPPORTUNITIES_TYPES.CONTRACT
                    ? state.contractorJobsTotalCount - 1
                    : state.contractorJobsTotalCount,
                fullTimeJobsTotalCount: action.payload.jobType === JOB_OPPORTUNITIES_TYPES.FULL_TIME
                    ? state.fullTimeJobsTotalCount - 1
                    : state.fullTimeJobsTotalCount
            }
        default:
            return state;
    }
};

const jobOpportunityDetailsInitialState = {
    agencyId: '',
    creator: '',
    position: '',
    client: '',
    aboutTheClient: '',
    jobSummary: '',
    aboutCandidate: '',
    benefits: '',
    imageData: {},
    applications: [],
    appliedCandidates: [],
    application: {},
    approvedApplicationsCount: 0,
    newApplicationsCount: 0,
    notVettedApplicationsCount: 0,
    pendingAdminReviewApplicationsCount: 0,
    pendingApplicationsCount: 0,
    profilesCount: 0,
    rejectedApplicationsCount: 0,
    isForContractor: false,
    isProConsultantOpportunity: false,
    dateAdded: '',
    privacy: JOB_OPPORTUNITIES_PRIVACY.PRIVATE._id,
    candidateDetails: {
        _id: '',
        name: '',
        appliedDate: '',
        status: '',
        candidateMatchingScores:
        {
            matchingScore: 0,
            categoryWeights: {},
        },
        comment: '',
        feedback: '',
        rejectionReason: ''
    },
    candidates: [],
    profileForwardType: null,
}

const jobOpportunityDetails = (state = jobOpportunityDetailsInitialState, action) => {
    switch (action.type) {
        case JOB_OPPORTUNITY_DETAILS_UPDATE_SUCCESS:
            return { ...state, ...action.payload }
        case JOB_OPPORTUNITY_NEW_APPLICATIONS_COUNT_UPDATE_SUCCESS:
            return {
                ...state,
                profilesCount: state.profilesCount + action.payload,
                newApplicationsCount: state.newApplicationsCount + action.payload
            }
        case JOB_OPPORTUNITY_PENDING_ADMIN_REVIEW_APPLICATIONS_COUNT_UPDATE_SUCCESS:
            return {
                ...state,
                profilesCount: state.profilesCount + action.payload,
                pendingAdminReviewApplicationsCount: state.pendingAdminReviewApplicationsCount + action.payload
            }
        case JOB_OPPORTUNITY_DETAILS_FETCH_SUCCESS:
            return { ...jobOpportunityDetailsInitialState, ...action.payload }
        case JOB_OPPORTUNITY_UPDATE_SUCCESS:
            return { ...state, ...action.payload }
        case JOB_APPLICATION_DELETE_SUCCESS:
            switch (action.payload.applicationStatus) {
                case APPLICATION_STATUSES.NEW_APPLICATION:
                    return { ...state, newApplicationsCount: state.newApplicationsCount - 1, profilesCount: state.profilesCount - 1 }
                case APPLICATION_STATUSES.NON_VETTED_CANDIDATE:
                    return { ...state, notVettedApplicationsCount: state.notVettedApplicationsCount - 1, profilesCount: state.profilesCount - 1 }
                case APPLICATION_STATUSES.PENDING_ADMIN_REVIEW:
                    return { ...state, pendingAdminReviewApplicationsCount: state.pendingAdminReviewApplicationsCount - 1, profilesCount: state.profilesCount - 1 }
                case APPLICATION_STATUSES.APPROVED:
                    return { ...state, approvedApplicationsCount: state.approvedApplicationsCount - 1, profilesCount: state.profilesCount - 1 }
                case APPLICATION_STATUSES.REJECTED:
                    return { ...state, rejectedApplicationsCount: state.rejectedApplicationsCount - 1, profilesCount: state.profilesCount - 1 }
                default:
                    return state;
            }
        case APPLICATION_UPDATE_STATUS_SUCCESS:
            return { ...state, candidateDetails: { ...state.candidateDetails, ...action.payload } }
        case HOME_INFORMATION_INITIAL_STATE_SET:
            return jobOpportunityDetailsInitialState
        default:
            return state;
    }
}

const jobOpportunitiesTechnologies = (state = [], action) => {
    switch (action.type) {
        case HOME_INFORMATION_INITIAL_STATE_SET:
            return []
        case JOB_OPPORTUNITIES_TECHNOLOGIES_FETCH_SUCCESS:
            return [...action.payload];
        default:
            return state;
    }
};

const matchingConsultantsInitialState = [];

const matchingConsultants = (state = [], action) => {
    switch (action.type) {
        case MATCHING_CONSULTANTS_FETCH_SUCCESS:
            return [...matchingConsultantsInitialState, ...action.payload];
        default:
            return state;
    }
};

const jobOpportunities = persistCombineReducers(PERSIST_JOB_OPPORTUNITIES_CONFIG, {
    jobOpportunitiesList,
    jobOpportunityDetails,
    jobOpportunitiesTechnologies,
    matchingConsultants,
    jobOpportunitiesCount,
});

export default jobOpportunities;

export const getJobOpportunities = (state, filters) => {
    const jobs = state.jobOpportunitiesList.filter(job => {
        const hasJobType = filters?.jobType ? filters.jobType === job.jobType : true;
        const hasStatus = filters?.status ? filters.status === job.status : true;
        const hasPrivacy = filters?.privacy ? Object.values(JOB_OPPORTUNITIES_PRIVACY).find(x => x.value === filters.privacy)._id === job.privacy : true;
        const hasPriority = filters?.priority ? filters.priority === job.priority : true;

        const technologySearchTerm = filters?.technologySearchTerm;
        const searchTerm = filters?.searchTerm;

        let isTechnologyMatch = !technologySearchTerm;
        let isSearchMatch = !searchTerm;

        const technologyPattern = technologySearchTerm ? new RegExp(`.*${escapeRegExp(technologySearchTerm)}.*`, 'i') : '';
        const searchPattern = searchTerm ? new RegExp(`.*${escapeRegExp(searchTerm)}.*`, 'i') : '';

        if (technologySearchTerm) {
            isTechnologyMatch =
                (job.mandatorySkills?.length > 0 && job.mandatorySkills.some(y => y.name.match(technologyPattern))) ||
                (job.niceToHaveSkills?.length > 0 && job.niceToHaveSkills.some(y => y.name.match(technologyPattern)));
        }

        if (searchTerm) {
            isSearchMatch = job.position?.match(searchPattern)
                || job.client?.name?.match(searchPattern)
                || job.creator?.name?.match(searchPattern)
                || job.aboutCandidate?.match(searchPattern)
                || job.aboutTheClient?.match(searchPattern)
                || job.jobSummary?.match(searchPattern)
                || job.externalJobId?.match(searchPattern);
        }

        return hasJobType && hasStatus && hasPrivacy && hasPriority && isTechnologyMatch && isSearchMatch;
    });

    return jobs;
};

export const getJobOpportunitiesTotalCount = (state) => state.jobOpportunitiesCount.jobsTotalCount;
export const getJobOpportunitiesContractorJobsTotalCount = (state) => state.jobOpportunitiesCount.contractorJobsTotalCount;
export const getJobOpportunitiesFullTimeJobsTotalCount = (state) => state.jobOpportunitiesCount.fullTimeJobsTotalCount;

export const getJobsCountByJobType = (state, jobType) => {
    const jobs = state.jobOpportunitiesList.filter(job => jobType === job.jobType);

    return jobs.length;
};

export const getJobOpportunityDetails = state => state.jobOpportunityDetails;
export const getJobOpportunitiesTechnologies = state => state.jobOpportunitiesTechnologies;
export const getMatchingJobOpportunities = (state, filters) => state.jobOpportunitiesList.filter(job => {
    const isMatchingLocationFilter = filters?.location
        ? filters?.location === LOCATION_TYPES.ALL_LOCATIONS || (filters?.location === LOCATION_TYPES.MATCHING_JOB_REQUIREMENTS && job.isMatchingLocation !== false)
        : true

    return (job.isMatch || job.isLastViewedJob) && isMatchingLocationFilter;
});
export const getJobOpportunityCandidateDetails = (state) => state.jobOpportunityDetails.candidateDetails;
export const getMatchingAgencyConsultants = (state, filters) => {
    return state.matchingConsultants.filter(consultant => {
        let availabilityFilter = true;
        if (filters.availability === AVAILABILITY_FILTERS.AVAILABLE_ONLY) {
            availabilityFilter = AVAILABLE_OCCUPATION_STATUSES.includes(consultant.occupationStatus);
        }
        const isInvitedFilter = filters.invitationStatus === '' || filters.invitationStatus === INVITATION_STATUS.INVITED || (filters.invitationStatus === INVITATION_STATUS.NOT_INVITED && !consultant.isInvited);

        const isMatchingLocationFilter = filters?.candidateLocation === ''
            || filters.candidateLocation === LOCATION_TYPES.ALL_LOCATIONS
            || (filters.candidateLocation === LOCATION_TYPES.MATCHING_JOB_REQUIREMENTS && consultant.isMatchingLocation !== false)

        return (consultant.role === ROLES.ADMIN || consultant.role === ROLES.EMPLOYEE) && availabilityFilter && isInvitedFilter && isMatchingLocationFilter;
    })
}
export const getMatchingSupplierConsultants = (state, filters) => {
    return state.matchingConsultants.filter(consultant => {
        const isInvitedFilter = filters.invitationStatus === '' || filters.invitationStatus === INVITATION_STATUS.INVITED || (filters.invitationStatus === INVITATION_STATUS.NOT_INVITED && !consultant.isInvited);
        let vettedFilter = true;
        let availabilityFilter = true;
        if (filters.vettingStatus === VETTING_STATUS.VETTED_ONLY) {
            vettedFilter = consultant.isVetted;
        }
        if (filters.availability === AVAILABILITY_FILTERS.AVAILABLE_ONLY) {
            availabilityFilter = consultant.isAvailable;
        }

        const isMatchingLocationFilter = filters?.candidateLocation === ''
            || filters.candidateLocation === LOCATION_TYPES.ALL_LOCATIONS
            || (filters.candidateLocation === LOCATION_TYPES.MATCHING_JOB_REQUIREMENTS && consultant.isMatchingLocation !== false)

        return (consultant.role === ROLES.SUPPLIER_ADMIN || consultant.role === ROLES.SUPPLIER_EMPLOYEE) && vettedFilter && availabilityFilter && isInvitedFilter && isMatchingLocationFilter;
    })
}
export const getMatchingCandidates = (state, filters) => {
    return state.matchingConsultants.filter(consultant => {
        const isInvitedFilter = filters.invitationStatus === '' || filters.invitationStatus === INVITATION_STATUS.INVITED || (filters.invitationStatus === INVITATION_STATUS.NOT_INVITED && !consultant.isInvited);
        let vettedFilter = true;
        let availabilityFilter = true;
        if (filters.vettingStatus === VETTING_STATUS.VETTED_ONLY) {
            vettedFilter = consultant.isVetted;
        }
        if (filters.availability === AVAILABILITY_FILTERS.AVAILABLE_ONLY) {
            availabilityFilter = consultant.earliestDate
                ? moment(new Date()).isAfter(consultant.earliestDate)
                : false;
        }

        const isMatchingLocationFilter = filters?.candidateLocation === ''
            || filters.candidateLocation === LOCATION_TYPES.ALL_LOCATIONS
            || (filters.candidateLocation === LOCATION_TYPES.MATCHING_JOB_REQUIREMENTS && consultant.isMatchingLocation !== false)

        return consultant.role === ROLES.CANDIDATE && vettedFilter && availabilityFilter && isInvitedFilter && isMatchingLocationFilter;
    })
}

export const getMatchingAllConsultantsCount = (state) => {
    return state.matchingConsultants.length
}

export const getMatchingConsultants = (state, filters) => {
    let employees = [];
    let partnerEmployees = [];
    let candidates = [];

    if (!filters.profileType.length || filters.profileType.includes(PROFILE_TYPES.EMPLOYEE)) {
        employees = getMatchingAgencyConsultants(state, filters);
    }
    if (!filters.profileType.length || filters.profileType.includes(PROFILE_TYPES.PARTNER_PROFILE)) {
        partnerEmployees = getMatchingSupplierConsultants(state, filters);
    }
    if (!filters.profileType.length || filters.profileType.includes(PROFILE_TYPES.CANDIDATE)) {
        candidates = getMatchingCandidates(state, filters);
    }

    const totalProfilesCount = employees.length + partnerEmployees.length + candidates.length;

    return { profiles: { employees, partnerEmployees, candidates }, totalProfilesCount }
}
