export const adminDataMarkupProperties = [
    {
        name: 'Open Positions',
        classType: 'positions',
        dataProp: 'positionsData',
        fields: [
            { name: 'Open Contract Jobs', tooltip: 'Number of active Contract jobs', dataProp: 'onDemandPositions' },
            { name: 'High Priority Jobs', tooltip: 'Number of active jobs, marked with high priority', dataProp: 'hotJobs' },
            { name: 'Open Full-time Jobs', tooltip: 'Number of active Full-time jobs', dataProp: 'dedicatedPositions' },
        ]
    },
    {
        name: 'Employees',
        classType: 'employees',
        dataProp: 'employeesData',
        fields: [
            { name: 'Bulgaria', tooltip: 'Number of employees residing in Bulgaria', dataProp: 'bulgaria' },
            { name: 'Romania', tooltip: 'Number of employees residing in Romania', dataProp: 'romania' },
            { name: 'Ukraine', tooltip: 'Number of employees residing in Ukraine', dataProp: 'ukraine' },
            { name: 'Turkey', tooltip: 'Number of employees residing in Turkey', dataProp: 'turkey' },
            { name: 'Belarus', tooltip: 'Number of employees residing in Belarus', dataProp: 'belarus' },
            { name: 'Bosnia and Herzegovina', tooltip: 'Number of employees residing in Bosnia and Herzegovina', dataProp: 'bosnia and herzegovina' },
            { name: 'Macedonia', tooltip: 'Number of employees residing in Macedonia', dataProp: 'macedonia' },
        ]
    },
    {
        name: 'Candidates',
        classType: 'candidates',
        dataProp: 'candidatesData',
        fields: [
            { name: 'Vetted Candidates', tooltip: 'Number of vetted candidates', dataProp: 'vettedCandidates' },
            { name: 'Rejected Candidates', tooltip: 'Number of rejected candidates', dataProp: 'rejectedCandidates' },
        ]
    },
];

export const adminDataMarkupPropertiesSaaS = [
    {
        name: 'Candidates',
        classType: 'candidates',
        dataProp: 'candidatesData',
        fields: [
            { name: 'Vetted Candidates', tooltip: 'Number of vetted candidates', dataProp: 'vettedCandidates' },
            { name: 'Rejected Candidates', tooltip: 'Number of rejected candidates', dataProp: 'rejectedCandidates' },
        ]
    },
    {
        name: 'Employees',
        classType: 'employees',
        dataProp: 'employeesData',
        fields: [
            { name: 'Bulgaria', tooltip: 'Number of employees residing in Bulgaria', dataProp: 'bulgaria' },
            { name: 'Romania', tooltip: 'Number of employees residing in Romania', dataProp: 'romania' },
            { name: 'Ukraine', tooltip: 'Number of employees residing in Ukraine', dataProp: 'ukraine' },
            { name: 'Turkey', tooltip: 'Number of employees residing in Turkey', dataProp: 'turkey' },
        ]
    },
];
