import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { registerAgency, login } from 'actions/authActions';
import { fetchAllCountries } from 'actions/staticDataActions';
import { getCountries } from 'reducers';

import { Formik } from 'formik';
import {
    validateEmailAddress,
    validateRequiredFields,
    validatePasswordEquality,
    validatePasswordRequirements,
    validateInputLength
} from 'utils/formValidations';
import RegisterAgencyFormView from './RegisterAgencyFormView';
import LoginCard from 'components/Shared/LoginCard';

import { ROLES, REGISTRATION_TYPES } from 'constants/userConstants';
import { MAX_NAME_LENGTH } from '../RegisterConstants';

import './RegisterAgency.scss';

const RegisterAgency = ({
    countries,
    fetchAllCountries,
    registerAgency,
    login,
}) => {
    const history = useHistory();

    useEffect(() => {
        fetchAllCountries();
    }, []);

    const gotoUrl = history.location.state?.gotoUrl;

    return (
        <LoginCard msg="Please complete to create your account.">
            <Formik
                initialValues={{
                    firstName: '',
                    lastName: '',
                    email: '',
                    password: '',
                    confirmPassword: '',
                    name: '',
                    country: null,
                }}
                validate={values => {
                    return {
                        ...validateEmailAddress(values, 'email'),
                        ...validatePasswordRequirements(values, 'password'),
                        ...validatePasswordEquality(values, 'password', 'confirmPassword'),
                        ...validateInputLength(values, [
                            { property: 'name', MAX_NAME_LENGTH },
                            { property: 'firstName', MAX_NAME_LENGTH },
                            { property: 'firstName', MAX_NAME_LENGTH },
                            { property: 'email', MAX_NAME_LENGTH },
                        ]),
                        ...validateRequiredFields(values, ['name', 'email', 'password', 'confirmPassword', 'firstName', 'lastName']),
                    };
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false);

                    values.name = values.name.trim();
                    values.email = values.email.toLowerCase().trim();
                    values.password = values.password.trim();
                    values.firstName = values.firstName.trim();
                    values.lastName = values.lastName.trim();
                    values.isSaas = true;

                    const userData = {
                        ...values,
                        role: ROLES.ADMIN,
                        registrationType: REGISTRATION_TYPES.SELF_REGISTERED,
                    };

                    registerAgency(userData).then(() => {
                        history.push({ pathname: '/thank-you/admin', state: { email: values.email, agencyName: values.name, gotoUrl: gotoUrl } });
                    });
                }}
            >
                {(props) =>
                    <RegisterAgencyFormView
                        {...props}
                        countries={countries}
                    />
                }
            </Formik>
        </LoginCard>
    );
};

const mapStateToProps = (state) => ({
    countries: getCountries(state),
});

const mapDispatchToProps = {
    fetchAllCountries,
    registerAgency,
    login,
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterAgency);
