import React from 'react';
import moment from 'moment';
import { Formik } from 'formik';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import Dialog from 'components/Shared/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import AddEditCertificateFormView from './AddEditCertificateFormView';

import { addUtcOffset } from 'utils/formatTimes';
import {
    validateRequiredFields,
    validateInputLength,
    validateUrlAddress,
    validateDateFields,
} from 'utils/formValidations';

import './AddEditCertificateDialog.scss';

const MAX_LENGTH = 150;

const AddEditCertificateDialog = ({
    certificate,
    openDialog,
    handleCloseDialog,
    handleAddCertificate,
    handleEditCertificate
}) => (
    <MuiPickersUtilsProvider utils={MomentUtils}>
        <Formik
            enableReinitialize
            initialValues={{
                title: certificate?.title || '',
                documentUrl: certificate?.documentUrl || '',
                issueDate: certificate?.issueDate ? moment.utc(certificate.issueDate) : null,
            }}

            validate={values => {
                return {
                    ...validateRequiredFields(values, ['title']),
                    ...validateInputLength(values, [
                        { property: 'title', maxLength: MAX_LENGTH },
                    ]),
                    ...validateUrlAddress(values, 'documentUrl', true),
                    ...validateDateFields(values, ['issueDate']),
                }
            }}

            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
                const { title, documentUrl, issueDate } = values;

                const currCertificate = { title, documentUrl, issueDate: addUtcOffset(issueDate) };
                certificate
                    ? handleEditCertificate({ _id: certificate._id, ...currCertificate })
                    : handleAddCertificate(currCertificate);

                handleCloseDialog();
            }}
        >
            {(props) =>
                <Dialog className="add-edit-certificate-dialog" open={openDialog} onClose={handleCloseDialog} maxWidth={'md'}>
                    <MuiDialogTitle disableTypography className="dialog-title">
                        <Typography variant="body2" className="dialog-title-text">Create new certificate</Typography>
                        <IconButton className="dialog-close-btn" onClick={handleCloseDialog}>
                            <CloseIcon />
                        </IconButton>
                    </MuiDialogTitle>

                    <DialogContent className="dialog-content">
                        <AddEditCertificateFormView {...props} handleCloseDialog={handleCloseDialog} />
                    </DialogContent>
                </Dialog>
            }
        </Formik>
    </MuiPickersUtilsProvider>
);

export default AddEditCertificateDialog;
