import moment from 'moment';

import {
    fetchAgencyHomeInformationSuccess,
    fetchClientHomeInformationSuccess,
    fetchEmployeeHomeInformationSuccess,
    fetchSupplierHomeInformationSuccess,
    fetchRecruiterHomeInformationSuccess,
    fetchAgencyUiDependenciesSuccess,
    fetchClientsInvoicingStatusDoneSuccess,
    fetchPendingHolidaysCountSuccess,
    fetchOnboardingSuccess,
    fetchKnowYourTeamSampleOnLoginSuccess,
    fetchAgencyNameSuccess,
    fetchMainMenuInformationSuccess,
    fetchCandidateJourneyStartInformationFetchSuccess,
    initialJourneyFetchSuccess,
    fetchTermsAndConditionsSucces,
} from 'actions/agencyActions';
import { fetchUserInformationSuccess } from 'actions/authActions';
import { authorizedShowNotification } from 'actions/actionHelpers';
import { ACCESS_AS_MANAGER_SET_SUCCESS } from 'actions/actionTypes';
import tokenPropService from 'services/tokenPropService';
import { AGENCY_MESSAGES } from 'constants/messageConstants';
import { ROLES } from 'constants/userConstants';
import { NOTIFICATION_TYPES } from 'constants/notificationTypes';
import { getCandidateInitialStepId } from 'components/CandidateProfileJourney/CandidateProfileJourneyHelpers';
import { toggleWorkspaceMigrationDialog } from './uiActions';
import { shouldWorkspaceDialogBeReset } from 'utils/uiHelpers';

const setAccessAsManagerSuccess = data => ({
    type: ACCESS_AS_MANAGER_SET_SUCCESS,
    payload: data
});

const FETCH_INFO_MAPPER = {
    [ROLES.ADMIN]: fetchAgencyHomeInformationSuccess,
    [ROLES.CLIENT]: fetchClientHomeInformationSuccess,
    [ROLES.EMPLOYEE]: fetchEmployeeHomeInformationSuccess,
    [ROLES.SUPPLIER_ADMIN]: fetchSupplierHomeInformationSuccess,
    [ROLES.SUPPLIER_EMPLOYEE]: fetchEmployeeHomeInformationSuccess,
    [ROLES.RECRUITER]: fetchRecruiterHomeInformationSuccess,
    [ROLES.CANDIDATE]: fetchEmployeeHomeInformationSuccess,
    [ROLES.UNASSIGNED]: fetchEmployeeHomeInformationSuccess,
};

export const setRememberMeWithToken = (idToken) => async dispatch => {
    await tokenPropService.setRememberMeWithToken(idToken);
}

export const fetchHomeInformationWithToken = (agencyId, currentUser, displayMode, idToken, isTermsAndConditions) => async dispatch => {

    try {
        const { uid: userId, displayName } = currentUser;
        const userData = { userId, role: displayMode };
        const isLogin = true;

        const { 
            mainMenuInformation,
            isClientsInvoicingStatusDone,
            hasClients,
            isOnboardingPassed,
            isTermsAndConditionsAccepted,
            agencyName,
            agencyUiDependencies,
            accessAsManager,
            workspaceMigrationInfo,
            isCurrentlyOnProject,
            ...homeInformation
        } = await tokenPropService.fetchHomeInformationWithToken(agencyId, userData, idToken, isLogin);

        isTermsAndConditions = isTermsAndConditions || isTermsAndConditionsAccepted;

        const menuInformation = displayMode === ROLES.ADMIN
            ? {
                adminName: homeInformation.adminName || '',
                agencyName: agencyName || '',
                logoImgUrl: homeInformation.logoImgUrl || '',
            } : homeInformation;

        const pendingHolidays = homeInformation.pendingHolidaysCount || [];

        displayMode !== ROLES.ADMIN && dispatch(fetchAgencyNameSuccess({ name: agencyName }));
        displayMode && dispatch(FETCH_INFO_MAPPER[displayMode](menuInformation));

        accessAsManager && dispatch(setAccessAsManagerSuccess({ accessAsManager }))

        dispatch(fetchAgencyUiDependenciesSuccess(agencyUiDependencies));
        dispatch(fetchMainMenuInformationSuccess(mainMenuInformation));
        dispatch(fetchUserInformationSuccess({ user: { displayName, uid: userId }, agencyId, displayMode }));
        dispatch(fetchOnboardingSuccess({ isOnboardingPassed }));
        dispatch(fetchTermsAndConditionsSucces({ isTermsAndConditionsAccepted }))
        
        if (displayMode === ROLES.ADMIN || displayMode === ROLES.SUPPLIER_ADMIN) {
            dispatch(fetchClientsInvoicingStatusDoneSuccess({ isClientsInvoicingStatusDone, hasClients }));
            dispatch(fetchPendingHolidaysCountSuccess(pendingHolidays));
        }

        if (displayMode === ROLES.CANDIDATE) {
            const { initialJourney, isJourneyStarted } = homeInformation;

            const journeyStepsIds = (initialJourney?.completedSteps || []).map(x => x._id);
            const stepTypes = (initialJourney?.completedSteps || []).map(x => x.type);

            dispatch(fetchCandidateJourneyStartInformationFetchSuccess({ isJourneyStarted }));
            dispatch(initialJourneyFetchSuccess({
                ...initialJourney,
                currentStepId: getCandidateInitialStepId(journeyStepsIds, stepTypes),
            }));
        }

        if (displayMode === ROLES.EMPLOYEE) {
            const sampleSize = 2;
            const sample = await tokenPropService.fetchKnowYourTeamSampleWithToken(agencyId, userId, sampleSize, idToken);
            dispatch(fetchKnowYourTeamSampleOnLoginSuccess(sample))
        }

        // workspace migration dialog
        // dialog appears until 19 Feb 2023, if day is after that ignore
        const isTodayBefore19thFeb = moment().isBefore(new Date('2023-02-19'));

        if (!isTodayBefore19thFeb || (displayMode === ROLES.SUPPLIER_EMPLOYEE && !isCurrentlyOnProject)) {
            dispatch(toggleWorkspaceMigrationDialog(false));
        }

        if ((displayMode === ROLES.ADMIN || displayMode === ROLES.EMPLOYEE || (displayMode === ROLES.SUPPLIER_EMPLOYEE && isCurrentlyOnProject)) && workspaceMigrationInfo) {
            const shouldDialogBeReset = shouldWorkspaceDialogBeReset(workspaceMigrationInfo);

            if (shouldDialogBeReset && isTodayBefore19thFeb) {
                dispatch(toggleWorkspaceMigrationDialog(true));
            } else {
                dispatch(toggleWorkspaceMigrationDialog(!workspaceMigrationInfo.seen));
            }
        } 
    } catch (error) {
        dispatch(authorizedShowNotification(error)(AGENCY_MESSAGES.FETCH_HOME_INFORMATION_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};
