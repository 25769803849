import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import defaultAvatar from 'assets/dummy-avatar.jpg'

import { getEmployeePendingHolidaysCount, getToggleNavigation, isAdminMode } from 'reducers';

import { getProfileLabelText } from './SubmenuUtils';
import { formatStringAsClassName } from 'utils/helpers';

import './Submenu.scss';

const Submenu = ({
    path,
    profileName,
    supplierName,
    avatarUrl,
    role,
    employmentType,
    items,
    pendingHolidaysCount,
    applicationsCount,
    trainingTitle,
    isAdmin,
    isWithProfileInfo,
    actionButtons,
    toggleNavigationValue,
}) => {
    const onAvatarLoadError = e => { e.target.value = defaultAvatar };
    const exact = itemPath => itemPath === '';

    return (
        <Drawer variant="permanent" className={`submenu${toggleNavigationValue ? ' closed-navigation' : ''}`}>
            <div className="profile">
                {isWithProfileInfo &&
                    <>
                        <Avatar
                            alt="Submenu Avatar"
                            className="avatar"
                            src={avatarUrl}
                            onError={onAvatarLoadError}
                        />
                        <div>
                            {profileName &&
                                <Typography variant="h5" className="profile-name">
                                    {supplierName ? `${profileName} (${supplierName})` : profileName}
                                </Typography>
                            }
                            {(isAdmin && role) &&
                                <div className={`profile-label ${formatStringAsClassName(getProfileLabelText(role, employmentType))}`}>
                                    {getProfileLabelText(role, employmentType)}
                                </div>
                            }
                        </div>
                        <div className="profile-divider-wrapper">
                            <Divider className="profile-divider" />
                        </div>
                    </>
                }
            </div>
            {trainingTitle && <div className="training-title-content">
                <Typography variant="h6" className={isAdmin ? "training-title" : "training-title-employee"}>
                    {trainingTitle}
                </Typography>
                {isAdmin &&
                    <Typography variant="body2" className="training-general-settings">
                        General Settings
                    </Typography>
                }
            </div>}
            <List component="div" disablePadding className="submenu-list">
                {items.map(item => (
                    <ListItem
                        button
                        key={item.text}
                        component={NavLink}
                        exact={exact(item.path)}
                        to={`${path}${item.path}`}
                        className="list-item"
                        activeClassName="Mui-selected"
                        replace
                    >
                        <ListItemText primary={item.text} />
                        {
                            item.text === 'Holidays' && !!pendingHolidaysCount
                                ? <div className="notification-badge"><span className="badge-text">{pendingHolidaysCount}</span></div>
                                : null
                        }
                        {
                            item.text === 'Applications' && !!applicationsCount
                                ? <div className="notification-badge"><span className="badge-text">{applicationsCount}</span></div>
                                : null
                        }
                    </ListItem>
                ))}
            </List>
            <div className="submenu-action-buttons-wrapper">
                {(actionButtons && isAdmin) && actionButtons}
            </div>
        </Drawer>
    );
};

const mapStateToProps = state => ({
    pendingHolidaysCount: getEmployeePendingHolidaysCount(state),
    isAdmin: isAdminMode(state),
    toggleNavigationValue: getToggleNavigation(state),
});

export default connect(mapStateToProps, null)(Submenu);
