import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { fetchCandidateApplicationApproved, updateApplicationStartDate } from 'actions/jobApplicationActions';
import { ROLES } from 'constants/userConstants';

import { getCandidateApplicationDetails, getEmployeeBasicInfo } from 'reducers';
import { fetchProfile } from 'actions/employeeActions';
import { Paper, Typography } from '@material-ui/core';

import SecondaryButton from 'components/Shared/Buttons/SecondaryButton';
import EditStartDateDialog from 'components/Shared/EditStartDateDialog';
import MoveToEmployeesDialog from 'components/Shared/MoveToEmployeesDialog';
import { ReactComponent as IconAddressCardPurple } from '../../../assets/hiring-details-list-icons/icon-address-card-purple.svg';
import { ReactComponent as IconCalendarPurple } from '../../../assets/hiring-details-list-icons/icon-calendar-purple.svg';
import { ReactComponent as IconUserPurple } from '../../../assets/hiring-details-list-icons/icon-user-purple.svg';
import { ReactComponent as IconDolarPurple } from '../../../assets/hiring-details-list-icons/icon-dolar-purple.svg';

import { DATE_FORMAT_WITH_DOTS_FULL_YEAR } from 'constants/commonConstants';
import { JOB_OPPORTUNITIES_TYPES } from 'constants/jobOpportunitiesConstants';

import './CandidateHiringDetails.scss';

const HiringDetailsInformation = ({ Icon, iconText, textInformation }) => {
    return (
        <div className="details-information">
            <div className="icon-container">
                <Icon className="details-information-icon" />
            </div>
            <div className='text-container'>
                {iconText}
                <Typography className="information">{textInformation}</Typography>
            </div>
        </div>
    );
};

const CandidateHiringDetails = ({
    agencyId,
    userId,
    fetchProfile,
    fetchCandidateApplicationApproved,
    candidateApplicationDetails,
    updateApplicationStartDate,
    basicInfo,
}) => {
    const [openEditStartDateDialog, setOpenStartDateDialog] = useState(false);
    const [openMoveToEmployeesDialog, setOpenMoveToEmployeesDialog] = useState(false);

    const handleOpenMoveToEmployeeDialog = () => setOpenMoveToEmployeesDialog(true);
    const handleCloseMoveToEmployeeDialog = () => setOpenMoveToEmployeesDialog(false);

    useEffect(() => {
        fetchProfile(userId, agencyId);
        fetchCandidateApplicationApproved(agencyId, userId);
    }, []);

    const handleOpenEditStartDateDialog = () => setOpenStartDateDialog(true);
    const handleCloseEditStartDateDialog = () => setOpenStartDateDialog(false);

    const handleUpdateStartDate = (startDate) => {
        updateApplicationStartDate(agencyId, candidateApplicationDetails._id, startDate);
    }

    return (
        <div className="candidate-hiring-details">
            <Paper className="candidate-hiring-details-inner">
                <div className="section-head">
                    <Typography variant="h3">Contract Details</Typography>

                    <SecondaryButton text="Edit Start Date" handleClick={handleOpenEditStartDateDialog} />
                </div>

                <div className="details-container">
                    <HiringDetailsInformation
                        Icon={IconUserPurple}
                        iconText="Name"
                        textInformation={`${candidateApplicationDetails.firstName} ${candidateApplicationDetails.lastName}`}
                    />

                    {!candidateApplicationDetails.jobType === JOB_OPPORTUNITIES_TYPES.CONTRACT && (
                        <HiringDetailsInformation
                            Icon={IconAddressCardPurple}
                            iconText="Address"
                            textInformation={candidateApplicationDetails.location}
                        />
                    )}

                    <HiringDetailsInformation
                        Icon={IconCalendarPurple}
                        iconText="Start date"
                        textInformation={moment(candidateApplicationDetails.startDate).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR)}
                    />

                    {!candidateApplicationDetails.jobType === JOB_OPPORTUNITIES_TYPES.CONTRACT && (
                        <HiringDetailsInformation
                            Icon={IconDolarPurple}
                            iconText="Monthly Gross Salary"
                            textInformation={`${candidateApplicationDetails.salaryCurrency
                                ? candidateApplicationDetails.salaryCurrency
                                : ''
                                } ${candidateApplicationDetails.salaryValue ? candidateApplicationDetails.salaryValue : ''
                                }`}
                        />
                    )}
                </div>

                {basicInfo && basicInfo.role === ROLES.CANDIDATE && (
                    <div className="section-foot">
                        <SecondaryButton text="Move To Employees" handleClick={handleOpenMoveToEmployeeDialog} />
                    </div>
                )}
            </Paper>

            <EditStartDateDialog
                openDialog={openEditStartDateDialog}
                handleCloseDialog={handleCloseEditStartDateDialog}
                handleSubmit={handleUpdateStartDate}
                formData={candidateApplicationDetails}
            />

            {openMoveToEmployeesDialog &&
                <MoveToEmployeesDialog
                    agencyId={agencyId}
                    userId={userId}
                    userName={`${candidateApplicationDetails.firstName} ${candidateApplicationDetails.lastName}`}
                    openDialog={openMoveToEmployeesDialog}
                    handleCloseDialog={handleCloseMoveToEmployeeDialog}
                    userRole={ROLES.CANDIDATE}
                />
            }
        </div>
    );
};

const mapStateToProps = (state) => ({
    candidateApplicationDetails: getCandidateApplicationDetails(state),
    basicInfo: getEmployeeBasicInfo(state),
});

const mapDispatchtoProps = {
    fetchProfile,
    fetchCandidateApplicationApproved,
    updateApplicationStartDate,
};

export default connect(mapStateToProps, mapDispatchtoProps)(CandidateHiringDetails);
