export const DELETED_USERS_TABLE_COLUMNS = [
    { name: 'Name', value: 'name', isSortable: true },
    { name: 'Email', value: 'email', isSortable: true },
    { name: 'Date Deleted', value: 'dateDeleted', isSortable: true },
];

export const DEFAULT_DELETED_USERS_TABLE_COLUMNS = ['name', 'email', 'dateDeleted'];

export const DELETED_USERS_TABLE_SORT_QUERY_SELECTOR = 'deletedUsersSort';
export const DELETED_USERS_TABLE_PAGE_QUERY_SELECTOR = 'deletedUsersTablePage';

export const DEFAULT_ITEMS_PER_PAGE = 25;

export const INITIAL_QUERY = {
    page: 1,
    sortBy: 'name',
    sortByDir: 'desc',
    itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
    pagesToLoad: 5,
};

export const QUERY_PARAMS_MAP = {
    page: DELETED_USERS_TABLE_PAGE_QUERY_SELECTOR,
    sortBy: DELETED_USERS_TABLE_SORT_QUERY_SELECTOR,
    sortByDir: `${DELETED_USERS_TABLE_SORT_QUERY_SELECTOR}Dir`,
};
