import React, { useEffect, createContext, useContext } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { withRouter } from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';

import ProjectsOverview from '../ProjectsOverview';
import Tasks from 'components/Tasks';
import ActiveHolidaysDashboard from '../ActiveHolidaysDashboard';

import { fetchAgencyDashboardInformation } from 'actions/agencyActions';
import {
    getDashboardDataFetchState,
} from 'reducers';
import { employeeRequiredFields, employeeRoles } from 'components/Employees/EmployeesConstants';
import { DATE_FORMAT_WITH_DASH } from '../../../constants/commonConstants';

export const AgencyAdminDashboardContext = createContext();
const { Provider } = AgencyAdminDashboardContext;

const AgencyAdminDashboard = ({
    userId,
    agencyId,
    fetchAgencyDashboardInformation,
    children,
    dataFetchState
}) => {
    const context = { userId, agencyId, dataFetchState };

    useEffect(() => {
        fetchAgencyDashboardInformation(
            agencyId,
            moment().format(DATE_FORMAT_WITH_DASH),
            moment().add(14, 'day').format(DATE_FORMAT_WITH_DASH),
            employeeRequiredFields,
            employeeRoles
        );
    }, []);

    return (
        <div>
            {
                dataFetchState?.isDataFetching && !dataFetchState.isDataFetched
                    ? <CircularProgress size={50} disableShrink className="page-loader" />
                    : <Provider value={context}>
                        <div className="agency-dashboard-wrapper">
                            {children}
                        </div>
                    </Provider>
            }
        </div>
    )
}

const TasksWrapper = () => {
    const { userId, agencyId } = useContext(AgencyAdminDashboardContext);

    return <Tasks
        agencyId={agencyId}
        agencyDashboard={true}
        match={{ params: { id: userId } }}
        userId={userId} />
}

const ProjectsOverviewWrapper = () => {
    const { agencyId, dataFetchState } = useContext(AgencyAdminDashboardContext);

    return <ProjectsOverview
        isDataAvailable={true}
        agencyDashboard={true}
        agencyId={agencyId}
        dataFetchState={dataFetchState}
    />
};

const ActiveHolidays = () => {
    const { dataFetchState } = useContext(AgencyAdminDashboardContext);

    return <ActiveHolidaysDashboard dataFetchState={dataFetchState}/>
}


AgencyAdminDashboard.ProjectsOverveiw = ProjectsOverviewWrapper;
AgencyAdminDashboard.Tasks = TasksWrapper;
AgencyAdminDashboard.ActiveHolidays = ActiveHolidays;

const mapStateToProps = (state, props) => ({
    dataFetchState: getDashboardDataFetchState(state),
})

const mapDispatchToProps = {
    fetchAgencyDashboardInformation
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AgencyAdminDashboard));
