import React, { useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

import {
    createContract,
    deleteContract,
    downloadContractFiles,
    fetchContractFilesUrl,
    sendContractSignReminderEmail,
} from 'actions/contractActions';

import { Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';

import TableWrapper from 'components/Shared/TableWrapper/TableWrapper';
import ConfirmDeleteDialog from 'components/Shared/ConfirmDeleteDialog';
import ModalPdfViewer from 'components/Shared/ModalPdfViewer';

import AddContractDialog from '../AddContractDialog';

import { logEvent } from 'utils/amplitude';
import { usePreloadTable } from 'utils/hooks';

import { ROLES } from 'constants/userConstants';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitudeConstants';
import { tableColumns, defaultTableColumns, SORT_QUERY_SELECTOR, PAGE_QUERY_SELECTOR, SEARCH_QUERY_SELECTOR, TABLE_INITIAL_QUERY, TABLE_QUERY_MAP } from './ContractTableConstants';
import { CONTRACT_SIGNATURE_STATUSES } from 'constants/contractsConstants';
import { DATE_FORMAT_WITH_DOTS_FULL_YEAR } from 'constants/commonConstants';
import { DEFAULT_ITEMS_PER_PAGE } from 'components/Shared/TableWrapper/TableWrapperConstants';

import './ContractsTable.scss';

const ContractsTable = ({
    agencyId,
    userId,
    departmentId,
    isAdmin,
    isEvrotrustIntegrated,
    agencyInfo,
    contractTypes,
    receiverRole,
    dataSelector,
    fetchAction,
    createContract,
    deleteContract,
    downloadContractFiles,
    fetchContractFilesUrl,
    sendContractSignReminderEmail,
}) => {

    const [reload, setReload] = useState(false);
    const [isTableDataLoading, totalCount] = usePreloadTable(TABLE_INITIAL_QUERY, TABLE_QUERY_MAP, null, fetchAction, reload, setReload);

    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [openPdfDialog, setOpenPdfDialog] = useState(false);
    const [selectedContract, setSelectedContract] = useState(null);
    const [currentFilesUrl, setCurrentFilesUrl] = useState(null);

    const getContracts = (state, page) => dataSelector(state, page - 1, DEFAULT_ITEMS_PER_PAGE);

    const handleCloseDialog = () => setOpenAddDialog(false);
    const handleConfirmDialogClose = () => setOpenConfirmDialog(false);

    const handleClosePdfDialog = () => {
        setOpenPdfDialog(false);
    }

    const handleOpenPdfDialog = (contract) => {
        fetchContractFilesUrl(agencyId, userId, contract._id).then((result) => {
            setCurrentFilesUrl(result.filesUrl);
            setOpenPdfDialog(true);
        });
    }

    const handleOpenDialog = () => {
        setOpenAddDialog(true);

        if (receiverRole === ROLES.EMPLOYEE) {
            logEvent(AMPLITUDE_EVENT_TYPES.CLICK_ADD_EMPLOYEE_CONTRACT);
        } else if (receiverRole === ROLES.CLIENT) {
            logEvent(AMPLITUDE_EVENT_TYPES.CLICK_ADD_CLIENT_CONTRACT);
        } else {
            logEvent(AMPLITUDE_EVENT_TYPES.CLICK_ADD_SUPPLIER_CONTRACT);
        }
    }

    const addContractHandler = (contract, eSignatureRequired) => {
        createContract(agencyId, userId, contract, receiverRole, true, eSignatureRequired).then(() => {
            setReload(true);
        });
    };

    const concatName = str => str.replace(/\s/g, '').trim();

    const handleContractDownloadClick = (contract) => {
        if (contract) {
            const fileName = `${concatName(contract.title)}-${concatName(contract.type)}`;

            return downloadContractFiles(agencyId, userId, contract._id, fileName.toLocaleLowerCase());
        }
    };

    const handleContractDeleteClick = (contract) => {
        setSelectedContract(contract);
        setOpenConfirmDialog(true);
    };

    const handleContractReminderEmailClick = (contract) => {
        sendContractSignReminderEmail(agencyId, userId, contract._id);
    };

    const handleContractDelete = () => {
        setOpenConfirmDialog(false);
        deleteContract(agencyId, selectedContract._id, departmentId);
    };

    const allActions = [
        { title: "Resend Email", handleClick: handleContractReminderEmailClick },
        { title: "Download Contract", handleClick: handleContractDownloadClick },
        { title: "Delete Contract", handleClick: handleContractDeleteClick },
        { title: "View Contract", handleClick: handleOpenPdfDialog },
    ];

    const employeeActions = [
        { title: "Download Contract", handleClick: handleContractDownloadClick },
        { title: "View Contract", handleClick: handleOpenPdfDialog },
    ];

    const getPopoverActions = () => {
        if (isAdmin) {
            return allActions;
        } else {
            return employeeActions;
        }
    };

    const getContractDocumentsInformation = contract => {
        return contract.uploadedFiles.map((file, index) =>
            <div key={index}>
                {contract.dateSigned && <p>Date signed: {moment(contract.dateSigned).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR)}</p>}
                {file.trackingInfo?.transactions.length > 0 ? file.trackingInfo.transactions.map(transaction =>
                    <div key={transaction.transactionID}>
                        <p>Email: {transaction.email} </p>
                        <p>Document Processed: {transaction.isProcessing ? 'No' : 'Yes'} </p>
                        {(file.signatureStatus === CONTRACT_SIGNATURE_STATUSES.SIGNED || transaction.status) &&
                            <p>
                                Document Status: {file.signatureStatus === CONTRACT_SIGNATURE_STATUSES.SIGNED ? CONTRACT_SIGNATURE_STATUSES.SIGNED : transaction.status}
                            </p>
                        }
                    </div>
                ) : null}
            </div>
        );
    };

    const adjustKeys = contracts => contracts.map(contract => {
        const endDate = contract.endDate ? moment.utc(contract.endDate).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR) : 'ongoing';

        return {
            uploadDate: moment(contract.uploadDate).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR),
            title: contract.title,
            startDate: `${moment.utc(contract.startDate).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR)} - ${endDate}`,
            type: contract.type,
            signatureStatus: (
                <div className="signature-status-wrapper">
                    <span className={contract.signatureStatus}>{contract?.signatureStatus === 'Pending' ? '' : contract.signatureStatus}</span>
                    {contract?.signatureStatus === 'Pending' ? <CircularProgress size={25} disableShrink className="status-loader" /> :
                        (isAdmin && contract?.uploadedFiles?.some(x => x.trackingInfo))
                            ? <Tooltip title={getContractDocumentsInformation(contract)} classes={{ tooltip: 'custom-tooltip' }}
                            >
                                <InfoOutlinedIcon className="global-information-icon" />
                            </Tooltip>
                            : null
                    }
                </div>
            ),
            item: contract,
        };
    });

    return (
        <div className="contracts-table-wrapper">
            <TableWrapper
                title="Contracts"
                columns={tableColumns}
                defaultColumns={defaultTableColumns}
                dataSelector={getContracts}
                sortQuerySelector={SORT_QUERY_SELECTOR}
                adjustKeysFunction={adjustKeys}
                isTableDataLoading={isTableDataLoading}
                withPagination
                addMargin
                pagesCount={Math.ceil(totalCount / DEFAULT_ITEMS_PER_PAGE)}
                pageQuerySelector={PAGE_QUERY_SELECTOR}
                withActions
                getActionsHandler={getPopoverActions}
            >
                <TableWrapper.TableAlignedItems>
                    <TableWrapper.TableAlignedItems.LeftAligned>
                        <TableWrapper.TableAlignedItems.TableHeader />
                        <TableWrapper.TableAlignedItems.TableSearch queryParamName={SEARCH_QUERY_SELECTOR} />
                    </TableWrapper.TableAlignedItems.LeftAligned>
                    <TableWrapper.TableAlignedItems.RightAligned>
                        { isAdmin &&
                            <TableWrapper.TableAlignedItems.TableActionButton text="New Contract" handleClick={handleOpenDialog} />
                        }
                    </TableWrapper.TableAlignedItems.RightAligned>
                </TableWrapper.TableAlignedItems>
                <TableWrapper.TableContent />

                <AddContractDialog
                    contractTypes={contractTypes}
                    openDialog={openAddDialog}
                    handleCloseDialog={handleCloseDialog}
                    addContractHandler={addContractHandler}
                    receiverRole={receiverRole}
                    isEvrotrustIntegrated={isEvrotrustIntegrated}
                    departmentId={departmentId}
                    agencyInfo={agencyInfo}
                />

                <ConfirmDeleteDialog
                    itemToDelete="the selected contract"
                    handleDeleteItem={handleContractDelete}
                    openDialog={openConfirmDialog}
                    handleCloseDialog={handleConfirmDialogClose}
                />

                <ModalPdfViewer
                    open={openPdfDialog}
                    onClose={handleClosePdfDialog}
                    filesUrl={currentFilesUrl}
                />
            </TableWrapper>
        </div>
    );
};

const mapDispatchToProps = {
    createContract,
    deleteContract,
    downloadContractFiles,
    fetchContractFilesUrl,
    sendContractSignReminderEmail,
};

export default connect(null, mapDispatchToProps)(ContractsTable);
