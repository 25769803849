import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Formik } from 'formik';
import {
    Dialog,
    IconButton,
    DialogTitle,
    DialogContent
} from '@material-ui/core';
import IconClose from '@material-ui/icons/Close';

import { getAgencyId } from 'reducers';
import { deleteRecruiterEmployeeCV, uploadRecruiterEmployeeCV } from 'actions/recruiterActions';

import UploadCVDialogFormView from './UploadCVDialogFormView';
import { validateFilesArrayLength } from 'utils/formValidations';

import './UploadCVDialog.scss';

const UploadCVDialog = ({
    agencyId,
    openDialog,
    recruiterId,
    filesAttached,
    handleCloseDialog,
    recruiterEmployeeInfo,
    uploadRecruiterEmployeeCV,
    deleteRecruiterEmployeeCV,
}) => {
    const { id } = useParams();

    const handleDeleteFile = () => {
        if (recruiterEmployeeInfo.profileCV) {
            deleteRecruiterEmployeeCV(agencyId, recruiterId, id);
        }
    }

    return (
        <Dialog className='upload-cv-dialog' open={openDialog} onClose={handleCloseDialog}>
            <DialogTitle className='dialog-header'>
                Upload CV
                <IconButton className="dialog-close-btn" onClick={handleCloseDialog}>
                    <IconClose />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Formik
                    initialValues={{
                        filesAttached: filesAttached || []
                    }}
                    validate={values => {
                        return {
                            ...validateFilesArrayLength(values, 'filesAttached', 1)
                        }
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(false);
                        const { filesAttached, ...rest } = values;
                        const data = new FormData();
                        data.append('cvs', JSON.stringify({ ...rest, filesCount: filesAttached.length }));

                        filesAttached.forEach((file, i) => {
                            data.append(`attachedfile${i + 1}`, file);
                        });

                        uploadRecruiterEmployeeCV(agencyId, recruiterId, id, data, filesAttached[0].name);
                        handleCloseDialog();
                    }}>
                    {(props) => (
                        <UploadCVDialogFormView
                            {...props}
                            handleDeleteFile={handleDeleteFile}
                            handleCloseDialog={handleCloseDialog}
                        />
                    )}
                </Formik>
            </DialogContent>
        </Dialog >
    )
}

const mapStateToProps = state => ({
    agencyId: getAgencyId(state),
})

const mapDispatchToProps = {
    uploadRecruiterEmployeeCV,
    deleteRecruiterEmployeeCV,
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadCVDialog);
