import React from 'react';

import { getEmployees } from 'reducers';

import TableWrapper from 'components/Shared/TableWrapper';
import Avatar from '@material-ui/core/Avatar';

import { ReactComponent as AddIcon } from 'assets/add-button-icon.svg';

import {
    TABLE_COLUMNS,
    DEFAULT_TABLE_COLUMNS,
    SORT_QUERY_SELECTOR,
    PAGE_QUERY_SELECTOR,
    SEARCH_QUERY_SELECTOR,
    DEFAULT_ITEMS_PER_PAGE,
    TABLE_INITIAL_QUERY,
    TABLE_QUERY_MAP,
} from './DepartmentMembersTableConstants';

import { usePreloadTable } from 'utils/hooks';

const DepartmentMembersTable = ({
    handleOpenAddDialog,
    reload,
    setReload,
    tableFetchAction,
}) => {
    const dataSelector = (state, page) => getEmployees(state, page - 1, DEFAULT_ITEMS_PER_PAGE);

    const [isTableDataLoading, totalCount] = usePreloadTable(
        TABLE_INITIAL_QUERY,
        TABLE_QUERY_MAP,
        null,
        tableFetchAction,
        reload,
        setReload,
    );

    const generateToString = (member) => {
        return `members/${member._id}`;
    };

    const adjustMembersKeys = members => members.map(member => {
        return {
            name: (
                <div className="logo-name-wrapper">
                    <Avatar
                        className="avatar-logo-image"
                        alt="Member Avatar"
                        src={member.profilePictureImgUrl}
                    />
                    <div className="name-text">
                        {member.name}
                    </div>
                </div>
            ),
            email: member.email || '-',
            pendingHolidaysCount: (
                member.pendingHolidaysCount > 0
                    ? <div className="pending-holidays-count">
                        <span>{`${member.pendingHolidaysCount > 1 ? `${member.pendingHolidaysCount} pending holidays` : `1 pending holiday`}`}</span>
                    </div>
                    : '-'
            ),
            item: member,
        };
    });

    return (
        <TableWrapper
            title='My Department'
            columns={TABLE_COLUMNS}
            defaultColumns={DEFAULT_TABLE_COLUMNS}
            dataSelector={dataSelector}
            sortQuerySelector={SORT_QUERY_SELECTOR}
            adjustKeysFunction={adjustMembersKeys}
            isTableDataLoading={isTableDataLoading}
            addMargin
            withPagination
            withLink
            generateToString={generateToString}
            pagesCount={Math.ceil(totalCount / DEFAULT_ITEMS_PER_PAGE)}
            pageQuerySelector={PAGE_QUERY_SELECTOR}
        >
            <TableWrapper.TableAlignedItems>
                <TableWrapper.TableAlignedItems.LeftAligned>
                    <TableWrapper.TableAlignedItems.TableHeader />
                    <TableWrapper.TableAlignedItems.TableSearch queryParamName={SEARCH_QUERY_SELECTOR} />
                </TableWrapper.TableAlignedItems.LeftAligned>
                <TableWrapper.TableAlignedItems.RightAligned>
                    <TableWrapper.TableAlignedItems.TableActionButton
                        text='Add Employee'
                        icon={<AddIcon />}
                        handleClick={handleOpenAddDialog}
                    />
                </TableWrapper.TableAlignedItems.RightAligned>
            </TableWrapper.TableAlignedItems>
            <TableWrapper.TableContent />
        </TableWrapper>
    );
};

export default DepartmentMembersTable;
