import { persistReducer } from 'redux-persist';

import clientProjects from './clientProjectsReducer';
import clientUsers from './clientUsersReducer';

import { createStructuredSelector } from 'reselect';
import moment from 'moment';
import {
    CLIENT_INFORMATION_FETCH_SUCCESS,
    CLIENT_AVATAR_UPDATE_SUCCESS,
    CLIENT_AVATAR_DELETE_SUCCESS,
    CLIENT_BASIC_INFORMATION_UPDATE_SUCCESS,
    CLIENT_CONTACT_INFORMATION_UPDATE_SUCCESS,
    CLIENT_INVOICE_INFORMATION_UPDATE_SUCCESS,
    CLIENT_PROJECTS_FETCH_SUCCESS,
    CLIENT_PROJECT_ADD_SUCCESS,
    PROJECT_REVIEWS_FETCH_SUCCESS,
    EMPLOYEE_TO_PROJECT_ADD_SUCCESS,
    DEDICATED_EMPLOYEE_TO_PROJECT_ADD_SUCCESS,
    DEDICATED_EMPLOYEE_TO_PROJECT_UPDATE_SUCCESS,
    AGENCY_CLIENT_HOME_INFORMATION_FETCH_SUCCESS,
    HOME_INFORMATION_INITIAL_STATE_SET,
    PROJECT_EMPLOYEE_UPDATE_SUCCESS,
    PROJECT_EMPLOYEE_DELETE_SUCCESS,
    PROJECT_DEDICATED_EMPLOYEE_DELETE_SUCCESS,
    CLIENT_PROJECT_UPDATE_SUCCESS,
    INVOICE_ADD_SUCCESS,
    INVOICES_FETCH_SUCCESS,
    INVOICE_EDIT_SUCCESS,
    INVOICE_STATUS_UPDATE_SUCCESS,
    CLIENT_ACCOUNT_MANAGEMENT_INFORMATION_UPDATE_SUCCESS,
    CLIENT_TASKS_FETCH_SUCCESS,
    CLIENT_TASK_CREATE_SUCCESS,
    CLIENT_TASK_EDIT_SUCCESS,
    CONTRACT_ADD_SUCCESS,
    CONTRACTS_FETCH_SUCCESS,
    SUPPLIER_EMPLOYEES_PROJECTS_FETCH_SUCCESS,
    CLIENT_PROJECT_FILES_ADD_SUCCESS,
    CLIENT_PROJECT_DOCUMENTS_FETCH_SUCCESS,
    CLIENT_PROJECT_LINK_ADD_SUCCESS,
    CLIENT_PROJECT_LINK_DELETE_SUCCESS,
    CLIENT_PROJECT_FILE_DELETE_SUCCESS,
    CLIENT_PROJECTS_FEEDBACK_FETCH_SUCCESS,
    CLIENT_ACCOUNT_MANAGER_INFORMATION_FETCH_SUCCESS,
    CLIENT_PROJECT_FETCH_PENDING,
    CLIENT_PROJECT_FETCH_SUCCESS,
    AGENCY_TASK_DELETE_SUCCESS,
    CLIENT_PROJECT_EMPLOYEE_HISTORY_FETCH_SUCCESS,
    ALL_CLIENT_TEAM_HISTORY_FETCH_SUCCESS,
    INVOICE_DELETE_SUCCESS,
    CLIENT_TOTAL_REVENUE_FETCH_SUCCESS,
    CLIENT_PROFILE_PAGE_UNMOUNTED_SUCCESS,
    CLIENT_INTERVIEW_DATES_FETCH_SUCCESS,
    CLIENT_NOTIFICATIONS_FETCH_SUCCESS,
    CLIENT_ADMINS_FETCH_SUCCESS,
    CLIENT_ADMINS_REPLACE_SUCCESS,
    CLIENT_ADMIN_ADD_SUCCESS,
    CLIENT_ADMIN_DELETE_SUCCESS,
    CLIENT_USERS_FETCH_SUCCESS,
    CLIENT_REGISTER_FETCH_SUCCESS,
    USER_UPDATE_SUCCESS,
    USERS_DISABLE_SUCCESS,
    CLIENT_PROJECTS_COUNT_FETCH_SUCCESS,
    CLIENT_TEAM_MEMBERS_COUNT_FETCH_SUCCESS,
    USERS_RESTORE_SUCCESS,
    CLIENT_INVOICING_FETCH_SUCCESS,
    INVOICES_RESET_SUCCESS,
    CLIENT_USERS_REPLACE_SUCCESS,
    ACCOUNT_MANAGER_FETCH_ALL_SUCCESS,
} from 'actions/actionTypes';

import { PERSIST_CLIENT_CONFIG } from 'utils/persistUtils';

const invoiceInitialState = {
    _id: '',
    projectId: '',
    period: '',
    issueDate: '',
    dueDate: '',
    recipientEmail: '',
    amount: 0,
    projectName: '',
    status: '',
    amountPaid: '',
    currencyPaid: '',
    paymentDate: '',
    invoiceNumber: ''
};

const invoice = (state = invoiceInitialState, action = {}) => {
    if (state._id && state._id !== action.payload._id) {
        return state;
    }

    switch (action.type) {
        case INVOICE_STATUS_UPDATE_SUCCESS:
        case INVOICE_EDIT_SUCCESS:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

const clientTasksInitialState = [];

const clientTasks = (state = clientTasksInitialState, action = {}) => {
    switch (action.type) {
        case CLIENT_TASKS_FETCH_SUCCESS:
            return action.payload || clientTasksInitialState
        case CLIENT_TASK_CREATE_SUCCESS:
            return [...state, action.payload]
        case CLIENT_TASK_EDIT_SUCCESS:
            return state.map(task => {
                if (task._id === action.payload._id) {
                    return action.payload
                }
                return task
            })
        case AGENCY_TASK_DELETE_SUCCESS:
            return state.filter(x => x._id !== action.payload)
        default:
            return state;
    }

};

const projectsFeedbackInitialState = [];

const projectsFeedback = (state = projectsFeedbackInitialState, action = {}) => {
    switch (action.type) {
        case CLIENT_PROJECTS_FEEDBACK_FETCH_SUCCESS:
            return action.payload || projectsFeedbackInitialState;
        default:
            return state;
    }
};

const clientInitialState = {
    basic: {
        name: '',
        country: '',
        email: '',
        hqLocation: '',
        obfuscatedName: '',
        adminEmails: [],
        date: null,
        referrer: '',
    },
    contact: {
        contactPerson: '',
        contactEmail: '',
        contactNumber: ''
    },
    invoicing: {
        currency: ''
    },
    logoImgUrl: '',
    logoImgName: '',
    logoImgDetails: {
        width: 0,
        height: 0,
        size: 0,
        sizeBits: 'B',
        extension: '',
    },
    accountManager: {
        name: '',
        email: '',
        phone: '',
        _id: '',
        profilePictureImgUrl: '',
        contactNumber: '',
    },
    accountManagers: [],
    totalRevenue: 0,
    contracts: [],
    invoices: [],
    projects: clientProjects(),
    tasks: clientTasks(),
    status: '',
    currency: 0,
    projectsFeedback: projectsFeedback(),
    clientId: '',
    interviewDates: [],
    notifications: [],
    admins: [],
    users: clientUsers(),
};


const client = (state = clientInitialState, action) => {
    switch (action.type) {
        case CONTRACTS_FETCH_SUCCESS:
            return { ...state, contracts: action.payload.contracts };
        case CONTRACT_ADD_SUCCESS:
            const clientContracts = state.contracts || [];
            return { ...state, contracts: [action.payload.contract, ...clientContracts] };
        case INVOICES_FETCH_SUCCESS:
            return { ...state, invoices: [...state.invoices, ...action.payload] };
        case INVOICES_RESET_SUCCESS:
            return { ...state, invoices: action.payload };
        case INVOICE_DELETE_SUCCESS:
            return { ...state, invoices: state.invoices.filter(x => x._id !== action.payload) };
        case INVOICE_ADD_SUCCESS:
            const clientInvoices = state.invoices;
            return { ...state, invoices: [action.payload, ...clientInvoices] };
        case INVOICE_STATUS_UPDATE_SUCCESS:
        case INVOICE_EDIT_SUCCESS:
            return { ...state, invoices: state.invoices.map(x => invoice(x, action)) };
        case CLIENT_INVOICING_FETCH_SUCCESS:
            return { ...state, invoicing: action.payload.invoicing }

        case SUPPLIER_EMPLOYEES_PROJECTS_FETCH_SUCCESS:
        case CLIENT_PROJECTS_FETCH_SUCCESS:
            return { ...state, projects: clientProjects(undefined, action) };
        case PROJECT_REVIEWS_FETCH_SUCCESS:
            return {
                ...state,
                projects: clientProjects(state.projects, action),
            };
        case EMPLOYEE_TO_PROJECT_ADD_SUCCESS:
            return {
                ...state,
                projects: clientProjects(state.projects, action),
            };
        case DEDICATED_EMPLOYEE_TO_PROJECT_ADD_SUCCESS:
            return {
                ...state,
                projects: clientProjects(state.projects, action)
            }
        case CLIENT_PROJECT_ADD_SUCCESS:
        case CLIENT_PROJECT_UPDATE_SUCCESS: {
            return {
                ...state,
                projects: clientProjects(state.projects, action)
            };
        }
        case CLIENT_TOTAL_REVENUE_FETCH_SUCCESS:
            return { ...state, totalRevenue: action.payload.totalRevenue };
        case CLIENT_ACCOUNT_MANAGER_INFORMATION_FETCH_SUCCESS:
            return { ...state, accountManager: action.payload.accountManager };
        case CLIENT_INFORMATION_FETCH_SUCCESS:
            return { ...state, ...action.payload };
        case CLIENT_BASIC_INFORMATION_UPDATE_SUCCESS:
        case CLIENT_CONTACT_INFORMATION_UPDATE_SUCCESS:
        case CLIENT_INVOICE_INFORMATION_UPDATE_SUCCESS:
        case CLIENT_ACCOUNT_MANAGEMENT_INFORMATION_UPDATE_SUCCESS:
        case CLIENT_AVATAR_UPDATE_SUCCESS:
            return { ...state, ...action.payload };
        case HOME_INFORMATION_INITIAL_STATE_SET:
            return {
                ...state,
                logoImgUrl: '',
                basic: { ...state.basic, name: '' },
                contact: { ...state.contact, contactPerson: '' },
                clientId: ''
            };
        case AGENCY_CLIENT_HOME_INFORMATION_FETCH_SUCCESS:
            return {
                ...state,
                logoImgUrl: action.payload.logoImgUrl || '',
                basic: { ...state.basic, name: action.payload.name },
                contact: { ...state.contact, contactPerson: action.payload.contactPerson },
                clientId: action.payload.clientId,
            };
        case CLIENT_AVATAR_DELETE_SUCCESS:
            return {
                ...state,
                logoImgUrl: '',
                logoImgName: '',
                logoImgDetails: {
                    width: 0,
                    height: 0,
                    size: 0,
                    sizeBits: 'B',
                    extension: ''
                }
            };
        case PROJECT_EMPLOYEE_UPDATE_SUCCESS:
        case DEDICATED_EMPLOYEE_TO_PROJECT_UPDATE_SUCCESS:
        case PROJECT_DEDICATED_EMPLOYEE_DELETE_SUCCESS:
        case PROJECT_EMPLOYEE_DELETE_SUCCESS:
            return {
                ...state,
                projects: clientProjects(state.projects, action)
            };
        case CLIENT_TASKS_FETCH_SUCCESS:
        case CLIENT_TASK_CREATE_SUCCESS:
        case CLIENT_TASK_EDIT_SUCCESS:
        case AGENCY_TASK_DELETE_SUCCESS:
            return {
                ...state,
                tasks: clientTasks(state.tasks, action)
            };
        case CLIENT_PROJECTS_FEEDBACK_FETCH_SUCCESS:
            return {
                ...state,
                projectsFeedback: projectsFeedback(state.projectsFeedback, action)
            };
        case CLIENT_PROJECT_EMPLOYEE_HISTORY_FETCH_SUCCESS:
        case CLIENT_PROJECT_FETCH_PENDING:
        case CLIENT_PROJECT_FETCH_SUCCESS:
        case CLIENT_PROJECT_DOCUMENTS_FETCH_SUCCESS:
        case CLIENT_PROJECT_LINK_ADD_SUCCESS:
        case CLIENT_PROJECT_LINK_DELETE_SUCCESS:
        case CLIENT_PROJECT_FILE_DELETE_SUCCESS:
        case CLIENT_PROJECTS_COUNT_FETCH_SUCCESS:
        case CLIENT_TEAM_MEMBERS_COUNT_FETCH_SUCCESS:
        case CLIENT_PROJECT_FILES_ADD_SUCCESS: {
            return {
                ...state,
                projects: clientProjects(state.projects, action)
            };
        }
        case ALL_CLIENT_TEAM_HISTORY_FETCH_SUCCESS:
            return { ...state, allClientTeamHistory: action.payload }
        case CLIENT_INTERVIEW_DATES_FETCH_SUCCESS:
            return { ...state, interviewDates: action.payload }
        case CLIENT_PROFILE_PAGE_UNMOUNTED_SUCCESS:
            return clientInitialState;
        case CLIENT_NOTIFICATIONS_FETCH_SUCCESS:
            return { ...state, notifications: action.payload };
        case CLIENT_ADMINS_FETCH_SUCCESS:
            return { ...state, admins: [...state.admins, ...action.payload] };
        case CLIENT_ADMINS_REPLACE_SUCCESS:
            return { ...state, admins: action.payload };
        case CLIENT_ADMIN_ADD_SUCCESS:
            return { ...state, admins: [...state.admins, action.payload] }
        case CLIENT_ADMIN_DELETE_SUCCESS:
            return { ...state, admins: state.admins.filter(admin => admin._id !== action.payload) }
        case CLIENT_USERS_FETCH_SUCCESS:
        case CLIENT_USERS_REPLACE_SUCCESS:
        case CLIENT_REGISTER_FETCH_SUCCESS:
        case USER_UPDATE_SUCCESS:
        case USERS_DISABLE_SUCCESS:
            return { ...state, users: clientUsers(state.users, action) };
        case ACCOUNT_MANAGER_FETCH_ALL_SUCCESS:
            return { ...state, accountManagers: action.payload }
        case USERS_RESTORE_SUCCESS:
            return { ...state, isDeleted: false }
        default:
            return state;
    }
};

export default persistReducer(PERSIST_CLIENT_CONFIG, client);

export const getClientBasicInformation = state => ({
    name: state.basic.name,
    country: state.basic.country,
    email: state.basic.email,
    hqLocation: state.basic.hqLocation,
    obfuscatedName: state.basic.obfuscatedName,
    logoImgUrl: state.logoImgUrl,
    logoImgDetails: state.logoImgDetails,
    adminEmails: state.basic.adminEmails,
    registrationDate: state.basic.registrationDate,
    referrer: state.basic.referrer,
});

const clientNameSelector = (state) => state.basic.name;
const clientContactPersonSelector = (state) => state.contact.contactPerson;
const clientLogoSelector = (state) => state.logoImgUrl;
export const getClientMenuInformation = createStructuredSelector({
    name: clientNameSelector,
    contactPerson: clientContactPersonSelector,
    logoImgUrl: clientLogoSelector,
});

export const getInvoices = (state, page, count) => {
    const from = page * count;
    const to = page * count + count;

    return state.invoices.slice(from, to || state.invoices.length)
};

export const getAllInvoices = state => state.invoices;

export const getClientContactInformation = state => state.contact;
export const getClientInvoicingInformation = state => state.invoicing;
export const getClientProjects = (state) => state.projects.projectsList;
export const getClientAccountManager = state => state.accountManager;
export const getClientTasks = state => state.tasks;
export const getClientProjectsFeedback = state => state.projectsFeedback;
export const getSelectedClient = (state, clientId) => {
    return {
        _id: clientId,
        name: state.basic.name
    }
}

export const getProjectFeedback = (state, projectId) => {
    const project = state.projects.projectsList.find(x => x._id === projectId);
    return project?.feedback || {};
}

export const getClientStatus = state => state.status;
export const getProject = (state) => state.projects.projectDetails;

export const getAllClientTeamHistory = (state) => state.allClientTeamHistory;
export const getProjectEmployeeHistory = (state) => state.projects.projectEmployeeHistory;

export const getClientProjectDocuments = (state, page, count, sortBy, sortByDir) => {
    const project = { ...state.projects.projectDetails };

    let allProjectDocuments = [...(project.documents?.files.map(x => ({ ...x, name: x.originalFileName })) || []), ...(project.documents?.links || [])];

    if (sortBy && sortByDir) {
        const dirToInt = sortByDir === "asc" ? 1 : -1;
        allProjectDocuments = allProjectDocuments.sort((a, b) => {
            if (typeof a[sortBy] === 'string' && typeof b[sortBy] === 'string') {
                return dirToInt === 1 ? a[sortBy].localeCompare(b[sortBy]) : b[sortBy].localeCompare(a[sortBy]);
            } else {
                return dirToInt === 1 ? moment(a[sortBy]).isBefore(moment(b[sortBy])) : moment(b[sortBy]).isBefore(moment(a[sortBy]));
            }
        });
    }

    const from = page * count;
    const to = page * count + count;

    return {
        result: allProjectDocuments.slice(from, to || allProjectDocuments.length),
        count: allProjectDocuments.length,
    }
};

export const getProjectContractEmployeesHistory = (state, page, count, sortBy, sortByDir) => {
    let employees = [...state.projects.projectDetails.employees.filter(x => x.isDeleted)];

    if (sortBy && sortByDir) {
        const dirToInt = sortByDir === "asc" ? 1 : -1;
        employees = employees
            .map(x => ({ ...x, name: `${x.firstName} ${x.lastName}` }))
            .sort((a, b) => {
                if (typeof a[sortBy] === 'string' && typeof b[sortBy] === 'string') {
                    return dirToInt === 1 ? a[sortBy].localeCompare(b[sortBy]) : b[sortBy].localeCompare(a[sortBy]);
                } else {
                    return dirToInt === 1 ? moment(a[sortBy]).isBefore(moment(b[sortBy])) : moment(b[sortBy]).isBefore(moment(a[sortBy]));
                }
            });
    }

    const from = page >= 0 && count ? page * count : 0;
    const to = page >= 0 && count ? page * count + count : employees.length;

    return {
        result: employees.slice(from, to || employees.length),
        count: employees.length,
    }
}

export const getProjectFullTimeEmployeesHistory = (state, page, count, sortBy, sortByDir) => {
    let employees = [...state.projects.projectDetails.dedicatedEmployees.filter(x => x.isDeleted)];

    if (sortBy && sortByDir) {
        const dirToInt = sortByDir === "asc" ? 1 : -1;
        employees = employees
            .map(x => ({ ...x, name: `${x.firstName} ${x.lastName}` }))
            .sort((a, b) => {
                if (typeof a[sortBy] === 'string' && typeof b[sortBy] === 'string') {
                    return dirToInt === 1 ? a[sortBy].localeCompare(b[sortBy]) : b[sortBy].localeCompare(a[sortBy]);
                } else {
                    return dirToInt === 1 ? moment(a[sortBy]).isBefore(moment(b[sortBy])) : moment(b[sortBy]).isBefore(moment(a[sortBy]));
                }
            });
    }

    const from = page >= 0 && count ? page * count : 0;
    const to = page >= 0 && count ? page * count + count : employees.length;

    return {
        result: employees.slice(from, to || employees.length),
        count: employees.length,
    }
};

export const getProjectAllEmployeesHistory = (state, page, count, sortBy, sortByDir) => {
    let employees = [
        ...state.projects.projectDetails.dedicatedEmployees.filter(x => x.isDeleted),
        ...state.projects.projectDetails.employees.filter(x => x.isDeleted)
    ];

    if (sortBy && sortByDir) {
        const dirToInt = sortByDir === "asc" ? 1 : -1;
        employees = employees
            .map(x => ({ ...x, name: `${x.firstName} ${x.lastName}` }))
            .sort((a, b) => {
                if (typeof a[sortBy] === 'string' && typeof b[sortBy] === 'string') {
                    return dirToInt === 1 ? a[sortBy].localeCompare(b[sortBy]) : b[sortBy].localeCompare(a[sortBy]);
                } else {
                    return dirToInt === 1 ? moment(a[sortBy]).isBefore(moment(b[sortBy])) : moment(b[sortBy]).isBefore(moment(a[sortBy]));
                }
            });
    }

    const from = page >= 0 && count ? page * count : 0;
    const to = page >= 0 && count ? page * count + count : employees.length;

    return {
        result: employees.slice(from, to || employees.length),
        count: employees.length,
    }
}

export const getClientTotalRevenue = state => state.totalRevenue;

export const getClientProjectAllTeamMembers = (state, page, count, sortBy, sortByDir) => {
    let employees = [...state.projects.projectDetails.employees.map(x => ({ ...x, billingType: "Per Hour" })), ...state.projects.projectDetails.dedicatedEmployees.map(x => ({ ...x, billingType: "Monthly" }))];

    if (sortBy && sortByDir) {
        const dirToInt = sortByDir === "asc" ? 1 : -1;
        employees = employees
            .map(x => ({ ...x, name: `${x.firstName} ${x.lastName}` }))
            .sort((a, b) => {
                if (typeof a[sortBy] === 'string' && typeof b[sortBy] === 'string') {
                    return dirToInt === 1 ? a[sortBy].localeCompare(b[sortBy]) : b[sortBy].localeCompare(a[sortBy]);
                } else if (typeof a[sortBy] === 'number' && typeof b[sortBy] === 'number') {
                    return (a[sortBy] - b[sortBy]) * dirToInt;
                } else if (a[sortBy] && b[sortBy]) {
                    return dirToInt === 1 ? moment(a[sortBy]).isBefore(moment(b[sortBy])) : moment(b[sortBy]).isBefore(moment(a[sortBy]));
                } else if (!a[sortBy]) {
                    return 1;
                } else if (!b[sortBy]) {
                    return -1;
                } else {
                    return 0;
                }
            });
    }

    const from = page >= 0 && count ? page * count : 0;
    const to = page >= 0 && count ? page * count + count : employees.length;

    return {
        result: employees.slice(from, to || employees.length),
        count: employees.length,
    }
};

export const getClientProjectFullTimeMembers = (state, page, count, sortBy, sortByDir) => {
    let employees = [...state.projects.projectDetails.dedicatedEmployees].filter(employee => {
        const monthsDiff = moment().startOf('month').diff(moment(employee.dateDeleted).startOf('month'), 'months')

        return !employee.isDeleted || monthsDiff < 2;
    });

    if (sortBy && sortByDir) {
        const dirToInt = sortByDir === "asc" ? 1 : -1;
        employees = employees
            .map(x => ({ ...x, name: `${x.firstName} ${x.lastName}` }))
            .sort((a, b) => {
                if (typeof a[sortBy] === 'string' && typeof b[sortBy] === 'string') {
                    return dirToInt === 1 ? a[sortBy].localeCompare(b[sortBy]) : b[sortBy].localeCompare(a[sortBy]);
                } else if (typeof a[sortBy] === 'number' && typeof b[sortBy] === 'number') {
                    return (a[sortBy] - b[sortBy]) * dirToInt;
                } else if (a[sortBy] && b[sortBy]) {
                    return dirToInt === 1 ? moment(a[sortBy]).isBefore(moment(b[sortBy])) : moment(b[sortBy]).isBefore(moment(a[sortBy]));
                } else if (!a[sortBy]) {
                    return 1;
                } else if (!b[sortBy]) {
                    return -1;
                } else {
                    return 0;
                }
            });
    }

    const from = page >= 0 && count ? page * count : 0;
    const to = page >= 0 && count ? page * count + count : employees.length;

    return {
        result: employees.slice(from, to || employees.length),
        count: employees.length,
    }
}
export const getClientProjectContractMembers = (state, page, count, sortBy, sortByDir) => {
    let employees = [...state.projects.projectDetails.employees].filter(employee => {
        const monthsDiff = moment().startOf('month').diff(moment(employee.dateDeleted).startOf('month'), 'months')

        return !employee.isDeleted || monthsDiff < 2;
    });

    if (sortBy && sortByDir) {
        const dirToInt = sortByDir === "asc" ? 1 : -1;
        employees = employees
            .map(x => ({ ...x, name: `${x.firstName} ${x.lastName}` }))
            .sort((a, b) => {
                if (typeof a[sortBy] === 'string' && typeof b[sortBy] === 'string') {
                    return dirToInt === 1 ? a[sortBy].localeCompare(b[sortBy]) : b[sortBy].localeCompare(a[sortBy]);
                } else if (typeof a[sortBy] === 'number' && typeof b[sortBy] === 'number') {
                    return (a[sortBy] - b[sortBy]) * dirToInt;
                } else if (a[sortBy] && b[sortBy]) {
                    return dirToInt === 1 ? moment(a[sortBy]).isBefore(moment(b[sortBy])) : moment(b[sortBy]).isBefore(moment(a[sortBy]));
                } else if (!a[sortBy]) {
                    return 1;
                } else if (!b[sortBy]) {
                    return -1;
                } else {
                    return 0;
                }
            });
    }

    const from = page >= 0 && count ? page * count : 0;
    const to = page >= 0 && count ? page * count + count : employees.length;

    return {
        result: employees.slice(from, to || employees.length),
        count: employees.length,
    }
}
export const getClientId = state => state.clientId;
export const getClientAdmins = (state, page, count) => {
    const from = page * count;
    const to = page * count + count;

    return state.admins.slice(from, to || state.admins.length);
}
export const getClientUsers = (state, page, count) => {
    const from = page * count;
    const to = page * count + count;

    return state.users.slice(from, to || state.users.length);
}

export const getClientPastAndActiveProjectsCount = state => state.projects.projectsCount;
export const getClientTeamMembersCount = state => state.projects.teamMembersCount;

export const getClientInterviewDates = state => state.interviewDates;
export const getClientNotifications = state => state.notifications;

export const getClientDeleteInformation = state => state.isDeleted;

export const getClientsAccountManagers = state => state.accountManagers;
