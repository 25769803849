import React from 'react';

import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import { ErrorMessage } from 'formik';
import { KeyboardDatePicker } from '@material-ui/pickers';
import SecondaryButton from 'components/Shared/Buttons/SecondaryButton';

import isHoliday from 'hoc/isHoliday';

import { DATE_PICKER_FORMAT_MM_YYYY } from 'constants/timesFormat';

import './AddEditCertificateFormView.scss';

const AddEditCertificateFormView = ({
    values,
    handleChange,
    errors,
    touched,
    handleCloseDialog,
    handleSubmit,
    setFieldValue,
}) => (
        <form className="add-edit-certificate-form" autoComplete="off">
            <div>
                <TextField
                    required
                    className="add-title-field"
                    name="title"
                    value={values.title}
                    onChange={handleChange}
                    id="add-certificate-title"
                    label="Certificate Title"
                    error={errors.title && touched.title}
                    margin="normal"
                    variant="outlined"
                />
                <ErrorMessage name="title" component="div" className="invalid-field-message" />
            </div>
            <div className="date-url-block">
                <div>
                    <TextField
                        className="add-document-url-field"
                        name="documentUrl"
                        value={values.documentUrl}
                        onChange={handleChange}
                        id="add-certificate-url"
                        label="Document URL"
                        error={errors.documentUrl && touched.documentUrl}
                        margin="normal"
                        variant="outlined"
                    />
                    <ErrorMessage name="documentUrl" component="div" className="invalid-field-message" />
                </div>

                <div className="certificate-issue-date-field">
                    <KeyboardDatePicker
                        autoOk={true}
                        className={(errors.issueDate && touched.issueDate) ? "datepicker-field-error" : "datepicker-field"}
                        label="Issue Date"
                        disableToolbar
                        variant="inline"
                        inputVariant="outlined"
                        fullWidth
                        value={values.issueDate}
                        onChange={value => {
                            setFieldValue('issueDate', moment(value));
                        }}
                        openTo="year"
                        views={["year", "month"]}
                        renderDay={isHoliday}
                        format={DATE_PICKER_FORMAT_MM_YYYY}
                        allowKeyboardControl={true}
                    />
                </div>
            </div>
            <DialogActions className="dialog-actions">
                <SecondaryButton buttonStyle="discard" handleClick={handleCloseDialog} text="Discard" />
                <SecondaryButton buttonStyle="save" handleClick={handleSubmit} text="Save" type="submit" />
            </DialogActions>
        </form>
    );

export default AddEditCertificateFormView;
