import { useState } from 'react';

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { ReactComponent as CheckIcon } from 'assets/icon-check.svg';
import { ReactComponent as FilterListIcon } from 'assets/icon-material-filter-list.svg';

import './GroupedSelectFilterList.scss'

const GroupedSelectFilterList = ({
    title,
    values,
    groups,
    handleChange,
    className,
    anchorOrigin,
    closeMenuOnSelect,
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const isMenuOpen = Boolean(anchorEl);

    const handleOpen = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    return (
        <div className="grouped-select-filter-list-wrapper">
            <Button
                className={`grouped-select-filter-list-button${className ? ` ${className}` : ''}${isMenuOpen ? ` active` : ''}`}
                variant="outlined"
                onClick={handleOpen}
            >
                <FilterListIcon className="filter-list-icon" />
                <span>{title}</span>
            </Button>
            <Menu
                className={`grouped-select-filter-list-menu${className ? ` ${className}` : ''}`}
                getContentAnchorEl={null}
                anchorOrigin={anchorOrigin || { vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                anchorEl={anchorEl}
                open={isMenuOpen}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        minWidth: '130px',
                        marginTop: '10px',
                        boxShadow: '0px 1px 6px #00000033',
                    }
                }}
            >
                {
                    Object.keys(groups).map(group => {
                        const currentGroup = groups[group];
                        return (
                            <div
                                key={currentGroup.name}
                                className="group-wrapper"
                            >
                                <p className="group-name">{currentGroup.name}</p>
                                {currentGroup.values.map((item, index) => {
                                    return (
                                        <MenuItem
                                            id={item}
                                            key={index}
                                            className={`grouped-select-filter-list-menu-item${values[group]?.includes(item) ? ' selected' : ''}`}
                                            onClick={() => {
                                                handleChange(group, item);

                                                if (closeMenuOnSelect) {
                                                    handleClose();
                                                }
                                            }}
                                        >
                                            <span>{item}</span>
                                            {values[group]?.includes(item) && <CheckIcon className="check-icon" />}
                                        </MenuItem>
                                    )
                                })}
                            </div>
                        )
                    })
                }
            </Menu>
        </div>
    );
};

export default GroupedSelectFilterList;
