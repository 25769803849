import React from 'react';
import { connect } from 'react-redux';
import { ErrorMessage } from 'formik';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { getStaticLanguages } from 'reducers';

import { LANGUAGE_LEVELS } from '../LanguageDialog/LanguageDialogConstants';

const LanguageDialogView = ({
    languages,
    values,
    errors,
    touched,
    handleChange,
}) => {

    return (
        <form className="language-dialog-view form" autoComplete="off">
            <div className="language-name-field">
                <FormControl fullWidth variant="outlined">
                    <InputLabel>Language</InputLabel>
                    <Select
                        name="name"
                        label="Language"
                        IconComponent={KeyboardArrowDownIcon}
                        value={values.name}
                        error={errors.name && touched.name}
                        onChange={handleChange}
                    >
                        {languages.map((language, i) => (
                            <MenuItem key={i} value={language}>
                                {language}
                            </MenuItem>
                        ))}
                    </Select>
                    <ErrorMessage className="invalid-field-message" name="name" component="div" />
                </FormControl>
            </div>
            <div className="language-level-field">
                <FormControl fullWidth variant="outlined">
                    <InputLabel>Proficiency</InputLabel>
                    <Select
                        name="level"
                        label="Proficiency"
                        IconComponent={KeyboardArrowDownIcon}
                        value={values.level}
                        error={errors.level && touched.level}
                        onChange={handleChange}
                    >
                        {LANGUAGE_LEVELS.map((level, i) => (
                            <MenuItem key={i} value={level}>
                                {level}
                            </MenuItem>
                        ))}
                    </Select>
                    <ErrorMessage className="invalid-field-message" name="level" component="div" />

                </FormControl>
            </div>
        </form>
    );
};

const mapStateToProps = state => ({
    languages: getStaticLanguages(state),
});

export default connect(mapStateToProps)(LanguageDialogView);
