import React, { useState } from 'react';
import moment from 'moment';

import { Paper, Typography } from '@material-ui/core';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Legend, ResponsiveContainer, Tooltip } from 'recharts';
import DashboardSectionWrapper from 'components/Dashboard/SharedDashboardComponents/DashboardSectionWrapper';
import SubmitFeedbackDialog from 'components/Shared/SubmitFeedbackDialog';
import NoMatchesFound from 'components/Shared/NoMatchesFound';
import PrimaryAddButton from 'components/Shared/Buttons/PrimaryAddButton';

import { feedbackCriteria } from 'components/Client/Projects/ProjectsConstants';
import { CHART_COLORS, CHART_PARAMETERS } from './ClientProjectsFeedbackConstants';

import './ClientProjectsFeedback.scss';

const ClientProjectsFeedback = ({ 
    agencyId,
    clientId,
    projectsFeedback,
    projects,
    submitFeedbackToProject,
    clientName,
    fetchClientDashboardInformation,
}) => {
    const [isSubmitFeedbackOpen, setIsSubmitFeedbackOpen] = useState(false);

    const handleOpenSubmitFeedbackDialog = () => setIsSubmitFeedbackOpen(true);
    const handleCloseSubmitFeedbackDialog = () => setIsSubmitFeedbackOpen(false);

    const handleSubmitFeedback = (values) => {
        const { project, details, ...ratings } = values;
		const feedbackWithReviewer = { details, ratings, reviewer: clientName };
		submitFeedbackToProject(agencyId, clientId, project._id, feedbackWithReviewer).then(() => {
            fetchClientDashboardInformation(agencyId, clientId);
        });
    };

    const formattedFeedback = projectsFeedback
        ? projectsFeedback.map(feedback => ({
            ...feedback,
            performanceReviews: feedback.performanceReviews.map(review => ({
                ...review.ratings,
                date: moment.utc(review.date).format(CHART_PARAMETERS.DATE_FORMAT),
                overallRating: review.overallRating,
            }))
        }))
        : [];

    const renderLegend = ({ payload }) => {
        return (
            <ul>
                {
                    payload.map((entry, index) => {
                        return (
                            <li key={`item-${index}`}>
                                <div className="legend-item-icon" style={{ backgroundColor: `${entry.color}` }} />
                                <span>{entry.value}</span>
                            </li>
                        );
                    })
                }
            </ul>
        );
    };

    const subTitleComponent = <p className="performance-review-sub-title">Click to <span onClick={handleOpenSubmitFeedbackDialog}>submit a review</span></p>

    return (
        <DashboardSectionWrapper>
            <DashboardSectionWrapper.SectionTitle>
                <p>Performance Analytics</p>
                {formattedFeedback.length > 0 &&
                    <PrimaryAddButton
                        text='Submit Review'
                        handleClick={handleOpenSubmitFeedbackDialog}
                    />
                }
            </DashboardSectionWrapper.SectionTitle>
            <DashboardSectionWrapper.SectionContent classes={['list']} >
                {formattedFeedback.length > 0 ? formattedFeedback.map((feedback, index) =>
                    <Paper className="client-performance-analytics-wrapper" key={index}>
                        <Typography variant="h5" className="performance-analytics-title">{feedback.name}</Typography>
                        <ResponsiveContainer height={CHART_PARAMETERS.CHART_HEIGHT}>
                            <LineChart
                                data={feedback.performanceReviews}
                                margin={{ top: 20, right: 0, left: -45, bottom: 20 }}
                            >
                                <CartesianGrid
                                    stroke={CHART_COLORS.GRID_STROKE}
                                />
                                <XAxis
                                    dataKey='date'
                                    axisLine={false}
                                    padding={{ right: 5 }}
                                    tickLine={false}
                                    tick={{ fontSize: 13, fill: CHART_COLORS.AXIS_LINES_TICK }}
                                    height={50}
                                />
                                <YAxis
                                    type='number'
                                    domain={[0, 5]}
                                    ticks={[0, 1, 2, 3, 4, 5]}
                                    interval={0}
                                    axisLine={false}
                                    tickLine={false}
                                    tick={{ fontSize: 13, fill: CHART_COLORS.AXIS_LINES_TICK }}
                                />
                                <Legend
                                    wrapperStyle={{ paddingLeft: '45px' }}
                                    content={renderLegend}
                                />
                                <Line
                                    type='monotone'
                                    dataKey='overall'
                                    stroke={CHART_COLORS.OVERALL_STROKE}
                                    strokeWidth={CHART_PARAMETERS.STROKE_WIDTH}
                                    name='Overall'
                                    isAnimationActive={false}
                                    dot={{ r: 6.5 }}
                                    activeDot={{ r: 6.5 }}
                                />
                                <Tooltip classes={{ tooltip: 'custom-tooltip' }} />
                                {feedbackCriteria.map((criteria, i) => (
                                    <Line
                                        key={i}
                                        type='monotone'
                                        dataKey={criteria}
                                        stroke={CHART_COLORS.COLORS[i].STROKE}
                                        strokeWidth={CHART_PARAMETERS.STROKE_WIDTH}
                                        name={criteria}
                                        isAnimationActive={false}
                                        dot={{ stroke: CHART_COLORS.COLORS[i].DOT, r: 6.5 }}
                                        activeDot={{ r: 6.5 }}
                                    />
                                ))}
                            </LineChart>
                        </ResponsiveContainer>
                    </Paper>
                ): <NoMatchesFound text="No performance review" subTitleComponent={subTitleComponent} />}
            <SubmitFeedbackDialog
                openDialog={isSubmitFeedbackOpen}
                handleCloseDialog={handleCloseSubmitFeedbackDialog}
                feedbackCriteria={feedbackCriteria}
                handleSubmit={handleSubmitFeedback}
                chooseProject={true}
                projects={projects}
			/>
            </DashboardSectionWrapper.SectionContent>
        </DashboardSectionWrapper>
    );
};

export default ClientProjectsFeedback;
