import React, { useState } from 'react';

import { Field, ErrorMessage } from 'formik';
import CommonFormikDialog from 'components/Shared/CommonFormikDialog';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { CANDIDATE_SALARY_CURRENCIES } from 'constants/currenciesConstant';

import './ExpectedSalary.scss';

const ExpectedSalaryDialog = ({
    handleSubmit,
    handleChange,
    values,
    onClose,
    open,
    props
}) => {
    const [showValueLabel, setShowValueLabel] = useState(false);
    const [showCurrencyLabel, setShowCurrencyLabel] = useState(false);
    
    const onFormSubmit = () => {
        handleSubmit();
    }
    return (
        <form>
            <CommonFormikDialog open={open} className={"expected-salary-dialog"} onClose={onClose} dialogTitle="Edit Salary" saveBtnText="Save" discardBtnText="Discard" onSave={onFormSubmit}>
                <div className="form-row">
                    <div className="form-cols">
                        <div className="col-1of2">
                            <Field
                                as={TextField}
                                type="number"
                                variant="outlined"
                                onMouseOver={() => setShowValueLabel(true)}
                                onMouseOut={() => setShowValueLabel(false)}
                                label={showValueLabel ? 'Number' : false}
                                value={values.salaryValue}
                                name="salaryValue"
                                fullWidth={true}
                            />

                            <ErrorMessage name="salaryValue" component="div" className="invalid-field-message" />
                        </div>

                        <div className="col-2of2">
                            <TextField
                                select
                                onMouseOver={() => setShowCurrencyLabel(true)}
                                onMouseOut={() => setShowCurrencyLabel(false)}
                                label={showCurrencyLabel ? 'Currency' : false}
                                value={values.salaryCurrency}
                                variant="outlined"
                                onChange={handleChange}
                                name="salaryCurrency"
                                id="salaryCurrency"
                                fullWidth={true}
                            >
                                {CANDIDATE_SALARY_CURRENCIES.map((currency) => {
                                    return (
                                        <MenuItem key={currency.VALUE} value={currency.LABEL}>
                                            {currency.LABEL}
                                        </MenuItem>
                                    );
                                })}
                            </TextField>

                            <ErrorMessage name="salaryCurrency" component="div" className="invalid-field-message" />
                        </div>
                    </div>
                </div>
            </CommonFormikDialog>
        </form>
    );
}

export default ExpectedSalaryDialog;
