import React from "react";

import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";

import defaultAvatar from "assets/dummy-avatar.jpg";

import { CONTRACT_ID, FULL_TIME_ID, DIALOG_TYPES } from 'constants/clientProjectConstants';

const AllEmployees = ({
    project,
    handlePopoverOpen,
    handlePopoverClose,
    isClientMode,
    isEmployeeMode,
    handleAddEmployeeDialog,
}) => {
    const allEmployees = [...project.employees, ...project.dedicatedEmployees];

    const handleAddAllEmployeeDialog = () => {
        handleAddEmployeeDialog(DIALOG_TYPES.BILLING_TYPE);
    };

    return (<>
        {!allEmployees.length && (isClientMode || isEmployeeMode) ? null : (
            <div className="people-container dedicated-members">
                <h2 className="people-title-container">
                    Team Members
                </h2>
                <div className="people-container-members">
                    {project.employees.filter(x => !x.isDeleted).map((employee, i) => (
                        <Avatar
                            alt="Employee Avatar"
                            className="avatar2 profile-image"
                            src={employee.profilePictureImgUrl}
                            onError={(e) => (e.target.src = defaultAvatar)}
                            key={i + "avatar"}
                            id={employee._id + project._id + CONTRACT_ID}
                            onMouseEnter={() => handlePopoverOpen(employee._id, CONTRACT_ID, employee._id + project._id + CONTRACT_ID)}
                            onMouseLeave={handlePopoverClose}
                        />
                    ))}
                    {project.dedicatedEmployees.filter(x => !x.isDeleted).map((employee, i) => (
                        <Avatar
                            alt="Emplyoee Avatar"
                            className="avatar2 profile-image"
                            src={employee.profilePictureImgUrl}
                            onError={(e) => (e.target.src = defaultAvatar)}
                            key={i + "avatar"}
                            id={employee._id + project._id + FULL_TIME_ID}
                            onMouseEnter={() => handlePopoverOpen(employee._id, FULL_TIME_ID, employee._id + project._id + FULL_TIME_ID)}
                            onMouseLeave={handlePopoverClose}
                        />
                    ))}
                    {!isClientMode && !isEmployeeMode && (
                        <Button
                            size="small"
                            variant="outlined"
                            color="primary"
                            className="add-person-button"
                            onClick={handleAddAllEmployeeDialog}>
                            <AddIcon />
                        </Button>
                    )}
                </div>
            </div>
        )}
    </>);
};

export default AllEmployees;
