import React from 'react';
import { useHistory } from 'react-router-dom';
import { getStateAgencyName } from 'utils/helpers';

import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';

import HiredCandidates from 'assets/hired-candidates.svg';

import './ProfileHiredBanner.scss';

const ProfileHiredBanner = ({
    employeeId
}) => {
    const history = useHistory(); 

    return (
         <div className="hire-banner-outer-container" > 
             <div className="hired-banner-wrapper row">
             <div className="action-wrapper col-1of2">
                <div className="action-wrapper-title">This candidate was hired</div>
                <div><PrimaryButton className="view-hiring-details" text="View Hiring Details"
                handleClick={() => history.push(`/${getStateAgencyName()}/candidates/${employeeId}/hiring-details`)}/></div>
             </div>
             <div className="icon-wrapper col-2of2">
                <img className="image-info" src={HiredCandidates} />
             </div>
             </div>
         </div>
    )
};

export default ProfileHiredBanner;
