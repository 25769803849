import React, { Fragment } from 'react';
import UploadFiles from 'components/Shared/UploadFiles';
import SecondaryButton from 'components/Shared/Buttons/SecondaryButton';
import DialogActions from '@material-ui/core/DialogActions';

const ACCEPTED_FORMATS = ['ppt', 'pptx', 'xlsx', 'csv', 'doc', 'docx', 'jpg', 'jpeg', 'png', 'pdf'];

const UploadCVDialogFormView = ({
    values,
    handleSubmit,
    setFieldValue,
    handleDeleteFile,
    handleCloseDialog,
}) => {
    const setFilesAttached = (name, value) => {
        const lastElement = value.pop();
        if (lastElement) {
            setFieldValue(name, [lastElement]);
        } else {
            setFieldValue(name, []);
        }
    }
    return (
        <Fragment>
            <UploadFiles
                single
                name='filesAttached'
                setFieldValue={setFilesAttached}
                acceptedFormats={ACCEPTED_FORMATS}
                handleDeleteFile={handleDeleteFile}
                filesAttached={values.filesAttached}
            />
            <DialogActions className="dialog-actions">
                <SecondaryButton buttonStyle="discard" handleClick={handleCloseDialog} text="Discard" />
                <SecondaryButton buttonStyle="save" handleClick={handleSubmit} text="Save" type="submit" />
            </DialogActions>
        </Fragment>
    )
}

export default UploadCVDialogFormView;
