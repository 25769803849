import { ROLES } from 'constants/userConstants';

export const usersRequiredFields = ['firstName', 'lastName', 'email', 'isDeleted', 'dateDeleted', 'name'];
export const userRoles = [ROLES.ADMIN, ROLES.EMPLOYEE];
export const filters = { $or: [{ isDeleted: { $exists: false } }, { isDeleted: false }] };

export const USER_ROLES_HEAD_CELLS = [
    { title: 'Title', value: 'role' },
    { title: 'Number users', value: 'count' },
];
