import React from 'react';
import { connect } from 'react-redux';

import { getEmploymentInformation } from 'reducers';

import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

import './EmployeeEvrotrustStatus.scss';

const EmployeeEvrotrustStatus = ({
    employmentInformation,
}) => {
    const evrotrustEmail = employmentInformation?.contractInformation?.evrotrustEmail;

    const {
        isRegistered,
        isIdentified,
        isSupervised,
        isReadyToSign,
        hasConfirmedPhone,
        hasConfirmedEmail
    } = employmentInformation.employeeEvrotrustStatus;

    if (!evrotrustEmail) {
        return null;
    }

    return (
        <div className="evrotrust-info-wrapper">
            <h1 className="evrotrust-info-title">e-Signature Details</h1>
            <div className="evrotrust-info-content">
                <div className="evrotrust-info-subsection">
                    <div className="evrotrust-info-item-wrapper">
                        {isRegistered ? <CheckIcon className="check-icon"/> : <CloseIcon className="close-icon"/>}
                        <p className="evrotrust-info-item">Email Registered</p>
                    </div>
                    <div className="evrotrust-info-item-wrapper">
                        {isIdentified ? <CheckIcon className="check-icon"/> : <CloseIcon className="close-icon"/>}
                        <p className="evrotrust-info-item">User Identified</p>
                    </div>
                    <div className="evrotrust-info-item-wrapper">
                        {isSupervised ? <CheckIcon className="check-icon"/> : <CloseIcon className="close-icon"/>}
                        <p className="evrotrust-info-item">Successfully Supervised</p>
                    </div>
                </div>
                <div className="evrotrust-info-subsection">
                    <div className="evrotrust-info-item-wrapper">
                        {isReadyToSign ? <CheckIcon className="check-icon"/> : <CloseIcon className="close-icon"/>}
                        <p className="evrotrust-info-item">User Ready to Sign</p>
                    </div>
                    <div className="evrotrust-info-item-wrapper">
                        {hasConfirmedPhone ? <CheckIcon className="check-icon"/> : <CloseIcon className="close-icon"/>}
                        <p className="evrotrust-info-item">Confirmed Phone</p>
                    </div>
                    <div className="evrotrust-info-item-wrapper">
                        {hasConfirmedEmail ? <CheckIcon className="check-icon"/> : <CloseIcon className="close-icon"/>}
                        <p className="evrotrust-info-item">Confirmed Email</p>
                    </div>
                </div>
            </div>
        </div>
    )
};

const mapStateToProps = (state) => ({
    employmentInformation: getEmploymentInformation(state),
});

export default connect(mapStateToProps, null)(EmployeeEvrotrustStatus);
