import React from 'react';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

import { ErrorMessage } from 'formik';

import '../AgencySettingsBasicForm.scss';

const AgencySettingsBasicFormView = ({
    values, 
    handleChange,
    handleShowHeaderButtons,
    errors, 
    touched,
    countries,
}) => {
    return ( 
        <form className='agency-basic-settings-form' autoComplete='off'>
            <div className='agency-basic-settings-form-field'>
                <TextField
                    required
                    id='agency-name'
                    label='Company Name'
                    variant='outlined'
                    name='name'
                    value={values.name}
                    onChange={(e) => { handleChange(e); handleShowHeaderButtons(); }}
                    error={errors.name && touched.name}
                    fullWidth
                />
                <ErrorMessage name="name" component="div" className="invalid-field-message" />                   
            </div>
            <FormControl className='country-field agency-basic-settings-form-field' variant='outlined' fullWidth>
                <InputLabel id='country-label'>Country</InputLabel>
                <Select
                    labelId='country-label'
                    id='agency-country'
                    name='country'
                    value={values.country}
                    onChange={(e) => { handleChange(e); handleShowHeaderButtons(); }}
                    label='HQ Country'
                    error={errors.country && touched.country}
                >
                    {countries.map(country => (
                        <MenuItem key={country.name} value={country.name}>
                            {country.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </form>);
};

export default AgencySettingsBasicFormView;
