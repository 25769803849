import React from 'react';

import DayPicker from 'react-day-picker';
import { ErrorMessage } from 'formik';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import { ReactComponent as Upload } from 'assets/upload.svg';

const TrackTimeDialogFormView = ({
    projects,
    currentFileName,
    handleOpenUploadFileDialog,
    values,
    handleChange,
    setFieldValue,
    selectedDays,
    renderDay
}) => (
    <>
        <div className='dates-container'>
            <DayPicker
                month={selectedDays[0] || new Date()}
                selectedDays={selectedDays}
                firstDayOfWeek={1}
                weekdaysShort={['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']}
                renderDay={renderDay}
            />
        </div>
        <div className="input-container">
            <FormControl className="project" variant="outlined" fullWidth>
                <InputLabel id="project-label">Project</InputLabel>
                <Select
                    labelId="project-label"
                    id="project"
                    name="project"
                    value={values.project}
                    classes={{ root: values.project.class }}
                    onChange={async (e) => { await setFieldValue('hours', 8); handleChange(e); }}
                    label="Project"
                >
                    {projects.map((project, i) => (
                        <MenuItem
                            key={i}
                            value={project}
                            classes={{ root: project.class }}
                        >
                            {project.name}
                        </MenuItem>
                    ))}
                </Select>
                <ErrorMessage name="project" component="div" className="invalid-field-message" />
            </FormControl>
            {values.project?.isSickLeave &&
                <div className="track-time-upload-file-container">
                    <TextField
                        className="track-time-upload-text-field"
                        label="Module"
                        type="text"
                        name="fileInfo"
                        variant="outlined"
                        value={currentFileName}
                        disabled
                    />

                    <div className="track-time-upload-image-wrapper">
                        <Upload
                            className="track-time-upload-image"
                            name="fileInfo"
                            onClick={async (e) => { handleOpenUploadFileDialog(e); await setFieldValue('fileInfo', currentFileName); }}
                        />
                    </div>

                    <ErrorMessage name="fileInfo" component="div" className="invalid-field-message" />
                </div>
            }
            {!values.project?.isSickLeave &&
                <>
                    <TextField
                        className="hours"
                        type="number"
                        label="Hours per Day"
                        variant="outlined"
                        name="hours"
                        onChange={(e) => { handleChange(e); }}
                        value={values.hours}
                    />
                    <ErrorMessage name="hours" component="div" className="invalid-field-message" />

                    <TextField
                        className={'tasks-desc'}
                        label="Tasks Description"
                        multiline
                        minRows={5}
                        variant="outlined"
                        name="tasksDesc"
                        onChange={(e) => { handleChange(e); }}
                        inputProps={{
                            maxLength: 190
                        }}
                        value={values.tasksDesc}
                    />
                    <ErrorMessage name="tasksDesc" component="div" className="invalid-field-message" />
                </>
            }
        </div >
    </>
);

export default TrackTimeDialogFormView;
