import React from 'react';
import { Link } from 'react-router-dom';

import { ErrorMessage } from 'formik';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import FeedbackDialogWrapper from 'components/Shared/FeedbackDialog';

import { DIALOG_COMMENT_INPUT } from 'constants/commonConstants';
import { ROLES } from 'constants/userConstants';

const PerformanceReviewFormView = ({
    values,
    errors,
    touched,
    performanceCriterias,
    setFieldValue,
    performanceToEdit,
    handleChange,
    role,
    displayMode
}) => {

    return <>
        <TextField
            className="reviewer"
            label="Reviewer"
            name="reviewer"
            variant="outlined"
            value={values.reviewer || performanceToEdit.reviewer}
            disabled
        />

        <div className="rating-container">
            <Typography variant="h5" className="rating-criteria-title">Rating Criteria</Typography>

            {performanceCriterias.length > 0
                ? <FeedbackDialogWrapper.RatingCriteriaWrapper>
                    {performanceCriterias.map((criteria, i) => (
                        <FeedbackDialogWrapper.RatingCriteriaItem id={`${i}_${criteria}`} name={criteria} title={criteria} readOnly={displayMode === ROLES.EMPLOYEE} />
                    ))}
                </FeedbackDialogWrapper.RatingCriteriaWrapper>
                : displayMode === ROLES.ADMIN
                    ? <p className="general-msg">If you wish you can set performance criteria for your reviews in <Link to='/settings/employee-settings' className="settings-link">Employee Settings</Link> under the Settings menu.</p>
                    : null
            }
            {displayMode === ROLES.ADMIN && <p className="feedback-msg">The feedback you provide in the following field will be sent to the employee. Please provide constructive and comprehensive comments.</p>}
        </div>

        <TextField
            className="overall-performance"
            label="Overall Feedback"
            name="summary"
            multiline
            minRows={DIALOG_COMMENT_INPUT.ROWS}
            maxRows={DIALOG_COMMENT_INPUT.MAX_ROWS}
            variant="outlined"
            value={values.summary || performanceToEdit.summary}
            onChange={handleChange}
            error={errors.summary && touched.summary}
            disabled={role === ROLES.EMPLOYEE}
        />
        <ErrorMessage name="summary" component="div" className="invalid-field-message" />
    </>
}

export default PerformanceReviewFormView
