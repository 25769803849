import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { getAgencyId } from 'reducers';
import { disableUsers } from 'actions/userActions';
import { registerEmployee } from 'actions/employeeActions';
import { deleteRecruiterEmployees, fetchRecruiterEmployees } from 'actions/recruiterActions';

import { ROLES } from 'constants/userConstants';
import AddProfileDialog from './AddProfileDialog';
import RecruiterProfilesTable from './RecruiterProfilesTable';
import ConfirmDeleteDialog from 'components/Shared/ConfirmDeleteDialog';

import './RecruiterProfiles.scss';

const RecruiterProfiles = ({
    userId,
    agencyId,
    registerEmployee,
    fetchRecruiterEmployees,
    deleteRecruiterEmployees,
}) => {
    const [openAddProfileDialog, setOpenAddProfileDialog] = useState(false);
    const [profileToRemove, setProfileToRemove] = useState({});
    const [openConfirmRemoveDialog, setOpenConfirmRemoveDialog] = useState(false);

    useEffect(() => {
        fetchRecruiterEmployees(agencyId, userId);
    }, []);

    const handleCloseConfirmRemoveDialog = () => {
        setOpenConfirmRemoveDialog(false);
        setProfileToRemove({});
    };

    const handleDeleteRecruiterClick = recruiter => {
        setProfileToRemove(recruiter);
        setOpenConfirmRemoveDialog(true);
    };

    const handleRemoveRecruiters = async () => {
        await deleteRecruiterEmployees([profileToRemove._id]);
        fetchRecruiterEmployees(agencyId, userId);
        setOpenConfirmRemoveDialog(false);
    };

    const handleOpenAddProfileDialog = () => setOpenAddProfileDialog(true);

    const handleCloseAddProfileDialog = () => setOpenAddProfileDialog(false);

    const handleSubmitClick = (values) => {
        const profileData = {
            ...values,
            recruiterId: userId,
            date: Date.now(),
            role: ROLES.RECRUITER_EMPLOYEE,
            employmentInformation: {
                generalInfo: {
                    isAvailable: false,
                    isVetted: false
                }
            },
            personalInfo: {
                linkedIn: values.linkedin
            },
        };
        registerEmployee(profileData, agencyId);
        handleCloseAddProfileDialog();
    };

    return (
        <div className="profiles-table-wrapper">
            <div className="profiles-table-container">
                <RecruiterProfilesTable
                    handleOpenAddProfileDialog={handleOpenAddProfileDialog}
                    handleDeleteRecruiterClick={handleDeleteRecruiterClick}
                />
            </div>
            <AddProfileDialog
                agencyId={agencyId}
                openDialog={openAddProfileDialog}
                handleCloseDialog={handleCloseAddProfileDialog}
                handleSubmitClick={handleSubmitClick}
            />
            <ConfirmDeleteDialog
                itemToDelete={`${profileToRemove?.firstName || ''} ${profileToRemove?.lastName || ''}`}
                openDialog={openConfirmRemoveDialog}
                handleDeleteItem={handleRemoveRecruiters}
                handleCloseDialog={handleCloseConfirmRemoveDialog}
            />
        </div>
    )
}

const mapStateToProps = state => ({
    agencyId: getAgencyId(state),
});

const mapDispatchToProps = {
    disableUsers,
    registerEmployee,
    fetchRecruiterEmployees,
    deleteRecruiterEmployees,
};

export default connect(mapStateToProps, mapDispatchToProps)(RecruiterProfiles);
