
import React from 'react';

import { ReactComponent as NoMatches } from 'assets/no-matches-found.svg';

import './NoMatchesFound.scss';

const NoMatchesFound = ({ text, classes=[], noImage, subTitleComponent }) => {
    return (
        <div className="no-matches-found">
            { !noImage &&
                <NoMatches className="content-svg" />
            }
            <p className={`text-message ${classes.join(' ')}`}>{text || 'No Matches Found'}</p>
            { subTitleComponent }
        </div>
    )
}

export default NoMatchesFound;
