import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import Paper from '@material-ui/core/Paper';
import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';
import HolidayAllowanceFormView from './HolidayAllowanceFormView';

import { getHolidayAllowance } from 'reducers';
import { updateHolidayAllowance } from 'actions/agencyActions';
import { updateSupplierHolidayAllowance } from 'actions/supplierActions'

import { validateEmployeeHolidayAllowance } from 'utils/formValidations';

const HolidayAllowance = ({
    holidayAllowance,
    updateHolidayAllowance,
    updateSupplierHolidayAllowance,
    agencyId,
    isAdmin,
    userId,
}) => {
    const [showHeaderButtons, setShowHeaderButtons] = useState(false);

    const holidayAllowanceFormRef = useRef();

    const handleSubmit = async (holidayAllowance, resetForm) => {
        isAdmin ? await updateHolidayAllowance(holidayAllowance, agencyId) : await updateSupplierHolidayAllowance(agencyId, userId, holidayAllowance);
        resetForm();
        setShowHeaderButtons(false);
    };

    const handleDiscard = () => {
        holidayAllowanceFormRef.current.handleReset();
        setShowHeaderButtons(false);
    };

    return (
        <div className="employment-info-wrapper holiday-allowance-wrapper">
            <SectionActionsWrapper>
                <SectionActionsWrapper.SectionTitle
                    sectionTitle='Holiday Allowance'
                />

                {showHeaderButtons && (
                    <SectionActionsWrapper.RightAlignedActions>
                        <SectionActionsWrapper.RightAlignedActions.SecondaryButton
                            text='Discard'
                            onClick={handleDiscard}
                            buttonStyle='discard'
                        />

                        <SectionActionsWrapper.RightAlignedActions.SecondaryButton
                            text='Save'
                            onClick={() => holidayAllowanceFormRef.current.handleSubmit()}
                            buttonStyle='save'
                        />
                    </SectionActionsWrapper.RightAlignedActions>

                )}
            </SectionActionsWrapper>

            <Paper className="inner-content-wrapper">
                <Formik
                    initialValues={{
                        holidayAllowance: holidayAllowance || '',
                    }}
                    validate={values => {
                        return { ...validateEmployeeHolidayAllowance(values, ['holidayAllowance']) }
                    }}
                    innerRef={holidayAllowanceFormRef}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        setSubmitting(false);
                        handleSubmit(values, resetForm)
                    }}
                    enableReinitialize={true}
                >
                    {(props) => <HolidayAllowanceFormView {...props} onInputChanged={() => setShowHeaderButtons(true)} />}
                </Formik>
            </Paper>
        </div >
    )
}

const mapStateToProps = state => ({
    holidayAllowance: getHolidayAllowance(state),
});

const mapDispatchToProps = {
    updateHolidayAllowance,
    updateSupplierHolidayAllowance,
};

export default connect(mapStateToProps, mapDispatchToProps)(HolidayAllowance);
