import React from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import { ROLES } from 'constants/userConstants';

import './AdditionalMenuMobile.scss';

const AdditionalMenuMobile = ({
    handleLogout,
    isAdmin,
    displayMode,
    handleSwitchRoleMode,
    handleGoToTCLink,
    modeText,
    closeMobileNavigationHandler
}) => {

    const handleLogoutAndCloseMenu = () => {
        handleLogout();
        closeMobileNavigationHandler();
    }

    const handleGoToTCLinkAndCloseMenu = () => {
        handleGoToTCLink();
        closeMobileNavigationHandler();
    }

    const handleSwitchRoleModeAndCloseMenu = () => {
        handleSwitchRoleMode();
        closeMobileNavigationHandler();
    }
    
    return (
        <div className="navigation-footer-mobile"> 
            <List className="navigation-footer-list">
                <ListItem button className="logout-list-item" onClick={handleLogoutAndCloseMenu}>
                    <ListItemIcon className="logout-list-icon">
                        <ExitToAppIcon />
                    </ListItemIcon>
                    <ListItemText primary="Logout" className="logout-list-item-text" />
                </ListItem>

                <div className="divider-wrapper">
                    <Divider />
                </div>
                
                {displayMode !== ROLES.ADMIN && displayMode !== ROLES.RECRUITER &&
                    <ListItem button className="navigation-footer-list-item" onClick={handleGoToTCLinkAndCloseMenu}>
                        <ListItemText primary="Terms & Conditions" className="list-item-text" />
                    </ListItem>
                }
                
                {isAdmin &&
                    <ListItem button className="navigation-footer-list-item" onClick={handleSwitchRoleModeAndCloseMenu}>
                        <ListItemText primary={`Switch to ${modeText} Mode`} className="list-item-text" />
                    </ListItem>
                }
            </List>
        </div>
    );
}

export default AdditionalMenuMobile;
