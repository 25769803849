import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Paper } from '@material-ui/core';

import {
    createCoachingOpportunity,
    editCoachingOpportunity,
    deleteCoachingOpportunity,
    fetchTagsSuggestions,
    fetchCoachingOpportunities,
    fetchCoachingOpportunitiesSessions,
} from 'actions/coachingOpportunitiesActions';
import { fetchStaticLanguagesSuggestions } from 'actions/staticDataActions';

import { 
    getAgencyId,
    getAgencyName, 
    getCoachingOpportunities,
    getCoachingOpportunitiesPastSessions,
    getCoachingOpportunitiesUpcomingSessions,
    getDisplayMode,
    getStaticLanguages
} from 'reducers';

import CircularProgress from '@material-ui/core/CircularProgress';

import CoachingOpportunities from './CoachingOpportunities';
import CoachingDialog from './CoachingDialog';
import CoachingOpportunityPopover from './CoachingOpportunities/CoachingOpportunityCard/CoachingOpportunityPopover';

import NoMatchesFound from 'components/Shared/NoMatchesFound';
import Section from 'components/Shared/Section';
import PrimaryAddButton from 'components/Shared/Buttons/PrimaryAddButton';
import ConfirmDeleteDialog from 'components/Shared/ConfirmDeleteDialog';
import SessionCard from 'components/Shared/SessionCard';
import BookedSessions from '../CoachingOpportunityDetails/BookedSessions';

import './Coaching.scss';

const Coaching = ({
    agencyId,
    agencyName,
    displayMode,
    languagesSuggestions,
    coachingOpportunities,
    createCoachingOpportunity,
    editCoachingOpportunity,
    deleteCoachingOpportunity,
    fetchTagsSuggestions,
    fetchStaticLanguagesSuggestions,
    fetchCoachingOpportunities,
    fetchCoachingOpportunitiesSessions,
    upcomingCoachingSessions,
    pastCoachingSessions,
}) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

    const [tags, setTags] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [selectedOpportunity, setSelectedOpportunity] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);

    const [currentImageSelected, setCurrentImgSelected] = useState(null);
    const [imageFile, setImageFile] = useState(null);

    const [isPageLoading, setIsPageLoading] = useState(true); 

    useEffect(() => {
        fetchCoachingOpportunities(agencyId, displayMode).then(() => {
            fetchCoachingOpportunitiesSessions(agencyId).then(() => {
                setIsPageLoading(false);
            });
        });
    }, []);

    const handleOpenDialog = () => setIsDialogOpen(true);

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
        setTags([]);
        setLanguages([]);
        setCurrentImgSelected(null);
        setImageFile(null);
        setIsEditMode(false);
        setSelectedOpportunity({});
    };

    const handleOpenDeleteDialog = (event) => {
        setIsDeleteDialogOpen(true);
        handleClosePopover(event);
    };

    const handleCloseDeleteDialog = () => setIsDeleteDialogOpen(false);

    const handleEditClick = (event, selectedOpportunity) => {
        setSelectedOpportunity(selectedOpportunity);
        setTags([...selectedOpportunity.tags]);
        setLanguages([...selectedOpportunity.languages]);
        setIsEditMode(true);
        setIsDialogOpen(true);
        setCurrentImgSelected(selectedOpportunity.coachingOpportunityImgUrl);
        handleClosePopover(event)
    };

    const handleAddTag = (value, isNew = false) => {
        if (value !== null && value !== '' && !tags.some(x => x.title.toLowerCase() === value.toLowerCase())) {
            const oldState = [...tags];
            oldState.push({ _id: value.toLowerCase(), title: value, isNew });
            setTags([...oldState]);
        }
    };

    const handleRemoveTag = (value) => {
        const oldState = [...tags];
        const index = oldState.findIndex(x => x.title.toLowerCase() === value.toLowerCase());

        if (index >= 0) {
            oldState.splice(index, 1);
            setTags([...oldState]);
        }
    };

    const handleAddLanguage = value => {
        if (value !== null && value !== '' && !languages.some(language => language.toLowerCase() === value.toLowerCase())) {
            setLanguages(prevState => ([...prevState, value]));
        }
    };

    const handleRemoveLanguage = value => {
        setLanguages(prevState => prevState.filter(language => language !== value));
    };

    const imageSelectedHandler = (e) => {
        const currentFile = e.target.files[0]
        const imgReader = new FileReader()

        imgReader.addEventListener("load", () => {
            const resultImg = imgReader.result
            setCurrentImgSelected(resultImg);
            setImageFile(currentFile);
        }, false)

        imgReader.readAsDataURL(currentFile)
    };

    const handleClosePopover = (event) => {
        event.stopPropagation();
        event.preventDefault();
        setAnchorEl(null);
    };

    const updateStatus = (status) => {
        const formData = new FormData();

        formData.append('coachingOpportunityInfo', JSON.stringify({ status }));

        editCoachingOpportunity(agencyId, selectedOpportunity._id, formData);
    };

    if (isPageLoading) {
        return (
            <div className="coaching-wrapper">
                <div className="loader-wrapper">
                    <CircularProgress size={50} disableShrink className="centered" />
                </div>
            </div>
        )
    }

    return (
        <div className="coaching-wrapper">
            <Section
                title="Coaching & Mentoring Opportunities"
                subTitle={`You're looking at the list of coaching and mentoring opportunities available for all ${agencyName} employees.`}
                actionComponent={<PrimaryAddButton text="Add New" handleClick={handleOpenDialog} />}
            >
                { coachingOpportunities.length
                    ?
                    <CoachingOpportunities
                        coachingOpportunities={coachingOpportunities}
                        setAnchorEl={setAnchorEl}
                        setSelectedOpportunity={setSelectedOpportunity}
                    />
                    :
                    <Paper className="paper-wrapper">
                        <h3>You haven't created a coaching opportunity</h3>
                        <h4>Click to <span className="add-link" onClick={handleOpenDialog}>add new</span></h4>
                    </Paper>
                }
            </Section>
            <Section title="Upcoming Coaching Sessions">
                { upcomingCoachingSessions.length
                    ?
                    <div className="global-sessions-list thin">
                        { upcomingCoachingSessions.map((x) => 
                            <SessionCard
                                key={x._id}
                                session={x}
                                title={x.coachingOpportunity.coachName}
                                footerContent={x.coachingOpportunity.title}
                                className="thin"
                            />) }
                    </div>
                    :
                    <Paper className="no-matches-paper centered-content">
                        <NoMatchesFound text="No Upcoming Coaching Sessions" />
                    </Paper>
                    }
            </Section>
                   
            <Section title="History of Past Sessions">
                { pastCoachingSessions.length > 0
                        ? 
                            <BookedSessions
                                coachingSessions={pastCoachingSessions}
                            />
                        :
                        <Paper className="no-matches-paper centered-content">
                            <NoMatchesFound text="No Past Coaching Sessions" />
                        </Paper>
                }
            </Section>

            <CoachingDialog
                agencyId={agencyId}
                openDialog={isDialogOpen}
                handleCloseDialog={handleCloseDialog}
                editMode={isEditMode}
                tags={tags}
                handleAddTag={handleAddTag}
                handleRemoveTag={handleRemoveTag}
                languages={languages}
                languagesSuggestions={languagesSuggestions}
                handleAddLanguage={handleAddLanguage}
                handleRemoveLanguage={handleRemoveLanguage}
                imageSelectedHandler={imageSelectedHandler}
                currentImageSelected={currentImageSelected}
                setCurrentImgSelected={setCurrentImgSelected}
                createCoachingOpportunity={createCoachingOpportunity}
                imageFile={imageFile}
                fetchTagsSuggestions={fetchTagsSuggestions}
                fetchStaticLanguagesSuggestions={fetchStaticLanguagesSuggestions}
                selectedOpportunity={selectedOpportunity}
                editCoachingOpportunity={editCoachingOpportunity}
            />

            <CoachingOpportunityPopover
                handleClose={handleClosePopover}
                anchorEl={anchorEl}
                onEditClick={handleEditClick}
                selectedOpportunity={selectedOpportunity}
                updateStatus={updateStatus}
                openDeleteDialog={handleOpenDeleteDialog}
            />

            <ConfirmDeleteDialog
                openDialog={isDeleteDialogOpen}
                handleCloseDialog={handleCloseDeleteDialog}
                handleDeleteItem={() => {
                    deleteCoachingOpportunity(agencyId, selectedOpportunity._id);
                    handleCloseDeleteDialog()
                }}
                itemToDelete={selectedOpportunity.title}
            />
        </div>
    )
};

const mapStateToProps = (state) => ({
    agencyId: getAgencyId(state),
    coachingOpportunities: getCoachingOpportunities(state),
    agencyName: getAgencyName(state),
    displayMode: getDisplayMode(state),
    languagesSuggestions: getStaticLanguages(state),
    upcomingCoachingSessions: getCoachingOpportunitiesUpcomingSessions(state),
    pastCoachingSessions: getCoachingOpportunitiesPastSessions(state),
});

const mapDispatchToProps = {
    createCoachingOpportunity,
    editCoachingOpportunity,
    deleteCoachingOpportunity,
    fetchTagsSuggestions,
    fetchStaticLanguagesSuggestions,
    fetchCoachingOpportunities,
    fetchCoachingOpportunitiesSessions,
}

export default connect(mapStateToProps, mapDispatchToProps)(Coaching);
