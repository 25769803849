import React from 'react';
import { Formik } from 'formik';
import moment from 'moment';

import CommonFormikDialog from 'components/Shared/CommonFormikDialog';
import CertificateDialogView from '../FormViews/CertificateDialogView';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import MomentUtils from '@date-io/moment';
import { addUtcOffset } from 'utils/formatTimes';
import {
    validateRequiredFields,
    validateInputLength,
    validateUrlAddress,
    validateDateFields,
} from 'utils/formValidations';

import { TITLE_MAX_LENGTH } from './CertificateDialogConstants';

const CertificateDialog = ({
    open,
    onClose,
    onSubmit,
    selectedItem,
}) => {
    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <Formik
                enableReinitialize
                initialValues={{
                    title: selectedItem?.title || '',
                    documentUrl: selectedItem?.documentUrl || '',
                    issueDate: selectedItem?.issueDate ? moment.utc(selectedItem?.issueDate) : null,
                }}
                validate={values => {
                    return {
                        ...validateRequiredFields(values, ['title']),
                        ...validateInputLength(values, [{ property: 'title', maxLength: TITLE_MAX_LENGTH }]),
                        ...validateUrlAddress(values, 'documentUrl', true),
                        ...validateDateFields(values, ['issueDate']),
                    }
                }}

                onSubmit={(values) => {
                    const { title, documentUrl, issueDate } = values;

                    const entry = { title, documentUrl, issueDate: addUtcOffset(issueDate) };

                    selectedItem
                        ? onSubmit({ ...selectedItem, ...entry }, selectedItem?.index)
                        : onSubmit(entry);

                    onClose();
                }}
            >
                {(props) => (
                    <CommonFormikDialog
                        size="sm"
                        className="certificate-dialog"
                        dialogTitle="Add Certificate"
                        saveBtnText="Save"
                        discardBtnText="Discard"
                        open={open}
                        onClose={onClose}
                        onSave={props.handleSubmit}
                    >
                        <CertificateDialogView {...props} />
                    </CommonFormikDialog>
                )}
            </Formik>
        </MuiPickersUtilsProvider>
    );
};

export default CertificateDialog;
