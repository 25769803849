import React from 'react';

import PrimaryButton from '../Buttons/PrimaryButton';
import { ReactComponent as ContentPasteIcon } from 'assets/content-paste-icon.svg';

import { BASE_APP_URL } from 'constants/env';
import './CandidateInvitationLink.scss';

const CandidateInvitationLink = ({
    agencyId,
    agencyName,
}) => {
    const agencyData = {
        agencyId,
        agencyName,
    };

    const agencyInfo = encodeURIComponent(btoa(JSON.stringify(agencyData)));

    const handleAddToClipboard = () => {
        navigator.clipboard.writeText(`${BASE_APP_URL}/register/candidate/${agencyInfo}`);
    };

    return (
        <div className="candidate-invitation-link">
            <div className="header-text">Invitation Link</div>
            <div className="sub-header-text">This link helps you to invite candidates to positions.</div>
            <div className="link-container">
                <div className="link-container-text-wrapper">
                    <p>{`${BASE_APP_URL}/register/candidate/${agencyInfo}`}</p>
                </div>
                <PrimaryButton
                    handleClick={handleAddToClipboard}
                    icon={<ContentPasteIcon />}
                />
            </div>
        </div>
    )
};

export default CandidateInvitationLink;
