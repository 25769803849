import React, { useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';
import SecondaryButton from 'components/Shared/Buttons/SecondaryButton';
import InterviewInvitePopup from 'components/Shared/InterviewInvitePopup';

import { ROLES } from 'constants/userConstants';

import { updateInterviewStatus, createInterviewInvitation, updateInterviewData } from 'actions/interviewInvitationActions';
import { getDisplayMode } from 'reducers';

import { ReactComponent as DateIcon } from 'assets/icon-date.svg';
import { ReactComponent as ClockIcon } from 'assets/icon-clock.svg';
import { CONTINENTAL_TIME_FORMAT, DATE_FORMAT_WITH_DOTS_FULL_YEAR } from 'constants/commonConstants';
import { INTERVIEW_INVITATION_STATUSES, INTERVIEW_INVITATION_CONTRIBUTORS } from 'constants/interviewInvitationConstants';
import './InterviewInvitationCard.scss'

const InterviewInvitationCard = ({
    interview,
    agencyId,
    handleSubmit,
    updateInterviewData,
    displayMode,
    isInDialog,
    handleCloseConfirmDialog,
}) => {
    const [checkedRow, setCheckedRow] = useState({});
    const [isInviteDialogOpen, setIsInviteDialogOpen] = useState(false);
    const [isApproveButtonDisabled, setIsApproveButtonDisabled] = useState(true);


    const handleOpenInviteDialog = () => setIsInviteDialogOpen(true);
    const handleCloseInviteDialog = () => setIsInviteDialogOpen(false);

    const handleCheckRow = (timeSlot) => {
        if (checkedRow?.date === timeSlot.date && checkedRow?.time === timeSlot.time) {
            setIsApproveButtonDisabled(true);
            setCheckedRow({});
        } else {
            setIsApproveButtonDisabled(false);
            setCheckedRow(timeSlot);
        }
    };

    const handelInterviewInvitationSubmit = async (formData) => {
        const { clientNote, ...restData } = formData;
        const additionalData = {
            dateAdded: moment.utc().format(),
            status: INTERVIEW_INVITATION_STATUSES.PENDING,
            proposedBy: interview.proposedBy === INTERVIEW_INVITATION_CONTRIBUTORS.PARTICIPANT ?
                INTERVIEW_INVITATION_CONTRIBUTORS.INTERVIEWER : INTERVIEW_INVITATION_CONTRIBUTORS.PARTICIPANT,
            application: interview.applicationId || interview.application,
            note: clientNote,
            interviewer: interview.interviewer,
        };

        await updateInterviewData(agencyId, interview._id, { ...restData, ...additionalData });
    };

    const currentTimezone = moment.tz.guess();
    const proposer = interview.proposedBy === INTERVIEW_INVITATION_CONTRIBUTORS.PARTICIPANT ? 'candidate' : 'client';

    return (
        <Paper className={`interview-invitation-card-wrapper${isInDialog ? ' max-width' : ''}`}>
            {interview.isProConsultantOpportunity && displayMode !== ROLES.CLIENT &&
                <div className="pro-label-container">
                    <div className="content">PRO</div>
                </div>
            }
            <div className="information-container">
                <div className="information-container-title">
                    Invitation for Interview
                </div>
                <div className="information-container-content">
                    <div className="content-wrapper">
                        <div className="title">
                            Position:
                        </div>
                        <div className="content">
                            {interview.position}
                        </div>
                    </div>
                    <div className="content-wrapper">
                        <div className="title">
                            {displayMode === ROLES.CLIENT ? 'Candidate: ' : 'Company: '}
                        </div>
                        <div className="content">
                            {displayMode === ROLES.CLIENT ? interview.candidateName : interview.company}
                        </div>
                    </div>
                    <div className="content-wrapper">
                        <div className="title">
                            Location:
                        </div>
                        <div className="content">
                            {interview.location}
                        </div>
                    </div>
                    {interview.note &&
                        <div className="content-wrapper">
                            <div className="title">
                                Note:
                            </div>
                            <div className="content">
                                {interview.note}
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div className="date-container">
                <div className="date-container-title">
                    {'Choose Date & Time'}
                </div>
                <div className="date-container-description">
                    The {proposer} shared their availability for the upcoming interview. Please accept or propose new slots.
                </div>
                <div className="date-container-content">
                    {
                        interview.interviewTimeSlots.map((timeSlot, index) =>
                            <div key={index} className="date-block-container">
                                <Checkbox className="date-block-checkbox"
                                    checked={checkedRow?.date === timeSlot.date && checkedRow?.time === timeSlot.time}
                                    onChange={e => handleCheckRow(timeSlot)}
                                />
                                <div className="date-block-container-content date">
                                    <DateIcon className="date-block-icon date" />
                                    <div className="date-block-date">
                                        {moment(timeSlot.date).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR)}
                                    </div>
                                </div>
                                <div className="date-block-container-content hour">
                                    <ClockIcon className="date-block-icon hour" />
                                    <div className="date-block-hour">
                                        {`${moment(timeSlot.time).format(CONTINENTAL_TIME_FORMAT)} - ${moment(timeSlot.time).add(1, 'hours').format(CONTINENTAL_TIME_FORMAT)} (your browser time - [${currentTimezone}])`}
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
                <div className="date-container-button">
                    <SecondaryButton
                        text="Propose New Slots"
                        handleClick={handleOpenInviteDialog}
                    />
                    <PrimaryButton
                        text="Confirm Interview"
                        handleClick={() => handleSubmit(interview._id, checkedRow)}
                        disabled={isApproveButtonDisabled}
                    />
                </div>
            </div>

            <InterviewInvitePopup
                isDialogOpen={isInviteDialogOpen}
                handleCloseDialog={handleCloseInviteDialog}
                handleCloseConfirmDialog={handleCloseConfirmDialog}
                isWithNote={true}
                handelInterviewInvitationSubmit={handelInterviewInvitationSubmit}
                dialogTitle={'Propose New Time Slots'}
                isLocationDisabled={true}
                interviewInvitation={interview}
                displayMode={displayMode}
            />
        </Paper>
    )
};

const mapStateToProps = state => ({
    displayMode: getDisplayMode(state),
});

const mapDispatchToProps = {
    updateInterviewStatus,
    createInterviewInvitation,
    updateInterviewData
};

export default connect(mapStateToProps, mapDispatchToProps)(InterviewInvitationCard);
