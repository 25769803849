import React, { useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { getDeletedUsers } from 'reducers';

import { fetchAgencyDeletedUsers } from 'actions/agencyActions';
import { restoreUsers, permanentlyDeleteUser } from 'actions/userActions';

import TableWrapper from 'components/Shared/TableWrapper';
import ConfirmDialog from 'components/Shared/ConfirmDialog';
import ConfirmRestoreUserDialog from 'components/Shared/ConfirmRestoreUserDialog';

import { DATE_FORMAT_WITH_DOTS } from 'constants/commonConstants';
import { ROLES } from 'constants/userConstants';
import {
    DELETED_USERS_TABLE_COLUMNS,
    DEFAULT_DELETED_USERS_TABLE_COLUMNS,
    DELETED_USERS_TABLE_SORT_QUERY_SELECTOR,
    DELETED_USERS_TABLE_PAGE_QUERY_SELECTOR,
    DEFAULT_ITEMS_PER_PAGE,
    INITIAL_QUERY,
    QUERY_PARAMS_MAP
} from './UsersTableConstants';

import { usePreloadTable } from 'utils/hooks';

const UsersTable = ({
    agencyId,
    fetchAgencyDeletedUsers,
    restoreUsers,
    permanentlyDeleteUser,
}) => {
    const [userToRestore, setUserToRestore] = useState(null);
    const [userToDelete, setUserToDelete] = useState(null);
    const [isRestoreDialogVisible, setIsRestoreDialogVisible] = useState(false);
    const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);

    const tableFetchAction = fetchAgencyDeletedUsers.bind(null, agencyId);
    const [isTableDataLoading, totalCount] = usePreloadTable(INITIAL_QUERY, QUERY_PARAMS_MAP, null, tableFetchAction);

    const dataSelector = (state, page) => getDeletedUsers(state, page - 1, DEFAULT_ITEMS_PER_PAGE);

    const handleOpenRestoreDialog = () => setIsRestoreDialogVisible(true);
    const handleCloseRestoreDialog = () => setIsRestoreDialogVisible(false);
    const handleOpenDeleteDialog = () => setIsDeleteDialogVisible(true);
    const handleCloseDeleteDialog = () => setIsDeleteDialogVisible(false);

    const handleRestore = (user) => {
        setUserToRestore(user);
        handleOpenRestoreDialog(true);
    };

    const restoreUser = () => {
        restoreUsers([userToRestore._id], userToRestore.role);
        handleCloseRestoreDialog();
    };

    const handleDeleteUserClick = (user) => {
        setUserToDelete(user);
        handleOpenDeleteDialog();
    };

    const deleteUser = () => {
        permanentlyDeleteUser(userToDelete._id);
        handleCloseDeleteDialog();
    };

    const adjustUsersKeys = (stateData) => stateData.map(user => {
        return {
            name: user.name || '-',
            email: user.email || '-',
            dateDeleted: user.dateDeleted ? moment(user.dateDeleted).format(DATE_FORMAT_WITH_DOTS) : '-',
            item: user,
        };
    });

    const actions = [
        { title: 'Restore User', handleClick: handleRestore },
        { title: 'Permanently Delete', handleClick: handleDeleteUserClick }
    ];

    const getPopoverActions = () => actions;

    return (
        <div className="users-table-container">
            <TableWrapper
                title='Deleted Users'
                columns={DELETED_USERS_TABLE_COLUMNS}
                defaultColumns={DEFAULT_DELETED_USERS_TABLE_COLUMNS}
                dataSelector={dataSelector}
                sortQuerySelector={DELETED_USERS_TABLE_SORT_QUERY_SELECTOR}
                adjustKeysFunction={adjustUsersKeys}
                isTableDataLoading={isTableDataLoading}
                addMargin
                withPagination
                withActions
                getActionsHandler={getPopoverActions}
                pagesCount={Math.ceil(totalCount / DEFAULT_ITEMS_PER_PAGE)}
                pageQuerySelector={DELETED_USERS_TABLE_PAGE_QUERY_SELECTOR}
            >
                <TableWrapper.TableAlignedItems>
                    <TableWrapper.TableAlignedItems.LeftAligned>
                        <TableWrapper.TableAlignedItems.TableHeader />
                    </TableWrapper.TableAlignedItems.LeftAligned>
                </TableWrapper.TableAlignedItems>
                <TableWrapper.TableContent />
            </TableWrapper>

            <ConfirmRestoreUserDialog
                openDialog={isRestoreDialogVisible}
                handleCloseDialog={handleCloseRestoreDialog}
                handleConfirmDialog={restoreUser}
                isUserEmployeeOrAdmin={[ROLES.ADMIN, ROLES.EMPLOYEE].includes(userToRestore?.role)}
            />

            <ConfirmDialog
                openDialog={isDeleteDialogVisible}
                dialogTitle="Permanently delete user"
                dialogSubtitle={`Are you sure you want to permanently delete ${userToDelete?.name}?`}
                confirmButtonName="Yes"
                discardButtonName="Close"
                handleConfirmDialog={deleteUser}
                handleCloseDialog={handleCloseDeleteDialog}
            />
        </div>
    )
};

const mapDispatchToProps = {
    fetchAgencyDeletedUsers,
    restoreUsers,
    permanentlyDeleteUser,
};

export default connect(null, mapDispatchToProps)(UsersTable);
