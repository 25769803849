import React from 'react';

import { Formik } from 'formik';
import Dialog from 'components/Shared/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import RejectApplicationDialogForm from './RejectApplicationDialogForm';
import { validateRequiredFields } from 'utils/formValidations';

import './RejectApplicationDialog.scss'

const RejectApplicationDialog = ({
    isOpen,
    handleCloseDialog,
    handleRejectSubmit,
    stepOfRejection,
    agencyName
}) => {
    return (
        <Formik
            initialValues={{
                comment: '',
            }}
            validate={(values) => {
                return {
                    ...validateRequiredFields(values, ['comment']),
                }
            }}
            onSubmit={(values, { setSubmitting }) => {
                handleRejectSubmit(stepOfRejection, values);
                handleCloseDialog();
            }}
        >
            {(props) =>
                <Dialog className="reject-application-dialog" open={isOpen} onClose={handleCloseDialog}>
                    <MuiDialogTitle disableTypography className="dialog-title">
                        <Typography variant="body2" className="dialog-title-text">Send feedback</Typography>
                        <IconButton className="dialog-close-btn" onClick={handleCloseDialog}>
                            <CloseIcon />
                        </IconButton>
                    </MuiDialogTitle>

                    <MuiDialogTitle disableTypography className="dialog-subtitle">
                        <Typography variant="body2" className="dialog-subtitle-text">Please provide a detailed feedback why you think the candidate is not suitable for your position. This will help our candidates to develop their skills and help {agencyName} to find the right profiles for your project.</Typography>
                    </MuiDialogTitle>

                    <DialogContent className="dialog-content">
                        <RejectApplicationDialogForm
                            {...props}
                            handleCloseDialog={handleCloseDialog}
                        />
                    </DialogContent>
                </Dialog>
            }
        </Formik>
    )
};

export default RejectApplicationDialog;
