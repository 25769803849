import React from 'react';
import { connect } from 'react-redux';
import MomentUtils from '@date-io/moment';

import { getClientConsultants, getCurrentUser, isClientProjectsModuleModified } from 'reducers';
import { addEmployeeToProject, addDedicatedEmployeeToProject, updateProjectEmployee, updateProjectDedicatedEmployee } from 'actions/clientActions';

import { Formik } from 'formik';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import AddEmployeeToProjectFormView from './AddEmployeeProjectsCardFormView/AddEmployeeProjectsCardFormView';
import CommonFormikDialog from 'components/Shared/CommonFormikDialog';

import { dialogInitialsValues, dialogValidations } from './AddEmployeeProjectsCardDialogUtils';

import { BILLING_TYPE_STATIC_VALUES, DIALOG_TYPES, BILLING_TYPE_TO_EMPLOYEE_MAPPER } from 'constants/clientProjectConstants';

const AddEmployeeProjectsCardDialog = ({
	isOpen,
	type,
	projectId,
	employees,
	clientId,
	agencyId,
	currentUser,
	handleCloseDialog,
	addEmployeeToProject,
	addDedicatedEmployeeToProject,
	updateProjectEmployee,
	updateProjectDedicatedEmployee,
	employeesAdded,
	project,
	setProject,
	projectCurrency,
	isClientProjectsModuleModified,
	isClientInvoicingCompleted,
}) => {
	const initialValues = dialogInitialsValues({ ...project, projectCurrency }, type);

	const filterAutoCompleteEmployees = (employees, employeesAdded) => {
		return employees
			.filter((employee) => !employeesAdded || !employeesAdded.some((x) => x.employeeId === employee._id && !x.isDeleted))
			.sort((a, b) => {
				const first = `${a.firstName} ${a.lastName}`;
				const second = `${b.firstName} ${b.lastName}`;
				return first.localeCompare(second);
			});
	};

	return (
		<MuiPickersUtilsProvider utils={MomentUtils}>
			<Formik
				initialValues={initialValues}
				validate={(values) => {
					const employeeType = type === DIALOG_TYPES.BILLING_TYPE
						? BILLING_TYPE_TO_EMPLOYEE_MAPPER[values.billingType] : type;
					return dialogValidations(values, type, employeeType, employees)
				}}
				onSubmit={(values, { setSubmitting }) => {
					setSubmitting(false);

					const isDedicated = type === DIALOG_TYPES.FULL_TIME || values.billingType === BILLING_TYPE_STATIC_VALUES[1].LABEL;
					const isOnDemand = type === DIALOG_TYPES.CONTRACT || values.billingType === BILLING_TYPE_STATIC_VALUES[0].LABEL;

					if (project.isEdit) {
						setProject(values);
					}

					if (isDedicated) {
						values = { ...values, monthlyServiceFee: Number(values.monthlyServiceFee), employerTotalCost: Number(values.employerTotalCost) };
						project.isEdit ?
							updateProjectDedicatedEmployee(agencyId, clientId, projectId, values, currentUser.name, initialValues) :
							addDedicatedEmployeeToProject(agencyId, clientId, projectId, values);
					} else if (isOnDemand) {
						values = { ...values, hourRate: Number(values.hourRate) };
						project.isEdit ?
							updateProjectEmployee(agencyId, clientId, projectId, values, currentUser.name, initialValues) :
							addEmployeeToProject(agencyId, clientId, projectId, values);
					}

					handleCloseDialog();
				}}
				enableReinitialize={true}
			>
				{(props) => (
					<CommonFormikDialog
						open={isOpen}
						onClose={handleCloseDialog}
						maxWidth={"sm"}
						fullWidth={true}
						onSave={props.handleSubmit}
						dialogTitle={isClientProjectsModuleModified ?
							"Member" :
							type === DIALOG_TYPES.FULL_TIME ? 
								project.isEdit ? "Edit full-time employee" : "Add full-time employee" :
								project.isEdit ? "Edit contract employee" : "Add contract employee"
						}
					>
						<AddEmployeeToProjectFormView
							{...props}
							employees={filterAutoCompleteEmployees(employees, employeesAdded)}
							isEdit={project.isEdit}
							projectCurrency={projectCurrency}
							isOpen={isOpen}
							type={type}
							isClientInvoicingCompleted={isClientInvoicingCompleted}
							isClientProjectsModuleModified={isClientProjectsModuleModified}
						/>
					</CommonFormikDialog>
				)}
			</Formik>
		</MuiPickersUtilsProvider>
	);
};

const mapStateToProps = (state) => ({
	employees: getClientConsultants(state),
	currentUser: getCurrentUser(state),
	isClientProjectsModuleModified: isClientProjectsModuleModified(state),
});

const mapDispatchToProps = {
	addEmployeeToProject,
	addDedicatedEmployeeToProject,
	updateProjectEmployee,
	updateProjectDedicatedEmployee,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEmployeeProjectsCardDialog);
