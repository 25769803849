import React from 'react';

import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';

import ExternalJobIdIcon from 'assets/external-job-id-icon.png';
import { ReactComponent as CopyIcon } from 'assets/copy-tracking-url-icon.svg';

import './ExternalJobIdLabel.scss';

const ExternalJobIdLabel = ({ externalJobId }) => {

    const handleCopyExternalJobId = () => navigator.clipboard.writeText(externalJobId);

    return (
        <Tooltip
            interactive
            classes={{ tooltip: 'external-job-id-label-custom-tooltip' }}
            placement="bottom"
            title={
                <div className="tooltip-content" onClick={handleCopyExternalJobId}>
                    <span className="external-job-id">{externalJobId}</span>
                    <Button
                        className="copy-btn"
                        startIcon={<CopyIcon />}
                        onClick={handleCopyExternalJobId}
                    >
                        Copy
                    </Button>
                </div>
            }
        >
            <img src={ExternalJobIdIcon} className="external-job-id-icon" />
        </Tooltip>
    );
};

export default ExternalJobIdLabel;
