import React from 'react';
import Slider from 'react-slick';
import { isMobile } from 'react-device-detect';

import { ReactComponent as PictureInPictureIcon } from 'assets/icon-picture-in-picture.svg';
import { ReactComponent as FiveStarsIcon } from 'assets/icon-5-stars.svg';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css'
import './SliderTestimonials.scss';

const SliderTestimonials = ({ data, isAfterJourney = false }) => {
    const settings = {
        arrows: false,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        customPaging: () => <span className="dot" />
    };

    return (
        <div className={`slider-testimonials-wrapper${(isMobile && !isAfterJourney) ? ' mobile' : ''}`}>
            <Slider {...settings}>
                {data.map((element, index) => {
                    return (
                        <div key={`${element.name}-${element.position}-${index}`}>
                            <div className="slider-testimonials-item-wrapper">
                                {(isMobile && !isAfterJourney)
                                    ? <>
                                        <p className="testimonial">"{element.text}"</p>
                                        <div className="slider-testimonials-item-image" style={{ backgroundImage: `url(${element.image})` }}>
                                            <div className="overlay" />
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className="col-1of2">
                                            <PictureInPictureIcon />
                                            <h3>
                                                Find a remote job that fits your lifestyle and interests
                                            </h3>
                                            <p className="testimonial">"{element.text}"</p>
                                            <div className="position-rating">
                                                <span>{element.position}</span>
                                                <div>
                                                    <FiveStarsIcon />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-2of2">
                                            <div className="slider-testimonials-item-image" style={{ backgroundImage: `url(${element.image})` }} />
                                        </div>
                                    </>
                                }

                            </div>
                        </div>
                    )
                })}
            </Slider>
        </div>
    );
};

export default SliderTestimonials;
