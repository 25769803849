import { TABLE_COLUMN_DIRECTIONS } from 'constants/commonConstants';
import { DEFAULT_PAGES_PRELOAD_COUNT } from 'components/Shared/TableWrapper/TableWrapperConstants';
import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { deepEqual, parseQueryString, queryObjToString } from './helpers';

export const useDebounce = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value]);

    return debouncedValue;
};


export const useOrderBy = (defaultSortValues, mapQueryToSorting) => {
    const location = useLocation();
    const history = useHistory();
    const [orderBy, setOrderBy] = useState({ ...defaultSortValues });

    useEffect(() => {
        const queryString = location.search;
        const parsedQuery = parseQueryString(queryString);
        const newSorting = mapQueryToSorting(parsedQuery);
        setOrderBy({ ...orderBy, ...newSorting });
    }, [location.search]);

    const getDirection = (newColumn) => {
        if (orderBy.column === newColumn) {
            return orderBy.direction === TABLE_COLUMN_DIRECTIONS.DESCENDING
                ? TABLE_COLUMN_DIRECTIONS.ASCENDING
                : TABLE_COLUMN_DIRECTIONS.DESCENDING;
        }

        return TABLE_COLUMN_DIRECTIONS.ASCENDING;
    };

    const handleSort = (newColumn) => {
        const parsedQuery = parseQueryString(location.search);

        const newOrder = { column: newColumn, direction: getDirection(newColumn, orderBy) };

        Object.keys(newOrder).forEach((key) => (parsedQuery[key] = newOrder[key]));

        const updatedQuery = queryObjToString(parsedQuery);

        history.replace({ pathname: location.pathname, search: updatedQuery });
    };

    return [orderBy, handleSort, setOrderBy]
};

export const useClickOutside = (ref, onClickOutside) => {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                onClickOutside();
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, onClickOutside]);
};

export const usePreloadTable = (initialQuery, queryParamsMap, filters = [], action, reload, setReload, prefetchAction) => {
    const location = useLocation();

    const [isTableLoading, setIsTableLoading] = useState(true);
    const [maxPageVisited, setMaxPageVisited] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [savedQuery, setSavedQuery] = useState({});
    const [isPrefetchedDataLoaded, setIsPrefetchedDataLoaded] = useState(false);

    useEffect(() => {
        if (prefetchAction && !isPrefetchedDataLoaded) {
            prefetchAction().then(() => { setIsPrefetchedDataLoaded(true); });
        } else {
            setIsPrefetchedDataLoaded(true);
        }
    }, []);

    useEffect(() => {
        if (isPrefetchedDataLoaded) {
            const parsedQueryFromUrl = parseQueryString(location.search, true);

            const query = { ...initialQuery };

            for (const key in initialQuery) {
                // there is some special logic for pages and filters
                if (key === "page") {
                    if (parsedQueryFromUrl[queryParamsMap[key]]) {
                        query.page = +parsedQueryFromUrl[queryParamsMap[key]][0];

                        if (query.page > maxPageVisited) {
                            setMaxPageVisited(query.page);
                        }
                    }
                } else if (key === "filters") {
                    if (parsedQueryFromUrl[queryParamsMap[key]]) {
                        const appliedFilters = {};

                        // In case of other tables on the same page, take only the filters that apply for this page
                        for (const query of parsedQueryFromUrl[queryParamsMap[key]]) {
                            const filter = filters.find(x => x.value.includes(query)) || null;
                            if (filter) {
                                if (appliedFilters[filter.key]) {
                                    appliedFilters[filter.key].push(query);
                                } else {
                                    appliedFilters[filter.key] = [query];
                                }
                            }
                        }

                        query.filters = JSON.stringify(appliedFilters);
                    }
                } else if (parsedQueryFromUrl[queryParamsMap[key]]) {
                    query[key] = parsedQueryFromUrl[queryParamsMap[key]][0];
                }
            }

            const formattedQuery = queryObjToString(query);

            const { page: currentQueryPage, ...currentQueryWithoutPage } = query;
            const { page: savedQueryPage, ...savedQueryWithoutPage } = savedQuery;

            const hasPageChanged = currentQueryPage !== savedQueryPage;
            const hasQueryChanged = !deepEqual(currentQueryWithoutPage, savedQueryWithoutPage);
            const hasNoDataLeft = (query.page - 1) % DEFAULT_PAGES_PRELOAD_COUNT === 0;

            if (hasPageChanged && !hasQueryChanged && (query.page === 1 || hasNoDataLeft) && query.page >= maxPageVisited) {
                setIsTableLoading(true);
                setSavedQuery({ ...query });
                // the action should accept as last 2 params the query and a boolean (should the state be reset or not)
                // if there are other params they should be bound to the function before it is passed
                action(formattedQuery, false).then((totalCount) => {
                    setIsTableLoading(false);
                    setTotalCount(totalCount);
                });
            } else if (hasQueryChanged) {
                setIsTableLoading(true);
                setSavedQuery({ ...query });
                // the action should accept as last 2 params the query and a boolean (should the state be reset or not)
                // if there are other params they should be bound to the function before it is passed
                action(formattedQuery, true).then((totalCount) => {
                    setIsTableLoading(false);
                    setTotalCount(totalCount);
                });
            }

            if (reload) {
                if (prefetchAction) {
                    prefetchAction()
                        .then(() => {
                            // the action should accept as last 2 params the query and a boolean (should the state be reset or not)
                            // if there are other params they should be bound to the function before it is passed
                            action(formattedQuery, true).then((totalCount) => {
                                setTotalCount(totalCount);
                                setReload(false);
                            });
                        });
                } else {
                    action(formattedQuery, true).then((totalCount) => {
                        // the action should accept as last 2 params the query and a boolean (should the state be reset or not)
                        // if there are other params they should be bound to the function before it is passed
                        setTotalCount(totalCount);
                        setReload(false);
                    });
                }
            }
        }

    }, [location.search, reload, isPrefetchedDataLoaded]);

    return [isTableLoading, totalCount];
};

