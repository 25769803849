import React, { useEffect, useLayoutEffect, useState } from 'react';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import './ContentModeratorWrapper.scss';

const ContentModeratorWrapper = ({
    children,
}) => {
    const [isFullContentShown, setIsFullContentShown] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(0);
    const scroller = document.getElementById('scroller');

    const handleShowChildren = () => {
        if (Array.isArray(children)) {
            children = children.filter(child => child)
            if (isFullContentShown || children.length < 2) {
                return children
            } else {
                return children[0]
            }
        }

        return children
    }

    const handleShowContentClick = () => {
        setIsFullContentShown(oldState => !oldState)
    }

    useEffect(() => {
        if (scroller) {
            const handleScroll = () => {
                setScrollPosition(scroller.scrollTop);
            };

            scroller.addEventListener('scroll', handleScroll);

            return () => {
                scroller.removeEventListener('scroll', handleScroll);
            }
        }
    }, [scroller]);

    useLayoutEffect(() => {
        if (scroller) {
            scroller.scrollTo(0, scrollPosition);
        }
    }, [isFullContentShown]);

    return (
        <div className="content-moderator-wrapper">
            {handleShowChildren(children)}
            {
                children.length > 1 &&
                <div className="action-wrapper" onClick={handleShowContentClick}>
                    {
                        isFullContentShown
                            ? 'Show less information'
                            : 'Show full information'
                    }
                    {
                        isFullContentShown
                            ? <KeyboardArrowUpIcon />
                            : <KeyboardArrowDownIcon />
                    }
                </div>
            }
        </div>
    )
}

export default ContentModeratorWrapper;
