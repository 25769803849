export const clientSubmenuItems = {
    overview: { text: 'Overview', path: '' },
    companyInformation: { text: 'Company Information', path: '/company-information' },
    projects: { text: 'Projects', path: '/projects' },
    jobOpportunities: { text: 'Job Opportunities', path: '/jobs' },
    billing: { text: 'Billing', path: '/billing' },
    contracts: { text: 'Contracts', path: '/contracts' },
    tasksReminders: { text: 'Tasks/Reminders', path: '/tasks-reminders' },
};


export const HIDDEN_MENU_ITEMS = []; 

export const clientStatuses = ['Prospect', 'Active (Invoicing)', 'Inactive (Not Invoicing)'];
