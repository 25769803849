import React from 'react';

import ConfirmDialog from '../ConfirmDialog';
import { CONFIRM_DIALOG_RESTORE_USER_CONFIRMATION_ITEMS } from '../ConfirmDialog/ConfirmDialogConstants';

import './ConfirmRestoreUserDialog.scss';

const ConfirmRestoreUserDialog = ({
    openDialog,
    handleConfirmDialog,
    handleCloseDialog,
    isUserEmployeeOrAdmin,
}) => (
    <ConfirmDialog
        openDialog={openDialog}
        dialogTitle={CONFIRM_DIALOG_RESTORE_USER_CONFIRMATION_ITEMS.DIALOG_TITLE}
        dialogSubtitle={isUserEmployeeOrAdmin
            ? CONFIRM_DIALOG_RESTORE_USER_CONFIRMATION_ITEMS.DIALOG_SUBTITLE_EMPLOYEE_OR_ADMIN
            : CONFIRM_DIALOG_RESTORE_USER_CONFIRMATION_ITEMS.DIALOG_SUBTITLE_DEFAULT
        }
        confirmButtonName={CONFIRM_DIALOG_RESTORE_USER_CONFIRMATION_ITEMS.CONFIRM_BUTTON_NAME}
        discardButtonName={CONFIRM_DIALOG_RESTORE_USER_CONFIRMATION_ITEMS.DISCARD_BUTTON_NAME}
        handleConfirmDialog={handleConfirmDialog}
        handleCloseDialog={handleCloseDialog}
    />
);

export default ConfirmRestoreUserDialog;

