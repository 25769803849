import React from 'react';
import moment from 'moment';

import { Formik } from 'formik';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import ProjectsFormView from './ProjectsFormView';
import CommonFormikDialog from 'components/Shared/CommonFormikDialog';

import {
	validateRequiredFields,
	validateEmailAddress,
	validatePhoneNumber,
	validateInputLength,
	validateDateFields,
	validateEndDateNotBeforeStartDate,
	validateStartDateNotBeforeEndDate,
} from 'utils/formValidations';
import { addUtcOffset } from 'utils/formatTimes';

import { NAME_MAX_LENGTH, DESCRIPTION_MAX_LENGTH } from '../ProjectsConstants';

import './AddProjectsDialog.scss';

const AddProjectsDialog = ({
	projectInfo,
	openDialog,
	handleAddProject,
	handleEditProject,
	handleCloseDialog,
	contactInformation
}) => {
	return (
		<MuiPickersUtilsProvider utils={MomentUtils}>
			<Formik
				enableReinitialize
				initialValues={{
					name: projectInfo?.name || '',
					type: projectInfo?.type || '',
					description: projectInfo?.description || '',
					startDate: projectInfo?.startDate ? moment.utc(projectInfo.startDate) : null,
					endDate: projectInfo?.endDate ? moment.utc(projectInfo.endDate) : null,
					ongoing: projectInfo?.ongoing || true,
					contactPerson: projectInfo?.contactPerson || contactInformation?.contactPerson || '',
					contactEmail: projectInfo?.contactEmail || contactInformation?.contactEmail || '',
					contactPhone: projectInfo?.contactPhone || contactInformation?.contactNumber || '',
				}}
				validate={(values) => {
					return {
						...validateRequiredFields(values, ["name", "type", "startDate"]),
						...validateInputLength(values, [
							{
								property: "name",
								maxLength: NAME_MAX_LENGTH,
							},
							{
								property: "description",
								maxLength: DESCRIPTION_MAX_LENGTH,
							},
						]),
						...validateEmailAddress(values, "contactEmail"),
						...validatePhoneNumber(values, "contactPhone"),
						...validateDateFields(values, ["startDate", "endDate"]),
						...validateEndDateNotBeforeStartDate(values, "startDate", "endDate"),
						...validateStartDateNotBeforeEndDate(values, "startDate", "endDate", false),
					};
				}}
				onSubmit={(values, { setSubmitting }) => {
					setSubmitting(false);
					const projectData = {
						...(values.name && { name: values.name }),
						...(values.type && { type: values.type }),
						...(values.description && { description: values.description }),
						ongoing: values.ongoing || !values.endDate,
						...(values.startDate && { startDate: addUtcOffset(values.startDate) }),
						...(values.endDate && { endDate: addUtcOffset(values.endDate) }),
						...(values.contactPerson && { contactPerson: values.contactPerson }),
						...(values.contactEmail && { contactEmail: values.contactEmail.toLowerCase().trim() }),
						...(values.contactPhone && { contactPhone: values.contactPhone }),
					};
					projectInfo ? handleEditProject({ _id: projectInfo._id, ...projectData }) : handleAddProject(projectData);
					handleCloseDialog();
				}}>
				{(props) => (
					<CommonFormikDialog
						className="add-project-dialog"
						open={openDialog}
						onClose={handleCloseDialog}
						maxWidth="sm"
						fullWidth={true}
						dialogTitle='Add new project'
						onSave={props.handleSubmit}
					>
						<ProjectsFormView
							{...props}
						/>
					</CommonFormikDialog>
				)}
			</Formik>

		</MuiPickersUtilsProvider>
	);
};

export default AddProjectsDialog;
