export const JOB_OPPORTUNITIES_STATUSES = {
    OPEN: 'open',
    CLOSED: 'closed',
};

export const JOB_OPPORTUNITIES_PRIORITIES = {
    LOW: 'Low',
    MEDIUM: 'Medium',
    HIGH: 'High'
}

export const BENEFITS_MAIN_BOXES = [
    {
        title: 'JOB SECURITY',
        description: 'Work remotely in the long-term, under a permanent employment contract with all social and additional benefits included, for the top European and Silicon Valley companies without moving anywhere.',
    },
    {
        title: 'FLEXIBILITY',
        description: 'Choose when and where you work from while doing the things you love. Your contribution is not based on how many hours you’ve spent at your desk.',
    },
    {
        title: 'REMOTE WORK',
        description: 'Find your own creative space. Whether that’s home office, a co-working space or Motion Software’s offices, we will support you.',
    }
]

export const BENEFITS_LISTS = [
    {
        title: 'GROWTH',
        benefits: [
            'External & internal Trainings', 'Courses', 'Scholarships & Certifications', 'Workshops & Conferences', 'Network of Experts'
        ]
    },
    {
        title: 'WELL-BEING',
        benefits: [
            'Premium Health Insurance & Medical Assistance', 'Gym Membership', 'Adventure Sports and Sporting Equipment', 'Weekly Team Activities', 'Community Events and Regular Team Retreats'
        ]
    },
    {
        title: 'EQUIPMENT',
        benefits: [
            '24/7 Access to Fully-Equipped Offices', 'Coworking', 'Recreational Facilities', 'Home Office Gear & Technical Equipment', 'Internet Connection & Office Supplies'
        ]
    }
]

export const DEFAULT_JOB_STATUS_VALUE = { value: 'open', name: 'Open' };

export const JOB_STATUS_FILTER_VALUES = [
    DEFAULT_JOB_STATUS_VALUE,
    { value: 'closed', name: 'Closed' },
    { value: 'all', name: 'All' },
]

export const JOB_OPPORTUNITIES_HEAD_CELLS = [
    { title: 'Position', value: 'position', sort: true },
    { title: 'Company', value: 'company', sort: true },
    { title: 'Location', value: 'location', sort: true },
    { title: 'Status', value: 'status', sort: false },
    { title: '', value: 'actions', sort: false },
];

export const JOB_OPPORTUNITIES_VIEW_TYPES = {
    LIST: 'list',
    TABLE: 'table',
};

export const JOB_OPPORTUNITIES_CONTRACTOR_SENIORITIES = [
    'junior',
    'mid',
    'senior',
];

export const JOB_OPPORTUNITIES_TECHNOLOGIES = [
    {
        _id: 'c#',
        title: 'C#',
    },
    {
        _id: 'javascript',
        title: 'JavaScript',
    },
    {
        _id: 'java',
        title: 'Java',
    },
    {
        _id: 'python',
        title: 'Python',
    },
    {
        _id: 'ruby',
        title: 'Ruby',
    },
    {
        _id: 'mobile',
        title: 'Mobile Development',
    },
    {
        _id: 'wordPress/PHP',
        title: 'PHP / WordPress',
    },
];

export const JOB_OPPORTUNITIES_PRIVACY = {
    PRIVATE: { _id: 0, value: 'Unpublished' },
    PUBLISHED: { _id: 1, value: 'Published' },
};

export const JOB_OPPORTUNITIES_QUERY_PARAMS = '?selectedTab=Recommended';

export const JOB_OPPORTUNITIES_PRIVACY_ARRAY = ['Draft', 'Published'];

export const JOB_OPPORTUNITIES_TYPES = {
    CONTRACT: 'Contract',
    FULL_TIME: 'Full-Time',
}

export const FILED_LENGTH = {
    LONG_TEXT_FIELD: 5000,
    SHORT_TEXT_FIELD: 100,
}

export const REQUIRED_FIELDS_VALIDATION = [
    'position',
    'jobType',
    'aboutCandidate',
    'aboutTheClient',
    'jobSummary',
    'requirements',
    'clientId',
    'profileForwardType'
]

export const CONTRACT_REQUIRED_FIELDS_VALIDATION = [
    'position',
    'jobType',
    'jobSummary',
]

export const FIELD_LENGTH_VALIDATION = [
    {
        property: 'position',
        maxLength: FILED_LENGTH.SHORT_TEXT_FIELD,
    },
    {
        property: 'aboutTheClient',
        maxLength: FILED_LENGTH.LONG_TEXT_FIELD,
    },
    {
        property: 'jobSummary',
        maxLength: FILED_LENGTH.LONG_TEXT_FIELD,
    },
    {
        property: 'aboutCandidate',
        maxLength: FILED_LENGTH.LONG_TEXT_FIELD,
    },
    {
        property: 'requirements',
        maxLength: FILED_LENGTH.LONG_TEXT_FIELD,
    },
    {
        property: 'benefits',
        maxLength: FILED_LENGTH.LONG_TEXT_FIELD,
    },
];

export const JOB_FORM_TITLES = {
    CREATE: 'Create new job',
    EDIT: 'Edit job',
}

export const JOB_OPPORTUNITIES_TYPES_ARRAY = [JOB_OPPORTUNITIES_TYPES.CONTRACT, JOB_OPPORTUNITIES_TYPES.FULL_TIME];

export const JOB_PROFILE_FORWARD_TYPES = [
    { key: 0, value: 'Manually forward' },
    { key: 1, value: 'Auto forward' },
];

export const SKILLS_CATEGORY = {
    MANDATORY: { _id: 0, title: "Mandatory Skills" },
    NICE_TO_HAVE: { _id: 1, title: "Nice to Have" },
};

export const DEFAULT_JOB_LOCATIONS = [
    'Bulgaria',
'Romania',
'Turkey',
'Belarus',
'Brazil',
'Canada',
'Chile',
'Colombia',
'Georgia',
'Germany', 
    'Hungary',
'Lithuania',
'Poland',
'Switzerland',
'Ukraine',
'USA',
'Uzbekistan',
]
