import React from 'react';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';

import { InlineWidget, CalendlyEventListener } from 'react-calendly';

import { getDisplayName, getEmail, getEmployeeEmail, getEmployeeName } from 'reducers';

import Dialog from '@material-ui/core/Dialog';

import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';

import './CalendlyDialog.scss';

const CalendlyDialog = ({
    handleClose,
    calendlyInfo,
    candidateName,
    candidateEmail,
    handleEventScheduling,
}) => {
    const calendlyFullUrl = calendlyInfo.calendlyUrl.includes('https://calendly.com')
        ? calendlyInfo.calendlyUrl
        : `https://calendly.com/${calendlyInfo.calendlyUrl}`;

    const isUrlLoading = !Boolean(calendlyInfo.calendlyUrl);

    const widgetProps = {
        url: calendlyFullUrl || '',
        pageSettings: {
            hideEventTypeDetails: false,
            hideLandingPageDetails: false,
            primaryColor: '16D696',
            primaryColorLevel4: 'C5C9F64D',
            textColor: '707070',
        },
        prefill: {
            email: candidateEmail,
            name: candidateName,
        },
    };

    const customStyles = isMobile ? { height: '85vh' } : { height: '660px', minWidth: '1000px' };

    return (
        <Dialog className="book-interview-dialog" open={true} onClose={handleClose} maxWidth={false}>
            <MuiDialogTitle disableTypography className="dialog-title">
                <IconButton className={`dialog-close-btn ${isMobile ? 'mobile': ''}`} onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </MuiDialogTitle>
            <DialogContent className={`${isUrlLoading ? (isMobile ? 'book-interview-container full-size-mobile' : 'book-interview-container full-size') : 'book-interview-container'}`}>
                {isUrlLoading ?
                    <CircularProgress size={50} className="page-loader" /> :
                    <>
                        <InlineWidget styles={customStyles} {...widgetProps} />
                        <CalendlyEventListener onEventScheduled={handleEventScheduling} />
                    </>
                }
            </DialogContent>
        </Dialog>
    );
};

const mapStateToProps = (state, props) => ({
    candidateEmail: props.isAdminMode ? getEmployeeEmail(state) : getEmail(state),
    candidateName: props.isAdminMode ? getEmployeeName(state) : getDisplayName(state),
});

export default connect(mapStateToProps, null)(CalendlyDialog);
