import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import TableWrapper from 'components/Shared/TableWrapper';
import CircularProgress from '@material-ui/core/CircularProgress';

import {
    REPORT_TABLE_COLUMNS,
    DEFAULT_REPORT_TABLE_COLUMNS,
    REFERRER_QUESTION,
} from '../ReportsPageConstants';

const ReportsTable = ({
    tableName,
    data,
    className,
    handleSingleRowExport,
    dataFetchState,
}) => {
    const adjustReportKeys = data => data.map(row => {
        return {
            ...row,
            answer: row.question === REFERRER_QUESTION ?
                <HighchartsReact
                    highcharts={Highcharts}
                    options={dataFetchState?.isDataFetching ? {} : row.answer}
                />
                : (dataFetchState?.isDataFetching
                    ? <CircularProgress size={14} disableShrink />
                    : row.answer
                ),
            item: row,
        }
    });

    const getActionsHandler = () => [{ title: 'Export', handleClick: handleSingleRowExport }];;

    return (
        <div className={`reports-table-container${className ? ` ${className}` : ''}`}>
            <TableWrapper
                title={tableName}
                columns={REPORT_TABLE_COLUMNS}
                defaultColumns={DEFAULT_REPORT_TABLE_COLUMNS}
                dataSelector={() => data}
                adjustKeysFunction={adjustReportKeys}
                addMargin
                withActions
                getActionsHandler={getActionsHandler}
            >
                <TableWrapper.TableAlignedItems>
                    <TableWrapper.TableAlignedItems.LeftAligned>
                        <TableWrapper.TableAlignedItems.TableHeader />
                    </TableWrapper.TableAlignedItems.LeftAligned>
                </TableWrapper.TableAlignedItems>
                <TableWrapper.TableContent />
            </TableWrapper>
        </div>
    );
};

export default ReportsTable;
