import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import PrimaryAddButton from 'components/Shared/Buttons/PrimaryAddButton';

import { getEmployees, getRoles } from 'reducers';
import { fetchAllRecruiterEmployees, registerEmployee } from 'actions/employeeActions';

import AddTeamMemberDialog from 'components/Employees/AddTeamMemberDialog';
import ConfirmInviteUserDialog from 'components/Shared/ConfirmInviteUserDialog';
import ExploreTalentsEmployeeCard from 'components/ExploreTalents/ExploreTalentsEmployeeCard';

import { ROLES } from 'constants/userConstants';
import './RecruiterEmployees.scss';

const RecruiterEmployees = ({
    agencyId,
    employees,
    recruiterId,
    registerEmployee,
    fetchAllRecruiterEmployees,
}) => {

    const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [newUserData, setNewUserData] = useState({});

    useEffect(() => {
        fetchAllRecruiterEmployees(agencyId, recruiterId);
    }, []);

    const handleOpenDialog = () => setIsAddDialogOpen(true);
    const handleCloseDialog = () => setIsAddDialogOpen(false);
    const handleCloseConfirmDialog = () => setOpenConfirmDialog(false);

    const handleSubmitClick = (values) => {
        values = {
            ...values,
            employmentInformation: { generalInfo: { isAvailable: false, isVetted: false } },
            recruiterId,
            role: ROLES.RECRUITER_EMPLOYEE
        };

        if (values.email) {
            setNewUserData(values);
            setOpenConfirmDialog(true);
            return
        }

        registerEmployee(values, agencyId);
        handleCloseDialog();
    };

    const handleProceedClick = () => {
        registerEmployee(newUserData, agencyId);
        handleCloseConfirmDialog();
        handleCloseDialog();
        setNewUserData({});
    };

    return (
        <div className="recruiter-employees-content">
            <div className="action-header">
                <PrimaryAddButton className="add-member-button" handleClick={handleOpenDialog} text="Add Team Member" />
            </div>
            {
                employees.map(employee => {
                    return (
                        <ExploreTalentsEmployeeCard
                            key={employee._id}
                            employee={employee}
                        >
                            <ExploreTalentsEmployeeCard.ProfileWrapper>
                                <ExploreTalentsEmployeeCard.ProfileWrapper.RoleBadge />
                                <div className="avatar-status-wrapper">
                                    <ExploreTalentsEmployeeCard.ProfileWrapper.Avatar />
                                    <ExploreTalentsEmployeeCard.ProfileWrapper.StatusOrb />
                                </div>
                                <ExploreTalentsEmployeeCard.ProfileWrapper.VettedProfile />

                            </ExploreTalentsEmployeeCard.ProfileWrapper>

                            <ExploreTalentsEmployeeCard.ProfileInfoWrapper employee={employee}>
                                <ExploreTalentsEmployeeCard.ProfileInfoWrapper />
                            </ExploreTalentsEmployeeCard.ProfileInfoWrapper>

                            <ExploreTalentsEmployeeCard.ButtonsWrapper>
                                <ExploreTalentsEmployeeCard.ButtonsWrapper.ViewProfile />
                                <ExploreTalentsEmployeeCard.ButtonsWrapper.EditProfile />
                            </ExploreTalentsEmployeeCard.ButtonsWrapper>
                        </ExploreTalentsEmployeeCard>
                    )
                })
            }

            <AddTeamMemberDialog
                isOpen={isAddDialogOpen}
                handleCloseDialog={handleCloseDialog}
                handleRegisterMember={handleSubmitClick}
            />
            <ConfirmInviteUserDialog
                openDialog={openConfirmDialog}
                invitedUser={ROLES.EMPLOYEE}
                handleInviteUser={handleProceedClick}
                handleCloseDialog={handleCloseConfirmDialog}
            />
        </div >
    );
};

const mapStateToProps = (state) => ({
    employees: getEmployees(state),
    roles: getRoles(state),
});

const mapDispatchToProps = {
    fetchAllRecruiterEmployees,
    registerEmployee
};

export default connect(mapStateToProps, mapDispatchToProps)(RecruiterEmployees);
