import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga4';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router'
import * as serviceWorker from './serviceWorker';
import * as Sentry from "@sentry/react";
import 'utils/firebase';

import { MuiThemeProvider, StylesProvider } from '@material-ui/core/styles';

import App from './App';
import store, { history, persistor } from 'utils/store';
import { theme } from 'utils/muiUtils';
import { PersistGate } from 'redux-persist/integration/react';

import { GOOGLE_ANALYTICS_TRACKING_ID, SENTRY_DSN } from 'constants/env';
import './index.scss';

ReactGA.initialize(GOOGLE_ANALYTICS_TRACKING_ID);

Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    tracesSampleRate: 0.5,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: process.env.REACT_APP_ENV || process.env.NODE_ENV,
});

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <ConnectedRouter history={history}>
                <StylesProvider injectFirst>
                    <MuiThemeProvider theme={theme}>
                        <App />
                    </MuiThemeProvider>
                </StylesProvider>
            </ConnectedRouter>
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);

if (window.Cypress) {
    window.store = store
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
