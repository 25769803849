import React, { createContext } from 'react';

import Paper from '@material-ui/core/Paper';
import CardButtonsWrapper from './ExploreTalentsEmployeeCardParts/CardButtonsWrapper';
import CardProfileWrapper from './ExploreTalentsEmployeeCardParts/CardProfileWrapper';
import CardProfileInfoWrapper from './ExploreTalentsEmployeeCardParts/CardProfileInfoWrapper';
import { setPersonalInfoInitialState } from 'actions/employeeActions';

import './ExploreTalentsEmployeeCard.scss';
import { connect } from 'react-redux';

export const ExploreTalentsEmployeeCardContext = createContext();

const { Provider } = ExploreTalentsEmployeeCardContext;

const ExploreTalentsEmployeeCard = ({
    employee,
    setPersonalInfoInitialState,
    children,
}) => {
    const context = { employee, setPersonalInfoInitialState};
    
    return (
        <div className="explore-talents-employee-card-wrapper">
            <Provider value={context}>
                <Paper className="employee-card-container">
                    <div className="employee-card">
                        {children}
                    </div>
                </Paper>
            </Provider>
        </div>
    );
};

ExploreTalentsEmployeeCard.ButtonsWrapper = CardButtonsWrapper;
ExploreTalentsEmployeeCard.ProfileWrapper = CardProfileWrapper;
ExploreTalentsEmployeeCard.ProfileInfoWrapper = CardProfileInfoWrapper;

const mapDispatchToProps = {
    setPersonalInfoInitialState,
}

export default connect(null, mapDispatchToProps)(ExploreTalentsEmployeeCard);
