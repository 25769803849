import React from 'react';
import moment from 'moment';

import { Paper } from '@material-ui/core';

import { applicationProgress, APPLICATION_STATUSES } from 'constants/applicationConstants';
import { DATE_FORMAT_WITH_DOTS_FULL_YEAR } from 'constants/commonConstants';
import { STEPS_OF_REJECTION } from 'constants/candidateConstants';

import './ApplicationProgressBar.scss';

const ApplicationProgressBar = ({
    applicationHistory,
    interviewDate={},
    agencyName,
    isAdminMode,
}) => (
    <Paper className="application-progress-bar-wrapper">
        <h4 className="title">Application History</h4>
        <ul>
            {applicationHistory.filter(x => x.status !== APPLICATION_STATUSES.NON_VETTED_CANDIDATE).sort((a, b) => new Date(a.date) - new Date(b.date)).map((record, i) => {
                return (
                    <li key={i} className="progress-bar-step">
                        <div className="step-content-container">
                            <span className="date-content">{moment.utc(record.date).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR)}</span>
                            <div className="status-content" dangerouslySetInnerHTML={{
                                __html:
                                    record.status === APPLICATION_STATUSES.REJECTED
                                        ? applicationProgress(agencyName, isAdminMode, record?.reviewerName)[record.status][record.stepOfRejection || STEPS_OF_REJECTION.REJECTED_BY_AGENCY]
                                        : applicationProgress(agencyName, isAdminMode, record?.reviewerName, record?.interviewDate || interviewDate)[record.status]
                            }} />
                        </div>
                    </li>
                );
            })}
        </ul>
    </Paper>
);

export default ApplicationProgressBar;
