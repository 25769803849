import { APPLICATION_STATUSES } from 'constants/applicationConstants';
import { INTERVIEW_INVITATION_STATUSES } from 'constants/interviewInvitationConstants';

export const INVITE_BUTTON_TEXTS = {
    INVITE_TO_INTERVIEW: 'Invite to interview',
    ALREADY_INVITED: 'Already invited',
    RESCHEDULE: 'Reschedule',
};

export const INVITE_DIALOG_TITLES = {
    INVITE: 'Invitation for Interview',
    PROPOSE_NEW: 'Propose New Time Slots',
    RESCHEDULE: 'Reschedule',
};

export const getHeaderContent = (status) => {
    switch (status) {
        case APPLICATION_STATUSES.NEW_APPLICATION:
            return {
                title: 'Interview',
                text: 'You can send an invitation and book an interview with this applicant.'
            }
        case APPLICATION_STATUSES.REJECTED:
            return {
                title: 'Interview',
                text: 'You can send an invitation and book an interview with this applicant.'
            }
        case INTERVIEW_INVITATION_STATUSES.PENDING:
            return {
                title: 'Interview Invitation',
                text: 'You have already sent an interview invitation to this candidate.'
            }
        case INTERVIEW_INVITATION_STATUSES.APPROVED:
            return {
                title: 'Upcoming Interview',
                text: 'You have a scheduled interview with this candidate'
            }
        default:
            break;
    }
}

export const DEFAULT_INVITE_DIALOG_TITLE = INVITE_DIALOG_TITLES.INVITE;
