import { useState } from 'react';

import SendProfilesListItem from '../SendProfilesListItem';
import InputBase from '@material-ui/core/InputBase';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from '@material-ui/icons/Search';

import './SendProfilesAutocomplete.scss';

const SendProfilesAutocomplete = ({
    values,
    setFieldValue,
    inputValue,
    setInputValue,
    handleChangeInputValue,
    searchResult,
    fetchSearchResultsPending,
    isLoading,
    isWithNote,
    handleShowComment,
    errors,
    touched,
}) => {
    const [isDropdownShown, setIsDropdownShown] = useState(false);

    const filterOptions = options =>
        options.filter(option => !(values.selectedEmployees.some(selectedEmployee => selectedEmployee._id === option._id)));

    return (
        <div className="send-profiles-autocomplete-wrapper">
            <Autocomplete
                className="search-field-autocomplete"
                open={!!((isDropdownShown && inputValue?.length >= 3))}
                loading={isLoading}
                loadingText={<CircularProgress className="send-profiles-autocomplete-loader" size={25} />}
                onChange={(e, value) => {
                    if (value) {
                        if (isWithNote) {
                            setFieldValue('comments', [...values.comments, '']);
                            handleShowComment(value._id);
                        }
                        setFieldValue('selectedEmployees', [...values.selectedEmployees, value]);
                        setInputValue('');
                        fetchSearchResultsPending();
                    }
                }}
                onFocus={() => setIsDropdownShown(true)}
                onClose={() => setIsDropdownShown(false)}
                options={searchResult}
                noOptionsText="No results found"
                filterOptions={filterOptions}
                filterSelectedOptions
                popupIcon={null}
                closeIcon={null}
                clearOnBlur={false}
                blurOnSelect
                handleHomeEndKeys
                getOptionLabel={() => ''}
                classes={{ listbox: "send-profiles-dropdown-list" }}
                renderOption={(option) => <SendProfilesListItem employee={option} />}
                renderInput={(params) => {
                    const { InputLabelProps, InputProps, ...rest } = params;
                    return (
                        <InputBase
                            {...rest}
                            {...params.InputProps}
                            className="search-field"
                            placeholder='Search'
                            autoComplete='off'
                            name='name'
                            startAdornment={(
                                <InputAdornment position="start">
                                    <SearchIcon className="search-icon icon" />
                                </InputAdornment>
                            )}
                            value={inputValue}
                            onChange={event => {
                                handleChangeInputValue(event.target.value);
                            }}
                        />
                    )
                }}
            />
            {(errors.selectedEmployees ? true : !!(false && touched.selectedEmployees)) &&
                <div className="invalid-field-message">
                    {errors.selectedEmployees}
                </div>
            }
        </div >
    );
};

export default SendProfilesAutocomplete;
