import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { MatchingJobCardContext } from '../MatchingJobCard';

import ExternalJobIdLabel from 'components/Shared/ExternalJobIdLabel';
import JobOptionsPopover from 'components/JobsDashboard/SharedComponents/JobOptionsPopover';
import ThreeDotsIcon from '@material-ui/icons/MoreHoriz';
import { ReactComponent as CompanyIcon } from 'assets/icon-company.svg';
import { ReactComponent as DateAddedIcon } from 'assets/icon-date-added.svg';
import { ReactComponent as AccountIcon } from 'assets/icon-account.svg';
import { ReactComponent as LocationIcon } from 'assets/icon-location.svg';

import { DATE_FORMAT_WITH_DOTS_FULL_YEAR } from 'constants/commonConstants';

import { getStateAgencyName } from 'utils/helpers';

const MatchingJobMainInfo = () => {
    const {
        agencyId,
        isAdmin,
        job,
        onEditClick,
        handleOpenDeleteDialog,
        updateJob,
        updateJobOpportunityPrivacy,
        setClosePopoverFunction,
    } = useContext(MatchingJobCardContext);

    const [anchorEl, setAnchorEl] = useState(null);

    const pathToJobDetails = `/${getStateAgencyName()}/job-page/${job._id}`;

    const handleClosePopover = (event) => {
        event.stopPropagation();
        event.preventDefault();
        setAnchorEl(null);
        setClosePopoverFunction(() => () => void 0);
    };

    const handleDotsClick = (event) => {
        event.stopPropagation();
        event.preventDefault();
        setAnchorEl(event.currentTarget);
        setClosePopoverFunction(() => () => setAnchorEl(null));
    };

    return (
        <>
            <div className="job-main-info">
                <div className="job-main-info-left">
                    <h2>
                        <Link className="job-details-link" to={pathToJobDetails}>
                            {job.position}
                        </Link>
                    </h2>
                    <div className="job-header-row">
                        <div className="job-header-icon-info-item">
                            <CompanyIcon />
                            <span>{job.client?.name}</span>
                        </div>
                        <div className="job-header-icon-info-item">
                            <DateAddedIcon />
                            <span>{moment(job.dateAdded).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR)}</span>
                        </div>
                        <div className="job-header-icon-info-item">
                            <AccountIcon />
                            <span>{job.creator?.name}</span>
                        </div>
                    </div>
                    <div className="job-header-icon-info-item location">
                        <LocationIcon />
                        <span>Remote {job.geographicLocations?.length > 0 && `(${job.geographicLocations.join(', ')})`}</span>
                    </div>
                </div>
                <div className="job-main-info-right">
                    {job.externalJobId && <ExternalJobIdLabel externalJobId={job.externalJobId} />}
                    <ThreeDotsIcon data-testid="three-dots" className="job-three-dots-action" onClick={(event) => handleDotsClick(event)} />
                </div>
            </div>

            <JobOptionsPopover
                agencyId={agencyId}
                anchorEl={anchorEl}
                handleClose={handleClosePopover}
                onEditClick={onEditClick}
                updatePrivacy={updateJobOpportunityPrivacy}
                updateJob={updateJob}
                selectedJob={job}
                openDeleteDialog={handleOpenDeleteDialog}
                isAdmin={isAdmin}
            />
        </>
    )
};

export default MatchingJobMainInfo;
