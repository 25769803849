import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik } from "formik";

import { getDepartmentDetails, getEmployees } from 'reducers';
import { fetchDepartmentDetails, } from 'actions/departmentActions';
import { showNotification } from 'actions/notificationActions';

import DepartmentFormView from './DepartmentFormView';
import CommonFormikDialog from 'components/Shared/CommonFormikDialog';

import { validateInputLength, validateRequiredFields } from 'utils/formValidations';

import { DEPARTMENTS_MESSAGES } from 'constants/messageConstants';
import { NOTIFICATION_TYPES } from 'constants/notificationTypes';

import './DepartmentDialog.scss';

const DepartmentDialog = ({
    isOpen,
    isForEdit,
    departmentId,
    agencyId,
    employees,
    handleCloseDialog,
    handleSubmitDialog,
    departmentDetails,
    fetchDepartmentDetails,
    showNotification,
}) => {
    useEffect(() => {
        if (departmentId && isOpen) {
            fetchDepartmentDetails(agencyId, departmentId);
        }
    }, [departmentId, isOpen]);

    const dialogMaxWidth = 'sm';

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                name: departmentId ? departmentDetails.name : '',
                managers: departmentId ? departmentDetails.managers : [],
                members: departmentId ? departmentDetails.members : []
            }}
            validate={values => {
                return {
                    ...validateRequiredFields(values, ['name']),
                    ...validateInputLength(values, [
                        { property: 'name', maxLength: 50 }
                    ]),
                };
            }}
            onSubmit={(values) => {
                const repeatingEmployees = employees.filter(x => values.managers.some(manager => manager._id === x._id) && values.members.some(member => member._id === x._id))

                if (repeatingEmployees.length > 0) {
                    const names = repeatingEmployees.map(x => `${x.firstName} ${x.lastName}`).join(', ');
                    const errorMessage = repeatingEmployees.length > 1
                        ? DEPARTMENTS_MESSAGES.REPEATING_DEPARTMENT_EMPLOYEES.replace('{names}', names)
                        : DEPARTMENTS_MESSAGES.REPEATING_DEPARTMENT_EMPLOYEE.replace('{name}', names);

                    showNotification(errorMessage, NOTIFICATION_TYPES.ERROR);
                    return;
                }

                handleSubmitDialog(values);
            }}
        >
            {props =>
                <CommonFormikDialog
                    className="add-department-dialog-container"
                    open={isOpen}
                    onClose={handleCloseDialog}
                    fullWidth={true}
                    maxWidth={dialogMaxWidth}
                    dialogTitle={isForEdit ? 'Edit department' : 'Add new department'}
                    onSave={props.handleSubmit}
                >
                    <DepartmentFormView
                        {...props}
                        employees={employees}
                        departmentData={isForEdit ? departmentDetails : null}
                    />
                </CommonFormikDialog>
            }
        </Formik>
    );
};

const mapStateToProps = (state) => ({
    employees: getEmployees(state),
    departmentDetails: getDepartmentDetails(state),
});

const mapDispatchToProps = {
    fetchDepartmentDetails,
    showNotification,
}

export default connect(mapStateToProps, mapDispatchToProps)(DepartmentDialog);
