import React from 'react';

import { FieldArray, ErrorMessage } from 'formik';
import { TextField } from '@material-ui/core';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

import './AddSkillField.scss';

const AddSkillField = ({
    values,
    handleChange,
    setFieldValue
}) => {

    const handleOnInputChange = e => { handleChange(e) };

    const handleSubmit = (arrayHelpers) => {
        values.technology.split(',').forEach(x => x.length ? arrayHelpers.push(x.trim()) : null);
        setFieldValue('technology', '');
    };

    return (
        <FieldArray name="technologies" render={arrayHelpers => (
            <div className="add-tech-container">
                <div className="add-skill-form" autoComplete="off">
                    <div className="add-skill-input">
                        <TextField
                            id="tech-name"
                            name='technology'
                            type="text"
                            className="tech-input"
                            variant="outlined"
                            label="Project Tech Stack"
                            helperText="List skills separated by comma (,)"
                            value={values.technology || ""}
                            onChange={handleOnInputChange}
                            fullWidth
                        />

                        <ErrorMessage name="technology" component="div" className="invalid-field-message" />
                        <button type="button" id='add-tech-btn' className="add-tech-button" onClick={() => handleSubmit(arrayHelpers)}>Add</button>
                    </div>
                </div>
                <div className="tech-skills-container">
                    {values.technologies && values.technologies.length > 0 && (
                        values.technologies.map(
                            (tech, index) => (
                                <div key={index} className="tech-box">
                                    <span className="tech-name">{tech}</span>
                                    <CloseRoundedIcon className="delete-tech-icon" fontSize="small" onClick={() => arrayHelpers.remove(index)} />
                                </div>
                            ))
                    )}
                </div>
            </div>
        )}
        />
    );
};

export default AddSkillField;
