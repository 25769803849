export const EMPLOYEE_OCCUPATION_STATUSES = {
    PLACED: 'Billable',
    BENCH: 'Bench',
    NON_BILLABLE_PROJECT: 'Non-billable',
    LEADERSHIP_TEAM: 'Leadership Team',
    PROJECT_ENDING: 'Project Ends'
};

export const EMPLOYEE_OCCUPATION_STATUSES_ARRAY = [
    { key: 1, value: 'Billable' },
    { key: 2, value: 'Bench' },
    { key: 3, value: 'Non-billable' },
    { key: 4, value: 'Leadership Team' },
    { key: 5, value: 'Project Ends' },
];

export const EMPLOYEE_OCCUPATION_STATUSES_MAP = {
    1: 'Billable',
    2: 'Bench',
    3: 'Non-billable',
    4: 'Leadership Team',
    5: 'Project Ends',
};

export const EMPLOYMENT_TYPES = {
    FULLTIME_CONTRACT: 'Full-time Contract',
    FREELANCE_CONTRACT: 'Freelance Contract',
};

export const EMPLOYEE_OCCUPATION_STATUSES_VALUES = {
    PLACED: 1,
    BENCH: 2,
    INTERNAL_PROJECT: 3,
    MANAGEMENT_TEAM: 4,
    PROJECT_ENDING: 5
};

export const AVAILABLE_OCCUPATION_STATUSES = [EMPLOYEE_OCCUPATION_STATUSES_VALUES.BENCH, EMPLOYEE_OCCUPATION_STATUSES_VALUES.INTERNAL_PROJECT, EMPLOYEE_OCCUPATION_STATUSES_VALUES.PROJECT_ENDING]

export const mapEmployeeOccupationStatusToStyles = occupationStatus => {
    switch (occupationStatus) {
        case 1:
            return 'unavailable'
        case 2:
        case 3:
        case 5:
            return 'available'
        default:
            return 'default'
    }
};

export const getToolTipTitleBasedOnOccupationStatus = occupationStatus => {
    switch (occupationStatus) {
        case 1:
            return 'This employee is marked as Billable.'
        case 2:
        case 3:
        case 5:
            return 'This employee is marked as Bench, Non-billable project or their project ends soon.'
        case 4:
            return 'This employee is marked as part of the leadership team.'
        default:
            return ''
    }
};
