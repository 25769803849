import React from 'react';
import { Formik } from 'formik';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import InterviewMarkAsInvitedFormView from './InterviewMarkAsInvitedFormView';
import CommonFormikDialog from 'components/Shared/CommonFormikDialog';

import MomentUtils from '@date-io/moment';
import { setTimeFromTimezone } from 'utils/formatTimes';
import {
    validateRequiredFields,
    validateArrayRequiredFields,
    validateArrayDateFields,
} from 'utils/formValidations';

const InterviewMarkAsInvitedPopup = ({
    isDialogOpen,
    handleCloseDialog,
    handleMarkAsInvitedSubmit,
}) => {
    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    timezone: '',
                    interviewTimeSlots: [{ time: null, date: null }],
                }}
                validate={values => {
                    const validations = {
                        ...validateRequiredFields(values, ['timezone']),
                        ...validateArrayDateFields(values, 'interviewTimeSlots', ['date', 'time']),
                        ...validateArrayRequiredFields(values, 'interviewTimeSlots', ['date', 'time']),
                    };

                    return validations;
                }}
                onSubmit={async (values) => {
                    const newInterviewTimeSlots = values.interviewTimeSlots.map(x => ({ ...x, time: setTimeFromTimezone(x.time, values.timezone) }));
                    const formData = { ...values, interviewTimeSlots: newInterviewTimeSlots };
                    await handleMarkAsInvitedSubmit(formData);
                    handleCloseDialog();
                }}
            >
                {(props) => (
                    <CommonFormikDialog
                        className="interview-mark-as-invited-dialog"
                        onSave={props.handleSubmit}
                        dialogTitle="Invitation for Interview"
                        open={isDialogOpen}
                        onClose={handleCloseDialog}
                        saveBtnText="Save"
                        discardBtnText="Discard"
                        size="sm"
                    >

                        <InterviewMarkAsInvitedFormView
                            {...props}
                        />
                    </CommonFormikDialog>
                )}
            </Formik>
        </MuiPickersUtilsProvider>
    );
};

export default InterviewMarkAsInvitedPopup;
