import React from 'react';
import moment from 'moment';

import { ErrorMessage } from 'formik';
import TextField from '@material-ui/core/TextField';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import { KeyboardDatePicker } from '@material-ui/pickers';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import UploadFiles from 'components/Shared/UploadFiles';

import isHoliday from 'hoc/isHoliday';

import { DATE_FORMAT_WITH_DOTS_FULL_YEAR, MAX_DATE } from 'constants/commonConstants'
import { CONTRACT_TYPES } from 'constants/contractsConstants'
import { CHECKBOX_VALUES } from './AddContractDialogConstants';

import './AddContractFormView.scss';

const ACCEPTED_FORMATS = ['pdf'];

const AddContractFormView = ({
    values,
    errors,
    touched,
    setFieldValue,
    contractTypes,
    receiverRole,
    handleChange,
    agencyName,
    isEvrotrustIntegrated,
    autoSignEnabled,
    evrotrustSecondaryEmails,
    primaryEvrotrustEmail,
}) => {
    return (
        <form className="add-contracts-form" autoComplete="off">
            <div className="contract-title-field">
                <TextField
                    name="title"
                    value={values.title}
                    onChange={handleChange}
                    label="Contract title"
                    error={errors.title && touched.title}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                />
                <ErrorMessage name="title" component="div" className="invalid-field-message" />
            </div>
            <div className="contract-type-field">
                <TextField
                    select
                    name="type"
                    label="Type of contract"
                    value={values.type}
                    onChange={(e) => {
                        setFieldValue('type', e.target.value);

                        if (e.target.value === CONTRACT_TYPES.EMPLOYEE[0].value) {
                            setFieldValue('ongoing', true);
                        } else {
                            setFieldValue('ongoing', false);
                        }
                    }}
                    variant="outlined"
                    fullWidth
                    error={errors.type && touched.type}
                >
                    {contractTypes.map(x => (
                        <MenuItem key={x.value} value={x.value}>
                            {x.value}
                        </MenuItem>
                    ))}
                </TextField>
                <ErrorMessage name="type" component="div" className="invalid-field-message" />
            </div>
            <div className="switch-wrapper">
                <FormControlLabel
                    control={
                        <Switch
                            name="ongoing"
                            checked={values.ongoing}
                            onChange={handleChange}
                            size="small"
                            disabled={values.type === CONTRACT_TYPES.EMPLOYEE[0].value}
                            color="primary"
                        />
                    }
                    label="Ongoing"
                    labelPlacement="start"
                    className="switch-label"
                />
            </div>
            <div className="date-fields-block">
                <div className="start-date-container">
                    <KeyboardDatePicker
                        autoOk={true}
                        className="start-date-field"
                        label="Start date"
                        name="startDate"
                        disableToolbar
                        maxDate={values.endDate || MAX_DATE}
                        variant="inline"
                        inputVariant="outlined"
                        value={values.startDate}
                        onChange={value => {
                            setFieldValue('startDate', moment(value));
                        }}
                        renderDay={isHoliday}
                        format={DATE_FORMAT_WITH_DOTS_FULL_YEAR}
                        allowKeyboardControl={true}
                        error={errors.startDate && touched.startDate}
                    />
                    <ErrorMessage name="startDate" component="div" className="invalid-field-message" />
                </div>
                <div className="end-date-container">
                    <KeyboardDatePicker
                        autoOk={true}
                        className="end-date-field"
                        label="End date"
                        name="endDate"
                        disableToolbar
                        minDate={values.startDate || new Date()}
                        variant="inline"
                        inputVariant="outlined"
                        value={values.endDate}
                        onChange={value => {
                            setFieldValue('endDate', moment(value));
                        }}
                        renderDay={isHoliday}
                        format={DATE_FORMAT_WITH_DOTS_FULL_YEAR}
                        allowKeyboardControl={true}
                        disabled={values.ongoing || values.type === CONTRACT_TYPES.EMPLOYEE[0].value}
                        error={errors.endDate && touched.endDate}
                    />
                    <ErrorMessage name="endDate" component="div" className="invalid-field-message" />
                </div>
            </div>
            <FormControl component="fieldset">
                <RadioGroup aria-label="signBox" value={values.sign} name="sign" onChange={handleChange}>
                    <FormControlLabel
                        value={CHECKBOX_VALUES.NO_SIGNATURE}
                        control={<Radio />}
                        label="Do not require e-Signature with Evrotrust"
                    />
                    <FormControlLabel
                        value={CHECKBOX_VALUES.ONLY_RECEIVER}
                        control={<Radio />}
                        label={`Require e-Signature from ${receiverRole}`}
                        disabled={!isEvrotrustIntegrated}
                    />
                    <FormControlLabel
                        value={CHECKBOX_VALUES.ONLY_AGENCY}
                        control={<Radio />}
                        label={`Require e-Signature from ${agencyName}`}
                        disabled={!isEvrotrustIntegrated}
                    />
                    <FormControlLabel
                        value={CHECKBOX_VALUES.BOTH}
                        control={<Radio />}
                        label={`Require e-Signature from both ${agencyName} and ${receiverRole}`}
                        disabled={!isEvrotrustIntegrated}
                    />
                </RadioGroup>
                <ErrorMessage name="sign" component="div" className="invalid-field-message" />
            </FormControl>

            {(values.sign === CHECKBOX_VALUES.ONLY_AGENCY || values.sign === CHECKBOX_VALUES.BOTH) && autoSignEnabled &&
                <div className="contract-type-field">
                    <TextField
                        select
                        name="agencyEvrotrustSignEmail"
                        label="Select signatory email"
                        value={values.agencyEvrotrustSignEmail}
                        onChange={(e) => {
                            setFieldValue('agencyEvrotrustSignEmail', e.target.value);
                        }}
                        variant="outlined"
                        fullWidth
                        error={errors.agencyEvrotrustSignEmail && touched.agencyEvrotrustSignEmail}
                    >
                        {[primaryEvrotrustEmail, ...evrotrustSecondaryEmails].map((x, idx) => (
                            <MenuItem key={idx} value={x}>
                                {x}
                            </MenuItem>
                        ))}
                    </TextField>
                    <ErrorMessage name="agencyEvrotrustSignEmail" component="div" className="invalid-field-message" />
                </div>
            }

            <div className="files-attached">
                <UploadFiles
                    name="filesAttached"
                    filesAttached={values.filesAttached}
                    setFieldValue={setFieldValue}
                    acceptedFormats={ACCEPTED_FORMATS}
                />
                <ErrorMessage name="filesAttached" component="div" className="invalid-field-message" />
            </div>
        </form>
    );
};

export default AddContractFormView;
