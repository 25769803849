import React from 'react';
import { TextField } from '@material-ui/core';
import './ExploreTalentsSearchBar.scss'

const ExploreTalentsSearchBar = ({ searchTerm, handleChange, clearInput }) => {
    return (
        <div className="explore-talents-search-bar-wrapper">
            <div className="secondary-wrapper">
                <TextField
                    id="explore-talents-search-bar"
                    className="talent-search-bar"
                    placeholder="Search by name"
                    variant="outlined"
                    autoComplete="off"
                    value={searchTerm}
                    onChange={handleChange}
                />
                <div className="skill-box global-css-skill-box">
                    <button className="delete-skill-icon" type="button" onClick={clearInput}></button>
                </div>
            </div>
        </div>
    );
};

export default ExploreTalentsSearchBar;
