import React from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import TableWrapper from 'components/Shared/TableWrapper';
import { Tooltip } from '@material-ui/core';
import { ReactComponent as WarningIcon } from 'assets/icon-warning.svg';

import { getAgencyTimesheets, } from 'reducers';

import { getStateAgencyName, parseQueryString } from 'utils/helpers';
import { usePreloadTable } from 'utils/hooks';

import { MONTH_YEAR_DATE_FORMAT, US_DATE_FORMAT_WITH_SLASH } from 'constants/commonConstants';
import {
    TABLE_COLUMNS,
    DEFAULT_TABLE_COLUMNS,
    TABLE_FILTERS,
    SORT_QUERY_SELECTOR,
    PAGE_QUERY_SELECTOR,
    DATE_QUERY_SELECTOR,
    DEFAULT_ITEMS_PER_PAGE,
    TABLE_INITIAL_QUERY,
    TABLE_QUERY_MAP,
} from './TimesheetsTableConstants';

import './TimesheetsTable.scss';

const TimesheetsTable = ({
    agencyId,
    tableFetchAction,
    exportAgencyTimesheets,
}) => {
    const history = useHistory();

    const [isTableDataLoading, totalCount] = usePreloadTable(
        TABLE_INITIAL_QUERY,
        TABLE_QUERY_MAP,
        TABLE_FILTERS,
        tableFetchAction
    );

    const dataSelector = (state, page) => {
        const parsedQuery = parseQueryString(history.location.search, true);
        const parsedQuerySortBy = parsedQuery[SORT_QUERY_SELECTOR] ? parsedQuery[SORT_QUERY_SELECTOR][0] : TABLE_INITIAL_QUERY.sortBy;
        const sortBy = TABLE_COLUMNS.find(x => x.value === parsedQuerySortBy).value;
        const sortByDir = parsedQuery[`${SORT_QUERY_SELECTOR}Dir`] ? parsedQuery[`${SORT_QUERY_SELECTOR}Dir`][0] : TABLE_INITIAL_QUERY.sortByDir;

        return getAgencyTimesheets(state, page - 1, DEFAULT_ITEMS_PER_PAGE, sortBy, sortByDir);
    };

    const handleWarningIconClick = (e, employeeId) => {
        e.preventDefault();

        const { date: monthYear } = parseQueryString(history.location.search);
        const month = monthYear ? moment(monthYear, MONTH_YEAR_DATE_FORMAT).format(US_DATE_FORMAT_WITH_SLASH) : moment().format(US_DATE_FORMAT_WITH_SLASH);
        history.push({
            pathname: `/${getStateAgencyName()}/team/people/${employeeId}/time-tracking`,
            state: { month },
        });
    };

    const handleExportTimesheet = user => {
        const { date: monthYear = TABLE_INITIAL_QUERY.date } = parseQueryString(history.location.search);
        const trackedProjects = [{ _id: user._id, projectNames: user.projectsNames }];

        exportAgencyTimesheets([user], monthYear, agencyId, { trackedProjects });
    };

    const adjustTimesheetsKeys = stateData => stateData.map(user => {
        return {
            _id: user._id,
            name: user.name,
            projects: user.projects,
            daysWorked: (
                <div className='days-worked'>
                    {user.daysWorked}
                    {user.untrackedHours < 0 && (
                        <Tooltip
                            title={`This employee has missing hours on their timesheet (less than 8 hours for each working day)`}
                            classes={{ tooltip: 'custom-tooltip' }}
                        >
                            <WarningIcon
                                className="warning-icon"
                                onClick={(e) => handleWarningIconClick(e, user._id)}
                            />
                        </Tooltip>
                    )}
                </div>
            ),
            item: user,
        };
    });

    const actions = [{ title: 'Export Timesheet', handleClick: handleExportTimesheet }];
    const getActionsHandler = () => actions;

    const generateToString = user => `/${getStateAgencyName()}/team/people/${user._id}/time-tracking`;

    return (
        <div className='timesheets-table-container'>
            <TableWrapper
                title="Timesheets"
                columns={TABLE_COLUMNS}
                defaultColumns={DEFAULT_TABLE_COLUMNS}
                dataSelector={dataSelector}
                sortQuerySelector={SORT_QUERY_SELECTOR}
                pageQuerySelector={PAGE_QUERY_SELECTOR}
                pagesCount={Math.ceil(totalCount / DEFAULT_ITEMS_PER_PAGE)}
                adjustKeysFunction={adjustTimesheetsKeys}
                isTableDataLoading={isTableDataLoading}
                withPagination
                withActions
                withLink
                generateToString={generateToString}
                getActionsHandler={getActionsHandler}
            >
                <TableWrapper.TableAlignedItems>
                    <TableWrapper.TableAlignedItems.LeftAligned>
                        <TableWrapper.TableAlignedItems.TableHeader />
                        <TableWrapper.TableAlignedItems.TableFilter filters={TABLE_FILTERS} />
                        <TableWrapper.TableAlignedItems.TableDateFilter
                            placeholderText={'Date'}
                            format={MONTH_YEAR_DATE_FORMAT}
                            queryParamName={DATE_QUERY_SELECTOR}
                        />
                    </TableWrapper.TableAlignedItems.LeftAligned>
                </TableWrapper.TableAlignedItems>
                <TableWrapper.TableContent />
            </TableWrapper>
        </div>
    )
}

export default TimesheetsTable;
