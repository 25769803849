import React from 'react';

import InputAdornment from '@material-ui/core/InputAdornment';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';

const SearchEmployeeBar = ({
    searchTerm,
    handleFocus,
    handleBlur,
    handleSearchTermChange,
}) => (
        <div className="search-bar">
            <InputBase
                className="search-field"
                placeholder="Search"
                name='searchTerm'
                value={searchTerm}
                onChange={handleSearchTermChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                startAdornment={(
                    <InputAdornment position="start">
                        <SearchIcon className="search-icon icon" />
                    </InputAdornment>
                )}
            />
        </div>
    );

export default SearchEmployeeBar;
