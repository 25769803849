import React from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';

import { ReactComponent as HomeIcon } from 'assets/home.svg';
import { motionRedirectLinks } from './RegisterConstants';

import LoginCard from 'components/Shared/LoginCard';

import './Register.scss';

const Register = ({
}) => {
    const history = useHistory();

    const gotoUrl = history.location.state?.gotoUrl;

    return (

        <LoginCard isMobile={isMobile} classes="register-card">

            <div className={`sign-up-container${isMobile ? ' mobile' : ''}`}>

                <Button
                    variant="outlined"
                    color="primary"
                    size="large"
                    component={RouterLink}
                    to={{ pathname: motionRedirectLinks.careers.link, state: { gotoUrl: gotoUrl } }}
                >
                    {motionRedirectLinks.careers.label}
                </Button>

                <Button
                    variant="outlined"
                    color="primary"
                    size="large"
                    component={RouterLink}
                    to={{ pathname: motionRedirectLinks.clients.link, state: { gotoUrl: gotoUrl } }}
                >
                    {motionRedirectLinks.clients.label}
                </Button>

                <Button
                    variant="outlined"
                    color="primary"
                    size="large"
                    href={motionRedirectLinks.suppliers.link}
                    target="_blank"
                    rel="noreferrer"
                >
                    {motionRedirectLinks.suppliers.label}
                </Button>

            </div>

            <footer className="sign-up-footer">
                <Button
                    color="primary"
                    size="small"
                    startIcon={<HomeIcon />}
                    href={motionRedirectLinks.home.link}
                    target="_blank"
                    rel="noreferrer"
                >
                    {motionRedirectLinks.home.label}
                </Button>

                <p>
                    Already have an account? <Link
                        component={RouterLink}
                        to={motionRedirectLinks.login.link}
                    >
                        {motionRedirectLinks.login.label}
                    </Link>
                </p>
            </footer>

        </LoginCard>
    );
};

export default Register;
