import styleConstants from 'style-constants.scss';

export const tableColumns = [
    { name: 'Name', value: 'fullName', isSortable: true },
    { name: 'E-mail', value: 'email', isSortable: true },
    { name: 'Speciality', value: "speciality", isSortable: true },
    { name: 'Experience', value: "experienceSince", isSortable: true },
    { name: 'Applications', value: "applications", isSortable: true },
    { name: 'Status', value: 'status', isSortable: true },
];

export const defaultTableColumns = ['fullName', 'email', 'speciality', 'experienceSince', 'applications', 'status'];

export const DEVELOPER = 'Developer';
export const JAVA = 'Java';
export const PYTHON = 'Python';
export const CSHARP = 'C#';
export const JAVASCRIPT = 'JavaScript';
export const RUBY = 'Ruby';
export const OTHER = 'Other';

export const tableTabs = [
    { name: "Applicant Pipeline", value: "applicantPipeline" },
    { name: "Vetted", value: "vetted" },
    { name: "Rejected", value: "rejected" },
    { name: "Incomplete Profile", value:"incompleteProfile" },
];

export const legendItems = [
    { color: styleConstants.yellowLight, text: "Registered via link" },
    { color: styleConstants.greenLight, text: "Invited by the admin" },
    { color: styleConstants.blueLight, text: "LinkedIn" },
    { color: styleConstants.purple, text: "Google" },
    { color: styleConstants.greyDark, text: "Other" },
];

export const defaultTab = tableTabs[0];

export const tableFilters = [
    { key: 'status', name: 'Status', value: ['New', 'CV Completed', 'Pending Video Review', 'Pending Calendly Review'] },
    { key: 'speciality', name: 'Speciality', value: [JAVA, PYTHON, CSHARP, JAVASCRIPT, RUBY, OTHER] },
];

export const PAGE_QUERY_SELECTOR = 'page';
export const SORT_QUERY_SELECTOR = 'sort';
export const TABS_QUERY_SELECTOR = 'tab';

export const INITIAL_QUERY = {
    page: 1,
    filters: JSON.stringify({}),
    tab: "applicantPipeline",
    sortBy: null,
    sortByDir: null,
    pagesToLoad: 5,
    itemsPerPage: 25,
};

export const QUERY_MAP = {
    page: PAGE_QUERY_SELECTOR,
    filters: "filters",
    tab: TABS_QUERY_SELECTOR,
    sortBy: SORT_QUERY_SELECTOR,
    sortByDir: `${SORT_QUERY_SELECTOR}Dir`,
};

export const DEFAULT_CANDIDATE_ASSIGNEE_SELECT = { value: 1, name: 'All Assignees' };
export const DEFAULT_CANDIDATE_SPECIALITY_SELECT = { value: 1, name: 'All Specialities' };
export const DEFAULT_CANDIDATE_STATUS_SELECT = { value: 1, name: 'All' };
