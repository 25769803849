import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';

import './ExpiredLinkCard.scss';

const ExpiredLinkCard = ({
    children,
    expiredLinkPageData,
}) => {
    return (
        <Card className="expired-link-card">
            <CardContent className="card-content">
                {expiredLinkPageData?.text}
            </CardContent>

            <CardActions className='expired-link-card-actions' disableSpacing>
                {children}
            </CardActions>

            <CardContent className="card-footer">
                <div className="footer-text-container">
                    <Typography className="expired-link-footer-text" variant="h5">
                        Didn't received the email? Please get in touch with us via <a className="email" href="mailto:hello@motion-software.com">hello@motion-software.com</a>
                    </Typography>
                </div>
            </CardContent>
        </Card>
    )
};

export default ExpiredLinkCard;
