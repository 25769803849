import React, { useState, useRef } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

import FormikTemplate from 'components/Shared/FormikTemplate/FormikTemplate';
import AssigneeDialog from 'components/Candidate/Dialogs/AssigneeDialog';
import EditFieldDialog from 'components/Candidate/Dialogs/EditFieldDialog/EditFieldDialog';
import ImageEditDialog from 'components/Shared/ImageEditDialog';

import { getCandidateAssignee, getAdmins, getEmployeeBasicInfo } from 'reducers';

import { updateCandidateAssignee } from 'actions/candidateActions';
import { updateBasicInfo, updateAvatar, deleteAvatar } from 'actions/employeeActions';

import Avatar from '@material-ui/core/Avatar';
import { Divider, Typography } from '@material-ui/core';
import EditSharpIcon from '@material-ui/icons/EditSharp';
import CloseIcon from '@material-ui/icons/Close';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';

import { ReactComponent as EmailIcon } from 'assets/email-icon.svg';
import { ReactComponent as IconProfileType } from 'assets/icon-profile-type.svg';

import { DATE_FORMAT_WITH_DOTS } from 'constants/commonConstants';
import {
    CANDIDATE_OVERVIEW_PAGE_DIALOGS,
    CANDIDATE_OVERVIEW_REQUIRED_FIELDS,
    CANDIDATE_OVERVIEW_EMAIL_FIELDS,
} from 'constants/candidateConstants';
import { ROLES, REFERRALS, REGISTRATION_TYPES } from 'constants/userConstants';

import { formatStringAsClassName } from 'utils/helpers';

import './CandidateBasicInformation.scss';

const CandidateBasicInformation = ({
    agencyId,
    userId,
    candidateId,
    basicInfo,
    assignee,
    admins,
    updateCandidateAssignee,
    updateBasicInfo,
    updateAvatar,
    deleteAvatar,
}) => {
    const [dialog, setDialog] = useState('');

    const [currentImgSelected, setCurrentImgSelected] = useState(basicInfo.profilePictureImgUrl);
    const [isEditImgDialogOpen, setIsEditImgDialogOpen] = useState(false);

    const profileImgRef = useRef();

    const handleOpenDialog = (dialogName) => {
        setDialog(dialogName)
    }

    const handleCloseDialog = () => setDialog('');

    const currentlyOpenedDialog = (props) => {
        switch (dialog) {
            case CANDIDATE_OVERVIEW_PAGE_DIALOGS.ASSIGNEE.VALUE:
                return <AssigneeDialog {...props} admins={admins} open={true} onClose={handleCloseDialog} />
            case CANDIDATE_OVERVIEW_PAGE_DIALOGS.NAME.VALUE:
                return <EditFieldDialog {...props} open={true} onClose={handleCloseDialog} dialogTitle={CANDIDATE_OVERVIEW_PAGE_DIALOGS.NAME.TITLE} />
            case CANDIDATE_OVERVIEW_PAGE_DIALOGS.EMAIL.VALUE:
                return <EditFieldDialog {...props} open={true} onClose={handleCloseDialog} dialogTitle={CANDIDATE_OVERVIEW_PAGE_DIALOGS.EMAIL.TITLE} />
            default:
                break;
        }
    }

    const getFormikInitialValue = () => {
        switch (dialog) {
            case CANDIDATE_OVERVIEW_PAGE_DIALOGS.ASSIGNEE.VALUE:
                return { assignee: assignee._id }
            case CANDIDATE_OVERVIEW_PAGE_DIALOGS.NAME.VALUE:
                return { field: `${basicInfo?.firstName} ${basicInfo?.lastName}` }
            case CANDIDATE_OVERVIEW_PAGE_DIALOGS.EMAIL.VALUE:
                return { field: basicInfo?.email }
            default:
                break;
        }
    }

    const handleUpdateBasicInfo = (values) => {
        let newBasicInfo = {};
        switch (dialog) {
            case CANDIDATE_OVERVIEW_PAGE_DIALOGS.ASSIGNEE.VALUE:
                updateCandidateAssignee(agencyId, candidateId, values)
                break;
            case CANDIDATE_OVERVIEW_PAGE_DIALOGS.NAME.VALUE:
                newBasicInfo = { fullName: values.field, email: basicInfo?.email, role: ROLES.CANDIDATE };
                updateBasicInfo(agencyId, null, candidateId, newBasicInfo, userId, basicInfo?.email);
                break;
            case CANDIDATE_OVERVIEW_PAGE_DIALOGS.EMAIL.VALUE:
                newBasicInfo = { firstName: basicInfo?.firstName, lastName: basicInfo?.lastName, email: values.field, role: ROLES.CANDIDATE };
                updateBasicInfo(agencyId, null, candidateId, newBasicInfo, userId, basicInfo?.email)
                break;
            default:
                break;
        }

        handleCloseDialog();
    }

    const imageSelectedHandler = (e) => {
        const currentFile = e.target.files[0]
        const imgReader = new FileReader()

        imgReader.addEventListener("load", () => {
            const resultImg = imgReader.result
            setCurrentImgSelected(resultImg);
        }, false)

        imgReader.readAsDataURL(currentFile)

        setIsEditImgDialogOpen(true);
    };

    const handleCloseEditImgDialog = () => {
        setIsEditImgDialogOpen(false);
        setCurrentImgSelected(null);
        profileImgRef.current.value = "";
    }

    const handleUpdateAvatar = (imgData) => {
        updateAvatar(imgData, candidateId, agencyId, null, userId);
    };

    const handleDeleteAvatar = () => {
        if (basicInfo?.profilePictureImgUrl && basicInfo?.profilePictureImgUrl !== '') {
            deleteAvatar(candidateId, agencyId, null, userId);
            setCurrentImgSelected('');
        }
    }

    const getRegistrationTypeLabel = (registrationType, referrer) => {
        if (registrationType === REGISTRATION_TYPES.INVITED_BY_ADMIN) {
            return registrationType;
        }

        if (referrer) {
            return Object.values(REFERRALS).find(r => r === referrer && referrer !== REFERRALS.WEBSITE_ORGANIC) || 'Other Sources';
        }

        return 'Registration link';
    };

    const registrationTypeLabelText = getRegistrationTypeLabel(basicInfo.registrationType, basicInfo.referrer);

    return (
        <div className="candidate-basic-information">
            <div className="header-wrapper">
                <div className="header">
                    <div className="candidate-avatar-wrapper">
                        <Avatar className="candidate-avatar" src={basicInfo?.profilePictureImgUrl} />
                        {
                            <div className="avatar-actions">
                                <IconButton className="avatar-delete-button icon-button" onClick={handleDeleteAvatar}>
                                    <Icon>
                                        <CloseIcon className="close-icon" />
                                    </Icon>
                                </IconButton>
                                <input
                                    accept="image/*"
                                    className="upload-input"
                                    id="upload-file-input"
                                    type="file"
                                    onChange={imageSelectedHandler}
                                    ref={profileImgRef}
                                />
                                <IconButton className="avatar-edit-button icon-button" onClick={() => { profileImgRef.current.click(); }}>
                                    <Icon>
                                        <EditSharpIcon className="edit-sharp-icon" />
                                    </Icon>
                                </IconButton>
                            </div>
                        }
                    </div>
                    <div className="header-info">
                        <div className="candidate-name-container">
                            <Typography variant="h4" className="candidate-name">
                                {`${basicInfo?.firstName} ${basicInfo?.lastName}`}
                            </Typography>
                            <IconButton className="name-edit-button" onClick={() => handleOpenDialog(CANDIDATE_OVERVIEW_PAGE_DIALOGS.NAME.VALUE)}>
                                <Icon>
                                    <EditSharpIcon className="edit-sharp-icon" />
                                </Icon>
                            </IconButton>
                        </div>
                        <div className="candidate-email">
                            <EmailIcon className="email-icon" />
                            <Typography variant="h4" className="candidate-email-address">
                                {basicInfo?.email}
                            </Typography>
                            <IconButton className="email-edit-button" onClick={() => handleOpenDialog(CANDIDATE_OVERVIEW_PAGE_DIALOGS.EMAIL.VALUE)}>
                                <Icon>
                                    <EditSharpIcon className="edit-sharp-icon" />
                                </Icon>
                            </IconButton>
                        </div>
                        <div className="registration-information">
                            <div className="registration-date">
                                Registration: {moment(basicInfo?.registrationDate).format(DATE_FORMAT_WITH_DOTS)}
                            </div>
                            <div className={`registration-type${registrationTypeLabelText ? ` ${formatStringAsClassName(registrationTypeLabelText)}` : ''}`}>
                                {registrationTypeLabelText}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Divider variant="middle" className="divider" />

            <div className="assignee-information">
                <div className="row-title">
                    <IconProfileType />
                    <Typography variant="h5" className="title">
                        Assignee
                    </Typography>
                </div>
                <div className="row-value">
                    {assignee?.name}
                </div>
                <div className="edit-icon-container">
                    <IconButton className="edit-button" onClick={() => handleOpenDialog(CANDIDATE_OVERVIEW_PAGE_DIALOGS.ASSIGNEE.VALUE)}>
                        <Icon>
                            <EditSharpIcon className="edit-sharp-icon" />
                        </Icon>
                    </IconButton>
                </div>
            </div>

            <FormikTemplate
                initialValues={getFormikInitialValue()}
                updateData={handleUpdateBasicInfo}
                requiredFields={CANDIDATE_OVERVIEW_REQUIRED_FIELDS[dialog]}
                emailFields={CANDIDATE_OVERVIEW_EMAIL_FIELDS[dialog]}>
                {(props) => (
                    currentlyOpenedDialog({ ...props })
                )}
            </FormikTemplate>

            <ImageEditDialog
                dialogTitle="Edit Profile Picture"
                isDialogOpen={isEditImgDialogOpen}
                handleCloseDialog={handleCloseEditImgDialog}
                currentImgSelected={currentImgSelected}
                handleUpdateAvatar={handleUpdateAvatar}
                handleDeleteAvatar={handleDeleteAvatar}
            />
        </div>
    )
}

const mapStateToProps = state => ({
    basicInfo: getEmployeeBasicInfo(state),
    admins: getAdmins(state),
    assignee: getCandidateAssignee(state),
});

const mapDispatchToProps = {
    updateCandidateAssignee,
    updateBasicInfo,
    deleteAvatar,
    updateAvatar,
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidateBasicInformation);
