import React from 'react';

import { Formik } from 'formik';

import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TrackTimeUploadFileFormView from './TrackTimeUploadFileFormView';

import './TrackTimeUploadFileDialog.scss'

const TrackTimeUploadFileDialog = ({
    formRef,
    openUploadFileDialog,
    setOpenUploadFileDialog
}) => {

    const handleCloseDialog = () => {
        formRef.current.setFieldValue('filesAttached', []);
        setOpenUploadFileDialog(false);
    };

    const handleSaveDialog = () => {
        setOpenUploadFileDialog(false);
    };

    return (
        <Formik
            initialValues={{
                filesAttached: []
            }}
            innerRef={formRef}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);

                const { filesAttached } = values;
                const data = new FormData();

                filesAttached.forEach((file, i) => {
                    data.append(`attachedfile${i + 1}`, file);
                });

                handleSaveDialog();
            }}
        >
            {props =>
                <Dialog className="track-time-upload-file-dialog" open={openUploadFileDialog} maxWidth={'md'}>
                    <MuiDialogTitle disableTypography className="dialog-title">
                        <Typography variant="body2" className="dialog-title-text">Upload Doctor's Notice</Typography>
                        <IconButton className="dialog-close-btn" onClick={handleCloseDialog}>
                            <CloseIcon />
                        </IconButton>
                    </MuiDialogTitle>

                    <TrackTimeUploadFileFormView
                        {...props}
                        handleCloseDialog={handleCloseDialog}
                    />
                </Dialog>
            }
        </Formik>
    )
}

export default TrackTimeUploadFileDialog;
