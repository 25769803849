import { combineReducers } from 'redux';

import {
    ADMIN_DASHBOARD_CANDIDATES_FETCH_SUCCESS,
    ADMIN_DASHBOARD_DEDICATED_POSITIONS_FETCH_SUCCESS,
    ADMIN_DASHBOARD_ON_DEMAND_POSITIONS_FETCH_SUCCESS,
    ADMIN_DASHBOARD_EMPLOYEES_BY_COUNTRY_FETCH_SUCCESS,
    ADMIN_DASHBOARD_VETTED_CANDIDATES_COUNT_FETCH_SUCCESS,
    ADMIN_DASHBOARD_REJECTED_CANDIDATES_COUNT_FETCH_SUCCESS,
    ADMIN_DASHBOARD_MANAGEMENT_EMPLOYEES_COUNT_FETCH_SUCCESS,
    ADMIN_DASHBOARD_BENCHED_EMPLOYEES_COUNT_FETCH_SUCCESS,
    ADMIN_DASHBOARD_PLACED_EMPLOYEES_COUNT_FETCH_SUCCESS,
} from 'actions/actionTypes';

import { COUNTRIES_OF_EMPLOYMENT } from 'constants/commonConstants';
import { JOB_OPPORTUNITIES_PRIORITIES, JOB_OPPORTUNITIES_STATUSES } from 'constants/jobOpportunitiesConstants';
import { createSelector, createStructuredSelector } from 'reselect';

const consultantsCountsInitialState = {
    placed: 0,
    bench: 0,
    managementTeam: 0,
};

const adminDashboardConsultantsCounts = (state = consultantsCountsInitialState, action) => {
    switch (action.type) {
        case ADMIN_DASHBOARD_MANAGEMENT_EMPLOYEES_COUNT_FETCH_SUCCESS:
            return { ...state, managementTeam: action.payload };
        case ADMIN_DASHBOARD_BENCHED_EMPLOYEES_COUNT_FETCH_SUCCESS:
            return { ...state, bench: action.payload };
        case ADMIN_DASHBOARD_PLACED_EMPLOYEES_COUNT_FETCH_SUCCESS:
            return { ...state, placed: action.payload };
        default:
            return state
    }
}

const candidateInitialState = {
    _id: '',
    firstName: '',
    lastName: '',
    status: '',
    email: '',
    assignee: {},
};


const candidate = (state = candidateInitialState, action) => {
    switch (action.type) {
        case ADMIN_DASHBOARD_CANDIDATES_FETCH_SUCCESS:
            return { ...candidateInitialState, ...state };
        default:
            return state;
    }
};

const adminDashboardCandidatesInitialState = {
    candidates: [],
    vettedCandidatesCount: 0,
    rejectedCandidatesCount: 0,
}

const adminDashboardCandidates = (state = adminDashboardCandidatesInitialState, action) => {
    switch (action.type) {
        case ADMIN_DASHBOARD_CANDIDATES_FETCH_SUCCESS:
            return { ...state, candidates: action.payload.map((x) => candidate(x, action)) };
        case ADMIN_DASHBOARD_VETTED_CANDIDATES_COUNT_FETCH_SUCCESS:
            return { ...state, vettedCandidatesCount: action.payload };
        case ADMIN_DASHBOARD_REJECTED_CANDIDATES_COUNT_FETCH_SUCCESS:
            return { ...state, rejectedCandidatesCount: action.payload };
        default:
            return state;
    }
};

const jobsInitialState = {
    dedicatedPositions: [],
    onDemandPositions: []
}

const adminDashboardJobPositions = (state = jobsInitialState, action) => {
    switch (action.type) {
        case ADMIN_DASHBOARD_DEDICATED_POSITIONS_FETCH_SUCCESS:
            return { ...state, dedicatedPositions: action.payload };
        case ADMIN_DASHBOARD_ON_DEMAND_POSITIONS_FETCH_SUCCESS:
            return { ...state, onDemandPositions: action.payload };
        default:
            return state;
    }
};

const employeesInitialState = [
    { country: 'Bulgaria', numberOfEmployees: 0 },
    { country: 'Romania', numberOfEmployees: 0 },
    { country: 'Ukraine', numberOfEmployees: 0 },
    { country: 'Turkey', numberOfEmployees: 0 },
    { country: 'Belarus', numberOfEmployees: 0 },
    { country: 'Bosnia and Herzegovina', numberOfEmployees: 0 },
    { country: 'Macedonia', numberOfEmployees: 0 },
];

const adminDashboardEmployees = (state = employeesInitialState, action) => {
    switch (action.type) {
        case ADMIN_DASHBOARD_EMPLOYEES_BY_COUNTRY_FETCH_SUCCESS:
            return { ...state, employeesByCountry: [...employeesInitialState, ...action.payload] };
        default:
            return state;
    }
};


const adminDashboard = combineReducers({
    adminDashboardCandidates,
    adminDashboardJobPositions,
    adminDashboardEmployees,
    adminDashboardConsultantsCounts,
});

export default adminDashboard;

const adminDashboardActiveConsultantCountInputSelector = (state) => state.adminDashboardConsultantsCounts.placed;
const adminDashboardInactiveConsultantCountInputSelector = (state) => state.adminDashboardConsultantsCounts.bench;
const adminDashboardManagementEmployeesCountInputSelector = (state) => state.adminDashboardConsultantsCounts.managementTeam;

export const getAdminDashboardConsultantsCounts = createStructuredSelector({
    placed: adminDashboardActiveConsultantCountInputSelector,
    bench: adminDashboardInactiveConsultantCountInputSelector,
    managementTeam: adminDashboardManagementEmployeesCountInputSelector,
})


const adminDashboardCandidatesInputSelector = (state) => state.adminDashboardCandidates.candidates;

const adminDashboardVettedCandidatesCount = (state) => state.adminDashboardCandidates.vettedCandidatesCount;

const adminDashboardRejectedCandidatesCount = (state) => state.adminDashboardCandidates.rejectedCandidatesCount;

export const getAdminDashboardVettedAndRejectedCandidates = createStructuredSelector({
    vettedCandidates: adminDashboardVettedCandidatesCount,
    rejectedCandidates: adminDashboardRejectedCandidatesCount,
});

const pageInputSelector = (_, page) => page;
const countInputSelector = (_, __, count) => count;

export const getAdminDashboardCandidates = createSelector(
    [adminDashboardCandidatesInputSelector, pageInputSelector, countInputSelector],
    (adminDashboardCandidates, userId, page, count) => {
        const candidates = adminDashboardCandidates.slice(
            page * count,
            page * count + count || adminDashboardCandidates.length
        );

        const candidatesCount = candidates.length;
        return { candidates, candidatesCount };
    }
);

const adminDashboardDedicatedPositionsInputSelector = (state) => state.adminDashboardJobPositions.dedicatedPositions;
export const getAdminDashboardDedicatedPositions = createSelector(
    [adminDashboardDedicatedPositionsInputSelector],
    (dedicatedPositions) => {
        return dedicatedPositions.filter(
            (position) => position.privacy === 1 && position.status === JOB_OPPORTUNITIES_STATUSES.OPEN
        );
    }
);

const adminDashboardOnDemandPositionsInputSelector = (state) => state.adminDashboardJobPositions.onDemandPositions;
export const getAdminDashboardOnDemandPositions = createSelector(
    [adminDashboardOnDemandPositionsInputSelector],
    (onDemandPositions) => {
        return onDemandPositions.filter(
            (position) => position.privacy === 1 && position.status === JOB_OPPORTUNITIES_STATUSES.OPEN
        );
    }
);

export const getAdminDashboardHotPositions = createSelector(
    [adminDashboardOnDemandPositionsInputSelector, adminDashboardDedicatedPositionsInputSelector],
    (onDemandPositions, dedicatedPositions) => {
        return [...onDemandPositions, ...dedicatedPositions].filter(
            (job) => 
                job.privacy === 1 && 
                job.status === JOB_OPPORTUNITIES_STATUSES.OPEN &&
                job.priority === JOB_OPPORTUNITIES_PRIORITIES.HIGH
        );
    }
);

export const getAdminDashboardPositionsNums = createSelector(
    [getAdminDashboardDedicatedPositions, getAdminDashboardOnDemandPositions, getAdminDashboardHotPositions],
    (dedicatedPositions, onDemandPositions, hotJobs) => {
        return {
            dedicatedPositions: dedicatedPositions.length,
            onDemandPositions: onDemandPositions.length,
            hotJobs: hotJobs.length
        }
    }
);

const adminDashboardInputSelector = (state) => state.adminDashboard;
export const getAdminDashboardEmployeesByCountry = createSelector([adminDashboardInputSelector], (adminDashboard) => {
    const employees = adminDashboard.adminDashboardEmployees.employeesByCountry;
    if (!employees) {
        return COUNTRIES_OF_EMPLOYMENT.map(x => ({ [x.toLowerCase()]: 0 }));
    }
    const employeesByCountries = employees.reduce((acc, adminDashboard) => {
        const { country, numberOfEmployees } = adminDashboard;
        return { ...acc, [country.toLowerCase()]: numberOfEmployees }
    }, {});

    return employeesByCountries;
})
