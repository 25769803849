export const REPORT_TABLE_COLUMNS = [
    { name: 'Key Performance Indicator', value: 'question' },
    { name: 'Value', value: 'answer' },
];

export const DEFAULT_REPORT_TABLE_COLUMNS = [
    'question',
    'answer',
];

export const REPORT_DATA_TABLE_TITLES = {
    OPEN_POSITIONS: 'Open Positions',
    REGISTERED_CANDIDATES: 'Registered Candidates',
    PARTNER_EMPLOYEES: 'Partner Employees',
    JOB_APPLICATIONS: 'Job Applications',
    NEW_HIRES: 'New Hires',
    ATTRITION: 'Attrition',
};

export const REPORT_DATA_TO_FIELD_MAP = {
    OPEN_CONTRACT_POSITIONS: 'openContractPositions',
    OPEN_FULL_TIME_POSITIONS: 'openFullTimePositions',
    CLOSED_POSITIONS: 'closedPositions',
    OPEN_POSITIONS: 'openPositions',
    APPLICANT_PIPELINE_PROFILES: 'applicantPipelineProfiles',
    NEW_CANDIDATES: 'newCandidates',
    POPULAR_REFERRERS: 'popularReferrers',
    VETTED_CANDIDATES: 'vettedCandidates',
    REJECTED_CANDIDATES: 'rejectedCandidates',
    INCOMPLETE_PROFILE_CANDIDATES: 'incompleteProfileCandidates',
    NEW_PARTNER_EMPLOYEES: 'newPartnerEmployees',
    VETTED_PARTNER_EMPLOYEES: 'vettedPartnerEmployees',
    CREATED_APPLICATIONS: 'createdApplications',
    CREATED_EMPLOYEE_APPLICATIONS: 'createdEmployeeApplications',
    CREATED_SUPPLIER_EMPLOYEE_APPLICATIONS: 'createdSupplierEmployeeApplications',
    CREATED_CANDIDATE_APPLICATIONS: 'createdCandidateApplications',
    REJECTED_APPLICATIONS: 'rejectedApplications',
    HIRED_APPLICATIONS: 'hiredApplications',
    OPEN_JOBS_NO_APPLICATIONS: 'openJobsNoApplications',
    HIRED_EMPLOYEES: 'hiredEmployees',
    ASSIGNED_EMPLOYEES: 'assignedEmployees',
    REMOVED_EMPLOYEES: 'removedEmployees',
    REMOVED_FROM_PROJECT_EMPLOYEES: 'removedFromProjectEmployees',
};

export const REFERRER_QUESTION = 'Source of candidates';

export const REPORT_DATA_TO_QUESTIONS_MAP = {
    [REPORT_DATA_TO_FIELD_MAP.OPEN_CONTRACT_POSITIONS]: 'New contract positions',
    [REPORT_DATA_TO_FIELD_MAP.OPEN_FULL_TIME_POSITIONS]: 'New full-time positions',
    [REPORT_DATA_TO_FIELD_MAP.OPEN_POSITIONS]: 'Total new positions',
    [REPORT_DATA_TO_FIELD_MAP.CLOSED_POSITIONS]: 'Closed positions',
    [REPORT_DATA_TO_FIELD_MAP.APPLICANT_PIPELINE_PROFILES]: 'How many profiles do we have in the applicant pipeline?', // unused for now
    [REPORT_DATA_TO_FIELD_MAP.NEW_CANDIDATES]: 'Registered and invited candidates',
    [REPORT_DATA_TO_FIELD_MAP.POPULAR_REFERRERS]: REFERRER_QUESTION,
    [REPORT_DATA_TO_FIELD_MAP.VETTED_CANDIDATES]: 'Vetted candidates',
    [REPORT_DATA_TO_FIELD_MAP.REJECTED_CANDIDATES]: 'Rejected candidates',
    [REPORT_DATA_TO_FIELD_MAP.INCOMPLETE_PROFILE_CANDIDATES]: 'Incomplete profiles',
    [REPORT_DATA_TO_FIELD_MAP.NEW_PARTNER_EMPLOYEES]: 'New partner profiles',
    [REPORT_DATA_TO_FIELD_MAP.VETTED_PARTNER_EMPLOYEES]: 'Vetted partner profiles',
    [REPORT_DATA_TO_FIELD_MAP.CREATED_APPLICATIONS]: 'New job applications',
    [REPORT_DATA_TO_FIELD_MAP.CREATED_EMPLOYEE_APPLICATIONS]: 'New employee job applications',
    [REPORT_DATA_TO_FIELD_MAP.CREATED_SUPPLIER_EMPLOYEE_APPLICATIONS]: 'New vendor job applications',
    [REPORT_DATA_TO_FIELD_MAP.CREATED_CANDIDATE_APPLICATIONS]: 'New candidate job applications',
    [REPORT_DATA_TO_FIELD_MAP.REJECTED_APPLICATIONS]: 'Rejected job applications',
    [REPORT_DATA_TO_FIELD_MAP.HIRED_APPLICATIONS]: 'Successful job applications',
    [REPORT_DATA_TO_FIELD_MAP.OPEN_JOBS_NO_APPLICATIONS]: 'How many open jobs don\'t have a single job application?', // unused for now
    [REPORT_DATA_TO_FIELD_MAP.HIRED_EMPLOYEES]: 'New employees',
    [REPORT_DATA_TO_FIELD_MAP.ASSIGNED_EMPLOYEES]: 'Profiles assigned to project (employees and partners)',
    [REPORT_DATA_TO_FIELD_MAP.REMOVED_EMPLOYEES]: 'Deleted profiles',
    [REPORT_DATA_TO_FIELD_MAP.REMOVED_FROM_PROJECT_EMPLOYEES]: 'Profiles de-assigned from project',
};

export const REPORTS_TABLE_ROWS_IDS = {
    OPEN_CONTRACT_JOBS: 'open-contract-jobs',
    OPEN_FULL_TIME_JOBS: 'open-full-time-jobs',
    CLOSED_POSITIONS: 'closed-positions',
    OPEN_POSITIONS: 'open-positions',
    APPLICANT_PIPELINE_PROFILES: 'applicant-pipeline-profiles',
    NEW_CANDIDATES: 'new-candidates',
    POPULAR_REFERRERS: 'popular-referrers',
    VETTED_CANDIDATES: 'vetted-candidates',
    REJECTED_CANDIDATES: 'rejected-candidates',
    INCOMPLETE_PROFILE_CANDIDATES: 'incomplete-profile-candidates',
    NEW_PARTNER_EMPLOYEES: 'new-partner-employees',
    VETTED_PARTNER_EMPLOYEES: 'vetted-partner-employees',
    NEW_APPLICATIONS: 'new-applications',
    NEW_EMPLOYEE_APPLICATIONS: 'new-applications',
    NEW_SUPPLIER_EMPLOYEE_APPLICATIONS: 'new-applications',
    NEW_CANDIDATE_APPLICATIONS: 'new-applications',
    REJECTED_APPLICATIONS: 'rejected-applications',
    APPROVED_APPLICATIONS: 'approved-applications',
    JOBS_WITH_NO_APPLICATIONS: 'jobs-with-no-applications',
    NEW_EMPLOYEES: 'new-employees',
    ASSIGNED_TO_PROJECTS_EMPLOYEES: 'assigned-to-projects-employees',
    DELETED_EMPLOYEES: 'deleted-employees',
    DELETED_FROM_PROJECTS_EMPLOYEES: 'deleted-from-projects-employees',
};

export const REPORTS_QUESTION_TO_TABLE_ROW_ID_MAP = {
    [REPORT_DATA_TO_QUESTIONS_MAP[REPORT_DATA_TO_FIELD_MAP.OPEN_CONTRACT_POSITIONS]]: REPORTS_TABLE_ROWS_IDS.OPEN_CONTRACT_JOBS,
    [REPORT_DATA_TO_QUESTIONS_MAP[REPORT_DATA_TO_FIELD_MAP.OPEN_FULL_TIME_POSITIONS]]: REPORTS_TABLE_ROWS_IDS.OPEN_FULL_TIME_JOBS,
    [REPORT_DATA_TO_QUESTIONS_MAP[REPORT_DATA_TO_FIELD_MAP.OPEN_POSITIONS]]: REPORTS_TABLE_ROWS_IDS.OPEN_POSITIONS,
    [REPORT_DATA_TO_QUESTIONS_MAP[REPORT_DATA_TO_FIELD_MAP.CLOSED_POSITIONS]]: REPORTS_TABLE_ROWS_IDS.CLOSED_POSITIONS,
    [REPORT_DATA_TO_QUESTIONS_MAP[REPORT_DATA_TO_FIELD_MAP.APPLICANT_PIPELINE_PROFILES]]: REPORTS_TABLE_ROWS_IDS.APPLICANT_PIPELINE_PROFILES,
    [REPORT_DATA_TO_QUESTIONS_MAP[REPORT_DATA_TO_FIELD_MAP.NEW_CANDIDATES]]: REPORTS_TABLE_ROWS_IDS.NEW_CANDIDATES,
    [REPORT_DATA_TO_QUESTIONS_MAP[REPORT_DATA_TO_FIELD_MAP.POPULAR_REFERRERS]]: REPORTS_TABLE_ROWS_IDS.POPULAR_REFERRERS,
    [REPORT_DATA_TO_QUESTIONS_MAP[REPORT_DATA_TO_FIELD_MAP.VETTED_CANDIDATES]]: REPORTS_TABLE_ROWS_IDS.VETTED_CANDIDATES,
    [REPORT_DATA_TO_QUESTIONS_MAP[REPORT_DATA_TO_FIELD_MAP.REJECTED_CANDIDATES]]: REPORTS_TABLE_ROWS_IDS.REJECTED_CANDIDATES,
    [REPORT_DATA_TO_QUESTIONS_MAP[REPORT_DATA_TO_FIELD_MAP.INCOMPLETE_PROFILE_CANDIDATES]]: REPORTS_TABLE_ROWS_IDS.INCOMPLETE_PROFILE_CANDIDATES,
    [REPORT_DATA_TO_QUESTIONS_MAP[REPORT_DATA_TO_FIELD_MAP.NEW_PARTNER_EMPLOYEES]]: REPORTS_TABLE_ROWS_IDS.NEW_PARTNER_EMPLOYEES,
    [REPORT_DATA_TO_QUESTIONS_MAP[REPORT_DATA_TO_FIELD_MAP.VETTED_PARTNER_EMPLOYEES]]: REPORTS_TABLE_ROWS_IDS.VETTED_PARTNER_EMPLOYEES,
    [REPORT_DATA_TO_QUESTIONS_MAP[REPORT_DATA_TO_FIELD_MAP.CREATED_APPLICATIONS]]: REPORTS_TABLE_ROWS_IDS.NEW_APPLICATIONS,
    [REPORT_DATA_TO_QUESTIONS_MAP[REPORT_DATA_TO_FIELD_MAP.CREATED_EMPLOYEE_APPLICATIONS]]: REPORTS_TABLE_ROWS_IDS.NEW_EMPLOYEE_APPLICATIONS,
    [REPORT_DATA_TO_QUESTIONS_MAP[REPORT_DATA_TO_FIELD_MAP.CREATED_SUPPLIER_EMPLOYEE_APPLICATIONS]]: REPORTS_TABLE_ROWS_IDS.NEW_SUPPLIER_EMPLOYEE_APPLICATIONS,
    [REPORT_DATA_TO_QUESTIONS_MAP[REPORT_DATA_TO_FIELD_MAP.CREATED_CANDIDATE_APPLICATIONS]]: REPORTS_TABLE_ROWS_IDS.NEW_CANDIDATE_APPLICATIONS,
    [REPORT_DATA_TO_QUESTIONS_MAP[REPORT_DATA_TO_FIELD_MAP.REJECTED_APPLICATIONS]]: REPORTS_TABLE_ROWS_IDS.REJECTED_APPLICATIONS,
    [REPORT_DATA_TO_QUESTIONS_MAP[REPORT_DATA_TO_FIELD_MAP.HIRED_APPLICATIONS]]: REPORTS_TABLE_ROWS_IDS.APPROVED_APPLICATIONS,
    [REPORT_DATA_TO_QUESTIONS_MAP[REPORT_DATA_TO_FIELD_MAP.OPEN_JOBS_NO_APPLICATIONS]]: REPORTS_TABLE_ROWS_IDS.JOBS_WITH_NO_APPLICATIONS,
    [REPORT_DATA_TO_QUESTIONS_MAP[REPORT_DATA_TO_FIELD_MAP.HIRED_EMPLOYEES]]: REPORTS_TABLE_ROWS_IDS.NEW_EMPLOYEES,
    [REPORT_DATA_TO_QUESTIONS_MAP[REPORT_DATA_TO_FIELD_MAP.ASSIGNED_EMPLOYEES]]: REPORTS_TABLE_ROWS_IDS.ASSIGNED_TO_PROJECTS_EMPLOYEES,
    [REPORT_DATA_TO_QUESTIONS_MAP[REPORT_DATA_TO_FIELD_MAP.REMOVED_EMPLOYEES]]: REPORTS_TABLE_ROWS_IDS.DELETED_EMPLOYEES,
    [REPORT_DATA_TO_QUESTIONS_MAP[REPORT_DATA_TO_FIELD_MAP.REMOVED_FROM_PROJECT_EMPLOYEES]]: REPORTS_TABLE_ROWS_IDS.DELETED_FROM_PROJECTS_EMPLOYEES,
};

export const REPORTS_REFERRER_TO_TEXT_MAP = {
    'GoogleAdCampaign': 'Google Ads',
    'LinkedInPaidJobPost': 'LinkedIn Ads',
    'WebsiteOrganic': 'Website Organic or Other',
    'Invited by admin': 'Invited by Recruiter',
    'Self-registered': 'Registration Link',
};
