import React, { useState } from 'react'

import CustomTooltip from '../ApplicationCustomTooltip';
import ApplicationInterviewActions from '../ApplicationInterviewActions';
import VideoPlayer from 'components/Shared/VideoPlayer';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { ReactComponent as NoVideosAvailableIcon } from 'assets/no-matches-found.svg';

import './ApplicationVideoIntroduction.scss';

const ApplicationVideoIntroduction = ({
    agencyId,
    applicationId,
    videoInterviewAnswers,
    agencyName,
    interviewInvitation,
    hideInterviewInviteButton
}) => {
    const [showTooltip, setShowTooltip] = useState(false);

    return (
        <Paper className="paper-element application-video-introduction-content-wrapper">
            <div className="video-introduction-wrapper">
                <Typography className="title">Video Introduction</Typography>
                {videoInterviewAnswers?.length > 0
                    ? <div className="video-interview-answers-wrapper">
                        {videoInterviewAnswers.map(video =>
                            <VideoPlayer
                                key={video?.question?._id}
                                videoUrl={video.temporarySignedUrl}
                            />)
                        }
                    </div>
                    : <div className="no-videos-available-wrapper">
                        <NoVideosAvailableIcon className="no-videos-available-icon" />
                        <div className="no-videos-available-message">
                            <p className="no-videos-available-text">No video available for this candidate</p>
                            <InfoOutlinedIcon
                                className="global-information-icon"
                                onMouseEnter={() => setShowTooltip(true)}
                                onMouseLeave={() => setShowTooltip(false)}
                            />
                            {showTooltip &&
                                <CustomTooltip styles={{ width: '285px', top: '30px', right: '-35px' }}>
                                    No video is available for this candidate, as they have successfully completed an interview with our team.
                                </CustomTooltip>
                            }
                        </div>
                    </div>
                }
            </div>

            <ApplicationInterviewActions
                agencyId={agencyId}
                applicationId={applicationId}
                agencyName={agencyName}
                interviewInvitation={interviewInvitation}
                hideInterviewInviteButton={hideInterviewInviteButton}
            />
        </Paper>
    );
};

export default ApplicationVideoIntroduction;
