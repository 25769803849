import React from 'react';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

import { ErrorMessage } from 'formik';

import '../CompanyInformationBasicForm.scss';

const CompanyInformationBasicFormView = ({
    values,
    handleChange,
    handleShowHeaderButtons,
    errors,
    touched,
    countries,
    isEmailChangeDisabled,
    isClient,
}) => {
    return (
        <form className={`company-basic-information-form ${isClient ? 'client' : ''}`} autoComplete="off">
            <div className="company-information-form-field">
                <TextField
                    required
                    id="name"
                    label="Company Name"
                    variant="outlined"
                    name="name"
                    value={values.name}
                    onChange={(e) => { handleChange(e); handleShowHeaderButtons(); }}
                    error={errors.name && touched.name}
                    fullWidth
                />
                <ErrorMessage name="name" component="div" className="invalid-field-message" />
            </div>
            <div>
                <FormControl className={`company-information-form-field country ${isClient ? 'client' : ''}`} variant="outlined" fullWidth>
                    <InputLabel id="country-label">Country</InputLabel>
                    <Select
                        labelId="country-label"
                        id="country"
                        name="country"
                        value={values.country}
                        onChange={(e) => { handleChange(e); handleShowHeaderButtons(); }}
                        label="Country"
                        error={errors.country && touched.country}
                    >
                        {countries.map(country => (
                            <MenuItem key={country.name} value={country.name}>
                                {country.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            {!isClient &&
                <div className={`company-information-form-field last`}>
                    <TextField
                        disabled={isEmailChangeDisabled}
                        id="email"
                        label="Email"
                        variant="outlined"
                        name="email"
                        value={values.email}
                        onChange={(e) => { handleChange(e); handleShowHeaderButtons(); }}
                        error={errors.email && touched.email}
                        fullWidth
                    />
                    <ErrorMessage name="email" component="div" className="invalid-field-message" />
                </div>
            }
            {isClient &&
                <>
                    <div className="company-information-form-field hq-location">
                        <TextField
                            id="hqLocation"
                            label="HQ Location"
                            variant="outlined"
                            name="hqLocation"
                            value={values.hqLocation}
                            onChange={(e) => { handleChange(e); handleShowHeaderButtons(); }}
                            fullWidth
                        />
                    </div>

                    <div className="company-information-form-field obfuscated-name last">
                        <TextField
                            id="obfuscatedName"
                            label="Obfuscated Name"
                            variant="outlined"
                            name="obfuscatedName"
                            value={values.obfuscatedName}
                            onChange={(e) => { handleChange(e); handleShowHeaderButtons(); }}
                            fullWidth
                        />
                    </div>
                </>
            }
        </form>);
};

export default CompanyInformationBasicFormView;
