import React from 'react';
import { connect } from 'react-redux';

import { Formik } from 'formik';

import PerformanceReviewForm from './PerformanceReviewForm';
import CommonFormikDialog from 'components/Shared/CommonFormikDialog';

import {
    validateRequiredFields,
    validateInputLength
} from 'utils/formValidations';

import { getDisplayMode } from 'reducers';

import { PERFORMANCE_REVIEW_SUMMARY_MAX_LENGTH } from '../PerformanceReviewConstants';
import { ROLES } from 'constants/userConstants';

import './PerformanceReviewDialog.scss';

const PerformanceReviewDialog = ({
    openDialog,
    handleCloseDialog,
    displayName,
    handleReview,
    agencyId,
    performanceCriterias = [],
    userId,
    performanceToEdit,
    handleDeletePerformanceReview,
    editDialog,
    role,
    displayMode,
    project,
}) => {
    const handleSave = (values) => {
        const { reviewer, summary, ...ratings } = values
        const reviewData = performanceToEdit?._id
            ? { _id: performanceToEdit._id, ratings, summary }
            : { ratings, summary: summary || "" };

        if (project) {
            const employee = 
                project.dedicatedEmployees.find(x => x._id === userId) ||
                project.employees.find(x => x._id === userId);
    
            handleReview(reviewData, employee.employeeId, agencyId);
        } else {
            handleReview(reviewData, userId, agencyId);
        }
    };

    return (
        <Formik
            enableReinitialize
            initialValues={{
                reviewer: performanceToEdit?.reviewer || displayName,
                summary: performanceToEdit?.summary || '',
                ...performanceCriterias.reduce((acc, criteria) => {
                    acc[criteria] = performanceToEdit?.ratings ? performanceToEdit.ratings[criteria] : 0;
                    return acc;
                }, {}),
            }}
            validate={values => {

                return {
                    ...validateRequiredFields(values, [
                        ...performanceCriterias,
                    ]),
                    ...validateInputLength(values, [
                        {
                            property: "summary",
                            maxLength: PERFORMANCE_REVIEW_SUMMARY_MAX_LENGTH,
                        },
                    ])
                }
            }}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
                handleSave(values);
                handleCloseDialog();
            }}
        >
            {(props) => (
                <CommonFormikDialog
                    className="performance-review-dialog"
                    open={openDialog}
                    onClose={() => (handleCloseDialog())}
                    dialogTitle={displayMode === ROLES.EMPLOYEE ? 'Performance review' : performanceToEdit?._id ? 'Edit performance review' : 'Create new performance review'}
                    onSave={props.handleSubmit}
                    fullWidth={true}
                    maxWidth='sm'
                    displayButtons={displayMode !== ROLES.EMPLOYEE}
                >
                    <PerformanceReviewForm
                        {...props}
                        displayName={displayName}
                        performanceCriterias={performanceCriterias || []}
                        performanceToEdit={performanceToEdit || { ratings: {} }}
                        role={role}
                        displayMode={displayMode}
                    />
                </CommonFormikDialog>
            )}
        </Formik>
    )
};

const mapStateToProps = state => ({
    displayMode: getDisplayMode(state),
});

export default connect(mapStateToProps)(PerformanceReviewDialog);
