import React from 'react';

import PerformanceBar from '../PerformanceBar';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import './PerformanceReviewOverallCard.scss';

const PerformanceReviewOverallCard = ({ overallPerformance }) => {
    return (
        <Paper className="performance-review-overall-card">
            <div className="overall-performance">
                <Typography variant="h5" className="overall-performance-title">Overall Performance</Typography>
                <Typography variant="h4" className="overall-performance-score">{overallPerformance.overallPerformance}</Typography>
                <div className="performance-bar-container large" >
                    <PerformanceBar score={overallPerformance.overallPerformance} />
                </div>
            </div>

            <div className="performance-criteria">
                {overallPerformance.categoriesPerformance.map((performance, i) => (
                    <div key={i} className="performance-criterion">
                        <Typography component="h6" className="performance-criterion-title">{performance.criteria}</Typography>
                        <div className="performance-bar-container small" >
                            <PerformanceBar score={performance.score} />
                        </div>
                    </div>
                ))}
            </div>
        </Paper>
    );
};

export default PerformanceReviewOverallCard;
