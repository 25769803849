import {
    HOLIDAY_APPROVAL_STATUSES,
    HOLIDAY_SIGNATURE_STATUSES,
} from 'constants/holidayConstants';

export const TABLE_COLUMNS = [
    { name: 'From', value: 'from', propertyName: 'startDate', isSortable: true },
    { name: 'To', value: 'to', propertyName: 'endDate', isSortable: true },
    { name: 'Days', value: 'numberOfDays', propertyName: 'numberOfDays', isSortable: true },
    { name: 'Type', value: 'type', propertyName: 'type', isSortable: true },
    { name: 'Request Date', value: 'requestedOn', propertyName: 'requestedOn', isSortable: true },
    { name: 'Approval Status', value: 'status', propertyName: 'status', isSortable: true },
    { name: 'Signature Status', value: 'signatureStatus', propertyName: 'signatureStatus', isSortable: true },
];
export const DEFAULT_TABLE_COLUMNS = [
    'from',
    'to',
    'numberOfDays',
    'type',
    'requestedOn',
    'status',
    'signatureStatus',
];


export const TABLE_COLUMNS_NO_SIGN = [
    { name: 'From', value: 'from', propertyName: 'startDate', isSortable: true },
    { name: 'To', value: 'to', propertyName: 'endDate', isSortable: true },
    { name: 'Days', value: 'numberOfDays', propertyName: 'numberOfDays', isSortable: true },
    { name: 'Type', value: 'type', propertyName: 'type', isSortable: true },
    { name: 'Request Date', value: 'requestedOn', propertyName: 'requestedOn', isSortable: true },
    { name: 'Approval Status', value: 'status', propertyName: 'status', isSortable: true },
];
export const DEFAULT_TABLE_COLUMNS_NO_SIGN = [
    'from',
    'to',
    'numberOfDays',
    'type',
    'requestedOn',
    'status',
];


export const SORT_QUERY_SELECTOR = 'historySort';
export const PAGE_QUERY_SELECTOR = 'historyPage';
export const DATE_QUERY_SELECTOR = 'historyDate';

export const DEFAULT_ITEMS_PER_PAGE = 25;

export const TABLE_FILTERS = [
    {
        key: 'status',
        name: 'Approval Status',
        value: [
            HOLIDAY_APPROVAL_STATUSES.APPROVED,
            HOLIDAY_APPROVAL_STATUSES.REJECTED,
        ]
    },
    {
        key: 'signatureStatus',
        name: 'Signature Status',
        value: [
            HOLIDAY_SIGNATURE_STATUSES.PENDING,
            HOLIDAY_SIGNATURE_STATUSES.REJECTED,
            HOLIDAY_SIGNATURE_STATUSES.SIGNED
        ]
    },
];

export const TABLE_INITIAL_QUERY = {
    filters: JSON.stringify({}),
    page: 1,
    date: null,
    sortBy: 'from',
    sortByDir: 'desc',
};

export const CONFIRM_DELETE_DIALOG_TITLE = 'selected request';
