import React from 'react';
import { connect } from 'react-redux';

import { deleteTask } from 'actions/agencyActions';

import { Formik } from 'formik';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';

import CreateEditTaskFormView from './CreateEditTaskFormView';

import CommonFormikDialog from 'components/Shared/CommonFormikDialog';

import {
    validateRequiredFields,
    validateInputLength,
    validateDateFields,
    validateDateNotInPast,
} from 'utils/formValidations';

import './CreateEditTaskDialog.scss';

const CreateEditTaskDialog = ({
    openDialog,
    currentTask,
    submitTaskHandler,
    handleCloseDialog,
    taskOwner,
    reletedTo,
    agencyId,
    taskAdmins,
}) => {
    const dateTime = currentTask.reminderDateTime
        ? moment.utc(currentTask.reminderDateTime, 'YYYY-MM-DDTHH:mm:ss.SSSSZ')
        : null;

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <Formik
                enableReinitialize
                initialValues={{
                    _id: currentTask._id ? currentTask._id : '',
                    name: currentTask.name ? currentTask.name : '',
                    relatedTo: reletedTo || { _id: '', name: '' },
                    owner: taskOwner?._id || '',
                    description: currentTask.description ? currentTask.description : '',
                    date: dateTime,
                    time: dateTime ? dateTime : moment(),
                    status: currentTask.status ? currentTask.status : '',
                    dateTime,
                    reminder: !!currentTask.reminder,
                }}
                validate={values => {
                    return {
                        ...validateRequiredFields(values, ['name']),
                        ...validateInputLength(values, [
                            { property: 'name', maxLength: 50 },
                            { property: 'description', maxLength: 1000 },
                        ]),
                        ...validateDateFields(values, ['date']),
                        ...validateDateNotInPast(values, ['date']),
                    }
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false);
                    const { name, relatedTo, owner, description, date, time, status } = values;
                    let { reminder } = values;

                    const dateDetails = moment(date, 'YYYY-MM-DDTHH:mm:ss.SSSSZ');
                    const timeDetails = moment(time, 'YYYY-MM-DDTHH:mm:ss.SSSSZ');

                    const reminderDateTime = moment.utc({
                        year: dateDetails.get('year'),
                        months: dateDetails.get('month'),
                        day: dateDetails.get('date'),
                        hour: timeDetails.get('hour') ? timeDetails.get('hour') : 0,
                        minute: timeDetails.get('minute') ? timeDetails.get('minute') : 0,
                        second: timeDetails.get('second') ? timeDetails.get('second') : 0,
                        millisecond: timeDetails.get('millisecond') ? timeDetails.get('millisecond') : 0,
                    });

                    reminder = !!(moment.utc(reminderDateTime).isValid() && reminder);

                    const { _id, name: adminName } = taskAdmins.find(x => x._id === owner);

                    let task = { name, relatedTo, owner: { _id, name: adminName, agencyId }, description, reminderDateTime, reminder, status };
                    task = currentTask._id ? { _id: currentTask._id, ...task } : task;

                    submitTaskHandler(task, reletedTo._id, agencyId);
                    handleCloseDialog();
                }}
            >
                {(props) =>
                    <CommonFormikDialog
                        className="create-task-dialog"
                        open={openDialog}
                        onClose={handleCloseDialog}
                        maxWidth={'sm'}
                        fullWidth={true}
                        dialogTitle='Create new task'
                        onSave={props.handleSubmit}
                    >
                        <CreateEditTaskFormView
                            {...props}
                            taskAdmins={taskAdmins}
                        />
                    </CommonFormikDialog>
                }
            </Formik>
        </MuiPickersUtilsProvider >
    );
};

const mapDispatchToProps = {
    deleteTask
};

export default connect(null, mapDispatchToProps)(CreateEditTaskDialog);
