import React, { useState } from 'react';
import { connect } from 'react-redux';

import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';

import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';
import ProfileInfoRow from '../ProfileInfoRow';
import AddEditProjectDialog from './AddEditProjectDialog';
import ConfirmDeleteDialog from 'components/Shared/ConfirmDeleteDialog';

import {
    getEmployeeProjectEntry,
    addEmployeeProjectEntry,
    editEmployeeProjectEntry,
    removeEmployeeProjectEntry,
    clearEmployeeCurrentProject,
    updateEmployeePersonalProjects,
} from 'actions/employeeActions';

import {
    addProjectTemplate,
    editProjectTemplate,
    getTemplate,
} from 'actions/projectTemplateActions';

import {
    getEmployeePersonalProjectsList,
    getEmployeeCurrentPersonalProject,
    getPersonalProjectTemplatesList,
    getDisplayMode,
} from 'reducers';

import isHoliday from 'hoc/isHoliday';

import './ProfileProjects.scss';

const ProfileProjects = ({
    employeeId,
    agencyId,
    departmentId,
    displayMode,
    projectsList,
    clearEmployeeCurrentProject,
    getTemplate,
    currentProject,
    getEmployeeProjectEntry,
    addEmployeeProjectEntry,
    editEmployeeProjectEntry,
    removeEmployeeProjectEntry,
    updateEmployeePersonalProjects,
    addProjectTemplate,
    editProjectTemplate,
    projectTemplatesList,
    isHoliday,
}) => {
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [projectToRemove, setProjectToRemove] = useState({});
    const [fromTemplate, setFromTemplate] = useState(false);

    const handleProjectDrag = async result => {
        await updateEmployeePersonalProjects(result, employeeId, agencyId, departmentId);
    }

    const handleCloseAddDialog = () => {
        setFromTemplate(false);
        setOpenAddDialog(false);
    };

    const handleCloseConfirmDialog = () => {
        setOpenConfirmDialog(false);
        setProjectToRemove({});
    }

    const removeElement = (index) => {
        const removedElement = projectsList[index];
        handleRemoveClick(removedElement);
    }

    const handleRemoveClick = project => {
        setOpenConfirmDialog(true);
        setProjectToRemove(project);
    };

    const handleAddClick = () => {
        clearEmployeeCurrentProject();
        setOpenAddDialog(true);
    };

    const handleEditClick = async entry => {
        await getEmployeeProjectEntry(entry._id, employeeId, agencyId, departmentId);
        setOpenAddDialog(true);
    };

    const handleAddProjectEntry = entry => {
        addEmployeeProjectEntry(entry, employeeId, agencyId, departmentId);
    };

    const handleRemoveProjectEntry = id => {
        removeEmployeeProjectEntry(id, employeeId, agencyId, departmentId);
        setOpenConfirmDialog(false);
    };

    const handleEditProjectEntry = entry => {
        editEmployeeProjectEntry(entry, employeeId, agencyId, departmentId);
    };

    const handleAddProjectTemplate = entry => {
        addProjectTemplate(entry, agencyId);
    };

    const handleEditProjectTemplate = entry => {
        editProjectTemplate(entry, agencyId);
    };

    const handleSelectProjectTemplate = templateId => {
        getTemplate(templateId, agencyId);
    };

    const SortableItem = SortableElement(({ index, item, onRemove, editItem }) => {
        return (
            <ProfileInfoRow
                item={item}
                shouldBeDraggable={true}
                removeItem={() => onRemove(index)}
                editItem={editItem}
            />
        )
    });

    const SortableList = SortableContainer(({ items, onRemove, editItem }) => {
        return (
            <div className="profile-info-content profile-projects-rows-wrapper">
                {items.map((value, index) => {
                    return (
                        <SortableItem
                            key={`item-${index}`}
                            index={index}
                            onRemove={() => { onRemove(index) }}
                            item={value}
                            editItem={editItem}
                        />
                    )
                })}
            </div>
        );
    });

    const onSortEnd = ({ oldIndex, newIndex }) => {
        handleProjectDrag(arrayMove(projectsList, oldIndex, newIndex))
    };

    return (
        <div className="profile-info-wrapper profile-projects-wrapper">
            <SectionActionsWrapper>
                <SectionActionsWrapper.SectionTitle
                    sectionTitle='Projects'
                />

                <SectionActionsWrapper.RightAlignedActions>
                    <SectionActionsWrapper.RightAlignedActions.PrimaryAddButton
                        text='Add New'
                        onClick={handleAddClick}
                    />
                </SectionActionsWrapper.RightAlignedActions>
            </SectionActionsWrapper>

            <SortableList
                axis="y"
                items={projectsList}
                onRemove={(index) => removeElement(index)}
                onSortEnd={onSortEnd}
                editItem={handleEditClick}
                distance={2}
            />

            <AddEditProjectDialog
                projectEntry={currentProject}
                displayMode={displayMode}
                templates={projectTemplatesList}
                fromTemplate={fromTemplate}
                setFromTemplate={setFromTemplate}
                openDialog={openAddDialog}
                handleCloseDialog={handleCloseAddDialog}
                handleAddEntry={handleAddProjectEntry}
                handleEditEntry={handleEditProjectEntry}
                handleAddProjectTemplate={handleAddProjectTemplate}
                handleEditProjectTemplate={handleEditProjectTemplate}
                handleSelectProjectTemplate={handleSelectProjectTemplate}
                agencyId={agencyId}
                renderHolidays={isHoliday}
            />

            <ConfirmDeleteDialog
                itemToDelete={projectToRemove.name}
                handleDeleteItem={() => handleRemoveProjectEntry(projectToRemove._id)}
                openDialog={openConfirmDialog}
                handleCloseDialog={handleCloseConfirmDialog}
            />
        </div>
    );
};

const mapStateToProps = state => ({
    projectsList: getEmployeePersonalProjectsList(state).slice(),
    currentProject: getEmployeeCurrentPersonalProject(state),
    projectTemplatesList: getPersonalProjectTemplatesList(state),
    displayMode: getDisplayMode(state),
});

export default connect(mapStateToProps, {
    getEmployeeProjectEntry,
    clearEmployeeCurrentProject,
    addEmployeeProjectEntry,
    editEmployeeProjectEntry,
    removeEmployeeProjectEntry,
    updateEmployeePersonalProjects,
    addProjectTemplate,
    editProjectTemplate,
    getTemplate,
    isHoliday,
})(ProfileProjects);
