import React from 'react';

import MissingInformation from '../MissingInformation';

import EditSharpIcon from '@material-ui/icons/EditSharp';

import { ROLES } from 'constants/userConstants';
import './CvLanguages.scss';

const CvLanguages = ({
    languages,
    enterEditMode,
    enableEdit,
    displayMode,
}) => {
    const isClient = displayMode === ROLES.CLIENT;

    return (
        <>
            <div className="cv-languages-wrapper">
                <div className="header">
                    <h1 className="title">
                        Languages
                    </h1>
                    {enableEdit &&
                        <div className="item-edit-icon-wrapper" onClick={() => enterEditMode('languages')}>
                            <EditSharpIcon className="item-edit-icon" />
                        </div>
                    }
                </div>
                {languages?.length > 0
                    ?
                    <div className="language-container">
                        {languages.map(language =>
                            <div className="language-box" key={language._id}>{language.name}</div>
                        )}
                    </div>
                    : isClient ? <div className="empty-category" /> : <MissingInformation />
                }
            </div>
        </>
    )
};

export default CvLanguages;
