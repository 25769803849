import React from 'react';
import { isMobile } from 'react-device-detect';
import moment from 'moment';

import MissingInformation from '../MissingInformation';

import EditSharpIcon from '@material-ui/icons/EditSharp';

import { ROLES } from 'constants/userConstants';
import { MONTH_NAME_FORMAT } from 'constants/timesFormat';

import './CvExperience.scss';

const CvExperience = ({
    projects,
    enterEditMode,
    enableEdit,
    displayMode,
}) => {
    const isClient = displayMode === ROLES.CLIENT;

    return (
        <>
            <div className="cv-experience-wrapper">
                <div className="header">
                    <h1 className="title">Experience</h1>
                    {enableEdit &&
                        <div className="item-edit-icon-wrapper" onClick={() => enterEditMode('experience')}>
                            <EditSharpIcon className="item-edit-icon" />
                        </div>
                    }
                </div>

                {projects?.length > 0 ?
                    projects?.map(project =>
                        <div className="experience-subsection" key={project._id}>
                            {
                                isMobile
                                    ?
                                    <div className="experience-row">
                                        <div>
                                            <p className="row-heading">{project.name}</p>
                                            <div className="dates-wrapper">
                                                {project.startDate
                                                    && <>
                                                        <i className="material-icons">event</i>
                                                        <p className="dates">
                                                            <span>{moment(project.startDate).format(MONTH_NAME_FORMAT)}</span>
                                                            <span> - </span>
                                                            {project.currentlyWork
                                                                ? <span>ongoing</span>
                                                                : <span>{moment(project.endDate).format(MONTH_NAME_FORMAT)}</span>}
                                                        </p>
                                                    </>}
                                            </div>
                                        </div>
                                        <p className="row-subheading">{project.position}
                                            {project.client && <span> at {project.client}</span>}
                                        </p>
                                    </div>
                                    :
                                    <div className="experience-row">
                                        <div>
                                            <p className="row-heading">{project.name}</p>
                                            <p className="row-subheading">{project.position}
                                                {project.client && <span> at {project.client}</span>}
                                            </p>
                                        </div>
                                        <div className="dates-wrapper">
                                            {project.startDate
                                                && <>
                                                    <i className="material-icons">event</i>
                                                    <p className="dates">
                                                        <span>{moment(project.startDate).format(MONTH_NAME_FORMAT)}</span>
                                                        <span> - </span>
                                                        {project.currentlyWork
                                                            ? <span>ongoing</span>
                                                            : <span>{moment(project.endDate).format(MONTH_NAME_FORMAT)}</span>}
                                                    </p>
                                                </>}
                                        </div>
                                    </div>

                            }

                            {project.description &&
                                <p className="description">
                                    {project.description}
                                </p>
                            }
                            {project.technologies.length > 0 &&
                                <div className="skills-container experience-skills-container">
                                    {project.technologies.map(technology =>
                                        <div className="skill-box" key={technology}>{technology}</div>
                                    )}
                                </div>
                            }
                        </div>)
                    : isClient ? <div className="empty-category" /> : <MissingInformation />
                }
            </div>
        </>
    )
}

export default CvExperience;
