import React from 'react';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { ErrorMessage } from 'formik';

import { clientStatuses } from 'components/Client/ClientConstants';

import './AddClientFormView.scss';

const AddClientFormView = ({
    values,
    handleChange,
    errors,
    touched,
    accountManagers,
    countries,
    handleBlur,
}) => {
    //TODO extract to constants after actions/reducers are extracted issue #1012
    return (<form className='add-client-form' autoComplete='off'>
        <div className='account-name-field'>
            <TextField
                required
                name='name'
                value={values.name}
                onChange={handleChange}
                label='Company Name'
                onBlur={handleBlur}
                error={errors.name && touched.name}
                margin='normal'
                variant='outlined'
                fullWidth
            />
            <ErrorMessage name='name' component='div' className='invalid-field-message' />
        </div>

        <div className='account-manager-field'>
            <TextField
                select
                required
                name='accountManager'
                value={values.accountManager}
                onChange={handleChange}
                label='Account Manager'
                onBlur={handleBlur}
                error={errors.accountManager && touched.accountManager}
                margin='normal'
                variant='outlined'
                fullWidth
            >
                {accountManagers.map((accountManager, i) => (
                    <MenuItem
                        native='true'
                        key={i}
                        value={accountManager._id}
                    >
                        {`${accountManager.name}`}
                    </MenuItem>
                ))}
            </TextField>
            <ErrorMessage name='accountManager' component='div' className='invalid-field-message' />
        </div>

        <div className="client-details-block">
            <div className="country-field">
                <TextField
                    select
                    name="country"
                    value={values.country}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Country"
                    error={errors.country && touched.country}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                >
                    {countries.map((country, i) => (
                        <MenuItem native="true" key={i} value={country.name}>
                            {country.name}
                        </MenuItem>
                    ))}
                </TextField>
                <ErrorMessage name="country" component="div" className="invalid-field-message" />
            </div>

            <div className="client-status-field">
                <TextField
                    select
                    required
                    name="status"
                    value={values.status}
                    onChange={handleChange}
                    label="Client Status"
                    error={errors.status && touched.status}
                    onBlur={handleBlur}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                >
                    {clientStatuses.map((status, i) => (
                        <MenuItem
                            native="true"
                            key={i}
                            value={status}
                        >
                            {status}
                        </MenuItem>
                    ))}
                </TextField>
                <ErrorMessage name="status" component="div" className="invalid-field-message" />
            </div>
        </div>
        <div className="client-details-block">
            <div className='contact-person-field'>
                <TextField
                    name='contactPerson'
                    value={values.contactPerson}
                    onChange={handleChange}
                    label='Admin Name'
                    onBlur={handleBlur}
                    error={errors.contactPerson && touched.contactPerson}
                    margin='normal'
                    variant='outlined'
                    fullWidth
                />
                <ErrorMessage name='contactPerson' component='div' className='invalid-field-message' />
            </div>
            <div className='email-field'>
                <TextField
                    name='email'
                    value={values.email}
                    onChange={handleChange}
                    label='Admin Email'
                    error={errors.email && touched.email}
                    onBlur={handleBlur}
                    margin='normal'
                    variant='outlined'
                    fullWidth
                />
                <ErrorMessage name='email' component='div' className='invalid-field-message' />
            </div>
        </div>
    </form>)
};

export default AddClientFormView;
