
import React from 'react';
import moment from 'moment';

import MissingInformation from '../MissingInformation';

import EditSharpIcon from '@material-ui/icons/EditSharp';

import { ROLES } from 'constants/userConstants';
import { MONTH_NAME_FORMAT } from 'constants/timesFormat';

import './CvEducation.scss';

const CvEducation = ({
    education,
    enterEditMode,
    enableEdit,
    displayMode,
}) => {
    const isClient = displayMode === ROLES.CLIENT;

    return (
        <>
            <div className="cv-education-wrapper">
                <div className="header">
                    <h1 className="title">Education</h1>
                    {enableEdit &&
                        <div className="item-edit-icon-wrapper" onClick={() => enterEditMode('education')}>
                            <EditSharpIcon className="item-edit-icon" />
                        </div>
                    }
                </div>
                {education?.length > 0 ?
                    education?.map(e =>
                        <div className="dates-row" key={e._id}>
                            <div>
                                <p className="row-heading">{e.title}</p>
                                {e.institution && <p className="row-subheading">{e.institution}</p>}
                            </div>
                            <div className="dates-wrapper">
                                {e.startDate
                                    && <>
                                        <i className="material-icons">event</i>
                                        <p className="dates">
                                            <span>{moment(e.startDate).format(MONTH_NAME_FORMAT)}</span>
                                            <span> - </span>
                                            {e.currentlyStudy
                                                ? <span>ongoing</span>
                                                : <span>{moment(e.endDate).format(MONTH_NAME_FORMAT)}</span>}
                                        </p>
                                    </>}
                            </div>
                        </div>)
                    : isClient ? <div className="empty-category" /> : <MissingInformation />
                }
            </div>
        </>
    )
}

export default CvEducation;
