import React from 'react';

import Typography from '@material-ui/core/Typography';

export const EXPIRED_LINK_TYPES = {
    RESET_PASSWORD: 'reset-password',
    SET_PASSWORD: 'set-password',
};

export const EXPIRED_LINK_PAGE_DATA = {
    [EXPIRED_LINK_TYPES.SET_PASSWORD]: {
        buttonName: 'Request New Link',
        text: <div>
            <div className="title-container">
                <Typography className="expired-link-title" variant="h4">
                    {'Your invitation link has already expired.'}
                </Typography>
            </div>
            <div className="content-text-container">
                <Typography className="expired-link-content-text" variant="h5">
                    {'Please click on the button below to receive a new email and complete your registration.'}
                </Typography>
            </div>
        </div>
    },
    [EXPIRED_LINK_TYPES.RESET_PASSWORD]: {
        buttonName: 'Reset Password',
        text: <div>
            <div className="title-container">
                <Typography className="expired-link-title" variant="h4">
                    {'Your password reset link has already expired.'}
                </Typography>
            </div>
            <div className="content-text-container">
                <Typography className="expired-link-content-text" variant="h5">
                    {'Please request a new reset password link from the button below.'}
                </Typography>
            </div>
        </div>
    }
}
