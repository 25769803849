import React from 'react';
import { Formik } from 'formik';

import AddCriteriaFormView from './AddCriteriaFormView';
import CommonFormikDialog from 'components/Shared/CommonFormikDialog';

import { validateRequiredFields, validateInputLength, validateCriteriaUniqueness } from 'utils/formValidations';

import './AddCriteriaDialog.scss';

const AddCriteriaDialog = ({
    selectedCriteria,
    criterias,
    openDialog,
    handleCloseDialog,
    handleCriteriaSubmit,
    title
}) => (
    <Formik
        enableReinitialize
        initialValues={{
            criteria: selectedCriteria || '',
        }}

        validate={values => {
            return {
                ...validateRequiredFields(values, ['criteria']),
                ...validateInputLength(values, [
                    { property: 'criteria', maxLength: 30 },
                ]),
                ...validateCriteriaUniqueness(values.criteria, criterias),
            }
        }}

        onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false);
            const { criteria } = values;
            const submitedCriteria = { criteria };
            handleCriteriaSubmit(submitedCriteria);
            handleCloseDialog();
        }}
    >
        {(props) =>
            <CommonFormikDialog
                className="add-criteria-dialog"
                open={openDialog}
                onClose={handleCloseDialog}
                maxWidth={'md'}
                onSave={props.handleSubmit}
                dialogTitle={selectedCriteria ? `Edit ${title}` : `Add new ${title}`}
            >
                <AddCriteriaFormView {...props} title={title} />
            </CommonFormikDialog>
        }
    </Formik>

);

export default AddCriteriaDialog;
