import React, { useState } from 'react';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';

import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';
import ProfileInfoRow from '../ProfileInfoRow';
import AddEditEducationDialog from './AddEditEducationDialog';
import ConfirmDeleteDialog from 'components/Shared/ConfirmDeleteDialog';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';

import { addEducationEntry, editEducationEntry, removeEducationEntry, updateEducationEntries } from 'actions/employeeActions';
import { getEmployeeEducationList } from 'reducers'

import './ProfileEducation.scss';

const ProfileEducation = ({
    employeeId,
    agencyId,
    departmentId,
    educationList,
    addEducationEntry,
    editEducationEntry,
    removeEducationEntry,
    updateEducationEntries
}) => {
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [entryToRemove, setEntryToRemove] = useState({});
    const [educationEntry, setEducationEntry] = useState(null);

    const handleOpenAddDialog = () => setOpenAddDialog(true);

    const handleCloseAddDialog = () => {
        setEducationEntry(null);
        setOpenAddDialog(false);
    };

    const handleCloseConfirmDialog = () => {
        setOpenConfirmDialog(false);
        setEntryToRemove({});
    }

    const handleRemoveClick = index => {
        setOpenConfirmDialog(true);
        setEntryToRemove(educationList[index]);
    };

    const handleEditClick = entry => {
        setEducationEntry(entry);
        setOpenAddDialog(true);
    };

    const handleAddEntry = entry => {
        addEducationEntry(entry, employeeId, agencyId, departmentId);
    };

    const handleRemoveEntry = id => {
        removeEducationEntry(id, employeeId, agencyId, departmentId);
        setOpenConfirmDialog(false);
    };

    const handleEditEntry = entry => {
        editEducationEntry(entry, employeeId, agencyId, departmentId);
    };

    const handleEducationDrag = async result => {
        await updateEducationEntries(result, employeeId, agencyId);
    };

    const SortableItem = SortableElement(({ index, item, onRemove, editItem }) => {
        return (
            <ProfileInfoRow
                item={item}
                shouldBeDraggable={true}
                removeItem={() => onRemove(index)}
                editItem={editItem}
            />
        )
    });

    const onSortEnd = ({ oldIndex, newIndex }) => {
        handleEducationDrag(arrayMove(educationList, oldIndex, newIndex))
    };

    const SortableList = SortableContainer(({ items, onRemove, editItem }) => {
        return (
            <div className="profile-info-content profile-certificates-rows-wrapper">
                {items.map((value, index) => {
                    return (
                        <SortableItem
                            key={`item-${index}`}
                            index={index}
                            onRemove={() => { onRemove(index) }}
                            item={value}
                            editItem={editItem}
                        />
                    )
                })}
            </div>
        );
    });

    return (
        <div className="profile-info-wrapper profile-education-wrapper">
            <SectionActionsWrapper>
                <SectionActionsWrapper.SectionTitle
                    sectionTitle='Education'
                />


                <SectionActionsWrapper.RightAlignedActions>
                    <SectionActionsWrapper.RightAlignedActions.PrimaryAddButton
                        text='Add'
                        onClick={handleOpenAddDialog}
                    />
                </SectionActionsWrapper.RightAlignedActions>
            </SectionActionsWrapper>

            <Paper className="profile-info-content profile-education-rows-wrapper">
                <SortableList
                    axis="y"
                    items={educationList}
                    onRemove={(index) => handleRemoveClick(index)}
                    onSortEnd={onSortEnd}
                    editItem={handleEditClick}
                    distance={2}
                />
            </Paper>

            <AddEditEducationDialog
                educationEntry={educationEntry}
                openDialog={openAddDialog}
                handleCloseDialog={handleCloseAddDialog}
                handleAddEntry={handleAddEntry}
                handleEditEntry={handleEditEntry}
            />

            <ConfirmDeleteDialog
                itemToDelete={entryToRemove.title}
                handleDeleteItem={() => handleRemoveEntry(entryToRemove._id)}
                openDialog={openConfirmDialog}
                handleCloseDialog={handleCloseConfirmDialog}
            />
        </div>
    );
};

const mapStateToProps = state => ({
    educationList: getEmployeeEducationList(state),
});

export default connect(mapStateToProps, {
    addEducationEntry,
    editEducationEntry,
    removeEducationEntry,
    updateEducationEntries
})(ProfileEducation);
