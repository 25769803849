import React from 'react';
import moment from 'moment';

import MissingInformation from '../MissingInformation';

import EditSharpIcon from '@material-ui/icons/EditSharp';

import { ROLES } from 'constants/userConstants';
import { MONTH_NAME_FORMAT } from 'constants/timesFormat';

import './CvCertificates.scss';

const CvCertificates = ({
    certificates,
    enterEditMode,
    enableEdit,
    displayMode,
    scrollToRef
}) => {
    const isClient = displayMode === ROLES.CLIENT;

    return (
        <>
            <div ref={scrollToRef} className="cv-certificates-wrapper">
                <div className="header">
                    <h1 className="title">Certificates</h1>
                    {enableEdit &&
                        <div className="item-edit-icon-wrapper" onClick={() => enterEditMode('certificates')}>
                            <EditSharpIcon className="item-edit-icon" />
                        </div>
                    }
                </div>
                {certificates?.length > 0 ?
                    certificates.map(certificate =>
                        <div className="dates-row" key={certificate._id}>
                            <div>
                                {certificate.documentUrl
                                    ? <a href={certificate.documentUrl} target='_blank' rel='noreferrer'>
                                        <p className="row-heading">{certificate.title}</p>
                                    </a>
                                    : <p className="row-heading">{certificate.title}</p>}
                            </div>
                            <div className="dates-wrapper">
                                {certificate.issueDate
                                    &&
                                    <>
                                        <i className="material-icons">event</i>
                                        <p className="dates">
                                            <span>{moment(certificate.issueDate).format(MONTH_NAME_FORMAT)}</span>
                                        </p>
                                    </>
                                }
                            </div>
                        </div>
                    )
                    : isClient ? <div className="empty-category" /> : <MissingInformation />
                }
            </div>
        </>
    )
}
export default CvCertificates;
