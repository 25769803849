import React from 'react';
import ConfirmDialog from '../ConfirmDialog';
import {CONFIRM_DIALOG_DELETION_CONFIRMATION_ITEMS} from '../ConfirmDialog/ConfirmDialogConstants'

import './ConfirmDeleteDialog.scss';

const ConfirmDeleteDialog = ({
    itemToDelete,
    handleDeleteItem,
    openDialog,
    handleCloseDialog,
}) => (
    <ConfirmDialog
        openDialog={openDialog}
        dialogTitle={CONFIRM_DIALOG_DELETION_CONFIRMATION_ITEMS.GET_DIALOG_TITLE(itemToDelete)}
        dialogSubtitle={CONFIRM_DIALOG_DELETION_CONFIRMATION_ITEMS.DIALOG_SUBTITLE}
        confirmButtonName={CONFIRM_DIALOG_DELETION_CONFIRMATION_ITEMS.CONFIRM_BUTTON_NAME}
        discardButtonName={CONFIRM_DIALOG_DELETION_CONFIRMATION_ITEMS.DISCARD_BUTTON_NAME}
        handleConfirmDialog={handleDeleteItem}
        handleCloseDialog={handleCloseDialog}
    />
);

export default ConfirmDeleteDialog;
