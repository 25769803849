import React, { useState } from 'react';
import moment from 'moment';

import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'

import LeftCalendarArrow from '@material-ui/icons/ArrowBackIosSharp';
import RightCalendarArrow from '@material-ui/icons/ArrowForwardIosSharp';

import MomentUtils from '@date-io/moment';

import { DATE_PICKER_FORMAT_WITH_DASHES } from 'constants/timesFormat';

import './DateRangePicker.scss';

const DateRangePicker = ({ values, handleChange }) => {
    const [from, to] = values;
    const [startDate, setStartDate] = useState(from ? moment(from, DATE_PICKER_FORMAT_WITH_DASHES) : null);
    const [endDate, setEndDate] = useState(to ? moment(to, DATE_PICKER_FORMAT_WITH_DASHES) : null);
    const [hover, setHover] = useState(null);

    const min = Math.min(startDate, endDate || hover);
    const max = Math.max(startDate, endDate || hover);

    moment.updateLocale('en', { week: { dow: 1 } });

    const createClassesString = (day, dayInCurrentMonth) => {
        const classes = `MuiPickersDay-day
                ${!dayInCurrentMonth ? 'outside-of-month-day' : ''}
                ${(day >= min && day <= max) ? 'MuiPickersDay-daySelected' : ''}
                ${moment(day).isSame(min) ? 'startDate' : ''}
                ${moment(day).isSame(max) ? 'endDate' : ''}
                ${(moment(day).date() === 1 && Math.abs(moment(day).diff(min, 'days')) >= 1 && moment(min).format('MM') !== moment(max).format('MM')) ? 'first-day-of-future-month' : ''}
                ${(moment(day).date() === moment(day).daysInMonth() && Math.abs(moment(day).diff(max, 'days')) >= 1 && moment(min).format('MM') !== moment(max).format('MM')) ? 'last-day-of-month' : ''}
                `;

        return classes.trim();
    };

    const renderDay = (day, selectedDate, dayInCurrentMonth, dayComponent) => {
        const handleClick = e => {
            e.stopPropagation();

            if (!dayInCurrentMonth) {
                return;
            }

            if (!startDate) {
                setStartDate(day);
            } else if (!endDate) {
                setEndDate(day);
                handleChange(...[startDate, day].sort((a, b) => a.diff(b)));
            } else {
                setStartDate(day);
                setEndDate(null);
            }
        };

        const handleMouseEnter = () => {
            if (dayInCurrentMonth) {
                setHover(day);

                if (!startDate && !endDate) {
                    setStartDate(null);
                    setEndDate(null);
                }
            }
        };

        return React.cloneElement(dayComponent, {
            onClick: handleClick,
            onMouseEnter: handleMouseEnter,
            className: createClassesString(day, dayInCurrentMonth),
        });
    };

    return (
        <div className="date-range-picker">
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                    disableToolbar
                    fullWidth
                    variant="static"
                    inputVariant="outlined"
                    allowKeyboardControl={true}
                    leftArrowIcon={<LeftCalendarArrow />}
                    rightArrowIcon={<RightCalendarArrow />}
                    renderDay={renderDay}
                    onChange={() => void 0}
                    initialFocusedDate={startDate}
                />
            </MuiPickersUtilsProvider>
        </div>
    );
};

export default DateRangePicker;
