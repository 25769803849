import React from 'react';

import Submenu from 'components/Common/Navigation/Submenu';
import SubmenuMobile from 'components/Common/Navigation/SubmenuMobile';

import './SubmenuContentLayout.scss';

const SubmenuContentLayout = ({
    children,
    path,
    profileName,
    supplierName,
    avatarUrl,
    role,
    employmentType,
    items,
    trainingTitle,
    applicationsCount,
    actionButtons,
    isWithProfileInfo = true,
}) => (
        <div className="submenu-content-container submenu-indicator">
            <Submenu
                path={path}
                profileName={profileName}
                supplierName={supplierName}
                avatarUrl={avatarUrl}
                role={role}
                employmentType={employmentType}
                items={items}
                trainingTitle={trainingTitle}
                applicationsCount={applicationsCount}
                actionButtons={actionButtons}
                isWithProfileInfo={isWithProfileInfo}
            />
            <div className="submenu-mobile-wrapper">
                <SubmenuMobile
                    path={path}
                    items={items}
                />
            </div>
            <div id='sub-menu-scroll-container' className="submenu-scroll-container">
                <div className="submenu-width-wrapper">
                    {children}
                </div>
            </div>
        </div>
);


export default SubmenuContentLayout;
