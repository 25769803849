export const settingsItems = {
    'admin': {
        companySettings: { text: 'Company Settings', path: '' },
        employeeSettings: { text: 'Employee Settings', path: '/employee-settings' },
        trainings: { text: 'Trainings', path: '/trainings' },
        userManagement: { text: 'User Management', path: '/user-management' },
        coaching: { text: 'Coaching & Mentoring', path: '/coaching' },
    },
    'supplier-admin': {
        employeeSettings: { text: 'Employee Settings', path: '/employee-settings' },
    },
    'client': {
        userManagement: { text: 'User Management', path: '/user-management' },
    }
}
