import React, { useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import {
    updateInterviewDate,
    updateInterviewData,
} from 'actions/interviewInvitationActions';

import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';
import InterviewInvitePopup from 'components/Shared/InterviewInvitePopup';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { ReactComponent as CalendarIcon } from 'assets/icon-date.svg';
import { ReactComponent as ClockIcon } from 'assets/icon-clock.svg';

import { INTERVIEW_INVITATION_STATUSES, INTERVIEW_INVITATION_CONTRIBUTORS } from 'constants/interviewInvitationConstants';
import { DATE_FORMAT_WITH_DOTS_FULL_YEAR, CONTINENTAL_TIME_FORMAT } from 'constants/commonConstants';
import { ROLES } from 'constants/userConstants';

import './ApplicationInterviewSlots.scss';

const ApplicationInterviewSlots = ({
    userId,
    agencyId,
    candidateId,
    clientId,
    applicationId,
    displayMode,
    interviewInvitation,
    updateInterviewData,
    updateInterviewDate,
    subtitle,
    isWaitingForResponse,
}) => {
    const [checkedBox, setCheckedBox] = useState({});
    const [isInviteDialogOpen, setIsInviteDialogOpen] = useState(false);
    const [isApproveButtonDisabled, setIsApproveButtonDisabled] = useState(true);

    const handleCheckBox = (timeSlot, index) => {
        if (checkedBox?.date === timeSlot.date && checkedBox?.time === timeSlot.time) {
            setIsApproveButtonDisabled(true);
            setCheckedBox({});
        } else {
            setIsApproveButtonDisabled(false);
            setCheckedBox({ ...timeSlot, index });
        }
    };

    const getInterviewSlotItemAdditionalClass = isInterviewSlotSelected => {
        if (Object.values(checkedBox).length === 0) {
            return '';
        } else if (isInterviewSlotSelected) {
            return 'selected';
        } else {
            return 'not-selected';
        }
    };

    const handleOpenInviteDialog = () => setIsInviteDialogOpen(true);
    const handleCloseInviteDialog = () => setIsInviteDialogOpen(false);

    const handelInterviewInvitationSubmit = async (formData) => {
        const { clientNote, ...restData } = formData;

        const additionalData = {
            dateAdded: moment.utc().format(),
            status: INTERVIEW_INVITATION_STATUSES.PENDING,
            proposedBy: displayMode === ROLES.CANDIDATE ? INTERVIEW_INVITATION_CONTRIBUTORS.PARTICIPANT : INTERVIEW_INVITATION_CONTRIBUTORS.INTERVIEWER,
            interviewer: clientId,
            participant: candidateId,
            application: applicationId,
            note: clientNote
        };

        await updateInterviewData(agencyId, interviewInvitation._id, { ...restData, ...additionalData }); 
    };

    const handleConfirmInterview = (interviewId, timeBox) => {
        const { index, ...timeSlot } = timeBox;

        if (Object.keys(timeSlot).length > 0) {
            updateInterviewDate(agencyId, interviewId, timeSlot);
        }
    };

    return (
        <Paper className="paper-element application-interview-slots-content-wrapper">
            <Typography className="title">Interview</Typography>
            <Typography className="sub-title">{subtitle || "The candidate proposed new time slots for interview. Please choose one and confirm."}</Typography>
            <ul className="application-interview-slots-list">
                {interviewInvitation.interviewTimeSlots.map((timeSlot, index) => {
                    const isInterviewSlotSelected = checkedBox?.date === timeSlot.date && checkedBox?.time === timeSlot.time && checkedBox?.index === index;

                    return (
                        <li
                            key={index}
                            className={`application-interview-slot-item${isWaitingForResponse ? ' waiting-for-response' : ''} ${getInterviewSlotItemAdditionalClass(isInterviewSlotSelected)}`}
                            onClick={() => !isWaitingForResponse && handleCheckBox(timeSlot, index)}
                        >
                            {isInterviewSlotSelected && <CheckCircleIcon className="check-circle-icon" />}
                            <div className="item-row">
                                <CalendarIcon className="icon" />
                                <p className="item-text">{moment(timeSlot.date).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR)}</p>
                            </div>
                            <div className="item-row">
                                <ClockIcon className="icon" />
                                <p className="item-text">
                                    {moment(timeSlot.time).format(CONTINENTAL_TIME_FORMAT)} - {moment(timeSlot.time).add(1, 'hours').format(CONTINENTAL_TIME_FORMAT)}
                                </p>
                            </div>
                        </li>
                    );
                })}
            </ul>

            {!isWaitingForResponse && <div className="interview-slots-action-buttons">
                {Object.values(checkedBox).length > 0
                    ? <PrimaryButton
                        className="confirm-interview-slots-button"
                        text="Confirm"
                        handleClick={() => handleConfirmInterview(interviewInvitation._id, checkedBox)}
                        disabled={isApproveButtonDisabled}
                    />
                    : <PrimaryButton text='Propose New Slots' className="propose-new-slots-button" handleClick={handleOpenInviteDialog} />
                }
            </div>}

            <InterviewInvitePopup
                dialogTitle="Propose New Time Slots"
                isDialogOpen={isInviteDialogOpen}
                isWithNote={true}
                isLocationDisabled={true}
                displayMode={displayMode}
                interviewInvitation={interviewInvitation}
                handleCloseDialog={handleCloseInviteDialog}
                handelInterviewInvitationSubmit={handelInterviewInvitationSubmit}
            />
        </Paper>
    );
};

const mapDispatchToProps = {
    updateInterviewDate,
    updateInterviewData,
};

export default connect(null, mapDispatchToProps)(ApplicationInterviewSlots);
