import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import ModalPdfViewer from 'components/Shared/ModalPdfViewer/ModalPdfViewer';
import ExportOptionsPopover from 'components/Shared/ExportOptionsPopover';
import TableWrapper from 'components/Shared/TableWrapper';

import {
    PAGE_QUERY_SELECTOR,
    SEARCH_QUERY_SELECTOR,
    SIGNED_DATE_QUERY_SELECTOR,
    SORT_QUERY_SELECTOR,
    TABLE_INITIAL_QUERY,
    TABLE_QUERY_MAP,
    UPLOAD_DATE_QUERY_SELECTOR,
    defaultTableColumns,
    tableColumns,
    tableFilters
} from '../TeamDocumentsConstants';
import { CONTRACT_SIGNATURE_STATUSES_TO_DOCUMENT_STATUSES_MAP, CONTRACT_TYPES_TO_DOCUMENT_TYPES_MAP, DOCUMENT_TYPES } from 'constants/documentsConstants';
import { DATE_FORMAT_WITH_DOTS_FULL_YEAR } from 'constants/commonConstants';
import { DATE_PICKER_FORMAT_WITH_DASHES } from 'constants/timesFormat';
import { DEFAULT_ITEMS_PER_PAGE } from 'components/Shared/TableWrapper/TableWrapperConstants';

import { getExportFileName } from '../TeamDocumentsUtils';
import { usePreloadTable } from 'utils/hooks';
import { parseQueryString, queryObjToString } from 'utils/helpers';

import { ReactComponent as ExportIcon } from 'assets/export-button-icon.svg';

import './TeamDocumentsTable.scss';

const TeamDocumentsTable = ({
    userId,
    agencyId,
    downloadDocumentsFiles,
    dataSelector,
    isAccessAsManager,
    fetchContractFilesUrl,
    action,
}) => {
    const history = useHistory();

    const [isTableDataLoading, totalCount] = usePreloadTable(TABLE_INITIAL_QUERY, TABLE_QUERY_MAP, tableFilters, action);
    const [isExportOptionsPopoverOpen, setIsExportOptionsPopoverOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [checkedRows, setCheckedRows] = useState([]);

    const [openPdfDialog, setOpenPdfDialog] = useState(false);
    const [currentFilesUrl, setCurrentFilesUrl] = useState(null);

    const handleClosePdfDialog = () => {
        setOpenPdfDialog(false);
    };

    const handleOpenPdfDialog = (contract) => {
        fetchContractFilesUrl(agencyId, userId, contract._id).then((result) => {
            setCurrentFilesUrl(result.filesUrl);
            setOpenPdfDialog(true);
        });
    };

    const handleOpenExportOptionsPopover = (event) => {
        setAnchorEl(event.target);
        setIsExportOptionsPopoverOpen(true);
    };

    const handleCloseExportOptionsPopover = () => {
        setAnchorEl(null);
        setIsExportOptionsPopoverOpen(false);
    };

    const adjustKeysFunction = documents => documents.map(document => {
        const startDate = moment(document.startDate).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR);
        const endDate = document.endDate ? moment(document.endDate).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR) : 'ongoing';

        return {
            _id: document._id,
            title: document.title,
            type: CONTRACT_TYPES_TO_DOCUMENT_TYPES_MAP[document.type],
            employeeName: `${document.user.firstName} ${document.user.lastName}`,
            uploadDate: moment(document.uploadDate).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR),
            contractPeriod: <span>{startDate} - {endDate}</span>,
            documentType: document.documentType,
            documentStatus:
                <div>
                    <p>{CONTRACT_SIGNATURE_STATUSES_TO_DOCUMENT_STATUSES_MAP[document.signatureStatus]}</p>
                    {document.dateSigned && <p className="date-signed">{moment(document.dateSigned).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR)}</p>}
                </div>,
            item: document
        }
    });

    const handleDownloadSingedDocument = documentData => {
        const fileName = getExportFileName([documentData]);

        const queryString = `?documentType=${DOCUMENT_TYPES.CONTRACT}`;

        if (isAccessAsManager) {
            downloadDocumentsFiles(agencyId, [documentData], fileName, userId, queryString);
        } else {
            downloadDocumentsFiles(agencyId, [documentData], fileName, queryString);
        }
    };

    const handleExportSelectedDocuments = () => {
        const documents = checkedRows.map(x => ({...x, userId: x.user._id}));

        const fileName = getExportFileName(documents);

        const queryString = `?documentType=${DOCUMENT_TYPES.CONTRACT}`;

        if (isAccessAsManager) {
            downloadDocumentsFiles(agencyId, documents, fileName, userId, queryString);
        } else {
            downloadDocumentsFiles(agencyId, documents, fileName, queryString);
        }
    };

    const handleExportAllDocuments = () => {
        const query = parseQueryString(history.location.search, true);
        const queryObj = {};

        if (query.filters) {
            const filterObj = {};

            for (const filter of query.filters) {
                const matchedFilter = tableFilters.find(x => x.value.includes(filter));

                if (!filterObj[matchedFilter.key]) {
                    filterObj[matchedFilter.key] = [];
                }

                filterObj[matchedFilter.key].push(filter);
            }

            queryObj.filters = JSON.stringify(filterObj);
        }
        if (query.uploadDate) {
            queryObj.uploadDate = query.uploadDate;
        }
        if (query.signedDate) {
            queryObj.signedDate = query.signedDate;
        }
        if (query.search) {
            queryObj.search = query.search;
        }

        const queryObjAsString = queryObjToString({ ...queryObj, documentType: DOCUMENT_TYPES.CONTRACT });

        const fileName = getExportFileName();

        if (isAccessAsManager) {
            downloadDocumentsFiles(agencyId, [], fileName, userId, queryObjAsString);
        } else {
            downloadDocumentsFiles(agencyId, [], fileName, queryObjAsString);
        }
    };

    const getPopupActions = () => ([
        { title: 'Download Document', handleClick: handleDownloadSingedDocument },
        { title: 'View Document', handleClick: handleOpenPdfDialog },
    ]);

    return (
        <div className="team-documents-table-container">
            <TableWrapper
                title="Documents"
                columns={tableColumns}
                defaultColumns={defaultTableColumns}
                dataSelector={dataSelector}
                adjustKeysFunction={adjustKeysFunction}
                isTableDataLoading={isTableDataLoading}
                withPagination
                addMargin
                pagesCount={Math.ceil(totalCount / DEFAULT_ITEMS_PER_PAGE)}
                pageQuerySelector={PAGE_QUERY_SELECTOR}
                withActions
                withCheckboxes
                checkedRows={checkedRows}
                setCheckedRows={setCheckedRows}
                getActionsHandler={getPopupActions}
                sortQuerySelector={SORT_QUERY_SELECTOR}
            >
                <TableWrapper.TableAlignedItems>
                    <TableWrapper.TableAlignedItems.LeftAligned>
                        <TableWrapper.TableAlignedItems.TableHeader />
                        <TableWrapper.TableAlignedItems.TableFilter filters={tableFilters} />
                        <TableWrapper.TableAlignedItems.TableDateFilter
                            queryParamName={UPLOAD_DATE_QUERY_SELECTOR}
                            format={DATE_PICKER_FORMAT_WITH_DASHES}
                            placeholderText={'Upload Date'}
                            views={["month", "year", "day"]}
                            isRangeCalendar
                        />
                        <TableWrapper.TableAlignedItems.TableDateFilter
                            queryParamName={SIGNED_DATE_QUERY_SELECTOR}
                            format={DATE_PICKER_FORMAT_WITH_DASHES}
                            placeholderText={'Signed Date'}
                            views={["month", "year", "day"]}
                            isRangeCalendar
                        />
                        <TableWrapper.TableAlignedItems.TableSearch queryParamName={SEARCH_QUERY_SELECTOR} />
                    </TableWrapper.TableAlignedItems.LeftAligned>
                    <TableWrapper.TableAlignedItems.RightAligned>
                        <TableWrapper.TableAlignedItems.TableActionButton text="Export" icon={<ExportIcon />} handleClick={handleOpenExportOptionsPopover} />
                    </TableWrapper.TableAlignedItems.RightAligned>
                </TableWrapper.TableAlignedItems>
                <TableWrapper.TableContent />
            </TableWrapper>

            <ModalPdfViewer
                open={openPdfDialog}
                onClose={handleClosePdfDialog}
                filesUrl={currentFilesUrl}
            />

            {isExportOptionsPopoverOpen &&
                <ExportOptionsPopover
                    anchorEl={anchorEl}
                    handleClose={handleCloseExportOptionsPopover}
                    handleExportDocumentsFiles={handleExportAllDocuments}
                    handleExportSelectedDocumentsFiles={handleExportSelectedDocuments}
                />
            }
        </div>
    );
};

export default TeamDocumentsTable;
