import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';
import CandidateApplicationsList from './CandidateApplicationsList';
import CandidateApplicationActions from './CandidateApplicationsActions';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as BriefcaseIcon } from 'assets/briefcase-icon.svg';

import { parseQueryString, updateQuery } from 'utils/helpers';
import { mapQueryToFiltersAppliedCandidates } from 'utils/applicationUtils';

import { DEFAULT_FILTERS } from 'components/JobPage/AppliedCandidates/AppliedCandidatesConstants';
import { DEFAULT_PROFILE_MATCHING_JOB_FILTER_QUERY } from 'constants/candidateConstants';

import './CandidateApplications.scss';

const CandidateApplications = ({
    displayMode,
    candidateId,
    isMissingCvInformation,
    agencyRouteName,
}) => {
    const history = useHistory();

    const [filters, setFilters] = useState(DEFAULT_FILTERS);

    useEffect(() => {
        const queryString = history.location.search;
        const parsedQuery = parseQueryString(queryString);
        setFilters(mapQueryToFiltersAppliedCandidates(parsedQuery, displayMode));
    }, [history.location.search]);

    const selectCandidateStatusTab = (tab) => {
        const updatedQuery = updateQuery(history.location.search, { status: tab });

        history.replace({ pathname: history.location.pathname, search: updatedQuery });
        setFilters(oldState => ({ ...oldState, status: tab }));
    };

    const handleSortChange = (name, item) => {
        const sortObj = {
            [name]: item,
        };

        const updatedQuery = updateQuery(history.location.search, sortObj);
        history.replace({ pathname: history.location.pathname, search: updatedQuery });
    };

    const handleSearchTermChange = (e) => {
        const text = e.target.value;

        const updatedQuery = updateQuery(history.location.search, { searchTerm: text });
        history.replace({ pathname: history.location.pathname, search: updatedQuery });

        setFilters(oldState => ({ ...oldState, searchTerm: text }));
    };

    return (
        <div className="candidate-applications-admin-view-wrapper">
            <SectionActionsWrapper>
                <SectionActionsWrapper.SectionTitle sectionTitle='Applications' />
                <SectionActionsWrapper.RightAlignedActions>
                    <SectionActionsWrapper.RightAlignedActions.PrimaryButton
                        className="find-a-match-button"
                        text="Find a Match"
                        component={Link}
                        target="_blank"
                        to={isMissingCvInformation
                            ? { pathname: `/${agencyRouteName}/candidates/${candidateId}/profile-information`, search: '?scrollIntoUploadCv=true' }
                            : `/${agencyRouteName}/profile/${candidateId}/matching-jobs${DEFAULT_PROFILE_MATCHING_JOB_FILTER_QUERY}`
                        } />
                </SectionActionsWrapper.RightAlignedActions>
            </SectionActionsWrapper>

            <Typography className="candidate-applications-sub-title">
                The candidate has also applied to the following jobs. <BriefcaseIcon />
            </Typography>

            <CandidateApplicationActions
                displayMode={displayMode}
                filters={filters}
                selectCandidateStatusTab={selectCandidateStatusTab}
                handleSortChange={handleSortChange}
                handleSearchTermChange={handleSearchTermChange}
            />

            <CandidateApplicationsList
                candidateId={candidateId}
                selectCandidateStatusTab={selectCandidateStatusTab}
                filters={filters}
                displayMode={displayMode}
            />
        </div>
    )
}

export default CandidateApplications;
