import React from 'react';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import { ErrorMessage } from 'formik';

import '../PersonalInfoContactForm.scss';

const PersonalInfoContactFormView = ({
    values,
    errors,
    touched,
    handleChange,
    handleShowHeaderButtons,
    countries,
}) => {
    return (
        <form className="grid-form-double-row" autoComplete="off">
            <div className="form-field">
                <TextField
                    name="phone"
                    value={values.phone}
                    onChange={(e) => { handleChange(e); handleShowHeaderButtons(); }}
                    id="person-phone"
                    label="Phone number"
                    error={errors.phone && touched.phone}
                    variant="outlined"
                    fullWidth
                />
                <ErrorMessage name="phone" component="div" className="invalid-field-message" />
            </div>

            <div className="form-field">
                <FormControl variant="outlined" fullWidth >
                    <InputLabel id="current-location-label">Current Location</InputLabel>
                    <Select
                        labelId="current-location-label"
                        name="currentLocation"
                        value={values.currentLocation}
                        onChange={(e) => { handleChange(e); handleShowHeaderButtons(); }}
                        id="person-current-location"
                        label="Current Location"
                        error={errors.currentLocation && touched.currentLocation}
                    >
                        {countries.map((country, i) => (
                            <MenuItem native="true" key={i} value={country.name}>
                                {country.name}
                            </MenuItem>
                        ))}
                    </Select>
                    <ErrorMessage name="currentLocation" component="div" className="invalid-field-message" />
                </FormControl>
            </div>

            <div className="form-field">
                <TextField
                    name="address"
                    value={values.address}
                    onChange={(e) => { handleChange(e); handleShowHeaderButtons(); }}
                    id="person-address"
                    label="Address"
                    error={errors.address && touched.address}
                    variant="outlined"
                    fullWidth
                />
                <ErrorMessage name="address" component="div" className="invalid-field-message" />
            </div>

            <div className="form-field">
                <TextField
                    name="linkedIn"
                    value={values.linkedIn}
                    onChange={(e) => { handleChange(e); handleShowHeaderButtons(); }}
                    label="LinkedIn Profile URL"
                    error={errors.linkedIn && touched.linkedIn}
                    variant="outlined"
                    fullWidth
                />
                <ErrorMessage name="linkedIn" component="div" className="invalid-field-message" />
            </div>

            <div className="form-field">
                <TextField
                    name="gitHub"
                    value={values.gitHub}
                    onChange={(e) => { handleChange(e); handleShowHeaderButtons(); }}
                    label="GitHub Profile URL"
                    error={errors.gitHub && touched.gitHub}
                    variant="outlined"
                    fullWidth
                />
                <ErrorMessage name="gitHub" component="div" className="invalid-field-message" />
            </div>

        </form>
    )
};

export default PersonalInfoContactFormView;
