export const tableColumns = [
    { name: 'Employee Name', value: 'name', isSortable: true },
    { name: 'Start Date', value: 'startDate', isSortable: true },
    { name: 'End Date', value: 'endDate', isSortable: true },
    { name: 'Country', value: 'country', isSortable: true },
];

export const defaultTableColumns = ['name', 'startDate', 'endDate', 'country'];

export const PAGE_QUERY_SELECTOR = 'page';
export const SORT_QUERY_SELECTOR = 'sort';

export const TABLE_INITIAL_QUERY = {
    page: 1,
    sortBy: "",
    sortByDir: "",
    pagesToLoad: 5,
    itemsPerPage: 25,
};

export const TABLE_QUERY_MAP = {
    page: PAGE_QUERY_SELECTOR,
    sortBy: SORT_QUERY_SELECTOR,
    sortByDir: `${SORT_QUERY_SELECTOR}Dir`,
};
