import React from 'react';

import { Link } from 'react-router-dom';

import { getStateAgencyName } from 'utils/helpers';

import defaultAvatar from 'assets/dummy-avatar.jpg'
import { Avatar, Tooltip } from '@material-ui/core'

import { TOOLTIPS, FIELDS, DETAILS_HEADER_KEYS, ICONS } from './CandidateDetailsHeaderConstants';
import { ROLES } from 'constants/userConstants';

import './CandidateDetailsHeader.scss'

const CandidateDetailsHeader = ({ candidateDetails, candidatePreferences, profilePictureImgUrl, displayMode }) => {
    const onAvatarLoadError = e => { e.target.value = defaultAvatar; return e.target.value; };

    const handleClientNameClick = () => {
        if (candidateDetails.role === ROLES.CANDIDATE) {
            return `/${getStateAgencyName()}/candidates/${candidateDetails.candidateId}/profile-information`;
        } else if (candidateDetails.role === ROLES.SUPPLIER_EMPLOYEE) {
            return `/${getStateAgencyName()}/partners/${candidateDetails.supplierId}/team/people/${candidateDetails.candidateId}/personal-information`;
        } else if (candidateDetails.role === ROLES.EMPLOYEE || candidateDetails.role === ROLES.ADMIN) {
            return `/${getStateAgencyName()}/team/people/${candidateDetails.candidateId}/personal-information`;
        } else if (candidateDetails.role === ROLES.SUPPLIER_ADMIN) {
            return `/${getStateAgencyName()}/partners/${candidateDetails.supplierId}/team/people/${candidateDetails.supplierId}/personal-information`;
        }
    }

    return (
        <div className="details-header">
            <Avatar
                alt="profile logo"
                className="profile-logo"
                src={profilePictureImgUrl}
                onError={onAvatarLoadError}
            />
            <div className="candidate-preferences-details">
                <div className="name-container">
                    {displayMode === ROLES.ADMIN
                        ?
                        <Link className="candidate-name-link" to={handleClientNameClick()}>
                            <p className="candidate-name">{candidateDetails?.name}</p>
                        </Link>
                        :
                        <p className="candidate-name">{candidateDetails?.name}</p>
                    }
                </div>
                <div className="preferences-data">
                    {Object.values(DETAILS_HEADER_KEYS).map((x, idx) => (
                        <div className="preference-item" key={idx}>
                            {ICONS[x]}
                            <Tooltip classes={{ tooltip: 'custom-tooltip' }} title={TOOLTIPS[x]}>
                                <p className="preference-value">
                                    {candidatePreferences?.[FIELDS[x]] || candidateDetails?.[FIELDS[x]] || ' - '}
                                </p>
                            </Tooltip>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default CandidateDetailsHeader;
